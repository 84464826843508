import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import EventBus from "./EventBus";

export const Editor = ({ callback, value, id, className, isReadonly }) => {
  console.log("sdssfsfds", value, id);
  // const [modules, setModules] = useState({
  //   toolbar: {
  //     container: `#${className}`,
  //     handlers: {
  //       undo: undoChange,
  //       redo: redoChange,
  //     },
  //   },
  //   history: {
  //     delay: 500,
  //     maxStack: 100,
  //     userOnly: true,
  //   },
  // });
  function undoChange() {
    this.quill.history.undo();
  }
  function redoChange() {
    this.quill.history.redo();
  }
  useEffect(() => {
    EventBus.on("EditorBus", (data) => {
      setState({ value: null });
    });

    return () => {
      EventBus.remove("EditorBus");
    };
  }, []);

  const [state, setState] = React.useState({ value: null });
  const handleChange = (value) => {
    setState({ value });
    callback(value);
  };
  return (
    <div className="text-editor">
      <EditorToolbar className={className} />
      <ReactQuill
        id={id}
        theme="snow"
        value={value ? value : state.value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
        readOnly={isReadonly}
      />
    </div>
  );
};

export default Editor;
