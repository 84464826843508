const apis = require("./api.requests");
const FileDownload = require("js-file-download");
const axios = require("axios");

const commonPath = "/common/v1";

async function createEstimate(data) {
  return await apis.hitPostApi(`${commonPath}/estimate`, data);
}
async function getAllEstimates(data) {
  return await apis.hitGetApi(`${commonPath}/estimate/all`);
}
async function estimationDetailsByType(estimateType) {
  return await apis.hitGetApi(
    `${commonPath}/estimateByType?estimateType=${estimateType}`
  );
}

async function updateEstimatebyId(estimateId, data) {
  return await apis.hitPutApi(`${commonPath}/estimate/${estimateId}`, data);
}
async function addEstimationOutlinebyId(estimateId, data) {
  return await apis.hitPostApi(
    `${commonPath}/estimate/outline/${estimateId}`,
    data
  );
}
async function updateEstimationOutlinebyId(estimateId, outlineId, data) {
  return await apis.hitPutApi(
    `${commonPath}/estimate/${estimateId}/outline/${outlineId}`,
    data
  );
}

async function removeEstimationOutlinebyId(estimateId, outlineId) {
  return await apis.hitDeleteApi(
    `${commonPath}/estimate/${estimateId}/outline/${outlineId}`
  );
}
async function createEstimation(data) {
  return await apis.hitPostApi(`${commonPath}/estimation`, data);
}
async function getEstimationById(estimationId) {
  return await apis.hitGetApi(
    `${commonPath}/estimation/${estimationId}/details`
  );
}

async function updateEstimationbyId(estimationId, data) {
  return await apis.hitPutApi(`${commonPath}/estimation/${estimationId}`, data);
}
async function getAllEstimation() {
  return await apis.hitGetApi(`${commonPath}/estimation/all`);
}
async function getEstimationByActionId(estimationId) {
  return await apis.hitGetApi(
    `${commonPath}/estimation/action/${estimationId}`
  );
}

async function getEstimateByType(moduleName) {
  return await apis.hitGetApi(
    `${commonPath}/estimateByType?estimateType=${moduleName}`
  );
}
async function latestEstimation(contactId, leadId, type) {
  return await apis.hitGetApi(
    `${commonPath}/estimation/latest/${contactId}/${leadId}?estimateType=${type}`
  );
}

async function createLeadProposal(data) {
  return await apis.hitPostApi(`${commonPath}/leadproposal`, data);
}

async function getLeadProposalPDFUrlByActionId(actionId) {
  return await apis.hitGetApi(`${commonPath}/leadproposal/action/${actionId}`);
}

export {
  createEstimate,
  getAllEstimates,
  updateEstimatebyId,
  addEstimationOutlinebyId,
  updateEstimationOutlinebyId,
  removeEstimationOutlinebyId,
  createEstimation,
  getEstimationById,
  updateEstimationbyId,
  getEstimationByActionId,
  getAllEstimation,
  getEstimateByType,
  latestEstimation,
  createLeadProposal,
  estimationDetailsByType,
  getLeadProposalPDFUrlByActionId,
};
