import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
// components
import Iconify from "../../components/UI/Iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useMatchCollectionName } from "../../utils/filterObject";
import { pluralize } from "../../utils/convertToPlural";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  // [theme.breakpoints.up("xl")]: {
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: "95%",
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE - 4,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP - 4,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();
  const param = window.location.pathname;
  const pathArray = param.split("/");
  const handleModuleName = (pathArray) => {
    if (pathArray[1] === "Leads") {
      return "Leads".toUpperCase();
    } else if (pathArray[1] === "emailtemplate") {
      return "email template".toUpperCase();
    } else if (pathArray[1] === "collectionreports") {
      return "collection report".toUpperCase();
    } else if (pathArray[1] === "accountsreport") {
      return "accounts report".toUpperCase();
    } else if (pathArray[1] === "salescampaign") {
      return "sales campaign".toUpperCase();
    } else {
      return pathArray[1].toUpperCase();
    }
  };
  let matchedCollection = useMatchCollectionName(pathArray[1]);
  console.log("pathArray[1]", pathArray[1], pathArray, matchedCollection);
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={() => {
            onOpenSidebar();
          }}
          // sx={{ mr: 1, color: "text.primary", display: { xl: "block" } }}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }}>
          {pathArray[2] === "" || pathArray[2] === undefined ? (
            <Typography
              variant="h5"
              gutterBottom="false"
              color="primary"
              sx={{ marginBottom: 0 }}
            >
              {/* {matchedCollection.displayName.plural} */}
              {/* {matchedCollection === null
                ? handleModuleName(pathArray)
                : `${pluralize(
                    matchedCollection?.displayName.singularName.trim()
                  ).toUpperCase()}`} */}
              {matchedCollection === null
                ? handleModuleName(pathArray)
                : `${matchedCollection?.displayName.singularName
                    .trim()
                    .toUpperCase()}`}
            </Typography>
          ) : (
            <Button
              startIcon={<ArrowBackIosIcon />}
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          )}
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
