const apis = require("./api.requests");
const commonPath = "/common/v1";

async function callHippoCredential(data) {
  return await apis.hitPostApi(`${commonPath}/callHippo`, data);
}
async function getCallHippoCredential(data) {
  return await apis.hitGetApi(`${commonPath}/callHippo`);
}

async function updateCallHippoCredential(callHippoCredentialsId, data) {
  return await apis.hitPutApi(
    `${commonPath}/callHippo/${callHippoCredentialsId}`,
    data
  );
}

export {
  callHippoCredential,
  getCallHippoCredential,
  updateCallHippoCredential,
};
