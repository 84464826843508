import React, { useState } from "react";
import Page from "../../components/UI/Page";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/UI/Iconify";
import { UserListHead } from "../../sections/@dashboard/user";
import { useEffect } from "react";
import { getAllCompanies } from "../../services/companies.service";
import Scrollbar from "../../components/UI/Scrollbar";
import { divide, filter } from "lodash";
import SearchNotFound from "../../components/SearchNotFound";
import { useMatchCollectionName } from "../../utils/filterObject";
import SearchCompany from "../../components/SearchDropDown/SearchDropDownCompany";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "website", label: "Website", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
];

export const useDynamicCompanyTitle = () => {
  let matchedCollection = useMatchCollectionName("companies");
  return matchedCollection;
};

function Companies() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [count, setCount] = useState(1);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [column, setColumn] = useState("");
  const [typeText, setTypeText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [companies, setCompanies] = useState([]);

  //sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order === "") setOrder("asc");
    if (order === "asc") setOrder("desc");
    setOrderBy(property);
    if (order === "desc") {
      setOrder("");
      setOrderBy("");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companies.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const filteredCompany = applySortFilter(
    companies,
    getComparator(order, orderBy),
    filterName
  );

  //pagination
  const handleChangePage = (event, newPage) => {
    console.log("Inside handle change page", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > companies.length &&
      companies.length < count
    ) {
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //get all company

  async function fetchCountries() {
    console.log("Type Text", typeText);
    if (!typeText) {
      setIsLoading(true);
      try {
        const response = await getAllCompanies();
        if (response.data.status) {
          setCompanies(response.data.data);
          setCount(response.data.data?.length);
        }
      } catch (error) {
        alert(error);
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchCountries();
    console.log("In use Effect");
  }, [typeText]);
  const companyDynamicTitle = useDynamicCompanyTitle();

  return (
    <Page>
      {console.log("Into Return")}
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <SearchCompany
                    setCount={setCount}
                    setCompanies={setCompanies}
                    companies={companies}
                    column={column}
                    setColumn={setColumn}
                    typeText={typeText}
                    setTypeText={setTypeText}
                    setPage={setPage}
                    setPageCount={setPageCount}
                    setIsLoading={setIsLoading}
                  />

                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => {
                      navigate(`/companies/create`);
                    }}
                    data-testid="add-company-button"
                  >
                    {`Create ${companyDynamicTitle?.displayName.singularName}`}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              variant="outlined"
              sx={{ paddingLeft: 2, paddingRight: 2 }}
            >
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    {isLoading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <>
                        <TableBody>
                          {filteredCompany
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((company, index) => {
                              return (
                                <TableRow
                                  sx={{ cursor: "pointer" }}
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  onClick={() => {
                                    navigate(`/companies/view/${company._id}`);
                                  }}
                                >
                                  <TableCell align="left">
                                    {company?.name}
                                  </TableCell>
                                  <TableCell align="left">
                                    <a
                                      style={{ textDecoration: "none" }}
                                      href={
                                        company?.website?.startsWith("http")
                                          ? company?.website
                                          : `https://${company?.website}`
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      {company?.website}
                                    </a>
                                  </TableCell>
                                  <TableCell align="left">
                                    {company.country}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>

                        {!companies.length ? (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ) : null}
                      </>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Scrollbar>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Companies;
