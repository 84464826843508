import React, { createContext, useState, useEffect } from "react";

//import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../services/users.service";
import Loader from "../Loader";
import { getEntityName, getModules } from "../services/common.service";
import { getSuperAdminDetailsDetailsById } from "../services/superAdmin.service";
//import authorizationService from "../Services/authorization.service";

//import { modules } from "../constants";

const users = {
  admin: "ADMIN",
  sales: "SALES",
};

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // eslint-disable-next-line
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sessionMessageFlag, setSessionMessageFlag] = useState(false);
  const [accessModules, setAccessModules] = useState([]);
  const [fcmToken, setFcmToken] = useState("");
  const [path, setPath] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [entityName, setEntityName] = useState([]);
  const [tempEntityName, setTempEntityName] = useState([]);
  //----Consent Popup----
  const [showMailConsentPopup, setShowMailConsentPopup] = useState(false);
  const [hideCallDialer, setHideCallDialer] = useState(false);

  const toggleShowMailConsentPopup = () => {
    setShowMailConsentPopup(!showMailConsentPopup);
  };
  const getEntityname = () => {
    getEntityName()
      .then((response) => {
        if (response) {
          const entityId = response?.data.data[0]._id;

          let result = response?.data.data.slice(1);
          result = result.map((item, index) => ({
            ...item,
            id: index + 1,
            toEdit: false,
            entityId: entityId,
          }));

          setEntityName(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const localToken = window.localStorage.getItem("AUTH_TOKEN");
    const localUser = JSON.parse(window.localStorage.getItem("AUTH_USER"));
    console.log("localUser: ", localUser);
    if (
      localToken !== null &&
      localToken !== undefined &&
      localToken !== "" &&
      localUser != null
    ) {
      //setIsAuthenticated(true);
      (async () => {
        // console.log("Executing auth useEffect");
        let response;
        try {
          if (localUser.role === "SUPER_ADMIN") {
            response = await getSuperAdminDetailsDetailsById(
              localUser.id,
              localUser.isTenant
            );
          } else {
            response = await getUserById(localUser.id, false);
          }
          console.log(response.data);
          if (response.data.status === true) {
            console.log(response.data.data);
            setCurrentUser((prevState) => ({
              ...prevState,
              ...response.data.data,
              isTenant: localUser.isTenant,
            }));

            setTimeout(() => {
              setIsAuthenticated(true);
            }, 0);
            setIsLoaded(true);
            setSessionMessageFlag(false);
            try {
              const moduleResponse = await getModules();
              if (moduleResponse.data.status) {
                setAccessModules([...moduleResponse.data.data]);
              }
            } catch (error) {
              console.log(error);
            }

            // getEntityname
            if (localUser.role !== "SUPER_ADMIN") {
              getEntityname();
            }
          } else {
            window.localStorage.clear();

            setIsAuthenticated(false);
            setCurrentUser({ id: "", role: "", name: "", phone: "" });
            setIsLoaded(true);
          }
        } catch (error) {
          console.log(error);
          window.localStorage.clear();
          setIsAuthenticated(false);
          setCurrentUser({ id: "", role: "", name: "", phone: "" });
          setIsLoaded(true);
        }
      })();
    } else {
      window.localStorage.clear();
      setIsAuthenticated(false);
      setCurrentUser({ id: "", role: "", name: "", phone: "" });
      setIsLoaded(true);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div>
      {!isLoaded ? (
        <>
          <Loader />
        </>
      ) : (
        <AuthContext.Provider
          value={{
            currentUser,
            isAuthenticated,
            setIsAuthenticated,
            setCurrentUser,
            sessionMessageFlag,
            setSessionMessageFlag,
            accessModules,
            toggleShowMailConsentPopup,
            showMailConsentPopup,
            setShowMailConsentPopup,
            fcmToken,
            setFcmToken,
            path,
            setPath,
            emailPassword,
            setEmailPassword,
            entityName,
            setEntityName,
            getEntityname,
            tempEntityName,
            setTempEntityName,
            hideCallDialer,
            setHideCallDialer,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </div>
  );
};

export default AuthProvider;
