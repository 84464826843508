const apis = require("./api.requests");
const FileDownload = require("js-file-download");
const axios = require("axios");

const commonPath = "/common/v1";
const openPath = "/open";

const TAG = "common.service";

async function getCountries() {
  return await apis.hitGetApi(`${openPath}/countrys`);
}

async function getSources() {
  return await apis.hitGetApi(`${openPath}/sources`);
}

async function getMyActions() {
  //localhost:3022/api/common/v1/myActions?filteredDate=09-06-2022
  return await apis.hitGetApi(`${openPath}/myActions`);
}

async function getMyProject() {
  return await apis.hitGetApi(`/open/myProjects`);
}

async function getModules() {
  return await apis.hitGetApi(`${openPath}/myModules`);
}

async function getActivityLogs(filter, onLoad) {
  return await apis.hitGetApi(
    `${openPath}/activityLogs?module=${filter.module}&event=${
      filter.event
    }&from=${
      onLoad
        ? new Date(new Date().setDate(new Date().getDate() - 1)).getTime()
        : filter.from
    }&to=${new Date().getTime()}`
  );
}

// async function getArchiveDocuments() {
//   return await apis.hitGetApi(`${contactsPath}${leadPath}/trashDoc`);
// }

//---by AbhisekM

async function getArchiveDocuments(from, to) {
  return await apis.hitGetApi(`${commonPath}/trashDoc?from=${from}&to=${to}`);
}

async function restoreDocument(documentId, body) {
  return await apis.hitPutApi(`${commonPath}/archiveDoc/${documentId}`, body);
}

async function download(url, fileName) {
  axios({
    url,
    method: "GET",
    responseType: "blob",
    headers: {
      authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
    }, // Important
  }).then((response) => {
    console.log("response.data, fileName", response.data, fileName);
    FileDownload(response.data, fileName);
  });
}

async function sendMail(mail, files, emailPassword) {
  console.log(mail);
  let formData = new FormData();
  Object.keys(mail).map((key) => {
    formData.append(key, mail[key]);
  });
  files.map((file) => {
    formData.append("attachment[]", file);
  });
  formData.append("emailPassword", emailPassword);
  return await apis.hitPostApi(`${openPath}/emails`, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}
async function getAllMyMails(email, password) {
  console.log(`${TAG}.getAllMyMails`, password);
  return await apis.hitGetApi(
    `${commonPath}/emails?email=${email}&password=${password.replace(
      "+",
      "%2B"
    )}`
  );
}

async function fecthModuleAndEvents() {
  return await apis.hitGetApi(`${commonPath}/emailTemplateKeys`);
}

// *Email Template

async function createEmailTemplet(body) {
  return await apis.hitPostApi(`${commonPath}/emailTemplate`, body);
}

async function getEmailTemplet() {
  return await apis.hitGetApi(`${commonPath}/emailTemplate`);
}

async function getEmailTempletByModuleName(moduleName) {
  return await apis.hitGetApi(
    `${commonPath}/emailTemplateByModuleName?moduleName=${moduleName}`
  );
}

async function deleteEmailTemplate(_id) {
  return await apis.hitDeleteApi(`${commonPath}/emailTemplate/${_id}`);
}

async function updateEmailTemplate(payload) {
  const { _id } = payload;
  delete payload._id;
  return await apis.hitPutApi(`${commonPath}/emailTemplate/${_id}`, payload);
}

// *Email Template end

async function getEntityName() {
  return await apis.hitGetApi(`${commonPath}/entityNameConfiguration`);
}

async function updateEntityName(entityId, body) {
  return await apis.hitPutApi(
    `${commonPath}/entityNameConfiguration/${entityId}`,
    body
  );
}

async function getCurrencies() {
  return await apis.hitGetApi(`/open/currency`);
}

async function getActionNames() {
  return await apis.hitGetApi(`/actionname/v1/`);
}

async function createDynamicActionName(data) {
  return await apis.hitPostApi(`/actionname/v1/create`, data);
}

async function updateDynamicActionName(_id, _data) {
  return await apis.hitPutApi(`/actionname/v1/update/${_id}`, _data);
}

async function deleteActionNamePermanently(_id) {
  return await apis.hitDeleteApi(`/actionname/v1/delete/${_id}`);
}

async function getAllSkills(status = "ALL") {
  return await apis.hitGetApi(`/leads/v1/skills?status=${status}`);
}

async function skillApprovance(skillId, data) {
  return await apis.hitPutApi(
    `/leads/v1/skill/changeApproval/${skillId}`,
    data
  );
}

async function deleteSkill(skillId) {
  return await apis.hitDeleteApi(`/leads/v1/delete/skill/${skillId}`);
}

export {
  getCountries,
  getMyActions,
  download,
  getMyProject,
  getModules,
  sendMail,
  getActivityLogs,
  getArchiveDocuments,
  restoreDocument,
  getSources,
  getAllMyMails,
  fecthModuleAndEvents,
  createEmailTemplet,
  getEmailTemplet,
  getEmailTempletByModuleName,
  getEntityName,
  updateEntityName,
  getCurrencies,
  getActionNames,
  createDynamicActionName,
  updateDynamicActionName,
  deleteActionNamePermanently,
  deleteEmailTemplate,
  updateEmailTemplate,
  getAllSkills,
  skillApprovance,
  deleteSkill,
};
