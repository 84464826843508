const apis = require("./api.requests");

const TAG = "auth.service";

async function signIn(body) {
  console.log("Body data", body);
  // debugger;
  return await apis.hitAuthLessPostApi("/signIn", body);
}

async function forgotPassword(body) {
  return await apis.hitAuthLessPostApi("/forgotPassword", body);
}

export { signIn, forgotPassword };
