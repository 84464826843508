import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { Link, TextField, IconButton, InputAdornment } from "@mui/material";
// component
import Iconify from "../../../components/UI/Iconify";
import { signIn } from "../../../services/auth.service";
import { AuthContext } from "../../../context/auth.context";
import { ToastContainer, toast } from "react-toastify";
import { login, loginAllTabs } from "../../../auth/auth";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const {
    currentUser,
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUser,
    fcmToken,
    path,
  } = useContext(AuthContext);

  const [loginCreds, setLoginCreds] = useState({ email: "", password: "" });
  let loginCredsWithFcm;

  function handleChange1(evt) {
    let value = "";
    if (evt.target.name === "password") {
      value = evt.target.value;
    }
    setLoginCreds({
      ...loginCreds,
      [evt.target.name]: value,
    });
  }
  function handleChange(evt) {
    let value = "";
    if (evt.target.name === "email") {
      value = evt.target.value.toLowerCase();
    }

    setLoginCreds({
      ...loginCreds,
      [evt.target.name]: value,
    });
  }

  console.log("initialData: ", loginCreds);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    // validationSchema: LoginSchema,
    onSubmit: async () => {
      console.log("login.submit: ", loginCreds);
      // setLoginCreds((prev) => ({
      //   ...prev, fcm_token: fcmToken
      // }))
      // await createDataInIndexedDb();
      try {
        if (fcmToken) {
          loginCredsWithFcm = loginCreds;
          loginCredsWithFcm.fcm_token = fcmToken;
        }
        const response = await signIn(
          fcmToken ? loginCredsWithFcm : loginCreds
        );
        console.log("response", response);
        if (response.data.status) {
          const {
            _id: id,
            token,
            role,
            name,
            isTenant = true,
          } = response.data.data;
          console.log("details", id, token, role, name, isTenant);
          login(token, name, id, role, isTenant);
          // localStorage.setItem("AUTH_TOKEN", token);
          // localStorage.setItem("AUTH_USER", JSON.stringify({ id, role, name }));
          //todo: Send to the googleAuth token to the backend

          setCurrentUser({ _id: id, role, name, isTenant });
          setTimeout(() => {
            setIsAuthenticated(true);
            console.log("Login path", path.split("/")[1] === "changePassword");
            if (path.split("/")[1] === "changePassword") {
              navigate("/dashboard");
            } else if (path.split("/")[1] !== "dashboard") {
              navigate(path);
            } else {
              navigate("/dashboard");
            }
          }, 1000);
          toast("User logged in successfully");
        } else {
          console.log(response.data);
          if (
            response.data.message ===
            "You're blocked! Please contact to the admin."
          ) {
            toast("You're blocked! Please contact to the admin");
          } else if (
            response.data.message ===
            "You're blocked! Please contact the super admin."
          ) {
            toast("You're blocked! Please contact to the super admin");
          } else {
            toast("Username or password is invalid");
          }
        }
      } catch (error) {
        console.log("login.error:", error);
        if (
          error.response.data.data[0] ===
          "You're blocked! Please contact to the admin."
        ) {
          toast("You're blocked! Please contact to the admin");
        } else if (
          error.response.data.data[0] ===
          "You're blocked! Please contact the super admin."
        ) {
          toast("You're blocked! Please contact to the super admin");
        } else {
          toast("Username or password is invalid");
        }
        // toast(error.response.data.data[0]);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  useEffect(() => {
    loginAllTabs();
  }, []);
  return (
    <>
      <ToastContainer />
      <FormikProvider value={formik}>
        <Card sx={{ backgroundColor: "#03203C" }}>
          <Typography
            variant="h3"
            align="center"
            marginTop="20px"
            color="white"
          >
            Login
          </Typography>
          <CardContent>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    variant="filled"
                    autoComplete="username"
                    type="email"
                    label="Email address"
                    name="email"
                    // inputProps={{ style: { textTransform: "uppercase" } }}
                    sx={{
                      input: {
                        color: "black",
                        background: "white",
                        borderRadius: "10px",
                      },
                    }}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    name="password"
                    onChange={handleChange1}
                    sx={{
                      input: {
                        color: "black",
                        background: "white",
                        borderRadius: "10px",
                        borderBottom: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ borderRadius: "10px" }}
                        >
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}> */}
                {/* <FormControlLabel
                    control={
                      <Checkbox
                        {...getFieldProps("remember")}
                        checked={values.remember}
                      />
                    }
                    label="Remember me"
                    sx={{ color: "white" }}
                  /> */}
                {/* </Grid> */}
                <Grid
                  item
                  // sx={{ display: "flex", flexDirection: "row-reverse" }}
                  xs={6}
                  sm={6}
                >
                  <Link
                    component={RouterLink}
                    color="white"
                    variant="subtitle2"
                    to="/forgotPassword"
                    underline="hover"
                    float="right"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid
                  item
                  sx={{ display: "flex", flexDirection: "row-reverse" }}
                  xs={6}
                  sm={6}
                >
                  {/* <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Login
                </LoadingButton> */}
                  <Button size="large" type="submit" variant="contained">
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </FormikProvider>
    </>
  );
}
