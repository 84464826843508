import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AuthContext } from "../../context/auth.context";

//------ICONS_______
import CancelIcon from "@mui/icons-material/Cancel";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// import AspectRatioIcon from '@mui/icons-material/AspectRatio';

//-----Body_________
import {
  Box,
  Card,
  CardContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { sendMail } from "../../services/common.service";
import CircularProgress from "@mui/material/CircularProgress";

//--------Toast-----
import { ToastContainer, toast } from "react-toastify";

//------Editor compose mail------
import Editor from "../texteditor/Editor";
import FileList from "../Fileupload/FileList/FileList";
import { getPassword } from "../../utils/indexedDb";

let hitApi = true;

export default function FormDialog({
  open,
  setOpen,
  setCurrentButton,
  isMailSent,
  setIsMailSent,
  mailTo,
  setContextMailClick,
  leadId,
  FetchLeads,
}) {
  console.log(leadId);
  const handleClose = () => {
    setFiles([]);
    setOpen(false);
    if (setCurrentButton) {
      setCurrentButton("AllMails");
    }
  };

  const { currentUser, emailPassword } = React.useContext(AuthContext);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [fromEmailDialog, setFromEmailDialog] = React.useState(true);

  const [mail, setMail] = React.useState({
    to: "",
    ccBcc: "",
    subject: "",
    html: "",
    leadId: leadId,
  });

  const [files, setFiles] = React.useState([]);
  console.log("Nov 8", files);

  const handleChange = (e) => {
    setMail((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      leadId: leadId,
    }));
  };

  const sentStatus = (isMailSent) => {
    if (isMailSent) {
      toast("Mail sent Sucessfully");
    } else {
      toast("Something went wrong");
    }
  };

  const validateEmail = (mail) => {
    console.log(mail);
    if (mail.length > 0) {
      const mailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      console.log(mailPattern.test(mail));
      if (mailPattern.test(mail)) {
        console.log(2);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const validateCc = (cc) => {
    if (cc.length > 0) {
      if (cc.includes(",")) {
        const mails = cc.split(",");
        var rtnValue = true;
        mails.forEach((element) => {
          if (!validateEmail(element)) {
            rtnValue = false;
            return false;
          }
        });
        return rtnValue;
      } else {
        return validateEmail(cc);
      }
    } else {
      return false;
    }
  };

  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const handleSend = async () => {
    const getpassword = await getPassword();
    hitApi = true;
    // if (!mail.to || !validate(mail.to)) {
    //     toast("To can not be empty");
    //     hitApi = false;
    // }
    if (!validate(mail.to) || !validateEmail(mail.to)) {
      toast("Enter a valid email for To:");
      hitApi = false;
    }
    // if (!validateCc(mail.ccBcc)) {
    //     toast("Cc can not be empty");
    //     hitApi = false;
    // }
    console.log(mail.ccBcc);
    console.log(validateCc(mail.ccBcc));
    if (mail.ccBcc.length > 0 && !validateCc(mail.ccBcc)) {
      toast("Enter valid email(s) for CC:");
      hitApi = false;
    }
    if (!validate(mail.subject)) {
      toast("Subject can not be empty");
      hitApi = false;
    }
    if (!validate(mail.html)) {
      toast("Deatils can not be empty");
      hitApi = false;
    }
    // if (validate(mail.to) && validateEmail(mail.to)
    //     && validate(mail.subject) && validate(mail.html)) {
    //     hitApi = true;
    // } else {
    //     hitApi = false;
    // }
    try {
      console.log("Hit API", hitApi);
      if (!!hitApi) {
        setLoaderDialog(true);
      }
      const response = hitApi ? await sendMail(mail, files, getpassword) : null;
      if (response.data.status) {
        sentStatus(true);
        setLoaderDialog(false);
        handleClose();
        setFiles([]);
        const temp = { ...mail };
        temp.to = "";
        setMail(temp);
        // setCurrentButton("AllMails");
        if (setContextMailClick) {
          setContextMailClick(false);
        }
        FetchLeads();
      } else {
        sentStatus(false);
        setLoaderDialog(false);
        setFiles([]);
        const temp = { ...mail };
        temp.to = "";
        setMail(temp);
      }
    } catch (error) {
      setLoaderDialog(false);
      // sentStatus(false);
      // handleClose();
      // setFiles([]);
      // const temp = { ...mail }
      // temp.to = ""
      // setMail(temp);
    }
  };

  //---attach document

  const uploadHandler = (event) => {
    const file = Array.from(event.target.files);

    console.log("File", file);

    setFiles((prev) => {
      return [...prev, ...file];
    });
  };

  const removeFile = (index) => {
    setFiles((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  React.useEffect(() => {
    if (open)
      setMail({
        to: mailTo,
        ccBcc: "",
        subject: "",
        html: "",
      });
  }, [open]);

  return (
    <Card>
      <Dialog open={open} onClose={handleClose}>
        <Card sx={{ backgroundColor: "#f1f2f6" }}>
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h5" color="primary">
                  COMPOSE MAIL
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <IconButton>
                  <Tooltip title="Click to close">
                    <CancelIcon onClick={handleClose} />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="From:"
                type="text"
                fullWidth
                variant="outlined"
                value={currentUser.email}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="To:"
                type="email"
                fullWidth
                variant="outlined"
                value={mail.to}
                name="to"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Cc:"
                type="email"
                fullWidth
                variant="outlined"
                value={mail.ccBcc}
                name="ccBcc"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                margin="dense"
                label="Subject:"
                type="text"
                fullWidth
                variant="outlined"
                value={mail.subject}
                name="subject"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1} sx={{ marginTop: "17px" }}>
              <IconButton
                variant="outlined"
                component="label"
                sx={{ "&:hover": { color: "#2065D1" } }}
              >
                <input
                  hidden
                  accept="*/*"
                  multiple
                  type="file"
                  onChange={uploadHandler}
                />
                <Tooltip title="Click to upload files">
                  <AttachFileIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <FileList
                files={files}
                removeFile={removeFile}
                fromEmailDialog={fromEmailDialog}
              />
            </Grid>
            <Grid item xs={12}>
              <Editor
                style={{
                  width: "100%",
                  fontSize: "24px",
                  border: "1px solid",
                  borderRadius: 10,
                }}
                name="html"
                value={mail.html}
                callback={(det) => {
                  setMail((prev) => ({
                    ...prev,
                    html: det,
                  }));
                }}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            CANCEL
          </Button>
          <Button onClick={handleSend}>SEND</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
    </Card>
  );
}
