import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as React from "react";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";
import { setContactAction } from "../../services/contacts.service";
import { setLeadAction } from "../../services/leads.service";
import constants from "../../constants";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  removeResource,
  setProjectAction,
  updateTeam,
} from "../../services/projects.service";
import { getUsersByRoles } from "../../services/users.service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { PhotoCamera } from "@material-ui/icons";
import SaveIcon from "@mui/icons-material/Save";

function Teams({
  userId,
  assignedRole,
  contribution,
  actionType,
  actionId,
  id,
  leadId,
  projectId,
  setLoadedActions,
  setPageCount,
  FetchActions,
  from,
  assignedBy,
  contactName,
  leadSummary,
  doerId,
  row,
  teamSingle,
  setTeamSingle,
  setIsProjectFetched,
  setResource,
  project,
  currentUser,
  setIactionedApiCalled,
}) {
  const navigate = useNavigate();
  // const handleRemove = props.handleRemove;
  // const action = props.action;
  // const date = props.date;
  // const handleSubmit = (targetKey, targetType) => {
  //   setMyAction(targetKey, targetType)
  //     .then((response) => {
  //       console.log(response);
  //       handleRemove(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // console.log(
  //   "isDone",
  //   isDone,
  //   "doer",
  //   doer,
  //   "doneBy",
  //   doneBy,
  //   "action type",
  //   actionType,
  //   "from",
  //   from
  // );

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    console.log("date", date);
    const dateString = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
    if (
      new Date(new Date().setHours(0, 0, 0, 0)).getHours() === date.getHours()
    ) {
      return dateString;
    } else {
      return (
        dateString +
        "  at  " +
        date.toLocaleTimeString("en-IN", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }
  }

  const [open, setOpen] = React.useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });

  const [isTeamFetched, setIsTeamFetched] = React.useState(true);

  const [isEditClicked, setIsEditClicked] = React.useState(false);

  const [singleResource, setSingleResource] = React.useState({
    userId: "",
    assignedRole: "",
    contribution: "",
    _id: "",
    user: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeletePopupOpen(false);
  };

  const [usersTL, setUsersTL] = React.useState([]);

  const formatDateWithDueTime = (date) => {
    console.log("date", date);
    const dateString = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
    return dateString;
  };

  const markAsDone = async () => {
    console.log("action type", actionType);
    let response;
    let authUserId = JSON.parse(localStorage.getItem("AUTH_USER"));
    console.log("authUser Id", authUserId.id, doerId);
    if (authUserId.id === doerId) {
      try {
        const date = {
          doneOn: new Date(),
        };
        if (actionType === constants.LEADS) {
          response = await setLeadAction(id, leadId, actionId, date);
          console.log(response);
        }
        if (actionType === constants.PROJECTS) {
          response = await setProjectAction(projectId, actionId, date);
          console.log(response);
        }
        if (actionType === constants.CONTACTS) {
          response = await setContactAction(id, actionId, date);
          console.log(response);
        }
        if (response && response.data.status) {
          if (from !== constants.DASHBOARD) {
            setLoadedActions([]);
            setPageCount(1);
          }
          FetchActions();
          toast("Action updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const style =
    from === constants.DASHBOARD
      ? { fontWeight: "bold", paddingLeft: "20px" }
      : { fontWeight: "bold" };
  const handleClick = (id, leadId) => {
    actionType === constants.CONTACTS
      ? navigate(`/contacts/${id}`, { replace: true })
      : navigate(`/contacts/lead/${id}/${leadId}`, { replace: true });
  };

  const timing = [
    { name: "Full Time", value: "FULL_TIME" },
    { name: "Part Time", value: "PART_TIME" },
  ];

  const roles = [
    { name: "Project Manager", value: "PM" },
    { name: "Team Lead", value: "TL" },
    { name: "Developer", value: "DEV" },
    { name: "Quality Assurance", value: "QA" },
  ];

  React.useEffect(() => {
    handleUser();
    fetchUsersTL();
  }, []);

  const fetchUsersTL = async () => {
    try {
      const response = await getUsersByRoles(4);
      if (response.data.status) {
        setUsersTL(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!isTeamFetched) {
      setResource([]);
      setIsProjectFetched(false);
      console.log("use effect triggered");
      setIsTeamFetched(true);
      handleClose();
    }
  }, [isTeamFetched]);

  const removeResourceSingle = async (id) => {
    console.log("Resource id", id);
    handleDeletePopupOpen(id);
  };

  const handleDeletePopupOpen = (id) => {
    setDeletePopupOpen({
      state: true,
      onSuccess: async () => {
        console.log("id", id);
        try {
          const response = await removeResource(projectId, id);

          if (response.data.status === true) {
            toast("Resource removed successfully");
            // handleClose();
            setIsTeamFetched(false);
            setIactionedApiCalled(true);
            // setUsers([]);
            // setPageCount(1);
            // FetchUsers();
            // console.log(users);
          }
        } catch (error) {
          console.log(error);
        }
      },
    });
    return id;
  };

  const updateResource = async (id) => {
    console.log(id);
    const temp = { ...singleResource };
    delete temp.userId;
    delete temp.user;
    let teamId = temp._id;
    delete temp._id;
    temp["teamId"] = teamId;
    try {
      const response = await updateTeam(projectId, temp);
      if (response.data.status) {
        setIsEditClicked(!isEditClicked);
        toast("Resource successfully updated");
        setIactionedApiCalled(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setSingleResource((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUser = () => {
    let temp = { ...row };
    setSingleResource(temp);
  };

  console.log("row", row, "teamSingle", teamSingle);
  return (
    <>
      {/* <ToastContainer /> */}
      <Grid container spacing={1}>
        <Grid item xs={3.5}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-userId-select-label">
                Resource Name
              </InputLabel>
              <Select
                // disabled={toEdit}
                inputProps={{ readOnly: true }}
                // inputProps={isEditPage ? { readOnly: true } : inputProps}
                labelId="demo-userId-select-label"
                id="demo-userId-select"
                // value={contact.data.country}
                label="Resource Name"
                name="userId"
                value={singleResource.userId}
                // onChange={}
              >
                {usersTL.length > 0
                  ? usersTL.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user._id}>
                          {user.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-assignedRole-select-label">
                Resource Role
              </InputLabel>
              <Select
                // disabled={toEdit}
                inputProps={{ readOnly: !isEditClicked }}
                // inputProps={
                //   isEditPage ? { readOnly: inputProps } : inputProps
                // }
                labelId="demo-assignedRole-select-label"
                id="demo-assignedRole-select"
                // value={contact.data.country}
                label="Resource Role"
                name="assignedRole"
                value={singleResource.assignedRole}
                onChange={handleChange}
              >
                {roles.length > 0
                  ? roles.map((user, index) => {
                      return (
                        <MenuItem key={index} value={user.value}>
                          {user.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-contribution-select-label">
                Resource Time
              </InputLabel>
              <Select
                // disabled={toEdit}
                inputProps={{ readOnly: !isEditClicked }}
                // inputProps={
                //   isEditPage ? { readOnly: inputProps } : inputProps
                // }
                labelId="demo-contribution-select-label"
                id="demo-contribution-select"
                // value={contact.data.country}
                label="Resource Contribution"
                name="contribution"
                value={singleResource.contribution}
                onChange={handleChange}
              >
                {timing.length > 0
                  ? timing.map((time, index) => {
                      return (
                        <MenuItem key={index} value={time.value}>
                          {time.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {currentUser.role === "ADMIN" ||
        currentUser._id === project.techOwner ? (
          <>
            {" "}
            <Grid item xs={6} sm={0.75}>
              <>
                {/* <IconButton
              color="primary"
              aria-label="Delete"
              component="label"
              onClick={() => {
                removeResource(row._id);
              }}
            >
              <DeleteForeverIcon />
            </IconButton> */}

                {!isEditClicked ? (
                  <IconButton
                    color="primary"
                    aria-label="Edit"
                    component="label"
                    onClick={() => {
                      setIsEditClicked(!isEditClicked);
                      // updateResource(singleResource._id);
                    }}
                  >
                    <ModeEditIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="Save"
                    component="label"
                    onClick={() => {
                      updateResource(singleResource._id);
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                )}
              </>
            </Grid>
            <Grid item xs={6} sm={0.75}>
              <>
                <IconButton
                  color="primary"
                  aria-label="Delete"
                  component="label"
                  onClick={() => {
                    // handleClickOpen();
                    console.log("Inside delete click");
                    removeResourceSingle(singleResource._id);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>

                {/* <IconButton
              color="primary"
              aria-label="Edit"
              component="label"
              onClick={() => {
                updateResource(row._id);
              }}
            >
              <ModeEditIcon />
            </IconButton> */}
              </>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
              Edit Popup
            </Button> */}
            <Dialog open={deletePopupOpen.state} onClose={handleClose}>
              <DialogTitle>Delete Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you wish to delete this resource?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={deletePopupOpen.onSuccess}>Agree</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Teams;
