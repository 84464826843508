import { Card, Stack, Typography } from "@mui/material";
import React from "react";

export default function StatsCard({ label, value, color }) {
  return (
    <Card
      elevation={5}
      sx={{
        borderRadius: 1,
        boxShadow: 3,
        paddingTop: 0,
        paddingLeft: -10,
        paddingRight: -10,
        paddingBottom: 1,
      }}
    >
      <Stack direction="column" alignItems="center" spacing="-8px">
        <Typography
          sx={{ fontWeight: "bold", fontSize: "30px", color: { color } }}
        >
          {value}
        </Typography>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "11px", color: { color } }}
        >
          {label}
        </Typography>
      </Stack>
    </Card>
  );
}
