import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

//-----------------------------
import Page from "../components/UI/Page";
import { createStyles, makeStyles } from "@material-ui/core";
import { Container } from "@mui/system";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// import { makeStyles } from "@mui/styles";
//Child Components
import ListMail from "../components/Emails/ListMail";
import EmailBody from "../components/Emails/EmailBody";
import EmailDialog from "../components/Dialogs/EmailDialog";
import { AuthContext } from "../context/auth.context";
import { MailSyncContext } from "../context/mailSync.context";
import {
  createDataInIndexedDb,
  deleteDataInIndexedDb,
  getMailsByEmail,
  getMyMails,
  getPassword,
  insertDataInIndexedDb,
} from "../utils/indexedDb";
import Scrollbar from "../components/UI/Scrollbar";
import { getAllMyMails } from "../services/common.service";
import { isBase64KeyInPassword } from "../utils/isBase64KeyInPassword";
import { encryption } from "../utils/encryption";
import SyncIcon from "@mui/icons-material/Sync";

export default function Emails({ result }) {
  const {
    currentUser,
    setCurrentUser,
    toggleShowMailConsentPopup,
    showMailConsentPopup,
    emailPassword,
    setEmailPassword,
  } = React.useContext(AuthContext);

  const {
    handlegetAllContactEmail,
    mailSync,
    setMailSync,
    openPasswordAlert,
    setOpenPasswordAlert,
  } = React.useContext(MailSyncContext);
  const [emails, setEmails] = useState([]);

  const top100Films = [
    {
      label: "The Shawshank Redemption",
      year: 1994,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Godfather",
      year: 1972,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Godfather: Part II",
      year: 1974,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Dark Knight",
      year: 2008,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "12 Angry Men",
      year: 1957,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Schindler's List",
      year: 1993,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Pulp Fiction",
      year: 1994,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Lord of the Rings: The Return of the King",
      year: 2003,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Good, the Bad and the Ugly",
      year: 1966,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Fight Club",
      year: 1999,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Lord of the Rings: The Fellowship of the Ring",
      year: 2001,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Casablanca",
      year: 1942,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "City Lights",
      year: 1931,
      Sub: "Action",
      About: "A True Based Story",
    },
    { label: "Psycho", year: 1960, Sub: "Action", About: "A True Based Story" },
    {
      label: "The Green Mile",
      year: 1999,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Intouchables",
      year: 2011,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Modern Times",
      year: 1936,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Raiders of the Lost Ark",
      year: 1981,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Rear Window",
      year: 1954,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Pianist",
      year: 2002,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "The Departed",
      year: 2006,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Terminator 2: Judgment Day",
      year: 1991,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Back to the Future",
      year: 1985,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Whiplash",
      year: 2014,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Gladiator",
      year: 2000,
      Sub: "Action",
      About: "A True Based Story",
    },
    {
      label: "Memento",
      year: 2000,
      Sub: "Action",
      About: "A True Based Story",
    },
  ];

  const idb =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;

  const [currentButton, setCurrentButton] = useState("AllMails");
  const [index, setIndex] = useState(0);
  const [userPassword, setUserPassword] = useState("");
  const [open, setOpen] = React.useState(false);

  const [isMailSent, setIsMailSent] = React.useState(false);
  // const [mailSync, setMailSync] = useState(false);
  // const [openPasswordAlert, setOpenPasswordAlert] = useState(false);
  const [isIndexDb, setIsIndexDb] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleButtonClick = (e) => {
    const { name } = e.target;
    setCurrentButton(name);
    if (name === "Compose") {
      if (emailPassword == "") {
        handleSetPasswordAgree();
      } else {
        handleClickOpen();
      }
    }
  };

  //-------Popup if the email_password is not set---
  const [agreeSetPasswordDialog, setAgreeSetPasswordDialog] =
    React.useState(false);

  const handleSetPasswordAgree = () => {
    setAgreeSetPasswordDialog(true);
  };

  const handleClose = () => {
    setAgreeSetPasswordDialog(false);
  };

  const handlePasswordAgree = () => {
    handleClose();
    toggleShowMailConsentPopup();
  };
  //indexdb is created or not

  // handlepassword dialog close
  const handlePasswordClose = (event) => {
    setOpenPasswordAlert(false);
    setMailSync(false);
  };
  // handelchangePassword email password
  const handleOnchagePassword = (event) => {
    setUserPassword(event.target.value);
  };
  // handel add email password
  const handleAddPassword = async () => {
    if (!userPassword) {
      // setMailSync(false);
      return toast("Empty password field!");
    }
    const encryptPassword = encryption(userPassword);
    createDataInIndexedDb();
    insertDataInIndexedDb(null, encryptPassword);
    setOpenPasswordAlert(false);
    const getpass = await getPassword();
    console.log("handleAddPassword getpass", getpass);
    const isBase64Key = isBase64KeyInPassword(getpass);
    // console.log("isBase64Key", isBase64Key);
    setMailSync(true);
    const response = await getAllMyMails(currentUser.email, isBase64Key);
    if (response.data.status) {
      setMailSync(false);
      console.log("response.data", response.data);
      const emailsArr = await getMyMails();
      console.log("emailsArr", emailsArr);
      if (emailsArr.length > 0) {
        console.log("email append");
        const lastIndex = emailsArr.length - 1;
        const insertDataResponse = await insertDataInIndexedDb(
          response.data.data
        );

        if (insertDataResponse == "success") {
          const response = await getMailsByEmail();
          setEmails((prev) => [...prev, ...response]);
        }
        console.log("handleAddPassword if", insertDataResponse);
        setMailSync(false);
        setIsIndexDb(true);
        toast("Email Sync Successfully");
      } else if (response.data.message === "Nothing to fetch") {
        setMailSync(false);
        toast("Email Not Found");
      } else {
        const insertDataResponse = await insertDataInIndexedDb(
          response.data.data
        );

        if (insertDataResponse == "success") {
          const response = await getMailsByEmail();
          setEmails(response);
        }
        console.log("handleAddPassword else", insertDataResponse);
        setMailSync(false);
        setIsIndexDb(true);
        toast("Email Sync Successfully");
      }
    } else {
      setMailSync(false);
      toast("Authentication Failed");
      await deleteDataInIndexedDb();
    }
  };

  //check if email is presnt in IndexedDb

  // const handlegetAllContactEmail = async () => {
  //   console.log("createindexdb");

  //   try {
  //     const getpass = await getPassword();
  //     console.log("getpass", getpass);
  //     const isBase64Key = isBase64KeyInPassword(getpass);
  //     // console.log("isBase64Key", isBase64Key);
  //     setMailSync(true);
  //     const response = await getAllMyMails(currentUser.email, isBase64Key);

  //     console.log("Response315", response);
  //     if (response.data.message === "Aunthetication failed") {
  //       console.log("Authentication Failed");
  //       setMailSync(false);
  //       toast("Authentication Failed");
  //       return;
  //     }
  //     if (response.data.status) {
  //       console.log("response.data", response.data);
  //       const emailsArr = await getMyMails();
  //       if (emailsArr.length > 0) {
  //         console.log("email append if");
  //         const lastIndex = emailsArr.length - 1;
  //         const insertDataResponse = await insertDataInIndexedDb(
  //           response.data.data
  //         );
  //         if (insertDataResponse == "success") {
  //           const response = await getMailsByEmail();
  //           setEmails((prev) => [...prev, ...response]);
  //         }
  //         console.log("email append if", insertDataResponse);
  //         setMailSync(false);
  //         setIsIndexDb(true);
  //         toast("Email Sync Sucessfuly");
  //       } else {
  //         const insertDataResponse = await insertDataInIndexedDb(
  //           response.data.data
  //         );
  //         console.log("email append else", insertDataResponse);
  //         if (insertDataResponse == "success") {
  //           const response = await getMailsByEmail();
  //           setEmails((prev) => [...prev, ...response]);
  //         }
  //         setIsIndexDb(true);
  //         setMailSync(false);
  //         toast("Email Sync Sucessfuly");
  //       }
  //     }
  //   } catch (err) {
  //     setOpenPasswordAlert(true);
  //     console.log("err");
  //   }
  // };

  const useStyles = makeStyles(() =>
    createStyles({
      rotateIcon: {
        animation: "$spin 2s linear infinite",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(360deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
    })
  );

  let styleForSyncIcon = useStyles();

  async function indexDbInfo() {
    await createDataInIndexedDb();
    const response = await getMailsByEmail();
    setEmails(response);
  }

  useEffect(() => {
    console.log("Email Password", emailPassword);
    indexDbInfo();
  }, [emailPassword]);

  const indexDbpassword = async () => {
    const getpass = await getPassword();
    if (getpass !== undefined && getpass !== null && getpass !== "") {
      setEmailPassword(getpass);
      console.log("UseEffect triggered");
    }
  };

  // getpassword from IndexedDB
  useEffect(() => {
    indexDbpassword();
  }, [mailSync]);

  useEffect(() => {
    indexDbInfo();
    if (isMailSent) {
      toast("Mail sent successfully");
      setIsMailSent(false);
    }
    if (emailPassword !== "") {
      if (currentButton === "Compose") {
        handleClickOpen();
      }
    }
  }, [mailSync]);

  return (
    <Page>
      <Container>
        <ToastContainer />
        <EmailDialog
          open={open}
          setOpen={setOpen}
          setCurrentButton={setCurrentButton}
          isMailSent={isMailSent}
          setIsMailSent={setIsMailSent}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={5} variant="outlined">
              <CardContent>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Button
                      variant={
                        currentButton === "AllMails" ? "contained" : "outlined"
                      }
                      onClick={handleButtonClick}
                      name="AllMails"
                    >
                      All Mails
                    </Button>
                  </Grid>
                  <Grid item xs={4} align="center">
                    {emails.length ? (
                      <Paper>
                        <Button
                          onClick={mailSync ? null : handlegetAllContactEmail}
                        >
                          {" "}
                          <Typography>Sync New Email</Typography>
                          <SyncIcon
                            className={
                              mailSync ? styleForSyncIcon.rotateIcon : null
                            }
                          />
                        </Button>
                      </Paper>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      variant={
                        currentButton === "Compose" ? "contained" : "outlined"
                      }
                      onClick={handleButtonClick}
                      name="Compose"
                    >
                      Compose
                    </Button>
                  </Grid>
                </Grid>

                {/* <Grid container spacing={0}>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="primary"
                      sx={{ marginBottom: 0 }}
                    >
                      EMAILS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    {/* <Button
                      sx={{ marginLeft: "12px" }}
                      variant={
                        currentButton === "Draft" ? "contained" : "outlined"
                      }
                      onClick={handleButtonClick}
                      name="Draft"
                    >
                      Draft
                    </Button> */}
                {/* <Button
                      sx={{ marginLeft: "12px" }}
                      variant={
                        currentButton === "SentMail" ? "contained" : "outlined"
                      }
                      onClick={handleButtonClick}
                      name="SentMail"
                    >
                      Sent Mail
                    </Button> 
                    <Button
                      sx={{ marginLeft: "12px" }}
                      variant={
                        currentButton === "AllMails" ? "contained" : "outlined"
                      }
                      onClick={handleButtonClick}
                      name="AllMails"
                    >
                      All Mails
                    </Button>
                    <Button
                      sx={{ marginLeft: "12px" }}
                      variant={
                        currentButton === "Compose" ? "contained" : "outlined"
                      }
                      onClick={handleButtonClick}
                      name="Compose"
                    >
                      Compose
                    </Button>
                  </Grid>
                </Grid> */}
              </CardContent>
            </Card>
          </Grid>
          {/* email page contenet isIndexDb */}
          {emails.length ? (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Card elevation={3} variant="outlined">
                    <CardContent>
                      <Grid container spacing={0} height="56vh" overflow="auto">
                        <Grid item xs={12}>
                          <ListMail
                            data={top100Films}
                            emails={emails}
                            index={index}
                            setIndex={setIndex}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={0.25}></Grid>
                <Grid item xs={6.75}>
                  <Card elevation={3} variant="outlined">
                    <CardContent>
                      <Grid container spacing={0} height="56vh" overflow="auto">
                        <Grid item xs={12}>
                          <EmailBody
                            data={top100Films}
                            emails={emails}
                            index={index}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card elevation={3} variant="outlined">
                    <CardContent>
                      <Grid container spacing={0} height="56vh" overflow="auto">
                        <Grid item xs={12} align="center">
                          <Paper>
                            <Button
                              onClick={
                                mailSync ? null : handlegetAllContactEmail
                              }
                            >
                              <SyncIcon
                                className={
                                  mailSync ? styleForSyncIcon.rotateIcon : null
                                }
                              />
                            </Button>
                            <Typography
                              gutterBottom
                              align="center"
                              variant="subtitle1"
                            >
                              Email Not found
                            </Typography>
                            <Typography variant="body2" align="center">
                              No Emails found &nbsp;
                              <strong>&quot;Please Sync Email&quot;</strong>. By
                              Clicking Email Sync Button
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Dialog
            open={agreeSetPasswordDialog}
            keepMounted
            onClose={handleClose}
          >
            <DialogTitle>{"Email Password Not Set"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                You haven't set a password for your email. Click 'AGREE' if you
                want to set one now.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                DISAGREE
              </Button>
              <Button onClick={handlePasswordAgree}>AGREE</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
      {/* email password pop-up */}
      <Dialog open={openPasswordAlert} onClose={openPasswordAlert}>
        <DialogTitle>Please Add Your Email Password</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={handleOnchagePassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordClose}>Cancel</Button>
          <Button onClick={handleAddPassword}>Submit</Button>
        </DialogActions>
      </Dialog>
      {/* loder */}
      {/* <Dialog open={mailSync}>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog> */}
    </Page>
  );
}
