import React from "react";
import Page from "../../components/UI/Page";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { getCountries } from "../../services/common.service";
import { useParams } from "react-router-dom";
import Iconify from "../../components/UI/Iconify";
import { toast } from "react-toastify";
import {
  createHomeAccount,
  createHomeCompany,
  getAllHomeAccounts,
  getAllHomeCompanies,
  updateHomeAccount,
  updateHomeCompany,
} from "../../services/homeCompanyAndAccount.service";
import IndivisualView from "../../components/HomeCompanyAndHomeAccount/Indivisual.view";
import isArrayEquals from "../../utils/array.utils";
import { IndianState } from "../../_mock/IndianState";

function AccountAndCompanyView() {
  const { identifier } = useParams();
  const [tempRenderableDataState, setTempRenderableDataState] = React.useState({
    data: [],
  });
  const [renderableDataState, setRenderableDataState] = React.useState({
    loader: true,
    data: [],
    stopLoader: () => {
      setRenderableDataState((prev) => ({
        ...prev,
        loader: false,
      }));
    },
  });
  const [customizeDialogState, setCustomizeDialogState] = React.useState({
    open: false,
    identifier: "",
    countries: [],
    readonlyView: false,
    openDialog: (mode) => {
      setCustomizeDialogState((prev) => ({
        ...prev,
        open: true,
        identifier: mode,
      }));
    },
    handleRemoveDetails: (index) => {
      setCustomizeDialogState((prev) => {
        const temp = { ...prev };
        temp.details.splice(index, 1);
        return temp;
      });
    },
    closeDialog: () => {
      setCustomizeDialogState((prev) => ({
        ...prev,
        open: false,
        identifier: "",
        details: [
          {
            key: "",
            value: "",
          },
        ],
        name: "",
        country: "",
        state: "",
        toEdit: false,
        readonlyView: false,
        currentUpdatableDetailsId: "",
        address: "",
      }));
    },
    name: "",
    country: "",
    state: "",
    address: "",
    details: [
      {
        key: "",
        value: "",
      },
    ],
    toEdit: false,
    currentUpdatableDetailsId: "",
    addNewPairToDetails: () => {
      setCustomizeDialogState((prev) => {
        const temp = { ...prev };
        temp.details.push({ key: "", value: "" });
        return temp;
      });
    },
    handleChange: (event, index = undefined) => {
      let {
        target: { name, value },
      } = event;

      if (value.includes("\n")) {
        toast.warn("New lines are not allowed");
        return;
      }

      if (index >= 0) {
        setCustomizeDialogState((prev) => {
          const temp = { ...prev };
          temp.details[index][name] = value;
          return temp;
        });
        return;
      }
      setCustomizeDialogState((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
  });
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  async function getMyCountries() {
    try {
      const response = await getCountries();
      if (response.data.status) {
        setCustomizeDialogState((prev) => ({
          ...prev,
          countries: [...response.data.data],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  function allowToUpdate(id, newData) {
    let result = {};
    const oldData = tempRenderableDataState.data.find(
      (ele) => ele._id + "" === id
    );
    Object.keys(newData).forEach((key) => {
      switch (Array.isArray(newData[key])) {
        case true:
          if (!isArrayEquals(newData[key], oldData[key]))
            result[key] = newData[key];
          break;
        case false:
          if (newData[key] !== oldData[key]) result[key] = newData[key];
          break;

        default:
          break;
      }
    });
    if (Object.keys(result).length) {
      return true;
    }
    toast("Nothing To Update");
    return false;
  }

  const handleUpdate = async () => {
    const gorForUpdate = allowToUpdate(
      customizeDialogState.currentUpdatableDetailsId,
      {
        name: customizeDialogState.name,
        country: customizeDialogState.country,
        state: customizeDialogState.state,
        details: customizeDialogState.details,
        address: customizeDialogState.address,
      }
    );
    if (gorForUpdate) {
      switch (identifier) {
        case "Account":
          setLoaderDialog(true);
          const updateHomeAccountResponse = await updateHomeAccount(
            customizeDialogState.currentUpdatableDetailsId,
            {
              name: customizeDialogState.name,
              country: customizeDialogState.country,
              state: customizeDialogState.state,
              details: customizeDialogState.details,
              address: customizeDialogState.address.trim().replace(/\s+/g, " "),
            }
          );
          if (updateHomeAccountResponse.data.status) {
            setLoaderDialog(false);
            toast("Home Account Updated Successfully");

            customizeDialogState.closeDialog();
            getRenderData();
          }
          break;
        case "Company":
          setLoaderDialog(true);
          const updateHomeCompanyReponse = await updateHomeCompany(
            customizeDialogState.currentUpdatableDetailsId,
            {
              name: customizeDialogState.name,
              country: customizeDialogState.country,
              state: customizeDialogState.state,
              details: customizeDialogState.details,
              address: customizeDialogState.address.trim().replace(/\s+/g, " "),
            }
          );
          if (updateHomeCompanyReponse) {
            setLoaderDialog(false);
            toast("Home Company Updated Successfully");
            customizeDialogState.closeDialog();
            getRenderData();
          }
          break;

        default:
          break;
      }
    }
  };

  const handleCreate = async () => {
    try {
      if (customizeDialogState.toEdit) {
        handleUpdate();
        return;
      }
      switch (identifier) {
        case "Account":
          setLoaderDialog(true);
          const homeResponse = await createHomeAccount({
            name: customizeDialogState.name,
            country: customizeDialogState.country,
            state: customizeDialogState.state,
            address: customizeDialogState.address,
            details: customizeDialogState.details,
          });
          if (homeResponse.data.status) {
            setLoaderDialog(false);
            toast("Account Created Successfully");
            customizeDialogState.closeDialog();
            getRenderData();
          }
          break;
        case "Company":
          setLoaderDialog(true);
          const companyResponse = await createHomeCompany({
            name: customizeDialogState.name,
            country: customizeDialogState.country,
            address: customizeDialogState.address,
            state: customizeDialogState.state,
            details: customizeDialogState.details,
          });
          if (companyResponse.data.status) {
            toast("Company Created Successfully");
            setLoaderDialog(false);
            customizeDialogState.closeDialog();
            getRenderData();
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRenderData = async () => {
    try {
      switch (identifier) {
        case "Account":
          const response1 = await getAllHomeAccounts();
          if (response1.data.status) {
            setRenderableDataState((prev) => ({
              ...prev,
              data: [...JSON.parse(JSON.stringify(response1.data.data))],
              loader: false,
            }));
            setTempRenderableDataState((prev) => ({
              ...prev,
              data: [...JSON.parse(JSON.stringify(response1.data.data))],
            }));
          }
          break;
        case "Company":
          const response2 = await getAllHomeCompanies();
          if (response2.data.status) {
            setRenderableDataState((prev) => ({
              ...prev,
              data: [...JSON.parse(JSON.stringify(response2.data.data))],
              loader: false,
            }));
            setTempRenderableDataState((prev) => ({
              ...prev,
              data: [...JSON.parse(JSON.stringify(response2.data.data))],
            }));
          }
          break;

        default:
          break;
      }
    } catch (error) {
      renderableDataState.stopLoader();
    }
  };

  const handleEditIconClick = (detailId) => {
    const output = renderableDataState.data.find(
      (d) => d._id + "" === detailId + ""
    );

    if (output) {
      const parsedValue = JSON.parse(JSON.stringify(output));
      setCustomizeDialogState((prev) => ({
        ...prev,
        name: parsedValue.name,
        country: parsedValue.country,
        state: parsedValue.state || "",
        address: parsedValue.address || "",
        details: [...parsedValue.details],
        identifier: identifier,
        open: true,
        toEdit: true,
        readonlyView: false,
        currentUpdatableDetailsId: detailId,
      }));
    }
  };

  const handleShowDetails = (detailId) => {
    const output = renderableDataState.data.find(
      (d) => d._id + "" === detailId + ""
    );

    if (output) {
      setCustomizeDialogState((prev) => ({
        ...prev,
        name: output.name,
        country: output.country,
        address: output.address || "",
        state: output.state,
        details: [...output.details],
        identifier: identifier,
        open: true,
        toEdit: false,
        readonlyView: true,
      }));
    }
  };

  React.useEffect(() => {
    getMyCountries();
    getRenderData();
  }, []);
  return (
    <Page title="Informations">
      <Dialog open={customizeDialogState.open}>
        <DialogTitle>
          {customizeDialogState.identifier === "Account"
            ? `Bank ${customizeDialogState.identifier} Details`
            : `${customizeDialogState.identifier} Details`}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={
                customizeDialogState.country.toLowerCase() === "india" ? 4 : 6
              }
              lg={
                customizeDialogState.country.toLowerCase() === "india" ? 4 : 6
              }
              xl={
                customizeDialogState.country.toLowerCase() === "india" ? 4 : 6
              }
            >
              <TextField
                required
                fullWidth
                variant="outlined"
                label={
                  customizeDialogState.identifier === "Account"
                    ? `Bank Name`
                    : `${customizeDialogState.identifier} Name`
                }
                name="name"
                onChange={(event) => {
                  if (event.target.value && !event.target.value?.trim()) {
                    toast.warn(
                      `${
                        customizeDialogState.identifier === "Account"
                          ? `Bank Name`
                          : `${customizeDialogState.identifier} Name`
                      } can not contain only spaces`
                    );
                    return;
                  }
                  if (event.target.value && event.target.value?.length > 50) {
                    toast.warning(
                      `${customizeDialogState.identifier} Name can not be greater than 50`
                    );
                    return;
                  }
                  customizeDialogState.handleChange(event);
                }}
                value={customizeDialogState.name || ""}
                InputProps={{ readOnly: customizeDialogState.readonlyView }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={
                customizeDialogState.country.toLowerCase() === "india" ? 4 : 6
              }
              lg={
                customizeDialogState.country.toLowerCase() === "india" ? 4 : 6
              }
              xl={
                customizeDialogState.country.toLowerCase() === "india" ? 4 : 6
              }
            >
              <FormControl fullWidth required>
                <InputLabel>Country</InputLabel>
                <Select
                  required
                  label="Country"
                  name="country"
                  onChange={(event) => {
                    customizeDialogState.handleChange(event);
                  }}
                  value={customizeDialogState.country}
                  readOnly={customizeDialogState.readonlyView}
                >
                  {customizeDialogState.countries.map((ele, index) => (
                    <MenuItem key={index} value={ele}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {customizeDialogState.country.toLowerCase() === "india" ? (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl fullWidth required>
                  <InputLabel>State</InputLabel>
                  <Select
                    required
                    label="State"
                    name="state"
                    onChange={(event) => {
                      customizeDialogState.handleChange(event);
                    }}
                    value={customizeDialogState.state}
                    readOnly={customizeDialogState.readonlyView}
                  >
                    {IndianState.map((ele, index) => (
                      <MenuItem key={index} value={ele}>
                        {ele}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address"
                label="Address"
                required
                multiline
                value={customizeDialogState.address}
                rows={2}
                onChange={(event) => {
                  if (event.target.value && !event.target.value?.trim()) {
                    toast.warn(
                      `${
                        customizeDialogState.identifier === "Account"
                          ? `Bank Address`
                          : `${customizeDialogState.identifier} Address`
                      } can not contain only spaces`
                    );
                    return;
                  }
                  if (event.target.value?.length > 150) {
                    toast("Address can not exceed 150 characters");
                    return;
                  }
                  customizeDialogState.handleChange(event);
                }}
              />
            </Grid>
            {customizeDialogState.details.map((ele, index) => (
              <React.Fragment key={index}>
                {!customizeDialogState.readonlyView ? (
                  <Grid item xs={1} alignSelf="center">
                    <IconButton
                      onClick={() => {
                        if (customizeDialogState.details.length > 1) {
                          customizeDialogState.handleRemoveDetails(index);
                          return;
                        }
                        toast.warn("This Row can not be deleted");
                      }}
                    >
                      <RemoveIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={!customizeDialogState.readonlyView ? 5 : 6}
                  xl={!customizeDialogState.readonlyView ? 5 : 6}
                >
                  <TextField
                    required
                    label="Key"
                    fullWidth
                    name="key"
                    value={ele.key || ""}
                    onChange={(event) => {
                      if (event.target.value && !event.target.value?.trim()) {
                        toast.warn("Key can not contain only spaces");
                        return;
                      }
                      if (
                        event.target.value &&
                        event.target.value.length > 35
                      ) {
                        toast.warning(`Key can not be greater than 35`);
                        return;
                      }
                      customizeDialogState.handleChange(event, index);
                    }}
                    InputProps={{ readOnly: customizeDialogState.readonlyView }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    required
                    label="Value"
                    fullWidth
                    name="value"
                    value={ele.value}
                    onChange={(event) => {
                      if (event.target.value && !event.target.value?.trim()) {
                        toast.warn("Value can not contain only spaces");
                        return;
                      }
                      if (
                        event.target.value &&
                        event.target.value.length > 50
                      ) {
                        toast.warning(`Value can not be greater than 50`);
                        return;
                      }
                      customizeDialogState.handleChange(event, index);
                    }}
                    InputProps={{ readOnly: customizeDialogState.readonlyView }}
                  />
                </Grid>
              </React.Fragment>
            ))}
            {!customizeDialogState.readonlyView ? (
              <Grid item xs={12} display="flex" flexDirection="row-reverse">
                <Button
                  variant="contained"
                  onClick={customizeDialogState.addNewPairToDetails}
                  disabled={customizeDialogState.readonlyView}
                >
                  Add Details
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          {customizeDialogState.identifier === "Account" ? null : (
            <Typography color="grey" fontWeight="300" fontSize="13px">
              After providing your address, follow this sequential order:
              mobile, email, and website and additional input fields
            </Typography>
          )}
          <Button onClick={customizeDialogState.closeDialog}>CANCEL</Button>
          {!customizeDialogState.readonlyView ? (
            <Button
              onClick={handleCreate}
              disabled={
                !customizeDialogState.name ||
                !customizeDialogState.countries ||
                !customizeDialogState.address ||
                !!customizeDialogState.details.filter(
                  (det) => !det.key || !det.value
                ).length ||
                customizeDialogState.readonlyView ||
                (customizeDialogState.country.toLowerCase() === "india" &&
                  !customizeDialogState.state)
              }
            >
              SAVE
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Stack flexDirection="row-reverse">
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => {
                      customizeDialogState.openDialog(identifier);
                    }}
                  >
                    {identifier === "Account"
                      ? `Add Bank ${identifier}`
                      : `Add ${identifier}`}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {renderableDataState.loader &&
              !renderableDataState.data.length ? (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              ) : !renderableDataState.loader &&
                renderableDataState.data.length ? (
                tempRenderableDataState.data.map((ele, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Card sx={{ height: "22vw", overflow: "auto" }}>
                        <CardContent>
                          <IndivisualView
                            name={ele?.name}
                            country={ele?.country}
                            state={ele?.state}
                            data={ele?.details}
                            address={ele?.address}
                            handleEditIconClick={() => {
                              handleEditIconClick(ele._id);
                            }}
                            handleShowDetails={() => {
                              handleShowDetails(ele._id);
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography textAlign="center">{`No Home ${identifier} Found`}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default AccountAndCompanyView;
