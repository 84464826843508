import { Grid, Button } from "@mui/material";
import React, { useContext } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CommonInformation from "./CommonInformation";
import CostInvoiceViewSection from "./CostInvoiceViewSection";
import { AuthContext } from "../../../context/auth.context";

function CostEstimationView({
  fetchCostEstimation,
  project,
  costInforMations,
  projectId,
  getInvoiceDetails,
  setLoaderDialog,
  gstValues,
  parentCompanyDetails,
  isEditIcon,
  invoiceDetailsAndManage,
}) {
  console.log(costInforMations);
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CommonInformation costInforMations={costInforMations} />
        </Grid>
        <Grid item xs={12}>
          {currentUser.role === "ADMIN" ||
          currentUser._id === project.contact.salesOwner ? (
            <CostInvoiceViewSection
              data={
                costInforMations.mode.toLowerCase() === "fixed"
                  ? costInforMations.modeDataContainer.milestones
                  : costInforMations.modeDataContainer.costEstimation
              }
              numberOfMileStone={
                costInforMations.modeDataContainer.noOfMilestones
              }
              costInforMations={costInforMations}
              projectId={projectId}
              fetchCostEstimation={fetchCostEstimation}
              gstValues={gstValues}
              project={project}
              getInvoiceDetails={getInvoiceDetails}
              setLoaderDialog={setLoaderDialog}
              parentCompanyDetails={parentCompanyDetails}
              isEditIcon={isEditIcon}
              invoiceDetailsAndManage={invoiceDetailsAndManage}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default CostEstimationView;
