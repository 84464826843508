import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import BlockIcon from "@mui/icons-material/Block";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemText,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import FilterEmail from "./FilterEmail";
import { updateSalesCampaignExclusionEmail } from "../../services/salesCampaign.service";
import { useState } from "react";
import { fetchfilterContactEmails } from "../../services/contacts.service";
import { getCurrentMonthDate, getDateString } from "../../utils/dateUtils";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCallback } from "react";
import SearchNotFound from "../../components/SearchNotFound";
import { useMemo } from "react";

export default function EmailList({
  stableContactEmails = [],
  setStableContactEmails,
  contactEmail,
  setcontactEmail,
  activeStep,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showChip,
  setShowChip,
  setCustomDate,
  initialIntervals,
  intervals,
  setIntervals,
  setPopup,
  toggleCheck,
  checked,
  setChecked,
  formData = {},
  getExclusionEmail,
  interval,
  setInterval,
  open,
  searchText,
  setSearchText,
  getCoantactEmails,
}) {
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [country, setCountry] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length > +formData.maxCapacity) {
      toast.error("Maximum capacity reached");
      // Remove the last added value if capacity is exceeded
      if (currentIndex === -1) {
        newChecked.pop();
      }
      return;
    }

    setChecked(newChecked);
  };

  const updateExclusionEmail = async (contactId) => {
    setLoading(true);
    try {
      let isExcluded = true;
      const result = await updateSalesCampaignExclusionEmail(
        contactId,
        isExcluded
      );
      if (result) {
        await getfilterContactEmails();
        await getExclusionEmail();
        toast.success("Email ID Successfully Excluded from the List!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getfilterContactEmails = async (fromfetch = false) => {
    try {
      let from;
      let to;
      let countryString = country.join(",");
      if (intervals.label === "Custom") {
        from = getDateString(startDate);
        to = endDate;
      } else if (intervals.value === "Select Interval") {
        from = "null";
        to = "null";
      } else {
        from = intervals.value;
        to = getCurrentMonthDate();
      }

      const result = await fetchfilterContactEmails(
        countryString,
        from,
        to,
        searchText
      );
      if (result.status) {
        const modifyResult = result.data.data.map(
          ({ _id, email, name, company }) => {
            return { _id, email, name, company };
          }
        );
        if (fromfetch) {
          setStableContactEmails([...modifyResult]);
        }
        setcontactEmail(() => [...modifyResult]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handlePageChange = (e, newPage) => {
    if (
      rowsPerPage * (parseInt(newPage) + 1) > contactEmail.length &&
      contactEmail.length < pageCount
    ) {
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  React.useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchText !== "") {
        setLoading(true);
        await getfilterContactEmails();
      }
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  return (
    <>
      <Grid item xs={12}>
        <FilterEmail
          contactEmail={contactEmail}
          setcontactEmail={setcontactEmail}
          activeStep={activeStep}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          showChip={showChip}
          setShowChip={setShowChip}
          setCustomDate={setCustomDate}
          initialIntervals={initialIntervals}
          intervals={intervals}
          setIntervals={setIntervals}
          setPopup={setPopup}
          getfilterContactEmails={getfilterContactEmails}
          country={country}
          setCountry={setCountry}
          setChecked={setChecked}
          setIsLoading={setLoading}
          interval={interval}
          setInterval={setInterval}
          searchText={searchText}
          setSearchText={setSearchText}
          page={page}
          setPage={setPage}
          stableContactEmails={stableContactEmails}
          getCoantactEmails={getCoantactEmails}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: "500px" }}>
        {contactEmail.length === 0 || isSearching || loading ? (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="medium"
              aria-label="a dense table"
            >
              {isSearching || loading ? (
                [1, 2, 3, 4, 5, 6, 7].map((row) => (
                  <TableBody>
                    <TableRow key={row}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <SearchNotFound />
              )}
            </Table>
          </TableContainer>
        ) : (
          <Paper>
            {contactEmail.length ? (
              <>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="medium"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Email ID</TableCell>
                        <TableCell>Contact Name</TableCell>
                        <TableCell>Company Name</TableCell>

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contactEmail
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((value) => {
                          return value;
                        })
                        .map((value, index) => {
                          const labelId = `checkbox-list-label-${value._id}`;
                          return (
                            <TableRow>
                              <TableCell align="left" width={225}>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(value._id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                  onChange={() => handleToggle(value._id)}
                                />
                                {value.email}
                              </TableCell>

                              <TableCell align="left" width={225}>
                                {value.name}
                              </TableCell>
                              <TableCell align="left" width={225}>
                                {value.company}
                              </TableCell>

                              <TableCell align="left" width={125}>
                                <Tooltip title="Click here to exclude the email ID">
                                  <IconButton
                                    edge="end"
                                    aria-label="exclude"
                                    onClick={() =>
                                      updateExclusionEmail(value._id)
                                    }
                                    sx={{ m: 0, p: 0 }}
                                  >
                                    <BlockIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Navigate to Contact">
                                  <IconButton
                                    variant="contained"
                                    onClick={() => {
                                      const url = new URL(
                                        `/contacts/${value._id}`,
                                        window.location.origin
                                      );
                                      url.searchParams.set("open", open);
                                      window.open(url.toString(), "_blank");
                                    }}
                                    sx={{ ml: 2 }}
                                  >
                                    <ArrowOutwardIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={contactEmail ? contactEmail.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                />
              </>
            ) : (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography>No Records</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        )}
      </Grid>
    </>
  );
}
