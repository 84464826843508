import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import Page from "../../components/UI/Page";
import React, { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import { getMetaEntites } from "../../services/attributes.service";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GstCustomization from "./GstCustomization";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CustomizeTab from "./CustomizeTab";

let hitApi = true;

const Customize = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [inputChangeData, setInputChangeData] = useState("");
  const { entityName, getEntityname } = useContext(AuthContext);
  const [isEntityDialougeOpne, setIsEntityDialougeOpne] = useState(false);

  const [attributeList, setAttributeList] = useState([]);

  const [isAttributeListFetched, setIsAttributeListFetched] = useState(false);

  const getAllAttributes = async () => {
    try {
      const response = await getMetaEntites();
      if (response.data.status) {
        const data = response.data.data;

        const attributesWithEntityId = [];

        data.forEach((entity) => {
          const metaEntityId = entity._id;

          entity.attributes.forEach((attribute) => {
            const attributeWithEntityId = {
              metaEntityId,
              ...attribute,
            };
            attributesWithEntityId.push(attributeWithEntityId);
          });
        });

        setAttributeList(attributesWithEntityId);
        // toast("Attributes fetched successfully");
        setIsAttributeListFetched(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!isAttributeListFetched) {
      getAllAttributes();
    }
  }, [isAttributeListFetched]);

  return (
    <Page>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={7.4}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <GstCustomization />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2.3}>
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // maxHeight: "70%",
              }}
            >
              <CardContent>
                <Button
                  startIcon={<AccountBalanceIcon />}
                  onClick={() => {
                    navigate(`/customize/view/Account`);
                  }}
                >
                  HOME ACCOUNT
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2.3}>
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // maxHeight: "70%",
              }}
            >
              <CardContent>
                <Button
                  startIcon={<HomeWorkIcon />}
                  onClick={() => {
                    navigate(`/customize/view/Company`);
                  }}
                >
                  HOME COMPANY
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <CustomizeTab
              entityName={entityName}
              attributeList={attributeList}
              setAttributeList={setAttributeList}
              isEntityDialougeOpne={isEntityDialougeOpne}
              setIsEntityDialougeOpne={setIsEntityDialougeOpne}
              getEntityname={getEntityname}
              inputChangeData={inputChangeData}
              setInputChangeData={setInputChangeData}
              hitApi={hitApi}
              setIsAttributeListFetched={setIsAttributeListFetched}
              emailTemplateTab={state?.emailTempletTab}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Customize;
