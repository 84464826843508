export default function validateUniqueAttributes(
  previousArray,
  changableArray
) {
  const result = [];
  if (previousArray.length === changableArray.length) {
    previousArray.forEach((ele, index) => {
      switch (ele.uiType?.toLowerCase()) {
        case "autocompletemulti":
          if (
            ele.selectedValues.toString() !==
            changableArray[index].selectedValues.toString()
          ) {
            result.push(changableArray[index]);
          }

          break;
        case "multiselectdropdown":
          if (
            ele.selectedValues.toString() !==
            changableArray[index].selectedValues.toString()
          ) {
            result.push(changableArray[index]);
          }

          break;

        default:
          if (
            ele.selectedValues[0] !== changableArray[index].selectedValues[0]
          ) {
            result.push(changableArray[index]);
          }
          break;
      }
    });

    return result;
  }
  return result;
}
