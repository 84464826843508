import {
  Badge,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  createEstimate,
  getAllEstimates,
  updateEstimatebyId,
  addEstimationOutlinebyId,
  updateEstimationOutlinebyId,
  removeEstimationOutlinebyId,
  getEstimateByType,
} from "../../services/estimate.service";
import { toast } from "react-toastify";

function EstimateTemplate() {
  const [outlineIndex, setOutlineIndex] = useState(0);
  const [isLoaderEnable, setIsLoaderEnable] = useState(false);
  const initialHeadingState = {
    headingName: "",
    estimateType: "",
  };
  const initialOutlineState = {
    outlineName: "",
    isRequired: "mandatory",
  };

  const [headingDetails, setHeadingDetails] = useState(initialHeadingState);
  const [outlineDetails, setOutlineDetails] = useState(initialOutlineState);
  const [tempHeadingDetails, setTempHeadingDetails] =
    useState(initialHeadingState);
  const [tempOutlineDetails, setTempOutlineDetails] =
    useState(initialOutlineState);
  const [estimateType, setEstimateType] = useState("");
  const [isDialougeOpen, setIsDialougeOpen] = useState(false);
  const [isAddOutlineDialouge, setIsAddOutlineDialouge] = useState(false);
  const [allHeadingDetails, setAllHeadingDetails] = useState([]);
  const [isHeadingEditDialouge, setIsHeadingEditDialouge] = useState(false);
  const [isEditOutlineDialouge, setIsEditOutlineDialouge] = useState(false);
  const [estimateHeadingId, setEstimateHeadingId] = useState("");
  const [outlineId, setOutlineId] = useState("");
  const [isOutlineDelete, setIsOutlineDelete] = useState(false);

  const initialFilters = [
    {
      moduleName: "All",
      checked: true,
    },
    {
      moduleName: "Effort Estimate",
      checked: false,
    },
    {
      moduleName: "Cost Estimate",
      checked: false,
    },
  ];
  const [filter, setFilter] = useState([...initialFilters]);
  const [selectModuleName, setSelectModuleName] = useState("All");

  const fetchEstimate = async () => {
    try {
      const selectedModuleName = filter.find(
        (item) => item.checked
      )?.moduleName;
      if (selectedModuleName === "All") {
        fetchHeadingDetails();
      } else if (selectedModuleName === "Effort Estimate") {
        const response = await getEstimateByType(selectedModuleName);

        if (response.data.status) {
          setAllHeadingDetails([...response.data.data]);
        }
      } else if (selectedModuleName === "Cost Estimate") {
        const response = await getEstimateByType(selectedModuleName);

        if (response.data.status) {
          setAllHeadingDetails([...response.data.data]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function handleChipSwitchAndActivation(pIndex, moduleName) {
    setSelectModuleName(moduleName);
    let newFilterers = filter.map(({ moduleName }, index) => {
      return { moduleName: moduleName, checked: pIndex === index };
    });
    setFilter(newFilterers);
    try {
      if (moduleName === "All") {
        fetchHeadingDetails();
        return;
      }
      const response = await getEstimateByType(moduleName);
      console.log("moduleName444", moduleName, response);
      if (response.data.status) {
        // setEmailTemplet(response.data.data);
        setAllHeadingDetails([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDialouge = () => {
    setIsDialougeOpen((prev) => !prev);
    setHeadingDetails(initialHeadingState);
    setEstimateType("");
  };

  const handleOutlineDialouge = () => {
    const estimateId = allHeadingDetails[outlineIndex]._id;
    setEstimateHeadingId(estimateId);
    setIsAddOutlineDialouge((prev) => !prev);
    setOutlineDetails(initialOutlineState);
  };

  const handleCancelHeadingEditDialouge = () => {
    setIsHeadingEditDialouge((prev) => !prev);
    setHeadingDetails(initialHeadingState);
  };
  const handleCancelOutlineDialouge = () => {
    setIsAddOutlineDialouge((prev) => !prev);
    setOutlineDetails(initialOutlineState);
  };
  const handleCancelEditOutlineDialouge = () => {
    setIsEditOutlineDialouge((prev) => !prev);
    setOutlineDetails(initialOutlineState);
  };
  const handelDeleteOutlineDialouge = () => {
    setIsOutlineDelete((prev) => !prev);
  };
  const checkPureData = (data) => {
    if (
      data !== undefined &&
      data !== null &&
      typeof data === "string" &&
      data.trim() !== ""
    ) {
      return true;
    }

    return false;
  };
  const handleHeadingChange = (event) => {
    const { name, value } = event.target;
    if (value && !value?.trim()) {
      toast.warn("Heading name can not contain only space");
      return;
    }

    if (name === "headingName" && value.length > 30) {
      toast.warn("Heading name should not cross 30 characters");
      return;
    }
    setHeadingDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log("heeading", headingDetails);
  const handleOutlineChange = (event) => {
    const { name, value } = event.target;
    if (value && !value?.trim()) {
      toast.warn("Outline name can not contain only space");
      return;
    }

    if (name === "outlineName" && value.length > 30) {
      toast.warn("Outline name should not cross 30 characters");
      return;
    }
    setOutlineDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log("outline", outlineDetails);
  };
  const handelAddHeading = async () => {
    const trimmedHeadingName = headingDetails.headingName.trim();
    if (!checkPureData(trimmedHeadingName)) {
      toast("Heading Name can not be empty");
      return;
    }
    const data = {
      ...headingDetails,
      headingName: trimmedHeadingName,
      estimateType: estimateType,
    };
    try {
      console.log(data, "data");
      const response = await createEstimate(data);
      if (response.data.status) {
        // fetchHeadingDetails();
        fetchEstimate();
        toast("Estimation Heading Created Successfully");
        setHeadingDetails(initialHeadingState);
        setIsDialougeOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelAddOutline = async () => {
    const trimmedOutlineName = outlineDetails.outlineName.trim();
    if (!checkPureData(trimmedOutlineName)) {
      toast("Outline Name can not be empty");
      return;
    }
    const data = {
      ...outlineDetails,
      outlineName: trimmedOutlineName,
      isRequired: outlineDetails.isRequired === "mandatory" ? true : false,
    };
    try {
      console.log(data, "data");
      const response = await addEstimationOutlinebyId(estimateHeadingId, data);
      if (response.data.status) {
        // fetchHeadingDetails();
        fetchEstimate();
        toast("Estimation Outline Created Successfully");
        handleCancelOutlineDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelEditHeading = async () => {
    const trimmedHeadingName = headingDetails.headingName.trim();
    const trimmedEstimate = headingDetails.estimateType.trim();
    console.log("tempHeadingDetails", tempHeadingDetails, headingDetails);
    if (!checkPureData(trimmedHeadingName)) {
      toast("Heading Name can not be empty");
      return;
    }
    if (
      trimmedHeadingName === tempHeadingDetails.headingName.trim() &&
      trimmedEstimate === tempHeadingDetails.estimateType.trim()
    ) {
      toast("Nothing to update");
      return;
    }
    const data = { ...headingDetails, headingName: trimmedHeadingName };
    console.log(data, "editheadingdata");

    try {
      const response = await updateEstimatebyId(estimateHeadingId, data);
      if (response.data.status) {
        // fetchHeadingDetails();
        fetchEstimate();
        toast("Heading Name Updated Successfully");
        handleCancelHeadingEditDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelEditOutline = async (e) => {
    let { name, value } = e.target;
    console.log(name, value, e, "jhjhj53");
    console.log(e.target.value, "jhjhj54");
    const trimmedOutlineName = outlineDetails.outlineName.trim();
    if (!checkPureData(trimmedOutlineName)) {
      toast("Outline Name can not be empty");
      return;
    }
    if (
      trimmedOutlineName === tempOutlineDetails.outlineName.trim() &&
      outlineDetails.isRequired === tempOutlineDetails.isRequired
    ) {
      toast("Nothing to update");
      return;
    }
    const data = {
      ...outlineDetails,
      outlineName: trimmedOutlineName,
      isRequired: outlineDetails.isRequired === "mandatory" ? true : false,
    };
    try {
      const response = await updateEstimationOutlinebyId(
        estimateHeadingId,
        outlineId,
        data
      );
      if (response.data.status) {
        // if (checked) {
        // } else {
        //   // fetchHeadingDetails();
        // }

        toast("Estimation Outline Updated Successfully");
        fetchEstimate();
        handleCancelEditOutlineDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editOutlineButton = (outlineId) => {
    setIsEditOutlineDialouge((prev) => !prev);
    setOutlineId(outlineId);
    const estimateId = allHeadingDetails[outlineIndex]._id;
    setEstimateHeadingId(estimateId);

    allHeadingDetails[outlineIndex].outlines.find((elem) => {
      if (elem._id === outlineId) {
        setOutlineDetails((prev) => ({
          outlineName: elem.outlineName,
          isRequired: elem.isRequired === true ? "mandatory" : "optional",
        }));
        setTempOutlineDetails({
          outlineName: elem.outlineName,
          isRequired: elem.isRequired === true ? "mandatory" : "optional",
        });
      }
    });
  };

  const handelDeleteOutline = (outlineId) => {
    const estimateId = allHeadingDetails[outlineIndex]._id;
    setEstimateHeadingId(estimateId);
    setOutlineId(outlineId);
    setIsOutlineDelete((prev) => !prev);
  };

  const removeEstimationOutline = async () => {
    try {
      const response = await removeEstimationOutlinebyId(
        estimateHeadingId,
        outlineId
      );
      if (response.data.status) {
        // fetchHeadingDetails();
        fetchEstimate();
        toast("Outline Removed Successfully");
        handelDeleteOutlineDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHeadingDetails = async () => {
    setIsLoaderEnable(true);
    try {
      const response = await getAllEstimates();
      if (response.data.status) {
        console.log(response.data, "data");
        setIsLoaderEnable(false);
        setAllHeadingDetails([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("useEffect");
    // fetchHeadingDetails();
    fetchEstimate();
  }, []);

  const handleHeadingEdit = (estimateId) => {
    setEstimateHeadingId(estimateId);
    allHeadingDetails.find((elem) => {
      if (elem._id === estimateId) {
        setHeadingDetails((prev) => ({
          headingName: elem.headingName,
          estimateType: elem.estimateType,
        }));
        setTempHeadingDetails({
          headingName: elem.headingName,
          estimateType: elem.estimateType,
        });
      }
    });
    setIsHeadingEditDialouge((prev) => !prev);
  };
  console.log("filterer54", filter, selectModuleName, allHeadingDetails);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
            {filter.map(({ moduleName, checked }, index) => {
              return (
                <Chip
                  key={index}
                  variant={checked ? "filled" : "outlined"}
                  clickable
                  label={moduleName}
                  color={checked ? "primary" : "default"}
                  onClick={() => {
                    handleChipSwitchAndActivation(index, moduleName);
                  }}
                  sx={{
                    border: checked
                      ? ""
                      : moduleName === "Cost Estimate"
                      ? "3px solid #FDDA0D"
                      : moduleName === "Effort Estimate"
                      ? "3px solid #FF5733"
                      : "3px solid #fff",
                    borderRadius: "62px",
                  }}
                />
              );
            })}
          </Stack>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography component="div"> Headings :</Typography>
        </Grid>
        <Grid item xs={9}>
          {isLoaderEnable ? (
            <LinearProgress sx={{ marginTop: "25px" }} />
          ) : !isLoaderEnable && allHeadingDetails.length > 0 ? (
            allHeadingDetails.map((data, i) => {
              const { _id, headingName, estimateType, outlines } = data;
              let icon;

              if (data === "mern") {
                icon = "hello";
              }

              return (
                <Badge
                  key={i}
                  badgeContent={allHeadingDetails.length ? outlines.length : 0}
                  color="secondary"
                  sx={{
                    margin: "1%",
                    border:
                      outlineIndex === i
                        ? "3px solid #3366FF"
                        : selectModuleName === "All" &&
                          estimateType === "Cost Estimate"
                        ? "3px solid #FDDA0D"
                        : selectModuleName === "All" &&
                          estimateType === "Effort Estimate"
                        ? "3px solid #FF5733" 
                        : estimateType === "Cost Estimate"
                        ? "3px solid #FDDA0D" 
                        : "3px solid #FF5733", 
                    borderRadius: "62px",
                  }}
                >
                  <Icon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      borderRadius: "62px",
                      borderTopLeftRadius: "16px",
                      borderBottomLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomRightRadius: "16px",
                      padding: "18px",
                      // marginRight: "2px",

                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" style={{ marginRight: "8px" }}>
                      {headingName}
                    </Typography>
                    <IconButton size="small" onClick={() => setOutlineIndex(i)}>
                      <InfoIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ pr: 0 }}
                      onClick={() => {
                        handleHeadingEdit(_id);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Icon>
                </Badge>
              );
            })
          ) : (
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <Typography textAlign="center" fontWeight="700" color="#857C7A">
                {`No Headings are available, please add outlines.`}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={handleDialouge}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            mt: 1.3,
          }}
        >
          <Typography> Outlines :</Typography>
        </Grid>
        <Grid item xs={9} sx={{ display: "flex", flexDirection: "row" }}>
          <Grid container spacing={1}>
            {isLoaderEnable ? (
              <LinearProgress sx={{ marginTop: "25px" }} />
            ) : !isLoaderEnable &&
              allHeadingDetails[outlineIndex]?.outlines.length > 0 ? (
              allHeadingDetails[outlineIndex]?.outlines.map((elem, i) => {
                const { _id, outlineName } = elem;
                return (
                  <>
                    <Grid item xs={6} key={i}>
                      <Card
                        sx={{
                          maxHeight: "40px",
                          display: "grid",
                          alignContent: "center",
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              xs={10}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography fontWeight={500} color="#5489dc">
                                {outlineName}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack direction="row">
                                <IconButton
                                  size="small"
                                  onClick={() => editOutlineButton(_id)}
                                  sx={{ ":hover": { color: "#2065d1" } }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() => handelDeleteOutline(_id)}
                                >
                                  <DeleteIcon sx={{ color: "red" }} />
                                </IconButton>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                );
              })
            ) : (
              <Grid item xs={12} sx={{ marginTop: "12px" }}>
                <Typography textAlign="center" fontWeight="700" color="#857C7A">
                  {`No Outlines are available, please add Outlines.`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              handleOutlineDialouge();
            }}
          >
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog maxWidth="lg" open={isDialougeOpen} onClose={() => {}}>
        <DialogTitle>Add Heading</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} sx={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <TextField
                label="Heading Name"
                variant="outlined"
                placeholder="Heading Name"
                type="text"
                name="headingName"
                required
                value={headingDetails.headingName}
                fullWidth
                onChange={handleHeadingChange}
                sx={{ minWidth: "400px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="Estimate-select">Estimate Type</InputLabel>
                <Select
                  labelId="Estimate-select"
                  id="Estimate-select"
                  value={estimateType}
                  label="Estimate Type"
                  onChange={(e) => setEstimateType(e.target.value)}
                >
                  <MenuItem value={"Effort Estimate"}>Effort Estimate</MenuItem>
                  <MenuItem value={"Cost Estimate"}>Cost Estimate</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialouge}>
            CANCEL
          </Button>
          <Button disabled={false} onClick={handelAddHeading}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="lg" open={isHeadingEditDialouge} onClose={() => {}}>
        <DialogTitle>Edit Heading</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
              <TextField
                label="Heading Name"
                variant="outlined"
                placeholder="Heading Name"
                type="text"
                name="headingName"
                required
                value={headingDetails.headingName}
                fullWidth
                sx={{ width: "400px" }}
                onChange={handleHeadingChange}
                // inputProps={{ maxLength: 2 }}
                // helperText="It should not cross more than 80 character*"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="Estimate-select">Estimate Type</InputLabel>
                <Select
                  labelId="Estimate-select"
                  id="Estimate-select"
                  value={headingDetails.estimateType}
                  label="Estimate Type"
                  name="estimateType"
                  onChange={handleHeadingChange}
                >
                  <MenuItem value={"Effort Estimate"}>Effort Estimate</MenuItem>
                  <MenuItem value={"Cost Estimate"}>Cost Estimate</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCancelHeadingEditDialouge}>
            CANCEL
          </Button>
          <Button disabled={false} onClick={handelEditHeading}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isAddOutlineDialouge}
        onClose={() => {}}
      >
        <DialogTitle>Add Outline</DialogTitle>
        <DialogContent dividers>
          {/* <Grid item xs={12} sm={12}> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
              <TextField
                label="Outline Name"
                variant="outlined"
                placeholder="Outline Name"
                type="text"
                name="outlineName"
                required
                value={outlineDetails.outlineName}
                fullWidth
                // sx={{ width: "400px" }}
                onChange={handleOutlineChange}
                // inputProps={{ maxLength: 2 }}
                // helperText="It should not cross more than 80 character*"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  {" "}
                  <Typography sx={{ ml: 1 }}>Outline Type : </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    component="fieldset"
                    // disabled={isEditAttributeData ? true : false}
                  >
                    <RadioGroup
                      row
                      name="isRequired"
                      value={outlineDetails.isRequired}
                      onChange={handleOutlineChange}
                    >
                      <FormControlLabel
                        // readOnly={isEditAttributeData ? true : false}
                        value={"mandatory"}
                        control={<Radio color="primary" />}
                        label="Mandatory"
                      />
                      <FormControlLabel
                        // readOnly={isEditAttributeData ? true : false}
                        value={"optional"}
                        control={<Radio color="primary" />}
                        label="Optional"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}
          {/* </CardContent> */}
          {/* </Card> */}
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCancelOutlineDialouge}>
            CANCEL
          </Button>
          <Button disabled={false} onClick={handelAddOutline}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="lg" open={isEditOutlineDialouge} onClose={() => {}}>
        <DialogTitle>Edit Outline</DialogTitle>
        <DialogContent dividers>
          {/* <Grid item xs={12} sm={12}> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
              <TextField
                label="Outline Name"
                variant="outlined"
                placeholder="Outline Name"
                type="text"
                name="outlineName"
                required
                value={outlineDetails.outlineName}
                fullWidth
                // sx={{ width: "400px" }}
                onChange={handleOutlineChange}
                // inputProps={{ maxLength: 2 }}
                // helperText="It should not cross more than 80 character*"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  {" "}
                  <Typography sx={{ ml: 1 }}>Outline Type : </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    component="fieldset"
                    // disabled={isEditAttributeData ? true : false}
                  >
                    <RadioGroup
                      row
                      name="isRequired"
                      value={outlineDetails.isRequired}
                      onChange={handleOutlineChange}
                    >
                      <FormControlLabel
                        // readOnly={isEditAttributeData ? true : false}
                        value={"mandatory"}
                        control={<Radio color="primary" />}
                        label="Mandatory"
                      />
                      <FormControlLabel
                        // readOnly={isEditAttributeData ? true : false}
                        value={"optional"}
                        control={<Radio color="primary" />}
                        label="Optional"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCancelEditOutlineDialouge}>
            CANCEL
          </Button>
          <Button disabled={false} onClick={(e) => handelEditOutline(e)}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth="md" open={isOutlineDelete} onClose={() => {}}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography>Do you want to delete outline?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => handelDeleteOutlineDialouge()}>
            CANCEL
          </Button>
          <Button disabled={false} onClick={removeEstimationOutline}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default EstimateTemplate;
