import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import React from "react";

const TABLE_HEAD = [
  {
    id: "period",
    label: "Period",
    alignRight: false,
  },
  {
    id: "date",
    label: "Date",
    alignRight: false,
  },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    alignRight: false,
  },
  {
    id: "customerID",
    label: "Customer ID",
    alignRight: false,
  },
  {
    id: "customerName",
    label: "Customer Name",
    alignRight: false,
  },
  {
    id: "gstNumber",
    label: "GST Number",
    alignRight: false,
  },
  {
    id: "currency",
    label: "Currency",
    alignRight: false,
  },
  {
    id: "amount",
    label: "Amount",
    alignRight: false,
  },
  {
    id: "outstandingAmount",
    label: "Outstanding Amount",
    alignRight: false,
  },
  {
    id: "igst",
    label: "IGST",
    alignRight: false,
  },
  {
    id: "cgst",
    label: "CGST",
    alignRight: false,
  },
  {
    id: "sgst",
    label: "SGST",
    alignRight: false,
  },
  {
    id: "amount",
    label: "Total Amount",
    alignRight: false,
  },
  {
    id: "status",
    label: "Status",
    alignRight: false,
  },
];

function AccountsReportTable({
  accountReports = [],
  page = 0,
  setPage,
  rowsPerPage = 5,
  setRowsPerPage,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ whiteSpace: "nowrap" }}>
              {TABLE_HEAD.map((th, index) => (
                <TableCell key={index}>{th.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accountReports
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                (
                  {
                    invoiceNumber = 0,
                    currency = "N/A",
                    cgst = "N/A",
                    sgst = "N/A",
                    igst = "N/A",
                    amountTotal = 0,
                    customerId = "N/A",
                    customerName = "N/A",
                    gstNumber = "N/A",
                    invoiceDate = "N/A",
                    dueAmount = 0,
                    paid = "N/A",
                    amount = 0,
                    outstandingAmount = "N/A",
                  },
                  index
                ) => {
                  return (
                    <TableRow key={index} sx={{ whiteSpace: "nowrap" }}>
                      <TableCell>
                        {new Date(invoiceDate).toDateString()}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {new Date(invoiceDate).toDateString()}
                      </TableCell>
                      <TableCell>{invoiceNumber}</TableCell>
                      <TableCell>{customerId}</TableCell>
                      <TableCell>{customerName}</TableCell>
                      <TableCell>{gstNumber}</TableCell>
                      <TableCell>{currency}</TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{outstandingAmount}</TableCell>
                      <TableCell>{igst ?? "N/A"}</TableCell>
                      <TableCell>{cgst ?? "N/A"}</TableCell>
                      <TableCell>{sgst ?? "N/A"}</TableCell>
                      <TableCell>{amountTotal}</TableCell>
                      <TableCell>
                        {dueAmount ? (
                          <b style={{ color: "red" }}>PENDING</b>
                        ) : (
                          <b style={{ color: "green" }}>PAID</b>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={accountReports?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default AccountsReportTable;
