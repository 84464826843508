import {
  Button,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import { useState } from "react";

const ActivityLogsBody = ({ results, page, setPage }) => {
  console.log("results", results);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (e, newPage) => {
    console.log("newPage", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > results.length &&
      results.length < pageCount
    ) {
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };
  return (
    <Paper>
      {results.length ? (
        <>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="medium"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Log Time</TableCell>
                  <TableCell align="left">Module</TableCell>
                  <TableCell align="left">Event</TableCell>
                  <TableCell align="left">Log Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((value) => {
                    return value;
                  })
                  .map((value, index) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" width={225}>
                          {new Date(value.createdAt).toDateString()}
                          {"   "}
                          {new Date(value.createdAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell align="left" width={200}>
                          {value.module}
                        </TableCell>
                        <TableCell align="left" width={200}>
                          {value.event}
                        </TableCell>
                        <TableCell align="left" width={225}>
                          {value.logMessage}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={results ? results.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>
                  <Typography>No Records</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default ActivityLogsBody;
