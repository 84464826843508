const apis = require("./api.requests");

const TAG = "report.service";
const path = "/open";

async function getReport(filter, onLoad, page, leadFilterOptions) {
  console.log("leadFilterOptions", leadFilterOptions);
  // Ensure the `to` date has a fallback
  const toDate = filter.to === "" ? new Date().getTime() : filter.to;

  // Construct the base query string
  const queryParams = new URLSearchParams({
    from: onLoad ? filter.from : filter.from,
    to: toDate.toString(),
    page: page.toString(),
    country: leadFilterOptions.country || "",
  });

  // Add optional fields if they exist
  if (leadFilterOptions?.status) {
    queryParams.append(
      "status",
      leadFilterOptions?.dropReason ? "Drop" : leadFilterOptions.status || ""
    );
  }
  if (leadFilterOptions?.salesOwner) {
    queryParams.append("salesOwner", leadFilterOptions.salesOwner || "");
  }
  if (leadFilterOptions?.skills) {
    queryParams.append("skills", leadFilterOptions.skills);
  }
  if (leadFilterOptions?.dropReason) {
    queryParams.append("reasons", leadFilterOptions.dropReason);
  }

  // Build the full URL
  const url = `${path}/report?${queryParams.toString()}`;

  // Call the API
  return await apis.hitGetApi(url);
}

async function getInvoiceReport(interval, salesOwners) {
  let { from, to } = interval;
  return await apis.hitGetApi(
    `/common/v1/getInvoiceReport?from=${from}&to=${to}&salesOwners=${[
      ...salesOwners,
    ]}`
  );
}

async function getAccountReport(interval, salesOwners) {
  let { from, to } = interval;
  return await apis.hitGetApi(
    `/common/v1/getAccountsReport?from=${from}&to=${to}&salesOwners=${[
      ...salesOwners,
    ]}`
  );
}

const getTechStacks = async () => {
  return await apis.hitGetApi(`${path}/techstacks`);
};

export { getReport, getInvoiceReport, getAccountReport };

// http://localhost:3022/api/open/report?from=1654860085&to=1668919343&page=1
