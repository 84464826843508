import { CircularProgress, Snackbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MailSyncContext } from "../../context/mailSync.context";

export default function SyncEmailSnackbar() {
  const { mailSync } = useContext(MailSyncContext);
  const [notInLeadPage, setNotInLeadPage] = useState(false);
  const pathName = window.location.pathname;
  console.log(pathName.split("/")[1]);

  const action = (
    <React.Fragment>
      <CircularProgress color="inherit" size="1.3rem" />
    </React.Fragment>
  );

  useEffect(() => {
    if (
      pathName.split("/")[1] !== "Leads" &&
      mailSync &&
      pathName.split("/")[1] !== "emails" &&
      pathName.split("/")[1] !== ""
    ) {
      setNotInLeadPage(true);
    } else {
      setNotInLeadPage(false);
    }
  }, [pathName, mailSync]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={notInLeadPage}
      message={mailSync ? "Your Emails Are Fetching" : null}
      action={action}
    />
  );
}
