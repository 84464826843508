import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Scrollbar from "../UI/Scrollbar";

export default function ListMail({ data, emails, index, setIndex }) {
  console.log("lunch", emails);
  const [loaderDialog, setLoaderDialog] = useState(false);

  const getIndex = (idx) => {
    setIndex(idx);
  };

  return (
    <Grid container spacing={0}>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* <CircularProgress /> */}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>

      <Grid item xs={12}>
        <FormControl>
          <Grid container spacing={2} sx={{ display: "inline-flex" }}>
            {emails.map((element, index) => {
              return (
                <>
                  <Grid item xs={2}>
                    <Avatar>
                      {/* {props.name && props.name.charAt(0)} */}
                      {element.from.charAt(0).toUpperCase()}
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    key={index}
                    onClick={() => {
                      getIndex(index);
                    }}
                  >
                    {/* <h4 style={{ margin: 0, textAlign: "left", display: "inline" }}>{props.name}</h4> <span>&nbsp;&nbsp;</span> says on  <span>&nbsp;&nbsp;</span>
                                            <Typography variant="caption" display="inline" sx={{ color: "#808080", marginTop: "10px" }} gutterBottom={false}>
                                                {new Date(props.createdAt).toDateString()}
                                            </Typography>
                                            {props.fromLeadPage || props.fromContactPage ? (currentUser._id === props.techOwner || currentUser._id === props.salesOwner || currentUser.role === "ADMIN") ? props.price ? <IconButton sx={{ color: "primary", height: "20px" }} onClick={handleClickOpen}><AttachMoneyIcon color="success" size="small" /></IconButton> : null : null : null}
                                            <pre style={{ fontFamily: "sans-serif" }}>{props.comment}</pre> */}
                    <span style={{ fontWeight: "bolder" }}>{element.from}</span>{" "}
                    <span style={{ fontSize: "12px", lineHeight: "0px" }}>
                      {element.date}
                    </span>
                    <br />
                    {/* <span>{element.About}</span> */}
                    <span>{element.subject}</span>
                    {/* <p style={{ textAlign: "left", color: "gray" }}>
                                             posted 1 minute ago
                                        </p> */}
                    {index === emails.length - 1 ? null : <Divider />}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
