import { Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import RestorePageIcon from "@mui/icons-material/RestorePage";

const data = " was deleted on ";

const docName = "Access.pdf";
const deletedFromLead = "RO Systems";
const deletedFromProject = "Fitter App";

const ArchiveBody = ({
  popup,
  setPopup,
  handleRestorePopupOpen,
  archiveData,
}) => {
  const formatName = (documentName) => {
    const split = documentName.split("/");
    const lastIndexName = split[split.length - 1];
    const lastName = lastIndexName.split("-");
    lastName.shift();
    const doc = lastName.join(" ");
    return doc;
  };
  //-----For Date And Time:->
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function dateChange(date) {
    console.log(date);
    const dateTimeInSeconds = new Date(date).getTime();
    console.log(dateTimeInSeconds);
    const offSet = new Date().getTimezoneOffset();
    console.log(offSet);
    const offSetInSeconds = offSet * 60 * 1000;
    console.log(offSetInSeconds);
    const newDateInSeconds = dateTimeInSeconds - offSetInSeconds;
    console.log(newDateInSeconds);
    const dateString = [
      padTo2Digits(new Date(newDateInSeconds).getUTCDate()),
      padTo2Digits(new Date(newDateInSeconds).getUTCMonth() + 1),
      new Date(newDateInSeconds).getUTCFullYear(),
    ].join("/");
    if (
      new Date(new Date().setHours(0, 0, 0, 0)).getHours() ===
        new Date(newDateInSeconds).getUTCHours() &&
      new Date(new Date().setHours(0, 0, 0, 0)).getMinutes() ===
        new Date(newDateInSeconds).getUTCMinutes()
    ) {
      return dateString;
    } else {
      return dateString + "  at  " + placeHoursAndMinutes(newDateInSeconds);
    }
  }

  function placeHoursAndMinutes(newDateInSeconds) {
    const hours = new Date(newDateInSeconds).getUTCHours();
    const stamp = hours > 12 ? "PM" : "AM";
    const newHours =
      hours > 12
        ? hours - 12 >= 10
          ? hours - 12
          : `0${hours - 12}`
        : hours >= 10
        ? hours
        : `0${hours}`;
    const minutes = new Date(newDateInSeconds).getUTCMinutes();
    const newMinutes = minutes >= 10 ? minutes : `0${minutes}`;
    const time = `${newHours}:${newMinutes} ${stamp}`;
    return time;
  }

  return (
    <Grid container spacing={1}>
      {archiveData.length > 0 ? (
        archiveData.map((element, index) => {
          if (!element.active_status) {
            return (
              <Grid item xs={12} key={index}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={11}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <span style={{ fontWeight: "bold" }}>
                        {formatName(element.location)}
                      </span>
                      &nbsp;{data} &nbsp;
                      <span style={{ fontWeight: "bold" }}>
                        {dateChange(element.updatedAt)}
                      </span>
                      &nbsp; from &nbsp;
                      {element.from === "LEADS" ? (
                        <span style={{ color: "#4a148c", fontWeight: "bold" }}>
                          {element.summary}
                        </span>
                      ) : element.from === "PROJECT" ? (
                        <span style={{ color: "#006064", fontWeight: "bold" }}>
                          {" "}
                          {element.title}
                        </span>
                      ) : (
                        <span style={{ color: "#2986DD", fontWeight: "bold" }}>
                          {element.name}
                        </span>
                      )}
                      &nbsp; by &nbsp;
                      <span style={{ fontWeight: "bold" }}>
                        {element.userName}
                      </span>
                    </span>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Restore Document">
                      <Button
                        onClick={() => {
                          console.log(element);
                          handleRestorePopupOpen(
                            element._id,
                            element.from,
                            element.projectId,
                            element.leadId,
                            element.contactId,
                            element.companyId
                          );
                        }}
                      >
                        <RestorePageIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingRight: "25px" }}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        })
      ) : (
        <Grid item xs={12}>
          <Typography sx={{ textAlign: "center" }}>Archive is empty</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ArchiveBody;
