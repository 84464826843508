export function removeDuplicateSkills(techStacks = []) {
  const uniqueTechStacks = new Set();
  const uniqueData = [];

  for (const techStack of techStacks) {
    const lowerCaseName = techStack.name.toLowerCase();

    if (!uniqueTechStacks.has(lowerCaseName)) {
      uniqueTechStacks.add(lowerCaseName);
      uniqueData.push(techStack);
    }
  }

  return uniqueData;
}
