import { createContext, useEffect, useState } from "react";
import { getMyActions } from "../services/common.service";
import { setContactAction } from "../services/contacts.service";
import { setLeadAction } from "../services/leads.service";
import { setProjectAction } from "../services/projects.service";
import { companyActionMarkAsDone } from "../services/companies.service";

export const ActionContext = createContext();

const ActionAboutProvider = ({ children }) => {
  const [refetchAction, setRefetchAction] = useState(false);

  const [actionList, setActionList] = useState([]);
  const [rowColor, setRowColor] = useState(null);
  const fetchActions = () => {
    getMyActions()
      .then((res) => {
        if (res.data.status) {
          setActionList([]);
          setActionList((prev) => {
            const temp = [...prev];
            res.data.data.map((item) => {
              item["checked"] = false;
              item["completed"] = false;
              temp.push(item);
            });
            return temp;
          });
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCompleteTask = async (action) => {
    try {
      let response;
      const date = {
        doneOn: new Date(),
      };
      if (action.actionType === "LEADS") {
        response = await setLeadAction(
          action.contactId,
          action.leadId,
          action._id,
          date
        );
        console.log(response);
      }
      if (action.actionType === "PROJECTS") {
        response = await setProjectAction(action.projectId, action._id, date);
      }
      if (action.actionType === "CONTACTS") {
        response = await setContactAction(action.contactId, action._id, date);
      }
      if (action.actionType === "COMPANIES") {
        response = await companyActionMarkAsDone(
          action.companyId,
          action._id,
          date
        );
      }
      if (response && response.data.status) {
        setRefetchAction((prev) => !prev);
        setActionList((prev) => {
          const temp = [...prev];
          temp[temp.indexOf(action)].checked = true;
          temp[temp.indexOf(action)].completed = true;
          return temp;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionContext.Provider
      value={{
        actionList,
        setActionList,
        fetchActions,
        handleCompleteTask,
        refetchAction,
        rowColor,
        setRowColor,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};

export default ActionAboutProvider;
