import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth.context";
import {
  getUserById,
  saveEmailIntegration,
} from "../../services/users.service";
import { encryption } from "../../utils/encryption";
import {
  createDataInIndexedDb,
  insertDataInIndexedDb,
} from "../../utils/indexedDb";
import Iconify from "../UI/Iconify";
import { MailSyncContext } from "../../context/mailSync.context";

export default function MailClientConsentDialog() {
  const idb =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;
  const {
    currentUser,
    setCurrentUser,
    toggleShowMailConsentPopup,
    showMailConsentPopup,
    setShowMailConsentPopup,
    setEmailPassword,
  } = React.useContext(AuthContext);

  const {
    handlegetAllContactEmail,
    mailSync,
    setMailSync,
    openPasswordAlert,
    setOpenPasswordAlert,
  } = React.useContext(MailSyncContext);

  const [formValue, setFormValue] = useState({
    email: currentUser.email,
    email_password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isAuthFailed, setIsAuthFailed] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    setIsAuthFailed(false);
    setFormValue((prev) => {
      const temp = { ...prev };
      temp[e.target.name] = e.target.value;
      return temp;
    });
  };

  const handleAgree = async () => {
    setShowMailConsentPopup(false);
    if (!formValue.email_password) {
      toast("Enter Your Email's Password");
      return;
    }
    setFormValue((prev) => ({
      ...prev,
      email: currentUser.email,
    }));
    const encryptPassword = encryption(formValue.email_password);
    createDataInIndexedDb();
    const insertData = await insertDataInIndexedDb(null, encryptPassword);
    console.log(
      "insertData",
      insertData,
      "formValue",
      formValue.email_password
    );
    const emailFetch = await handlegetAllContactEmail();
    console.log("emailFetch", emailFetch);
    if (
      emailFetch === "Aunthetication failed" ||
      emailFetch === "Timed out while authenticating with server"
    ) {
      setIsAuthFailed(true);
    } else {
      setEmailPassword(formValue.email_password);
      formValue.email_password = "";
      toggleShowMailConsentPopup();
    }
  };

  useEffect(() => {
    setFormValue((prev) => {
      const temp = { ...prev };
      temp.email = currentUser.email;
      temp.email_password = "";
      return temp;
    });
  }, [currentUser]);

  return (
    <Dialog open={showMailConsentPopup} onClose={() => {}}>
      <DialogTitle sx={{ textAlign: "center" }}>Consent Form</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", m: 2 }}>
          By clicking on "AGREE", you agree that we can read your mails and
          automate mails from your email.
        </DialogContentText>
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          name="email"
          value={currentUser.email}
          fullWidth
          variant="outlined"
          disabled
        />
        <TextField
          margin="dense"
          label="Enter Your Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          name="email_password"
          value={isAuthFailed ? "" : formValue.email_password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShowMailConsentPopup} color="error">
          DISAGREE
        </Button>
        <Button onClick={handleAgree}>AGREE</Button>
      </DialogActions>
    </Dialog>
  );
}
