import React, { useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
  createFilterOptions,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  TableHead,
  Link,
} from "@mui/material";
import { toast } from "react-toastify";
import { getCountries, getSources } from "../../services/common.service.js";
import {
  updateContact,
  getCompanyNames,
} from "../../services/contacts.service.js";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { getUsersByRoles } from "../../services/users.service.js";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context.js";
import EntityAttributeFields from "../Shared/Custom/EntityAttributeFields.js";
import { getAllCompanies } from "../../services/companies.service.js";
import { useDynamicContactTitle } from "../../pages/Contacts/Contacts.js";
import { useDynamicCompanyTitle } from "../../pages/Companies/Companies.js";
import getAttributesByEntity from "../../services/entity.attribute.services.js";
import validateUniqueAttributes from "../../utils/customAttributesUtils.js";
import { capitalizedString } from "../../utils/capitalizedString.js";

//var TempContact;
var regex = /^[a-zA-Z ]*$/;
var regexPhone = /^\+?\d*$/;
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,7})*$/;

const specialCharRegex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/;

const Info = ({
  setContact,
  contact,
  toEdit,
  setToEdit,
  id,
  isEditPage,
  TempContact,
  setTempContact,
  fromLeadViewPage,
  fromCompanyContactViewPage,
  fromContactViePage,
  setUpdateContactCalled,
  salesOwnerInfoCall,
  filterUsers,
  sourceData,
  isAutocomplete,
  fromCreatePage,
  getSourceLeadList,
  contactCreated,
  fromCompanyCreatePage,
  customAttributes,
  setCustomAttributes,
  afterCreate,
  setIactionedApiCalled,
}) => {
  const contactDynamicTitle = useDynamicContactTitle();
  // const notify = () => toast("Sucessfully Added");
  // useEffect(() => {
  //   TempContact = { ...contact };
  // }, []);

  console.log("Contact", contact);
  console.log("temp contact", TempContact);

  const navigate = useNavigate();
  const [parentCompanies, setParentCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userType, setUserType] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [display, setdisplay] = useState("");
  const [saveButton, setSaveButton] = useState(false);
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  console.log("Current user", currentUser);
  // const [countryCode, setCountryCode] = useState("");
  // const [value, setValue] = useState("");
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [sources, setSources] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [value, setValue] = React.useState(null);
  const filter = createFilterOptions();
  const [open, setOpen] = React.useState(false);

  // call getCompanyNames api
  const getCompanyName = async () => {
    await getCompanyNames().then((res) => {
      if (res.data.status) {
        console.log("getCompanyNames", res.data);
        setCompanyName(res.data.data);
      }
    });
  };
  React.useEffect(() => {
    getCountries().then((res) => {
      if (res.data.status) {
        console.log("company Name", res.data);
        setCountries(res.data.data);
      }
    });
    getCompanyName();
    getSourcesCall();
    getCompanyListing();
  }, [contactCreated]);

  const getCompanyListing = () => {
    getAllCompanies()
      .then((res) => {
        if (res.data.status) {
          setParentCompanies(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSourcesCall = async () => {
    await getSources().then((res) => {
      if (res.data.status) {
        console.log("sourcedata", res.data);
        setSources(res.data.data);
      }
    });
  };

  React.useEffect(() => {
    getUsersByRoles(3).then((res) => {
      if (res.data.status) {
        console.log("role data", res.data);
        setUserType(res.data.data);
      }
    });
  }, []);

  const handleChange = (e) => {
    function handleInputValidation(event, fieldName, companyDynamicTitle) {
      const value = event.target.value;
      if (value && !value.trim()) {
        toast.warning(
          `${companyDynamicTitle?.displayName.singularName} ${fieldName} cannot contain only spaces`
        );
        return true;
      }
      return false;
    }

    if (
      handleInputValidation(e, "name", contactDynamicTitle) ||
      handleInputValidation(e, "email", contactDynamicTitle) ||
      handleInputValidation(e, "address", contactDynamicTitle) ||
      handleInputValidation(e, "linkedIn", contactDynamicTitle)
    ) {
      return;
    }

    if (e.target.name === "name") {
      const value = regex.test(e.target.value);
      console.log("valuefdf", e.target.name);
      if (!value) {
        toast("Numbers and special characters are not allowed");
        return;
      }
      if (e.target.value && e.target.value.length > 30) {
        toast.warning(
          `${companyDynamicTitle?.displayName.singularName} name should not be more then 30 characters.`
        );
        return;
      }
    }

    if (
      e.target.name === "address" &&
      e.target.value &&
      e.target.value.length > 250
    ) {
      toast.warning(
        `${companyDynamicTitle?.displayName.singularName} address should not be more then 250 characters.`
      );
      return;
    }

    // if (
    //   e.target.name === "linkedIn" &&
    //   e.target.value &&
    //   e.target.value.length > 250
    // ) {
    //   toast.warning(
    //     `${companyDynamicTitle?.displayName.singularName} address should not be more then 250 characters.`
    //   );
    //   return;
    // }

    setContact((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  let toastId = null;
  const handleChangePhone = (e) => {
    if (e.target.name === "phone" || e.target.name === "alternateContact") {
      const value = regexPhone.test(e.target.value);
      if (!value) {
        toast(
          "Alphabets, spaces and special characters except '+' are not allowed"
        );
        return;
      }
    }
    if (e.target.value.length > 14) {
      if (toastId == null) {
        toastId = toast.warning(
          "It should not cross more than 14 digits Including Contry code"
        );
        return;
      } else {
        toast.update(
          toastId,
          "It should not cross more than 14 digits Including Contry code"
        );
        return;
      }
    }
    setContact((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRoutingToContatPage = (e, contactId) => {
    e.stopPropagation();
    setOpen(false);
    navigate(`/contacts/${contactId}`, { replace: true });
  };

  const handleSourceChange = (e) => {
    setContact((prevState) => ({
      ...prevState,
      source: e.target.value,
    }));
    // console.log(contact);
  };

  const handleSalesOwnerChange = (e) => {
    setContact((prevState) => ({
      ...prevState,
      salesOwner: e.target.value,
    }));
    // console.log(contact);
  };

  const CheckForUpdate = () => {
    // console.log(contact, customAttributes);
    const keys = Object.keys(contact);
    const res = {};
    // console.log(TempContact);
    // console.log(contact);
    keys.forEach((key) => {
      if (key === "source") {
        console.log("170 Triggered");
        if (contact[key].name !== TempContact[key].name)
          res[key] = contact[key];
      }
      if (key !== "source") {
        console.log("174 Triggered");
        if (contact[key] !== TempContact[key]) res[key] = contact[key];
      }
      if (key === "company") {
        if (contact[key] !== TempContact[key]) {
          // if (typeof contact[key]["name"] === "string") {
          //   res[key] = contact[key]["name"];
          // } else {
          //   res[key] = contact[key]["name"]
          //     ? contact[key]["name"]["companyName"]
          //     : "";
          // }
          /** new feature: company can update from contact*/
          res[key] = contact[key];
          res["previousCompnay"] = TempContact[key];
        }
      }
    });
    const updatedAttributes = validateUniqueAttributes(
      contact.attributes,
      customAttributes
    );
    if (updatedAttributes.length) {
      res["attributes"] = [...updatedAttributes];
    }
    if (res.company === "") {
      delete res.company;
      return res;
    }
    return res;
  };

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }
      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const validate = (company) => {
    const fields = ["name", "country", "email", "salesOwner"];
    let flag = true;
    Object.keys(company).forEach((ele) => {
      if (fields.includes(ele) && !company[ele]) {
        toast(`${capitalizedString(ele)} can not be empty`);
        flag = false;
      }
    });
    return flag;
  };

  const UpdateContact = async () => {
    console.log("Update Contact called", contact);
    const temp = { ...contact };
    temp.company =
      temp.company.name != null ? temp.company.name.companyName : "";
    // temp.company = temp.company.name.companyName;
    console.log("update", temp);
    // temp.source = { source: contact.source };
    delete temp.source._id;
    console.log("UpdateContact", temp);
    const result = CheckForUpdate();
    if (Object.hasOwn(result, "email") && !emailRegex.test(result.email)) {
      toast("Please enter a valid email address");
      return;
    }
    const checkMandatoryFieldStatus = validate(result);
    if (!checkMandatoryFieldStatus) {
      return;
    }
    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      return;
    }
    console.log(result);
    if (Object.keys(result).length > 0) {
      try {
        setSaveButton(true);
        setLoaderDialog(true);
        console.log("Custom attributes", customAttributes);
        const response = await updateContact(id, {
          ...result,
          attributes: [...customAttributes],
        });
        if (response.data.status) {
          salesOwnerInfoCall();
          filterUsers();
          getSourcesCall();
          setTempContact(temp);
          toast(
            `${contactDynamicTitle?.displayName.singularName} successfully updated`
          );
          setToEdit(true);
          setSaveButton(false);
          setLoaderDialog(false);
          setUpdateContactCalled(false);
          setIactionedApiCalled(true);
        }
      } catch (error) {
        console.log(error);
        setLoaderDialog(false);

        toast.warn(error.response.data.message);

        // toast(error.response.data.message);
        setToEdit(false);
        setSaveButton(false);
        // setLoaderDialog(false);
      }
    } else {
      toast("Nothing to update");
      setToEdit(false);
      setSaveButton(false);
      setLoaderDialog(false);
    }
  };

  const handleNullSource = (data) => {
    if (data) {
      console.log("Inside data");
      if (typeof data !== undefined) {
        // console.log("handleNullSource Inside data 1st if");
        return data;
      } else {
        // console.log("handleNullSource Inside data 1st else");
        return "SELECT";
      }
    }
    // else {
    //   console.log("Inside else")
    //   return "SELECTED"
    // }
  };

  const handleNullCompanyName = (data) => {
    // console.log("handleNullCompanyName data", data);
    if (data) {
      if (data !== undefined) {
        return data;
      } else {
        return "SELECT";
      }
    }
  };

  // source dialouge box
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderContactIconByCompanyValue = (data) => {
    if (data === undefined || data === null || data === "") {
      return true;
    }
    return false;
  };

  const handleInvalidData = (data) => {
    if (data !== undefined && data !== null && data !== "") {
      if (data.name === undefined || data.name === null || data.name === "") {
        return true;
      }
    }
    return false;
  };

  const handlePureData = (data) => {
    if (data !== undefined && data !== null && data !== "") {
      if (
        data.name !== undefined &&
        data.name !== null &&
        data.name.length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const companyDynamicTitle = useDynamicCompanyTitle();

  const isValidSourceName = (s) => /[a-zA-Z0-9]/.test(s);

  // Custom Attribute

  useEffect(() => {
    if (fromCreatePage) {
      (async () => {
        try {
          const response = await getAttributesByEntity("Contacts");
          if (response.data.status) {
            setCustomAttributes(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [afterCreate, contactCreated]);

  return (
    <Card
      className="child"
      sx={{ minWidth: "auto", minHeight: "auto" }}
      elevation={3}
      variant="outlined"
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          {isEditPage ? (
            currentUser._id === contact.salesOwner ||
            currentUser.role === "ADMIN" ? (
              toEdit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    startIcon={<ModeEditIcon />}
                    fontSize="small"
                    style={{ marginRight: "28px" }}
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setToEdit(false);
                      setReadOnly(false);
                      setdisplay("");
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    style={{ marginRight: "28px", display: "display" }}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    size="large"
                    disabled={saveButton}
                    onClick={() => {
                      UpdateContact();
                    }}
                  >
                    Save
                  </Button>
                  <div>&nbsp;</div>
                  <Button
                    style={{
                      display: "display",
                      marginRight: "2px",
                      height: "48px",
                      width: "107.39px",
                    }}
                    variant="contained"
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setContact({ ...TempContact });
                      setToEdit(true);
                      setdisplay("none");
                      setCustomAttributes(contact.attributes);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )
            ) : null
          ) : null}
        </Grid>
        <Dialog open={loaderDialog}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "20px",
            }}
          >
            Loading... Please Wait
          </DialogActions>
        </Dialog>
      </Grid>

      <CardContent>
        <FormControl>
          <Grid container spacing={4}>
            {!fromCompanyCreatePage ? (
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth required>
                  <InputLabel>{`Select ${companyDynamicTitle?.displayName.singularName}`}</InputLabel>
                  {fromCompanyContactViewPage ? (
                    <Select
                      readOnly={
                        fromCompanyContactViewPage
                          ? true
                          : toEdit
                          ? true
                          : false
                      }
                      label={`Select ${companyDynamicTitle?.displayName.singularName}`}
                      name="company"
                      value={contact.company}
                      onChange={(event) => {
                        setContact((prev) => ({
                          ...prev,
                          [event.target.name]: event.target.value,
                        }));
                      }}
                    >
                      {parentCompanies.map((ele, ind) => (
                        <MenuItem key={ind} value={ele._id}>
                          {ele.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      readOnly={
                        fromCreatePage
                          ? false
                          : fromLeadViewPage
                          ? true
                          : toEdit
                          ? true
                          : false
                      }
                      label={`Select ${companyDynamicTitle?.displayName.singularName}`}
                      name="company"
                      value={contact.company}
                      onChange={(event) => {
                        setContact((prev) => ({
                          ...prev,
                          [event.target.name]: event.target.value,
                        }));
                      }}
                    >
                      {companyName.map((ele, ind) => {
                        return (
                          <MenuItem key={ind} value={ele._id}>
                            {ele.companyName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <TextField
                // inputProps={isEditPage ? { readOnly: inputProps } : fromLeadViewPage ? { readOnly: true } : inputProps}
                inputProps={{
                  readOnly: fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false,
                }}
                // disabled={inputProps}
                label="Name"
                variant="outlined"
                placeholder="Name"
                type="text"
                name="name"
                data-testid="contactName"
                required
                value={contact.name}
                fullWidth
                // InputLabelProps={{ shrink: obj.name ? true : false }}
                onChange={
                  /* (e) => {
                  handleChange("name", e.target.value);
                  infoObj.name = e.target.value;
                  callback(infoObj);
                } */
                  handleChange
                }
              ></TextField>
            </Grid>
            {/* <Grid item xs={12} sm={6}> */}
            {fromCompanyCreatePage ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  InputProps={{ readOnly: true }}
                  value={contact.company}
                  variant="outlined"
                  label={`${companyDynamicTitle?.displayName.singularName} Name`}
                  fullWidth
                />
              </Grid>
            ) : // <Grid container spacing={0}>
            //   <Grid
            //     item
            //     xs={12}
            //     sm={fromLeadViewPage ? 12 : fromCreatePage ? 12 : 11}
            //   >
            //     {/* <TextField

            //     // disabled={toEdit}
            //     inputProps={{
            //       readOnly: true,
            //     }}
            //     label="Company Name"
            //     variant="outlined"
            //     placeholder="Company name"
            //     type="text"
            //     name="company"
            //     value={contact.company}
            //     fullWidth
            //     // InputLabelProps={{ shrink: obj.company ? true : false }}
            //     onChange={handleChange}
            //   ></TextField> */}
            //     <Autocomplete
            //       // readOnly={isEditPage ? toEdit : true}
            //       // disabled={toEdit}
            //       // readOnly={
            //       //   fromLeadViewPage
            //       //     ? true
            //       //     : fromContactViePage
            //       //     ? true
            //       //     : false
            //       // }
            //       readOnly={
            //         fromLeadViewPage
            //           ? true
            //           : isEditPage && handlePureData(TempContact.company)
            //           ? true
            //           : isEditPage &&
            //             toEdit &&
            //             handleInvalidData(TempContact.company)
            //           ? true
            //           : false
            //       }
            //       value={
            //         handleNullCompanyName(
            //           fromLeadViewPage
            //             ? contact.company
            //             : contact.company.name
            //         ) ||
            //         (fromLeadViewPage
            //           ? contact.company
            //           : contact.company.name)
            //       }
            //       onChange={(event, newValue) => {
            //         console.log("comp", newValue);
            //         if (typeof newValue === "string") {
            //           setContact((prev) => ({
            //             ...prev,
            //             company: {
            //               name: newValue.trim(),
            //             },
            //           }));
            //         } else if (newValue && newValue.inputValue) {
            //           setContact((prev) => ({
            //             ...prev,
            //             company: {
            //               name: newValue.inputValue.trim(),
            //             },
            //           }));
            //         } else {
            //           setContact((prev) => ({
            //             ...prev,
            //             company: { name: newValue },
            //           }));
            //         }
            //       }}
            //       filterOptions={(options, params) => {
            //         const filtered = filter(options, params);

            //         const { inputValue } = params;
            //         // Suggest the creation of a new value
            //         const isExisting = options.some(
            //           (option) => inputValue === option.companyName
            //         );
            //         if (inputValue !== "" && !isExisting) {
            //           filtered.push({
            //             inputValue,
            //             companyName: `Add "${inputValue}"`,
            //           });
            //         }

            //         return filtered;
            //       }}
            //       selectOnFocus
            //       // clearOnBlur={}
            //       clearOnBlur
            //       handleHomeEndKeys
            //       id="free-solo-with-text-demo"
            //       options={companyName.filter((company) => {
            //         return company.companyName !== "";
            //       })}
            //       // getOptionLabel={value => typeof value === 'undefined' ? '<undefined>' : `${value}`}
            //       getOptionLabel={(option) => {
            //         // Value selected with enter, right from the input
            //         if (typeof option === "string") {
            //           // console.log("Returns string");
            //           return option;
            //         }
            //         if (typeof option.companyName === "undefined") {
            //           // console.log("Returns empty string");
            //           return "";
            //         }
            //         // Add "xxx" option created dynamically
            //         if (option.inputValue) {
            //           // console.log("Returns empty value");
            //           return option.inputValue;
            //         }
            //         // Regular option
            //         // console.log("Returns option.name");
            //         return option.companyName;
            //       }}
            //       renderOption={(props, option) => (
            //         <li {...props} style={{ borderColor: "white" }}>
            //           {handleNullCompanyName(option.companyName)}
            //         </li>
            //       )}
            //       freeSolo
            //       renderInput={(params) => (
            //         <TextField
            //           {...params}
            //           label="Company Name"
            //           placeholder="Click To Add"
            //         />
            //       )}
            //     />
            //     {/* </Box> */}
            //     <span
            //       style={{
            //         fontSize: "10px",
            //         marginLeft: "8px",
            //         paddingBottom: 0,
            //         color: "#808080",
            //       }}
            //     >
            //       *To Add An Unregistered Company Name, Write Its Name & Hit
            //       Enter
            //     </span>
            //   </Grid>
            //   {fromLeadViewPage ? null : fromCreatePage ? null : renderContactIconByCompanyValue(
            //       contact.company.name
            //     ) ? null : (
            //     <Grid
            //       item
            //       alignItems="stretch"
            //       style={{ margin: 0, padding: 0 }}
            //     >
            //       <IconButton>
            //         {/* <IconButton> */}

            //         <PortraitSharpIcon
            //           onClick={() => {
            //             console.log(contact);
            //             handleClickOpen();
            //             getSourceLeadList(
            //               typeof contact.company.name === "object"
            //                 ? contact.company.name.companyName
            //                 : contact.company.name
            //             );
            //           }}
            //           color="primary"
            //           style={{
            //             width: 35,
            //             height: 35,
            //             padding: 0,
            //           }}
            //         />
            //       </IconButton>
            //     </Grid>
            //   )}
            // </Grid>
            null}
            {/* </Grid> */}

            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                // inputProps={{ readOnly: true }}
                // disabled={toEdit}
                inputProps={{
                  readOnly: fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false,
                }}
                variant="outlined"
                placeholder="Email ID"
                type="email"
                name="email"
                data-testid="contactEmail"
                required
                value={contact.email}
                // value={contact.data.email}
                fullWidth
                // InputLabelProps={{ shrink: obj.email ? true : false }}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={toEdit}
                // inputProps={{ readOnly: true }}
                inputProps={{
                  readOnly: fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false,
                }}
                label="Phone"
                variant="outlined"
                placeholder="Ex: +919876543210"
                type="text"
                name="phone"
                data-testid="contactPhone"
                value={contact.phone}
                // value={contact.data.phone}
                fullWidth
                // InputLabelProps={{ shrink: obj.phone ? true : false }}
                onChange={handleChangePhone}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={toEdit}
                // inputProps={{ readOnly: true }}
                inputProps={{
                  readOnly: fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false,
                }}
                label="Alternate contact"
                variant="outlined"
                type="text"
                name="alternateContact"
                placeholder="Ex: +919876543210"
                value={contact.alternateContact}
                // value={contact.data.alternateContact}
                fullWidth
                // InputLabelProps={{
                //   shrink: obj.alternateContact ? true : false,
                // }}
                onChange={handleChangePhone}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={toEdit}
                // inputProps={{ readOnly: true }}
                inputProps={{
                  readOnly: fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false,
                }}
                label="Address"
                variant="outlined"
                placeholder="Address"
                type="text"
                name="address"
                data-testid="contactAddress"
                value={contact.address}
                // value={contact.data.address}
                fullWidth
                // InputLabelProps={{ shrink: obj.address ? true : false }}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-country-select-label">
                    Country *
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    inputProps={{
                      readOnly: fromLeadViewPage
                        ? true
                        : isEditPage
                        ? toEdit
                          ? true
                          : false
                        : false,
                    }}
                    labelId="demo-country-select-label"
                    id="demo-country-select"
                    // value={contact.data.country}
                    label="Country"
                    name="country"
                    data-testid="contactCountry"
                    value={contact.country}
                    // InputLabelProps={{ shrink: obj.country ? true : false }}
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>SELECT</MenuItem>
                    {countries.map((country, index) => {
                      return (
                        <MenuItem key={index} value={country}>
                          {country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // disabled={toEdit}
                // inputProps={{ readOnly: true }}
                inputProps={{
                  readOnly: fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false,
                }}
                label="LinkedIn Profile"
                variant="outlined"
                placeholder="LinkedIn"
                type="text"
                name="linkedIn"
                data-testid="contactLinkedIn"
                value={contact.linkedIn}
                // value={contact.data.linkedIn}
                fullWidth
                // InputLabelProps={{ shrink: obj.linkedIn ? true : false }}
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Box> */}
              <Autocomplete
                readOnly={
                  fromLeadViewPage
                    ? true
                    : isEditPage
                    ? toEdit
                      ? true
                      : false
                    : false
                }
                // value={handleNullSource(contact.source) || contact.source}
                value={contact.source ?? ""}
                onChange={(event, newValue) => {
                  console.log("src", newValue);
                  if (typeof newValue === "string") {
                    if (newValue && !isValidSourceName(newValue)) {
                      toast("Source cannot contain only special characters");
                      return;
                    }
                    setContact((prev) => ({
                      ...prev,
                      source: {
                        name: newValue.trim(),
                      },
                    }));
                  } else if (newValue && newValue.inputValue) {
                    console.log(
                      "newValue",
                      newValue.inputValue,
                      typeof newValue.inputValue
                    );
                    // Create a new value from the user input
                    setContact((prev) => ({
                      ...prev,
                      source: {
                        name: newValue.inputValue.trim(),
                      },
                    }));
                  } else {
                    setContact((prev) => ({
                      ...prev,
                      source: newValue,
                    }));
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={sources.filter((source) => {
                  return source.name !== "";
                })}
                // getOptionLabel={value => typeof value === 'undefined' ? '<undefined>' : `${value}`}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    // console.log("Returns string");
                    return option;
                  }
                  if (typeof option.name === "undefined") {
                    // console.log("Returns empty string");
                    return "";
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    // console.log("Returns empty value");
                    return option.inputValue;
                  }
                  // Regular option
                  // console.log("Returns option.name");
                  return option.name ?? "";
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ borderColor: "white" }}>
                    {/* {handleNullSource(option.name)} */}
                    {option.name ?? ""}
                  </li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    placeholder="Click To Add"
                  />
                )}
              />
              {/* </Box> */}
              <span
                style={{
                  fontSize: "10px",
                  marginLeft: "8px",
                  color: "#808080",
                }}
              >
                *To Add An Unregistered Source, Write Its Name & Hit Enter
              </span>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-sales-owner-label">
                    Sales Owner
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    inputProps={{
                      readOnly: fromLeadViewPage
                        ? true
                        : isEditPage
                        ? toEdit
                          ? true
                          : false
                        : false,
                    }}
                    labelId="demo-simple-select-sales-owner-label"
                    id="demo-simple-select-sales-owner"
                    label="salesOwner"
                    name="salesOwner"
                    value={contact.salesOwner}
                    onChange={handleSalesOwnerChange}
                  >
                    <MenuItem value={""}>SELECT</MenuItem>
                    {userType?.map((e) => {
                      if (e.active_status === true) {
                        return (
                          <MenuItem key={e._id} value={e._id}>
                            {e.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {customAttributes?.length ? (
              // <Grid container spacing={4} mt="2px">
              <>
                {" "}
                {customAttributes.map((attribute, index) => (
                  <Grid item xs={6} key={index}>
                    <EntityAttributeFields
                      componentIndex={index}
                      componentDeatils={attribute}
                      allAttributes={customAttributes}
                      setterFunction={setCustomAttributes}
                      entityName={"Contact"}
                      isEditPage={isEditPage}
                      toEdit={toEdit}
                      fromLeadViewPage={fromLeadViewPage}
                    />
                  </Grid>
                ))}
              </>
            ) : // </Grid>
            null}
          </Grid>
        </FormControl>
        <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
          <DialogTitle>
            Source List
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <Scrollbar> */}
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{"SL NO."}</TableCell>

                    <TableCell align="left">{"Contact Name"}</TableCell>
                    <TableCell align="left">{"Sales Owner"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sourceData ? (
                    sourceData.map((ele, i) => {
                      return (
                        <TableRow>
                          <TableCell align="left">{i + 1}</TableCell>

                          <TableCell
                            sx={{ cursor: "pointer" }}
                            align="left"
                            onClick={(e) =>
                              handleRoutingToContatPage(e, ele._id)
                            }
                          >
                            <Link> {ele.name}</Link>
                          </TableCell>
                          <TableCell align="left">{ele.salesOwner}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        height: "20px",
                      }}
                    >
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {"Data Not Found"}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default Info;
