import React from "react";
import { useState } from "react";
import { getGst, updateGst } from "../../services/attributes.service";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export default function GstCustomization() {
  const [gstValue, setGstValue] = useState({});
  const [editGstValue, setEditGstValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isGSTFetched, setGSTFetched] = useState(false);
  const [isGstDialougeOpne, setIsGstDialougeOpne] = useState(false);
  const getGSTData = async () => {
    try {
      const response = await getGst();
      if (response.data.status) {
        const data = response.data.data;

        console.log("data1111", data);
        setGstValue({ ...data[0] });
        setEditGstValue({ ...data[0] });
        setGSTFetched(true);
      }
    } catch (error) {}
  };

  const handleUpdateGst = async () => {
    try {
      //   let updatedFields = { cgst: 15 };
      delete editGstValue._id;
      delete editGstValue.currency;
      delete editGstValue.createdAt;
      delete editGstValue.updatedAt;
      delete editGstValue.__v;

      delete gstValue._id;
      delete gstValue.currency;
      delete gstValue.createdAt;
      delete gstValue.updatedAt;
      delete gstValue.__v;

      const temp = gstValue;

      if (temp === editGstValue) {
        toast.warn("Nothing to update ");
        return;
      }

      const response = await updateGst(editGstValue);

      if (response?.data.status) {
        getGSTData();
        setIsGstDialougeOpne(false);
        // setGSTFetched(false);
        toast("GST updated successfully");
      }
    } catch (error) {
      if (error.response.data.message === "Attribute Name already Exists") {
        toast("Attribute Name already Exists");
      }
      console.log(error);
    }
  };

  const handleGstOnchange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\D/g, "").slice(0, 3);
    if (value >= 100) {
      toast.warning("GST value should not be greater than or equal to 100");
      return;
    }
    setEditGstValue((prevGstValue) => ({
      ...prevGstValue,
      [name]: sanitizedValue,
    }));
  };
  useEffect(() => {
    if (!isGSTFetched) {
      getGSTData();
    }
  }, [isGSTFetched]);
  console.log("data11112", gstValue);
  return (
    <Card>
      <CardContent>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="h6">GST Customization</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              <span style={{ color: "#2056D1" }}>IGST :</span>
              {`  ${gstValue.igst}%`}{" "}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              <span style={{ color: "#2056D1" }}>CGST :</span>
              {`  ${gstValue.cgst}%`}{" "}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              <span style={{ color: "#2056D1" }}>SGST :</span>
              {`  ${gstValue.sgst}%`}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <IconButton
              onClick={() => {
                setIsGstDialougeOpne(true);
                setIsEdit(true);
                setEditGstValue(gstValue);
              }}
              sx={{ "&:hover": { color: "#2065D1" }, marginLeft: "80%" }}
              data-testid="gstEditIcon"
            >
              <ModeEditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
      <Dialog open={isGstDialougeOpne} onClose={() => {}}>
        <DialogTitle>Edit GST Customization</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="IGST"
                fullWidth
                // autoFocus
                variant="outlined"
                name={"igst"}
                type="number"
                data-testid="igst"
                value={isEdit ? editGstValue.igst : gstValue.igst}
                onChange={(e) => handleGstOnchange(e)}
                inputProps={{
                  maxLength: 3,
                  pattern: "[0-9]{1,3}",
                }}
                // disabled={!item.toEdit}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="CGST"
                fullWidth
                // autoFocus
                variant="outlined"
                name={"cgst"}
                type="number"
                data-testid="cgst"
                value={isEdit ? editGstValue.cgst : gstValue.cgst}
                onChange={(e) => handleGstOnchange(e)}
                inputProps={{
                  maxLength: 3,
                  pattern: "[0-9]{1,3}",
                }}
                // disabled={!item.toEdit}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="SGST"
                fullWidth
                // autoFocus
                variant="outlined"
                name={"sgst"}
                type="number"
                data-testid="sgst"
                value={isEdit ? editGstValue.sgst : gstValue.sgst}
                onChange={(e) => handleGstOnchange(e)}
                inputProps={{
                  maxLength: 3,
                  pattern: "[0-9]{1,3}",
                }}
                // disabled={!item.toEdit}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setIsGstDialougeOpne(false);
              setIsEdit(false);
            }}
            // startIcon={<CancelIcon />}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleUpdateGst}
            color="primary"
            // startIcon={<SaveIcon />}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
