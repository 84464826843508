import { Link as RouterLink, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
// components
import Page from "../../components/UI/Page";
import { capitalizedString } from "../../utils/capitalizedString";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  maxHeight: "80vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function Unauthorized() {
  const { state } = useLocation();
  console.log(state);
  return (
    <Page title="Unauthorized Access">
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            Unauthorized access!
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            {state
              ? `${state.charAt(0).toUpperCase()}${state.substring(1)}`
              : `Sorry, you don't have access to visit this page.`}
          </Typography>

          <Box
            component="img"
            src="/static/illustrations/unauthorized-access.png"
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Button
            to="/dashboard"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Dashboard
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
