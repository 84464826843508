import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

function NameViewCard({
  name,
  fromDynamic,
  onEditButtonClick,
  onDeleteButtonClick,
}) {
  if (fromDynamic)
    return (
      <Card
        sx={{
          maxHeight: "40px",
          display: "grid",
          alignContent: "center",
        }}
      >
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontWeight={500} color="#5489dc">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row">
                <IconButton
                  size="small"
                  onClick={onEditButtonClick}
                  sx={{ ":hover": { color: "#2065d1" } }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={onDeleteButtonClick}>
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  return (
    <Card
      sx={{
        height: "40px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <CardContent>
        <Typography fontWeight={500} /*color="#5489dc"*/ color="#96a1ab">
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default NameViewCard;
