import React from "react";
import { useEffect } from "react";
import {
  deleteEmailTemplate,
  getEmailTemplet,
  getEmailTempletByModuleName,
} from "../../services/common.service";
import { useState } from "react";
import { Button, Chip, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailTemplet from "../../components/EmailTemplet";
import DeleteConfirmationDialog from "../../components/Dialogs/DeleteConfirmationDialog";
import { toast } from "react-toastify";

function ViewEmailTemplate() {
  const navigate = useNavigate();
  const [emailTemplate, setEmailTemplet] = useState([]);
  const filters = [
    {
      moduleName: "All",
      checked: true,
    },
    {
      moduleName: "Companies",
      checked: false,
    },
    {
      moduleName: "Contacts",
      checked: false,
    },
    {
      moduleName: "Leads",
      checked: false,
    },
    {
      moduleName: "Projects",
      checked: false,
    },
  ];
  const [filterers, setFilterers] = useState([...filters]);

  const [deleteTemplateState, setDeleteTemplateState] = useState({
    openDialog: false,
    templateId: "",
    open: (_id) => {
      setDeleteTemplateState((prev) => ({
        ...prev,
        templateId: _id,
        openDialog: true,
      }));
    },
    close: () => {
      setDeleteTemplateState((prev) => ({
        ...prev,
        templateId: "",
        openDialog: false,
      }));
    },
  });

  /*   const a = {
    All: true,
    Companies: false,
    Contacts: false,
    Leads: false,
    Projects: false,
  }; */

  function getAllEmailTemplates() {
    getEmailTemplet()
      .then((response) => {
        setEmailTemplet(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleChipSwitchAndActivation(pIndex, moduleName) {
    let newFilterers = filterers.map(({ moduleName }, index) => {
      return { moduleName: moduleName, checked: pIndex === index };
    });
    setFilterers(newFilterers);
    try {
      if (moduleName === "All") {
        getAllEmailTemplates();
        return;
      }
      const response = await getEmailTempletByModuleName(moduleName);
      if (response.data.status) {
        setEmailTemplet(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function deleteTemplate() {
    try {
      const response = await deleteEmailTemplate(
        deleteTemplateState.templateId
      );
      if (response.data.status) {
        deleteTemplateState.close();
        toast("Template Deleted Successfully");
        getAllEmailTemplates();
        setFilterers([...filters]);
      } else {
        deleteTemplateState.close();
        toast("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const selectModule = filterers.filter((item, index) => item.checked);
  useEffect(() => {
    getAllEmailTemplates();
  }, []);
  console.log("Filtererssdsd", filterers);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
          {filterers.map(({ moduleName, checked }, index) => {
            return (
              <Chip
                key={index}
                variant={checked ? "filled" : "outlined"}
                clickable
                label={moduleName}
                color={checked ? "primary" : "default"}
                onClick={() => {
                  handleChipSwitchAndActivation(index, moduleName);
                }}
              />
            );
          })}
        </Stack>
        <Button
          onClick={() => {
            navigate(`/emailtemplate/create`, { state: selectModule });
          }}
          variant="contained"
        >
          CREATE TEMPLATE
        </Button>
      </Grid>
      {emailTemplate.map((ele, index) => (
        <EmailTemplet
          key={index}
          wholeTemplate={ele}
          templateId={ele._id}
          to={ele.to}
          moduleName={ele.moduleName}
          eventName={ele.eventName}
          subject={ele.subject}
          body={ele.body}
          openConfermationDialog={deleteTemplateState.open}
        />
      ))}
      <DeleteConfirmationDialog
        opened={deleteTemplateState.openDialog}
        title="Template"
        handleCancel={deleteTemplateState.close}
        handleDelete={deleteTemplate}
      />
    </Grid>
  );
}

export default ViewEmailTemplate;
