const apis = require("./api.requests");

const TAG = "users.service";
const path = "/users/v1";

async function addUser(body) {
  return await apis.hitPostApi(`${path}/`, body);
}

async function getUsers(page, data, fromAction) {
  return await apis.hitPagedApiForUsers(`${path}/all`, page, data, fromAction);
}

async function getUserById(userId, data) {
  return await apis.hitGetApi(`/open/${userId}/details?forDetails=${data}`);
}

async function changePassword(payload) {
  console.log(payload.password, payload.token);
  return await apis.hitChangePasswordApi(path, payload.password, payload.token);
}

async function directChangePassword(body) {
  return await apis.hitDirectChangePasswordApi(path, body);
}

async function updateUser(userId, body) {
  return await apis.hitPutApi(`${path}/${userId}`, body);
}

async function updateUserStatus(userId, status) {
  return await apis.hitPutApi(`${path}/status/${userId}?status=${status}`);
}
async function getUsersByRoles(type) {
  return await apis.hitGetApi(`${path}?type=${type}`);
}

async function getUserSearch(key, value) {
  return await apis.hitGetApi(
    `${path}/search?searchKey=${key}&searchValue=${value}`
  );
}

async function saveEmailIntegration(body) {
  return await apis.hitPutApi(`${path}/emailPassword`, body);
}

async function createGoogleTokens(body) {
  return await apis.hitPostApi(`${path}/googleToken`, body);
}

export {
  addUser,
  getUsers,
  getUsersByRoles,
  getUserById,
  updateUser,
  changePassword,
  directChangePassword,
  updateUserStatus,
  getUserSearch,
  saveEmailIntegration,
  createGoogleTokens,
};
