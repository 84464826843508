import { TextField } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";

function DropOnTextField({ handleDrop, ...others }) {
  // * For Subject * //

  // eslint-disable-next-line no-unused-vars
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "copyableElement",
    drop: (item) => {
      handleDrop(item, "subject"); // Pass the field name and dropped label to the handler
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  return <TextField key="12345" {...others} ref={drop} />;
}

export default DropOnTextField;
