import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Page from "../components/UI/Page";
import React from "react";
import { useState, useEffect } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import ActivityLogsBody from "../components/ActivityLogsBody";
import ArchiveBody from "../components/Archives/ArchiveBody";
import {
  getArchiveDocuments,
  restoreDocument,
} from "../services/common.service";
import CircularProgress from "@mui/material/CircularProgress";
import { useDynamicContactTitle } from "./Contacts/Contacts";
import { useDynamicLeadTitle } from "./Leads/Leads";
import { useDynamicProjectTitle } from "./Projects/Projects";
import { useDynamicCompanyTitle } from "./Companies/Companies";

const modules = ["Users", "Roles", "Contacts", "Leads", "Projects"];

const events = [
  "Create",
  "Read",
  "Update",
  "Delete",
  "Action Create",
  "Action Read",
  "Action Update",
  "Action Done",
  "Document Create",
  "Document Read",
  "Document Delete",
  "Comment Create",
  "Comment Read",
];

const intervals = [
  "Last Day",
  "Last 7 Days",
  "Last 2 Weeks",
  "Last Month",
  "Custom",
];

const ArchivesPage = () => {
  const [restorePopupOpen, setRestorePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });
  const [colorSeven, setColorSeven] = useState("primary");
  const [colorTwo, setColorTwo] = useState("default");
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showDateGrid, setShowDateGrid] = useState(false);
  const [archiveData, setArchiveData] = useState([]);
  const [refreshArchiveData, setRefreshArchiveData] = useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  const handleRestorePopupOpen = (
    _id,
    from,
    projectId,
    leadId,
    contactId,
    companyId
  ) => {
    setRestorePopupOpen({
      state: true,
      onSuccess: async () => {
        console.log("_id", _id, from, projectId, leadId, contactId, companyId);
        try {
          setLoaderDialog(true);
          let temp = {};
          if (from === "LEADS") {
            temp.leadId = leadId;
            temp.contactId = contactId;
            temp.from = from;
          } else if (from === "COMPANY") {
            temp["from"] = from;
            temp["companyId"] = companyId;
          } else {
            temp.projectId = projectId;
            temp.from = from;
          }
          const response = await restoreDocument(_id, temp);
          if (response.data.status === true) {
            toast("Document restored successfully");
            setRefreshArchiveData(false);
            setLoaderDialog(false);
          } else {
            setLoaderDialog(false);
          }
          handleClose();
        } catch (error) {
          console.log(error);
          setLoaderDialog(false);
        }
      },
    });
    return _id;
  };

  const handleRestore = (_id, from, projectId, leadId, contactId) => {
    handleRestorePopupOpen(_id);
  };

  const handleClose = () => {
    setRestorePopupOpen(false);
    // setDeletePopupOpen(false);
  };

  const formatDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };

  const provideDate = (dateRange) => {
    switch (dateRange) {
      case "7Days":
        getArchiveData(Date.now() - 7 * 24 * 60 * 60 * 1000);
        break;

      case "Last2Weeks":
        getArchiveData(Date.now() - 14 * 24 * 60 * 60 * 1000);
        break;

      default:
        break;
    }
  };

  const getArchiveData = async (toDate) => {
    const response = await getArchiveDocuments(toDate, Date.now());
    if (response.data.status) {
      setArchiveData(response.data.data);
    }
  };

  useEffect(() => {
    if (!refreshArchiveData) {
      getArchiveData(Date.now() - 7 * 24 * 60 * 60 * 1000);
      setRefreshArchiveData(true);
    }
  }, [refreshArchiveData]);
  const companyDynamicTitel = useDynamicCompanyTitle();
  const leadDynamicTitle = useDynamicLeadTitle();
  const projectDynamicTitel = useDynamicProjectTitle();
  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card elevation={5} variant="outlined">
              {/* <CardContent sx={{ "&:last-child": { paddingBottom: "10px" } }}> */}
              <CardContent>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <Chip
                      label="Last 2 Weeks"
                      onClick={() => {
                        provideDate("Last2Weeks");
                        setColorSeven("default");
                        setColorTwo("primary");
                      }}
                      color={colorTwo}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Chip
                      label="Last 7 Days"
                      onClick={() => {
                        provideDate("7Days");
                        setColorTwo("default");
                        setColorSeven("primary");
                      }}
                      color={colorSeven}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={0}>
                  <Grid item xs={9}>
                                        <Typography variant="h5" gutterBottom color="primary">
                                            ARCHIVE
                                        </Typography>
                                    </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Chip
                      label="Last 2 Weeks"
                      sx={{ marginLeft: "15px" }}
                      onClick={() => {
                        setColorSeven("default");
                        setColorTwo("primary");
                      }}
                      color={colorTwo}
                    />
                    <Chip
                      label="Last 7 Days"
                      sx={{ marginLeft: "15px" }}
                      onClick={() => {
                        setColorTwo("default");
                        setColorSeven("primary");
                      }}
                      color={colorSeven}
                    />
                  </Grid>
                </Grid> */}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sx={{ maxHeight: "46vh", overflow: "auto" }}
                  >
                    <ArchiveBody
                      handleRestorePopupOpen={handleRestorePopupOpen}
                      archiveData={archiveData}
                      handleRestore={handleRestore}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "8px" }}>
            LEGENDS: &nbsp;
            <span style={{ color: "#2986DD", fontWeight: "bold" }}>
              {companyDynamicTitel?.displayName.singularName}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: "#4a148c", fontWeight: "bold" }}>
              {leadDynamicTitle?.displayName.singularName}
            </span>
            &nbsp;&nbsp;
            <span style={{ color: "#006064", fontWeight: "bold" }}>
              {projectDynamicTitel?.displayName.singularName}
            </span>
          </Grid>
        </Grid>
        <Dialog open={restorePopupOpen.state} onClose={handleClose}>
          <DialogTitle textAlign="center">Restore Document</DialogTitle>
          <DialogContent>
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
                Do you wish to restore the document?
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              sx={{ color: "red" }}
            >
              DISAGREE
            </Button>
            <Button onClick={restorePopupOpen.onSuccess}>AGREE</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={loaderDialog}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "20px",
            }}
          >
            Loading... Please Wait
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ArchivesPage;
