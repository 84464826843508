export default function handleButtonByRoleAccess(
  moduleName,
  currentUser,
  salesOwner,
  allDoers,
  techOwner
) {
  console.log(checkActionHolder(allDoers, currentUser));
  switch (moduleName) {
    case "CONTACTS":
      if (
        currentUser.role === "ADMIN" ||
        currentUser._id === salesOwner ||
        checkActionHolder(allDoers, currentUser)
      ) {
        return true;
      }
      break;
    case "LEADS":
      if (
        currentUser.role === "ADMIN" ||
        currentUser._id === salesOwner ||
        checkActionHolder(allDoers, currentUser) ||
        currentUser._id === techOwner
      ) {
        return true;
      }
      break;
    case "PROJECTS":
      if (
        currentUser.role === "ADMIN" ||
        currentUser._id === techOwner ||
        currentUser._id === salesOwner ||
        checkActionHolder(allDoers, currentUser)
      ) {
        return true;
      }
    default:
      return false;
  }
}

const checkActionHolder = (allDoers, currentUser) => {
  const result = [];
  allDoers.forEach((element) => {
    if (element._id === currentUser._id) {
      result.push(true);
    }
  });
  // return result[0];
  return true;
};
export { checkActionHolder };
