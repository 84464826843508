import { React } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { changePassword } from "../../services/users.service";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  console.log("Token", token);
  const [passwordObj, setPasswordObj] = useState({});
  const handleChange = (name, value) => {
    console.log(name);
    console.log(value);
    setPasswordObj((prevState) => ({ ...prevState, [name]: value }));
  };
  const notify = (str) => toast(str);
  const handleSubmit = (passwordObj) => {
    // let token = new URLSearchParams(window.location.search).get("token");
    // handleChange("token", token);
    if (passwordObj.rePassword === passwordObj.password) {
      changePassword({ ...passwordObj, token })
        .then((response) => {
          console.log("response.data", response.data);
          if (response.data.status === true) {
            notify("your password has been successfully changed");
            navigate("/");
          } else {
            if (response.data.code === 200) {
              notify(response.data.message);
              return;
            }
            notify("Unable to change passaword!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      notify("password should  match");
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Card
        className="child"
        elevation={5}
        sx={{ width: "40vw", minHeight: "auto", backgroundColor: "#03203C" }}
      >
        <ToastContainer />
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <Typography
                sx={{ marginTop: "20px" }}
                align="left"
                variant="h2"
                color="white"
              >
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="New Password"
                variant="outlined"
                placeholder="Type new password"
                type="password"
                required
                fullWidth
                sx={{ input: { color: "white", borderColor: "white" } }}
                onBlur={(e) => {
                  handleChange("password", e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Confirm New Password"
                variant="outlined"
                placeholder="Re-type the new password"
                type="password"
                required
                fullWidth
                sx={{ input: { color: "white" } }}
                onBlur={(e) => {
                  handleChange("rePassword", e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid
              item
              sm={12}
              sx={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit(passwordObj);
                }}
              >
                Change Password & Login
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ChangePassword;
