import moment from "moment";

export const getDateString = (date) => {
  return new Date(new Date(date).setHours(0, 0, 0, 0)).toString(); // Include time and timezone
};

export const getCurrentDate = () => {
  //return this months day
  const date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0); // Set time to midnight
  return getDateString(date); // Current Date
};

export const getCurrentMonthDate = () => {
  const date = new Date();

  return getDateString(date); // Current Date
};

// export const getLastTwoMonthsDate = () => {
//   const date = new Date();
//   date.setDate(date.getDate() - 60);
//   date.setHours(0, 0, 0, 0); // Set time to midnight
//   return getDateString(date); // Date 60 days ago
// };

export const getLastTwoMonthsDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 2); // Move two months back
  date.setDate(1); // Set to the first day of the month
  date.setHours(0, 0, 0, 0); // Set time to midnight
  return getDateString(date); // Format and return the date
};

export const getLastMonthDate = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1); // Move one months back
  date.setDate(1); // Set to the first day of the month
  date.setHours(0, 0, 0, 0); // Set time to midnight
  return getDateString(date); // Date 30 days ago
};

export const getCurrentQuarterDate = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
  const quarterStartDate = new Date(date.getFullYear(), quarterStartMonth, 1);
  quarterStartDate.setHours(0, 0, 0, 0); // Set time to midnight
  return getDateString(quarterStartDate); // Start of the current quarter
};

export const getLastQuarterDate = () => {
  const date = new Date();
  const currentQuarter = Math.floor((date.getMonth() + 3) / 3);
  let lastQuarterStartMonth = (currentQuarter - 2) * 3; // -2 to go back one quarter
  let year = date.getFullYear();
  if (lastQuarterStartMonth < 0) {
    lastQuarterStartMonth += 12;
    year -= 1;
  }
  const lastQuarterStartDate = new Date(year, lastQuarterStartMonth, 1);
  lastQuarterStartDate.setHours(0, 0, 0, 0); // Set time to midnight
  return getDateString(lastQuarterStartDate); // Start of the last quarter
};

export const getCurrentDateOnly = () => {
  const date = new Date();
  const [day, month, year] = [
    date.getDate(),
    date.getMonth(),
    date.getFullYear(),
  ];
  // *Will return only date month and year like "10-7-2024" //
  return `${day}-${month + 1}-${year}`;
};

export const addDaysinDate = (date = "", days = 0) => {
  if (days > 30) {
    return moment(date)
      .add(days, "days")
      .set({ hour: 23, minute: 59, second: 59 })
      .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  }
  const convertedDate = moment(date)
    .add(days, "days")
    .set({ hour: 23, minute: 59, second: 59 })
    .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  if (moment(convertedDate, "MMM") === moment(date, "MMM")) {
    return convertedDate;
  }
  return moment(date)
    .add(--days, "days")
    .set({ hour: 23, minute: 59, second: 59 })
    .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
};
