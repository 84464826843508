import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
// import User from "./pages/OldUser";
import Login from "./pages/Auth/Login";
import NotFound from "./pages/Unused/Page404";
import Register from "./pages/Unused/Register";
import DashboardApp from "./pages/DashboardApp";
import Users from "./pages/Users/Users";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import ContactCreate from "./pages/Contacts/Contact.Create";
import ContactView from "./pages/Contacts/Contact.View";
import Contacts from "./pages/Contacts/Contacts";
import Lead from "./pages/Leads/Leads";
import Roles from "./pages/Roles";
import Projects from "./pages/Projects/Projects";
import LeadViewPage from "./pages/Leads/LeadViewPage";
import LeadCreatePage from "./pages/Leads/LeadCreatePage";
import ActivityLogs from "./pages/ActivityLogs";
import Profile from "./pages/Profile";

import { useContext } from "react";
import { AuthContext } from "./context/auth.context";
import UserView from "./pages/Users/User.View";
import Settings from "./pages/Settings";
import ProjectView from "./pages/Projects/Project.view";
import ProjectCreate from "./pages/Projects/Project.create";
import Unauthorized from "./pages/Auth/Unauthorized";

import Emails from "./pages/Emails";
import ArchivesPage from "./pages/ArchivesPage";
import Reports from "./pages/Unused/Reports";
import CreateEmailTemplate from "./pages/EmailTemplate/CreateEmailTemplate";
import ViewEmailTemplate from "./pages/EmailTemplate/ViewEmailTemplate";
import CreateCompany from "./pages/Companies/CreateCompany";
import Companies from "./pages/Companies/Companies";
import CompanyView from "./pages/Companies/Company.View";
import Customize from "./pages/Customize/Customize";
import Automation from "./pages/Customize/Automation";
import CreateAutomation from "./pages/Customize/CreateAutomation";
import EditAutomation from "./pages/Customize/EditAutomation";
import AccountAndCompanyView from "./pages/Customize/AccountAndCompany.View";
import InvoiceReport from "./pages/InvoiceReport";
import ScanVisitingCard from "./pages/Contacts/ScanVisitingCard";
import AccountsReport from "./pages/AccountsReport";
import SalesCampaign from "./pages/SalesCampaign/SalesCampaign";

// ----------------------------------------------------------------------

export default function Router({ pathName }) {
  const { isAuthenticated, setPath } = useContext(AuthContext);
  setPath(pathName);
  return useRoutes([
    {
      path: "/",
      element: !isAuthenticated ? (
        <LogoOnlyLayout />
      ) : (
        <Navigate to={pathName} />
        // pathName.split("/")[1] !== "changePassword" ? <Navigate to={pathName} /> : <LogoOnlyLayout />
      ),
      // element: <LogoOnlyLayout />,
      children: [
        // { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "changePassword/:token", element: <ChangePassword /> },
        { path: "404", element: <NotFound /> },
        // { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/",
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: "dashboard", element: <DashboardApp /> },
        {
          path: "users",
          children: [
            { path: "", element: <Users /> },
            { path: ":userId", element: <UserView /> },
          ],
        },
        {
          path: "roles",
          element: <Roles />,
        },
        {
          path: "companies",
          children: [
            { path: "", element: <Companies /> },
            { path: "create", element: <CreateCompany /> },
            { path: "view/:id", element: <CompanyView /> },
          ],
        },
        {
          path: "contacts",
          children: [
            { path: "", element: <Contacts /> },
            { path: "createContact", element: <ContactCreate /> },
            // { path: "lead/:id/:leadId", element: <LeadView /> },
            { path: ":id", element: <ContactView /> },
            { path: "scan-visisting-card", element: <ScanVisitingCard /> },
          ],
        },
        {
          path: "Leads",
          children: [
            { path: "", element: <Lead /> },

            { path: ":id/:contactId", element: <LeadViewPage /> },
            { path: "createLeadPage", element: <LeadCreatePage /> },
          ],
        },
        {
          path: "projects",
          children: [
            { path: "", element: <Projects /> },
            { path: ":projectId", element: <ProjectView /> },
            { path: "createProject", element: <ProjectCreate /> },
            // { path: "", element:  },
            // { path: "", element:  },
          ],
        },
        { path: "logs", element: <ActivityLogs /> },
        { path: "reports", element: <Reports /> },
        { path: "collectionreports", element: <InvoiceReport /> },

        { path: "emails", element: <Emails /> },

        { path: "archives", element: <ArchivesPage /> },
        { path: "settings", element: <Settings /> },
        { path: "profile", element: <Profile /> },
        { path: "unauthorized", element: <Unauthorized /> },
        // { path: "products", element: <Products /> },
        // { path: "sales", element: <Sales /> },
        // { path: "blog", element: <Blog /> },
        // { path: "adduser", element:  },
        {
          path: "emailtemplate",
          children: [
            { path: "", element: <ViewEmailTemplate /> },
            { path: "create", element: <CreateEmailTemplate /> },
          ],
        },
        {
          path: "customize",
          children: [
            { path: "", element: <Customize /> },
            { path: ":moduleName", element: <Automation /> },
            { path: "create/:moduleName", element: <CreateAutomation /> },
            {
              path: "edit/:moduleName/:automationRuleId",
              element: <EditAutomation />,
            },
            {
              path: "view/:identifier",
              element: <AccountAndCompanyView />,
            },
          ],
        },
        {
          path: "accountsreport",
          element: <AccountsReport />,
        },
        {
          path: "salescampaign",
          element: <SalesCampaign />,
        },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
