import React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material";
import {
  Stack,
  TextField,
  OutlinedInput,
  MenuItem,
  AutoCompleteSearch,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDynamicCompanyTitle } from "../../pages/Companies/Companies";
import { useSearchCollectionName } from "../../utils/filterObject";
import { getCompanySearch } from "../../services/companies.service";

export default function SearchCompany({
  setCount,
  companies,
  setCompanies,
  column,
  setColumn,
  typeText,
  setTypeText,
  setPage,
  page,
  setPageCount,
  setIsLoading,
}) {
  const companyDynamicTitle = useDynamicCompanyTitle();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isEmptyTextField, setIsEmptyTextField] = React.useState(false);
  const theme = useTheme();
  const companyPage = "companyPage";

  console.log("In search value", companies, typeText);
  const notify = () => toast("Please Select a column!");
  const handleChange = (event) => {
    setIsEmpty(false);
    const {
      target: { value },
    } = event;
    setColumn(value);
    setTypeText("");
  };

  const searchChange = (e) => {
    if (column === "Country") {
      var regex = /^[a-zA-Z ]*$/;
      const value = regex.test(e.target.value);
      if (!value) {
        toast("Numbers and special characters are not allowed");
        return;
      }
    }
    setCompanies([]);
    setTypeText(e.target.value);
    // setCount(1);
    setPageCount(1);
    setPage(0);
    // searchExecution = null;
    console.log("setTypetext", e.target.value);
  };
  const getCollectionName = useSearchCollectionName(column);

  React.useEffect(() => {
    if (typeText) {
      setIsLoading(true);
      setIsEmpty(false);
      setIsEmptyTextField(true);

      const lowerColumnName = getCollectionName.toLowerCase();

      const timerId = setTimeout(() => {
        getCompanySearch(lowerColumnName, typeText).then((response) => {
          const result = response.data.data
            ? response.data.data.allContacts
            : [];
          console.log("response", response.data);
          setCompanies([...result]);
          setCount(result.length);
          setIsLoading(false);
        });
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    } else {
      setColumn("");
      setIsEmpty(true);
      setIsEmptyTextField(false);
    }
  }, [typeText]);

  return (
    <Stack direction="row" spacing={1}>
      <Select
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        displayEmpty
        value={column}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (isEmpty) {
            return <>Select Column</>;
          }

          return selected;
        }}
      >
        <MenuItem disabled value="">
          <>Select Column</>
        </MenuItem>
        <MenuItem value="Name">Name</MenuItem>
        {/* <MenuItem value={companyDynamicTitle?.displayName.singularName}>
          {companyDynamicTitle?.displayName.singularName}
        </MenuItem> */}
        <MenuItem value="Country">Country</MenuItem>
      </Select>

      <TextField
        size="small"
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        inputProps={column ? "" : { readOnly: true }}
        label="Search"
        variant="outlined"
        value={typeText}
        onChange={searchChange}
        onClick={() => {
          if (!column) {
            notify();
          }
        }}
      />
    </Stack>
  );
}
