import { hashObject } from "react-hash-string";
import FormDialog from "../components/Unused/dialogbox/FormDialog";
import { encryption } from "./encryption";

// import { getmail } from "./mail.util";

const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

const TAG = "indexedDb.js";
// crete database
export const createDataInIndexedDb = () => {
  return new Promise((resolve, reject) => {
    //check for support
    if (!idb) {
      console.log("This browser doesn't support IndexedDB");
      reject("This browser doesn't support IndexedDB");
      return;
    }

    const request = idb.open("emailDb", 1.1);

    request.onerror = function (event) {
      console.error("An error occurred with IndexedDB");
      console.error(event);
      reject("An error occurred with IndexedDB");
    };
    console.log("create createindexdb");
    request.onupgradeneeded = function (event) {
      console.log(event);
      const db = request.result;
      // const db = event.targetresult;

      if (!db.objectStoreNames.contains("userContactEmail")) {
        console.log("inside userContactEmail");
        const objectStore = db.createObjectStore("userContactEmail", {
          keyPath: "hash",
        });

        objectStore.createIndex("date", "date", {
          unique: false,
          multiEntry: true,
        });
      }
      console.log("create userContactEmail");
      if (!db.objectStoreNames.contains("userEmailPassword")) {
        const emailPassword = db.createObjectStore("userEmailPassword", {
          keyPath: "id",
        });

        emailPassword.createIndex("password", "password", {
          unique: false,
          multiEntry: true,
        });
      }
      // event.target.close();
      console.log("create userEmailPassword");
      console.log("Database closed successfully");
    };
    resolve();
  });
};
// Delete index database
export const deleteDataInIndexedDb = () => {
  return new Promise((resolve, reject) => {
    //check for support
    if (!idb) {
      console.log("This browser doesn't support IndexedDB");
      reject("This browser doesn't support IndexedDB");
      return;
    }
    const request = idb.open("emailDb", 1.0);
    request.onsuccess = function (event) {
      const db = request.result;
      const transactionEmail = db.transaction("userContactEmail", "readwrite");
      const objectStore = transactionEmail.objectStore("userContactEmail");

      const objectStoreRequestEmail = objectStore.clear();
      objectStoreRequestEmail.onsuccess = (event) => {
        console.log("Object store userContactEmail clear sucessfully");
      };

      // clear password store

      const transactionPassword = db.transaction(
        "userEmailPassword",
        "readwrite"
      );
      const objectStorePassword =
        transactionPassword.objectStore("userEmailPassword");

      const objectStoreRequestPassword = objectStorePassword.clear();
      objectStoreRequestPassword.onsuccess = (event) => {
        console.log("Object store userContactPassword clear sucessfully");
      };
    };
    // createData stores for email and password
    request.onupgradeneeded = function (event) {
      console.log(event);
      const db = request.result;
      // const db = event.targetresult;

      if (!db.objectStoreNames.contains("userContactEmail")) {
        console.log("inside userContactEmail");
        const objectStore = db.createObjectStore("userContactEmail", {
          keyPath: "hash",
        });

        objectStore.createIndex("date", "date", {
          unique: false,
          multiEntry: true,
        });
      }
      console.log("create userContactEmail");
      if (!db.objectStoreNames.contains("userEmailPassword")) {
        const emailPassword = db.createObjectStore("userEmailPassword", {
          keyPath: "id",
        });

        emailPassword.createIndex("password", "password", {
          unique: false,
          multiEntry: true,
        });
      }
      // event.target.close();
      console.log("create userEmailPassword");
      console.log("Database closed successfully");
    };

    resolve();
  });
};
// insert data into inddexDb
export const insertDataInIndexedDb = (USER_DATA, EMAIL_PASSWORD) => {
  return new Promise((resolve, reject) => {
    const request = idb.open("emailDb", 1.0);
    // get all mails
    const allmails = async () => {
      return await getMyMails();
    };
    console.log("allmails", allmails);
    request.onsuccess = function (event) {
      console.log("Database opened successfully");
      const db = request.result;

      if (!!USER_DATA && !EMAIL_PASSWORD) {
        console.log(`${TAG}.inside.email`);
        USER_DATA.forEach((item, index) => {
          // item.hash = hashObject(item);
          item.hash = item.sequence;
        });
        USER_DATA.forEach((item) => {
          console.log("item", item);
          const tx = db.transaction("userContactEmail", "readwrite");
          const userContactEmail = tx.objectStore("userContactEmail");
          const res = userContactEmail.add(item);
          res.onsuccess = function (event) {
            const res1 = event.type;
            if (res1 === "success") {
              resolve(res1);
            }
          };
          return tx.complete;
        });
      }
      if (!!EMAIL_PASSWORD && !USER_DATA) {
        const tx = db.transaction("userEmailPassword", "readwrite");
        const emailPassword = tx.objectStore("userEmailPassword");
        const res = emailPassword.put({ id: 1, EMAIL_PASSWORD });
        res.onsuccess = function (event) {
          const res1 = event.type;
          if (res1 === "success") {
            resolve(res1);
          }
        };
        // console.log('res', res);
        // console.log('tx.complete', tx.complete);
        // resolve(tx.complete)
        // return tx.complete;
      }

      console.log("Database closed successfully");
    };
  });
};

// get emails

export const getMyMails = function () {
  return new Promise((resolve, reject) => {
    const request = idb.open("emailDb", 1.0);
    // console.log(`${TAG}.request`, request);
    request.onsuccess = function (event) {
      const db = event.target.result;
      // console.log(`${TAG}`, db);
      const tx = db
        .transaction("userContactEmail")
        .objectStore("userContactEmail")
        .getAll();

      tx.onsuccess = (event) => {
        let emailArr;
        emailArr = event.target.result;
        console.log("emailArr", emailArr);

        // let password;
        // if (passwordArr.length > 0) {
        //     password = passwordArr[0].EMAIL_PASSWORD;
        //     console.log(`${TAG}.password`, password);
        //     // passwordDecrypt = encryption(null, password)

        // }
        resolve(emailArr);

        // console.log(`${TAG}.password`, passwordDecrypt);
      };
      request.onerror = (err) => {
        console.error(`Error to get password: ${err}`);
        //reject
      };
    };
  });
};

// get password
export const getPassword = function () {
  return new Promise((resolve, reject) => {
    const request = idb.open("emailDb", 1.0);
    // console.log(`${TAG}.request`, request);

    // *NOTE* Checking if the necessary objects created or not.
    request.onupgradeneeded = function (event) {
      console.log(event);
      const db = request.result;
      // const db = event.targetresult;

      if (!db.objectStoreNames.contains("userContactEmail")) {
        console.log("inside userContactEmail");
        const objectStore = db.createObjectStore("userContactEmail", {
          keyPath: "hash",
        });

        objectStore.createIndex("date", "date", {
          unique: false,
          multiEntry: true,
        });
      }
      console.log("create userContactEmail");
      if (!db.objectStoreNames.contains("userEmailPassword")) {
        const emailPassword = db.createObjectStore("userEmailPassword", {
          keyPath: "id",
        });

        emailPassword.createIndex("password", "password", {
          unique: false,
          multiEntry: true,
        });
      }
      // event.target.close();
      console.log("create userEmailPassword");
      console.log("Database closed successfully");
    };

    request.onsuccess = function (event) {
      const db = event.target.result;
      console.log(`${TAG}.db`, db);
      const tx = db
        .transaction("userEmailPassword")
        .objectStore("userEmailPassword")
        .get(1);

      tx.onsuccess = (event) => {
        let passwordArr = event.target.result;
        let password;

        if (passwordArr) {
          password = passwordArr.EMAIL_PASSWORD;
          console.log(`${TAG}.password`, password);
          // passwordDecrypt = encryption(null, password)
          resolve(password);
        } else {
          console.log("Password length 0");
          resolve(null);
        }

        // console.log(`${TAG}.password`, passwordDecrypt);
      };
      request.onerror = (err) => {
        console.error(`Error to get password: ${err}`);
        reject();
        //reject
      };
    };
  });
};

// update email

export const updateEmail = function (contactMails, key) {
  return new Promise((resolve, reject) => {
    let contactMail = contactMails;
    if (key == "ASSOCIATE") {
      contactMail.status = true;
    } else {
      contactMail.status = false;
    }
    const request = idb.open("emailDb", 1.0);
    request.onsuccess = function (event) {
      const db = event.target.result;
      console.log(`${TAG}.db`, db);
      const tx = db
        .transaction(["userContactEmail"], "readwrite")
        .objectStore("userContactEmail")
        .put(contactMail);

      tx.onsuccess = (event) => {
        let response = event.target.result;
        let result;

        if (response) {
          result = response;
          console.log(`${TAG}.updatevalue`, result);
          // passwordDecrypt = encryption(null, password)
          resolve(result);
        } else {
          console.log("response not present");
          reject();
        }

        // console.log(`${TAG}.password`, passwordDecrypt);
      };
      request.onerror = (err) => {
        console.error(`Error to get password: ${err}`);
        //reject
      };
    };
  });
};

// clear password
export const clearPassword = function () {
  return new Promise((resolve, reject) => {
    const request = idb.open("emailDb", 1.0);
    // console.log(`${TAG}.request`, request);
    request.onsuccess = function (event) {
      const db = event.target.result;
      console.log(`${TAG}`, db);
      const tx = db
        .transaction("userEmailPassword", "readwrite")
        .objectStore("userEmailPassword")
        .clear();

      tx.onsuccess = (event) => {
        // const password = event.target.result[0].EMAIL_PASSWORD;
        // const passwordDecrypt = encryption(null, password)

        // console.log(`${TAG}.password`, passwordDecrypt);
        console.log("Password Successfully removed");

        // return passwordDecrypt;
      };
      request.onerror = (err) => {
        console.error(`Error to clear password: ${err}`);
        //reject
      };
    };
  });
};
//get email from indexdb by email
export const getMailsByEmail = function (leadEmail) {
  return new Promise((resolve, reject) => {
    const request = idb.open("emailDb", 1.0);
    request.onsuccess = function (event) {
      const db = event.target.result;

      const tx = db
        .transaction("userContactEmail")
        .objectStore("userContactEmail")
        .getAll();
      console.log("IndexDb", tx);
      tx.onsuccess = (event) => {
        let emailArr;
        emailArr = event.target.result;
        let filterArr = [];
        if (leadEmail) {
          emailArr.map((e) => {
            if (e.from == leadEmail) {
              filterArr.push(e);
            }
            console.log("get single mail", e);
          });
          console.log("get email from indexdb", leadEmail, filterArr);
          resolve(filterArr);
        } else {
          resolve(emailArr);
        }
      };
      request.onerror = (err) => {
        console.error(`Error to get Email: ${err}`);
        //reject
      };
    };
  });
};
