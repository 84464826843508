const EventBus = {
  on(EVENT_NAME, callback) {
    document.addEventListener(EVENT_NAME, (e) => callback(e.detail));
  },
  dispatch(EVENT_NAME, data) {
    document.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: data }));
  },
  remove(EVENT_NAME, callback) {
    document.removeEventListener(EVENT_NAME, callback);
  },
};

export default EventBus;
