export function htmlToNormalTextConverter(HTML) {
  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = HTML;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function cleanHtml(str) {
  console.log(str, "cleanhtml===>");
  return str.replace(
    /<p>(?:\s*<br>\s*)*([\s\S]*?)(?:\s*<br>\s*)*<\/p>/g,
    function (match, group) {
      return group.trim() ? "<p>" + group.trim() + "</p>" : "";
    }
  );
}
