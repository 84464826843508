import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
  Grid,
  Divider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import RestoreIcon from "@mui/icons-material/Restore";
import SalesCampaignStepper from "./SalesCampaignStepper";
import { getContacts } from "../../services/contacts.service";
import { updateSalesCampaignExclusionEmail } from "../../services/salesCampaign.service";
import LoaderDialouge from "../../components/Dialogs/LoaderDialouge";
import { toast } from "react-toastify";

const ActionDialog = ({
  open,
  onClose,
  title,
  emails,
  setEmails,
  actionText,
  icon,
  onAction,
  showEmailList = false,
  activeStep,
  setActiveStep,
  formData,
  setFormData,
  emailContent,
  setEmailContent,
  contactEmail,
  setcontactEmail,
  selectedEmails,
  setSelectedEmails,
  sendingDate,
  setSendingDate,
  sendingTime,
  setSendingTime,
  selectedCampaign,
  setSelectedCampaign,
  checked,
  setChecked,
  loaderDialog,
  setLoaderDialog,
  isEdit = false,
  isView,
  steps,
  getExclusionEmail,
  stableContactEmails = [],
  setStableContactEmails,
  searchText,
  setSearchText,
  getCoantactEmails,
}) => {
  const isValidStep = (step) => {
    if (activeStep === 0) {
      return (
        formData.campaignName.trim() !== "" &&
        formData.maxCapacity.trim() !== ""
      );
    } else if (activeStep === 1) {
      if (step === 0) {
        return (
          formData.campaignName.trim() !== "" &&
          formData.maxCapacity.trim() !== ""
        );
      }
      return cleanHtmls(emailContent.trim()) && formData.subject.trim() !== "";
    } else if (activeStep === 2) {
      if (step === 0) {
        return (
          formData.campaignName.trim() !== "" &&
          formData.maxCapacity.trim() !== ""
        );
      } else if (step === 1) {
        return (
          cleanHtmls(emailContent.trim()) && formData.subject.trim() !== ""
        );
      }
      return selectedEmails.length > 0;
    }
    return true; // Or add more validations for other steps if needed
  };
  const cleanHtmls = (html) => {
    const cleanTag = "<p><br></p>";

    // Trim cleanTag from the beginning
    while (html.startsWith(cleanTag)) {
      html = html.substring(cleanTag.length).trim();
    }

    // Trim cleanTag from the end
    while (html.endsWith(cleanTag)) {
      html = html.substring(0, html.length - cleanTag.length).trim();
    }

    return html;
  };
  const handleStepClick = (step) => {
    console.log("676hgj", step);
    if (!isValidStep(step)) {
      toast.warning(
        <>
          You must complete
          <strong style={{ color: "secondary" }}> current step </strong> before
          moving on.
        </>
      );
      return;
    } else {
      setActiveStep(() => step + 1);
    }
  };

  const [handlePagination, setHandlePagination] = useState({
    rowsPerPage: 3,
    page: 0,
    handleChangePage: (event, newPage) => {
      setHandlePagination((prev) => ({
        ...prev,
        page: +newPage,
        rowsPerPage: 3,
      }));
    },
    handleChangeRowsPerPage: (event) => {
      setHandlePagination((prev) => ({
        ...prev,
        page: 0,
        rowsPerPage: +event.target.value,
      }));
    },
  });

  const handleRemoveEmail = async (contactId) => {
    try {
      let isExcluded = false;
      const result = await updateSalesCampaignExclusionEmail(
        contactId,
        isExcluded
      );
      console.log("resultRemove", result.data.status);
      if (result.data.status) {
        await getExclusionEmail();
        toast.success("Email ID successfully restored from exclusion list!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  function checkTitle() {
    if (title === "Create Campaign" || title === "Update Campaign") return true;
    return false;
  }

  return (
    <Dialog open={open} onClose={() => {}} maxWidth="xl">
      <DialogTitle>{title}</DialogTitle>

      {/* <Divider /> */}
      <DialogContent dividers>
        {showEmailList ? (
          emails.length > 0 ? (
            <>
              <TableContainer sx={{ width: "500px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emails
                      .slice(
                        handlePagination.page * handlePagination.rowsPerPage,
                        handlePagination.page * handlePagination.rowsPerPage +
                          handlePagination.rowsPerPage
                      )
                      .map((email) => (
                        <TableRow key={email._id}>
                          <TableCell>{email.email}</TableCell>
                          <TableCell align="right">
                            <Tooltip
                              title="Restore Email ID"
                              placement="bottom"
                            >
                              <IconButton
                                onClick={() => handleRemoveEmail(email._id)}
                              >
                                <RestoreIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[3]}
                component="div"
                count={emails.length}
                rowsPerPage={handlePagination.rowsPerPage}
                page={handlePagination.page}
                onPageChange={handlePagination.handleChangePage}
                onRowsPerPageChange={handlePagination.handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Typography>No email IDs are currently excluded.</Typography>
          )
        ) : (
          <SalesCampaignStepper
            activeStep={isView ? 3 : activeStep} // Show last step if isView is true
            setActiveStep={setActiveStep}
            formData={formData}
            setFormData={setFormData}
            emailContent={emailContent}
            setEmailContent={setEmailContent}
            contactEmail={contactEmail}
            setcontactEmail={setcontactEmail}
            stableContactEmails={stableContactEmails}
            setStableContactEmails={setStableContactEmails}
            selectedEmails={selectedEmails}
            setSelectedEmails={setSelectedEmails}
            sendingDate={sendingDate}
            setSendingDate={setSendingDate}
            sendingTime={sendingTime}
            setSendingTime={setSendingTime}
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            checked={checked}
            setChecked={setChecked}
            loaderDialog={loaderDialog}
            setLoaderDialog={setLoaderDialog}
            isEdit={isEdit}
            isView={isView}
            steps={steps}
            getExclusionEmail={getExclusionEmail}
            searchText={searchText}
            setSearchText={setSearchText}
            getCoantactEmails={getCoantactEmails}
          />
        )}
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions sx={{ py: 2 }}>
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Grid item xs={6}>
            <Button
              color="error"
              onClick={() => {
                onClose();
                setHandlePagination((prev) => ({
                  ...prev,
                  page: 0,
                }));
              }}
              sx={{ width: "50px" }}
            >
              Cancel
            </Button>
          </Grid>
          {!isView && activeStep === 0 && (
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => handleStepClick(activeStep)}
                sx={{ float: "right" }}
              >
                Proceed &gt;&gt;
              </Button>
            </Grid>
          )}
          {!isView && activeStep === 1 && (
            <>
              <Grid item xs={2.2} sx={{ float: "right", px: 2 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setActiveStep((prev) => prev - 1)}
                  >
                    &lt;&lt; Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleStepClick(activeStep)}
                  >
                    Proceed &gt;&gt;
                  </Button>
                </div>
              </Grid>
            </>
          )}
          {!isView && activeStep === 2 && (
            <Grid item xs={2.2} sx={{ float: "right" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  onClick={() => setActiveStep((prev) => prev - 1)}
                >
                  &lt;&lt; Back
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleStepClick(activeStep)}
                >
                  Proceed &gt;&gt;
                </Button>
              </div>
            </Grid>
          )}
          {!isView && checkTitle() && activeStep === 3 && (
            <Grid
              item
              xs={activeStep === 3 ? 3.6 : 2.2}
              sx={{ float: "right", px: 2 }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  onClick={() => setActiveStep((prev) => prev - 1)}
                >
                  &lt;&lt; Back
                </Button>

                <Button
                  variant="contained"
                  onClick={() => onAction("Save As Draft")}
                  disabled={isEdit}
                >
                  Save As Draft
                </Button>

                <Button variant="contained" onClick={onAction}>
                  {actionText}
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </DialogActions>
      <LoaderDialouge loaderDialog={loaderDialog} />
    </Dialog>
  );
};

export default ActionDialog;
