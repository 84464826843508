import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { getCountries } from "../../services/common.service";
import { useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { updateCompany } from "../../services/companies.service";
import { useDynamicCompanyTitle } from "../../pages/Companies/Companies";
import getAttributesByEntity from "../../services/entity.attribute.services";
import EntityAttributeFields from "../Shared/Custom/EntityAttributeFields";
import validateUniqueAttributes from "../../utils/customAttributesUtils";
import { capitalizedString } from "../../utils/capitalizedString";
import { IndianState } from "../../_mock/IndianState";

function CompanyInfo({
  currentUser,
  company,
  handleChange,
  fromCompanyViewPage,
  toEdit,
  setToEdit,
  setCompany,
  tempCompanyData,
  fetchCompanyById,
  customAttributes,
  setCustomAttributes,
  afterCreate,
  fromCompanyCreate,
  actionHolders,
}) {
  const companyDynamicTitle = useDynamicCompanyTitle();
  const [country, setCountry] = useState([]);

  const validate = (company) => {
    const fields = ["name", "website", "country", "customerId"];
    let flag = true;

    Object.keys(company).forEach((ele) => {
      if (ele === "country" && company[ele] === "India") {
        if (!company.state) {
          toast("State cannot be empty!");
          flag = false;
        }

        // if (!company.gstNumber) {
        //   // toast("GST number cannot be empty!");
        //   toast(
        //     `${companyDynamicTitle?.displayName.singularName} GST number can not be empty`
        //   );
        //   flag = false;
        // }
      }
      if (fields.includes(ele) && !company[ele]) {
        console.log(company, "78767dsfs");

        toast(`${capitalizedString(ele)} can not be empty!`);
        flag = false;
      }
    });

    return flag;
  };

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }
      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const checkUnique = () => {
    console.log(customAttributes, company);
    let result = {};
    let flag = false;
    const checkMandatoryFieldStatus = validate(company);
    if (!checkMandatoryFieldStatus) {
      return;
    }

    Object.keys(company).forEach((ele) => {
      if (company[ele] !== tempCompanyData[ele]) {
        Object.assign(result, { [ele]: company[ele] });
        flag = true;
      }
    });

    result["attributes"] = validateUniqueAttributes(
      company.attributes,
      customAttributes
    );

    if (result["attributes"].length) {
      flag = true;
    }

    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      return;
    }

    if (flag) {
      doUpdate(result);
    } else {
      toast("Nothing to update");
    }
  };

  const doUpdate = async (result) => {
    try {
      const response = await updateCompany(company._id, {
        ...result,
        attributes: [...customAttributes],
      });
      if (response.data.status) {
        fetchCompanyById();
        setToEdit((prev) => (prev = true));
        toast(
          `${companyDynamicTitle?.displayName.singularName} Updated Successfully`
        );
      }
    } catch (error) {
      if (error.response.data.message) {
        toast(error.response.data.message);
      }
      toast(error.response.data.data[0]);
    }
  };

  useEffect(() => {
    getCountries()
      .then((res) => {
        if (res.data.status) {
          setCountry(res.data.data);
        }
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  // Custom Attribute

  useEffect(() => {
    if (!fromCompanyViewPage) {
      (async () => {
        try {
          const response = await getAttributesByEntity("Companies");
          if (response.data.status) {
            setCustomAttributes(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [afterCreate]);
  console.log("company.gstNumber", company.gstNumber, typeof company.gstNumber);
  return (
    <Card
      className="child"
      sx={{ padding: "20px 0px 20px 0px" }}
      elevation={3}
      variant="outlined"
    >
      <CardContent>
        {/* <FormControl> */}
        <Grid container spacing={4}>
          {!["ADMIN", "SALES"]?.includes(currentUser?.role) &&
          actionHolders?.includes(
            currentUser._id
          ) ? null : fromCompanyViewPage ? (
            <Grid item xs={12}>
              {toEdit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    startIcon={<ModeEditIcon />}
                    fontSize="small"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setToEdit(false);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    size="large"
                    onClick={() => {
                      checkUnique();
                    }}
                  >
                    Save
                  </Button>
                  <div>&nbsp;</div>
                  <Button
                    style={{
                      display: "display",
                      height: "48px",
                      width: "107.39px",
                    }}
                    variant="contained"
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setCompany((prev) => ({ ...prev, ...tempCompanyData }));
                      setCustomAttributes(company.attributes);
                      setToEdit(true);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              InputProps={{ readOnly: fromCompanyViewPage && toEdit }}
              label={`${companyDynamicTitle?.displayName.singularName} Name`}
              variant="outlined"
              placeholder={`${companyDynamicTitle?.displayName.singularName} Name`}
              type="text"
              name="name"
              value={company.name}
              onChange={handleChange}
              required
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{ readOnly: fromCompanyViewPage && toEdit }}
              label={`${companyDynamicTitle?.displayName.singularName} Website`}
              variant="outlined"
              placeholder={`${companyDynamicTitle?.displayName.singularName} Website`}
              type="text"
              name="website"
              value={company.website}
              onChange={handleChange}
              required
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputProps={{ readOnly: fromCompanyViewPage && toEdit }}
              label={`${companyDynamicTitle?.displayName.singularName} Address`}
              variant="outlined"
              placeholder={`${companyDynamicTitle?.displayName.singularName} Address`}
              type="text"
              name="address"
              value={company.address}
              onChange={handleChange}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-country-select-label">
                  Country *
                </InputLabel>
                <Select
                  readOnly={fromCompanyViewPage && toEdit}
                  labelId="demo-country-select-label"
                  id="demo-country-select"
                  label="Country"
                  name="country"
                  value={company.country}
                  onChange={handleChange}
                >
                  {country?.map((country, index) => {
                    return (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {company.country === "India" && (
            <Grid item xs={6}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-country-select-label">
                    State *
                  </InputLabel>
                  <Select
                    readOnly={fromCompanyViewPage && toEdit}
                    labelId="demo-country-select-label"
                    id="demo-country-select"
                    label="State"
                    name="state"
                    value={company.state}
                    onChange={handleChange}
                  >
                    {IndianState?.map((country, index) => {
                      return (
                        <MenuItem key={index} value={country}>
                          {country}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              InputProps={{ readOnly: fromCompanyViewPage && toEdit }}
              label="LinkedIn Profile"
              variant="outlined"
              placeholder={`${companyDynamicTitle?.displayName.singularName} LinkedIn`}
              type="text"
              name="linkedIn"
              value={company.linkedIn}
              onChange={handleChange}
              fullWidth
            ></TextField>
          </Grid>
          {company.country === "India" && (
            <Grid item xs={6}>
              <TextField
                InputProps={{ readOnly: fromCompanyViewPage && toEdit }}
                label={`${companyDynamicTitle?.displayName.singularName} GST Number`}
                variant="outlined"
                placeholder={`${companyDynamicTitle?.displayName.singularName} GST Number`}
                type="text"
                name="gstNumber"
                value={company?.gstNumber}
                onChange={handleChange}
                fullWidth
                // required
              ></TextField>
            </Grid>
          )}
          {fromCompanyCreate && (
            <Grid item xs={6}>
              <TextField
                InputProps={{ readOnly: fromCompanyViewPage && toEdit }}
                label={`Customer Id`}
                variant="outlined"
                placeholder={`Customer Id`}
                type="text"
                name="customerId"
                value={company?.customerId}
                onChange={handleChange}
                fullWidth
                helperText={
                  <Typography variant="caption" sx={{ fontSize: 11.5 }}>
                    P.S: It should be a number with a prefix ULC followed by an
                    alphanumeric sequence
                  </Typography>
                }
              ></TextField>
            </Grid>
          )}

          {/* {fromCompanyViewPage && (
            <Grid item xs={6}>
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                label={`Customer Id`}
                variant="outlined"
                placeholder={`Customer Id`}
                type="text"
                name="customerId"
                value={company?.customerId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          )} */}
        </Grid>
        {customAttributes.length ? (
          <Grid container spacing={4} mt="2px">
            {customAttributes.map((attribute, index) => (
              <Grid item xs={6} key={index}>
                <EntityAttributeFields
                  componentIndex={index}
                  componentDeatils={attribute}
                  allAttributes={customAttributes}
                  setterFunction={setCustomAttributes}
                  entityName={"Company"}
                  fromCompanyViewPage={fromCompanyViewPage}
                  toEdit={toEdit}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
        {/* </FormControl> */}
      </CardContent>
    </Card>
  );
}

export default CompanyInfo;
