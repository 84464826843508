import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Watcher = ({
  setIsWatcherDialogOnEdit,
  isWatcherDialogOnEdit,
  users,
  lead,
  setLead,
  salesOwnerTechOwnerInfo,
  setsalesOwnerTechOwnerInfo,
  currentUser,
  filterDone,
  setUsers,
}) => {
  useEffect(() => {
    if (filterDone) {
      const temp = [...users];
      if (temp.length > 0 && lead.watchers.length > 0) {
        for (let index = 0; index < temp.length; index++) {
          for (let index1 = 0; index1 < lead.watchers.length; index1++) {
            if (temp[index] && lead.watchers[index1]) {
              if (temp[index]._id === lead.watchers[index1]._id) {
                temp.splice(index, 1);
              }
            } else {
              return;
            }
          }
        }
      }
      setUsers(temp);
    }
  }, [filterDone]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          readOnly={!isWatcherDialogOnEdit ? true : false}
          multiple
          // freeSolo
          id="tags-outlined"
          // options={users}
          options={[...new Set(users)]}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            console.log("Option", option);
            if (
              option.active_status !== false &&
              option.name !== salesOwnerTechOwnerInfo.salesOwnerName &&
              option.name !== salesOwnerTechOwnerInfo.techOwnerName
            ) {
              return (
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <li {...props} style={{ borderColor: "white" }}>
                      {option.name}
                    </li>
                  </Grid>
                </Grid>
              );
            }
          }}
          value={lead.watchers || []}
          filterSelectedOptions={true}
          onChange={(event, newValue, reason) => {
            if (
              currentUser.role !== "ADMIN" &&
              currentUser._id !== lead.techOwner &&
              currentUser._id !== lead.salesOwner
            ) {
              if (lead.watchers.length > newValue.length) {
                let deletedItem = [...lead.watchers].filter(
                  (x) => !newValue.includes(x)
                );
                if (deletedItem.length > 0) {
                  deletedItem.forEach((e) => {
                    if (e._id === currentUser._id) {
                      // {let indexOfDeletedItem = lead.watchers.indexOf(e);
                      // setLead((prev) => {
                      //     let temp = { ...prev };
                      //     temp.watchers.splice(indexOfDeletedItem, 1);
                      //     return temp;
                      // })}
                      setLead((prevState) => ({
                        ...prevState,
                        watchers: [...newValue],
                      }));
                    } else {
                      toast("You can't remove other watchers");
                    }
                  });
                }
              } else {
                toast("You don't have permission to add other watchers.");
                return;
              }
            } else if (newValue.length < lead.watchers.length) {
              let deletedOne = lead.watchers.filter(
                (x) => !newValue.includes(x)
              );
              setUsers((prev) => {
                let temp = [...prev];
                temp.push(...deletedOne);
                return temp;
              });

              setLead((prevState) => ({
                ...prevState,
                watchers: [...newValue],
              }));
            } else {
              setLead((prevState) => ({
                ...prevState,
                watchers: [...newValue],
              }));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Platform Users"
              placeholder="Click to Add"
            />
          )}
        ></Autocomplete>
      </Grid>
    </Grid>
  );
};

export default Watcher;
