import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function SummaryTable({ invoiceReports }) {
  // Summary start

  let currencyArray = [...new Set(invoiceReports.map((item) => item.currency))];

  // Print or use the currencyArray as needed
  const TABLE_HEAD = currencyArray.map((currency) => ({
    id: currency,
    label: currency,
    alignRight: false,
  }));

  let currencyTotals = [];

  invoiceReports.forEach((item) => {
    // Extract relevant properties
    let { currency, amountTotal, paid, dueAmount } = item;
    console.log("currencyTotals1", currency, amountTotal, paid, dueAmount);

    // Find the index of the currency in currencyTotals array
    let index = currencyTotals.findIndex((obj) => obj.currency === currency);

    // If currency is not found, add it to the array
    if (index === -1) {
      currencyTotals.push({
        currency,
        amountTotal: amountTotal || 0, // Ensure amountTotal is defined, default to 0 if not
        paid: paid || 0, // Ensure paid is defined, default to 0 if not
        dueAmount: dueAmount || 0, // Ensure dueAmount is defined, default to 0 if not
      });
    } else {
      // Accumulate totals for each currency, ensuring values are defined before adding
      currencyTotals[index].amountTotal += amountTotal || 0;
      currencyTotals[index].paid += paid || 0;
      currencyTotals[index].dueAmount += dueAmount || 0;
    }
  });

  currencyTotals = currencyTotals.map(({ currency, ...rest }) => rest);
  const amountTotalArray = currencyTotals.map((item) => item.amountTotal);
  const paidArray = currencyTotals.map((item) => item.paid);
  const dueAmountArray = currencyTotals.map((item) => item.dueAmount);

  // Summary End
  console.log("currencyTotals", currencyTotals, invoiceReports);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 140 }}>
              <b>Summary</b>
            </TableCell>
            {TABLE_HEAD.map((th, index) => (
              <TableCell sx={{ width: 90 }} align="left">
                {th.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={0}
            //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <b>Total Chargable</b>
            </TableCell>
            {amountTotalArray.map((item) => (
              <TableCell align="left">{item}</TableCell>
            ))}
          </TableRow>
          <TableRow
            key={0}
            //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <b> Total Collected</b>
            </TableCell>
            {paidArray.map((item) => (
              <TableCell align="left">{item}</TableCell>
            ))}
          </TableRow>
          <TableRow
            key={0}
            //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <b>Total Pending</b>
            </TableCell>
            {dueAmountArray.map((item) => (
              <TableCell align="left">{item}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
