import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
  { id: "title", label: "Project Title", alignRight: false },
  { id: "salesOwner", label: "Sales Owner", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "due", label: "Chargable Amount", alignRight: false },
  { id: "collected", label: "Collected Amount", alignRight: false },
  { id: "pending", label: "Outstanding / Pending Amount", alignRight: false },
  { id: "dateOfGenerate", label: "Generated On", alignRight: false },
];

function InvoiceReportTable({
  dataView,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) {
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log("dataView", dataView);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((th, index) => (
                <TableCell key={index}>{th.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataView
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((report, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      component={Link}
                      sx={{
                        textDecoration: "none",
                        ":hover": { cursor: "pointer" },
                      }}
                      onClick={() => {
                        navigate(`/projects/${report.projectId}`);
                      }}
                    >
                      {report.title}
                    </TableCell>
                    <TableCell>{report.salesOwnerName || ""}</TableCell>
                    <TableCell>{report.currency || ""}</TableCell>
                    <TableCell>{report.amountTotal}</TableCell>
                    <TableCell>{report.paid || 0}</TableCell>
                    <TableCell>{report.dueAmount || 0}</TableCell>
                    <TableCell>
                      {new Date(report.invoiceDate).toDateString() || ""}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={dataView?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default InvoiceReportTable;
