import { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { AuthContext } from "../../context/auth.context";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Fragment } from "react";
import { useEffect } from "react";
import { getMyActions } from "../../services/common.service";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setLeadAction } from "../../services/leads.service";
import { setContactAction } from "../../services/contacts.service";
import { setProjectAction } from "../../services/projects.service";
import { ActionContext } from "../../context/action.context";

// ----------------------------------------------------------------------

const style = {
  margin: 0,
  top: "auto",
  right: 34.5,
  bottom: 130,
  left: "auto",
  position: "fixed",
};

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("xl")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [deskTopClick, setDeskTopClick] = useState(false);
  const { currentUser, isAuthenticated, hideCallDialer, setHideCallDialer } =
    useContext(AuthContext);
  const { actionList, setActionList, fetchActions, handleCompleteTask } =
    useContext(ActionContext);
  const navigate = useNavigate();
  if (!isAuthenticated) {
    console.log("isAuthenticated", isAuthenticated);
    navigate("/");
  }
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setHideCallDialer(open);
    if (currentUser && currentUser.role !== "SUPER_ADMIN") {
      fetchActions();
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const dateMatch = (date) => {
    const dueDate = new Date(date).toDateString();
    const todayDate = new Date().toDateString();
    if (dueDate === todayDate) {
      return true;
    }
    return false;
  };

  const pastDateMatch = (date) => {
    const dueDate = new Date(date).setHours(0, 0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0, 0);
    if (dueDate < todayDate) {
      return true;
    }
  };

  const handleCheckBoxChange = async (event, action) => {
    console.log(action);
    if (actionList.includes(action)) {
      handleCompleteTask(action);
    }
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function placeHoursAndMinutes(newDateInSeconds) {
    const hours = new Date(newDateInSeconds).getUTCHours();
    const stamp = hours > 12 ? "PM" : "AM";
    // const newHours = hours > 12 ? `0${hours - 12}` : hours;
    const newHours =
      hours > 12
        ? hours - 12 >= 10
          ? hours - 12
          : `0${hours - 12}`
        : hours >= 10
        ? hours
        : `0${hours}`;
    const minutes = new Date(newDateInSeconds).getUTCMinutes();
    const newMinutes = minutes >= 10 ? minutes : `0${minutes}`;
    const time = `${newHours}:${newMinutes} ${stamp}`;
    return time;
  }

  function dateChange(date) {
    // console.log(date);
    const dateTimeInSeconds = new Date(date).getTime();
    // console.log(dateTimeInSeconds);
    const offSet = new Date().getTimezoneOffset();
    // console.log(offSet);
    // const offSetInSeconds = offSet * 60 * 1000;
    const offSetInSeconds = offSet * 60 * 1000;
    // console.log(offSetInSeconds);
    const newDateInSeconds = dateTimeInSeconds - offSetInSeconds;
    // console.log(newDateInSeconds);
    // const newDate = new Date(newDateInSeconds);
    // console.log(newDate);
    // const newDateFormat = formatDate(newDate);
    // console.log(newDateFormat);
    const dateString = [
      padTo2Digits(new Date(newDateInSeconds).getUTCDate()),
      padTo2Digits(new Date(newDateInSeconds).getUTCMonth() + 1),
      new Date(newDateInSeconds).getUTCFullYear(),
    ].join("/");
    if (
      new Date(new Date().setHours(0, 0, 0, 0)).getHours() ===
        new Date(newDateInSeconds).getUTCHours() &&
      new Date(new Date().setHours(0, 0, 0, 0)).getMinutes() ===
        new Date(newDateInSeconds).getUTCMinutes()
    ) {
      return dateString;
    } else {
      return dateString + "  at  " + placeHoursAndMinutes(newDateInSeconds);
    }
    // return newDateFormat;
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ marginTop: "8px" }}>
        <Typography
          sx={{ marginLeft: "16px", fontWeight: "bold", color: "#2056D1" }}
          variant="h6"
        >
          By Today
        </Typography>
        <FormGroup>
          {actionList.map((action, index) => {
            if (dateMatch(action.dueDate)) {
              return (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ border: "none", padding: 0 }}
                >
                  <ListItemButton
                    disablePadding
                    sx={{ border: "none", padding: 0 }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary
                            sx={{
                              maxHeight: "50px",
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <FormControlLabel
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              control={
                                <Checkbox
                                  disabled={
                                    action.completed && action.checked
                                      ? true
                                      : false
                                  }
                                  checked={action.checked}
                                  onClick={(e) => {
                                    handleCheckBoxChange(e, action);
                                  }}
                                />
                              }
                            />
                            <Typography
                              sx={{
                                textDecoration:
                                  action.completed && action.checked
                                    ? "line-through"
                                    : null,
                                paddingTop: "8.5px",
                              }}
                            >
                              {action.toDo}
                            </Typography>
                            {action.completed ? (
                              <div
                                style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  paddingTop: "11px",
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: "green",
                                  }}
                                />
                              </div>
                            ) : null}
                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails>
                            {action.actionType === "PROJECTS" ? (
                              <span>
                                PROJECT:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(`/projects/${action.projectId}`);
                                  }}
                                >
                                  {action.projectTitle}
                                </Link>
                              </span>
                            ) : null}
                            {action.actionType === "CONTACTS" ? (
                              <span>
                                CONTACT:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(`/contacts/${action.contactId}`);
                                  }}
                                >
                                  {action.contactName}
                                </Link>
                              </span>
                            ) : null}
                            {action.actionType === "LEADS" ? (
                              <span>
                                LEAD:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(
                                      `/Leads/${action.leadId}/${action.contactId}`
                                    );
                                  }}
                                >
                                  {action.leadSummary}
                                </Link>
                              </span>
                            ) : null}
                            {action.actionType === "COMPANIES" ? (
                              <span>
                                COMPANY:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(
                                      `/companies/view/${action.companyId}`
                                    );
                                  }}
                                >
                                  {action.companyName}
                                </Link>
                              </span>
                            ) : null}
                            <br />
                            <span>
                              Due Date:{"  "}
                              <span style={{ fontWeight: "bold" }}>
                                {dateChange(action.dueDate)}
                              </span>
                            </span>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </ListItem>
              );
            }
          })}
        </FormGroup>
      </List>
      <Divider />
      <List
      // sx={{ marginTop: "8px" }}
      >
        <Typography
          sx={{ marginLeft: "16px", fontWeight: "bold", color: "#4A148C" }}
          variant="h6"
        >
          Pending Until Today
        </Typography>
        <FormGroup>
          {actionList.map((action, index) => {
            if (pastDateMatch(action.dueDate)) {
              return (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ border: "none", padding: 0 }}
                >
                  <ListItemButton
                    disablePadding
                    sx={{ border: "none", padding: 0 }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary
                            sx={{
                              maxHeight: "50px",
                            }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    action.completed && action.checked
                                      ? true
                                      : false
                                  }
                                  checked={action.checked}
                                  onClick={(e) => {
                                    handleCheckBoxChange(e, action);
                                  }}
                                />
                              }
                            />
                            <Typography
                              sx={{
                                textDecoration:
                                  action.completed && action.checked
                                    ? "line-through"
                                    : null,
                                paddingTop: "8.5px",
                              }}
                            >
                              {action.toDo}
                            </Typography>
                            {action.completed ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: "11px",
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{
                                    color: "green",
                                  }}
                                />
                              </div>
                            ) : null}
                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails>
                            {/* {action.projectTitle !== null
                              ? `PROJECT: ${action.projectTitle}`
                              : action.leadSummary !== null
                              ? `LEAD: ${action.leadSummary}`
                              : `CONTACT: ${action.contactName}`} */}
                            {action.actionType === "COMPANIES" ? (
                              <span>
                                COMPANY:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(
                                      `/companies/view/${action.companyId}`
                                    );
                                  }}
                                >
                                  {action.companyName}
                                </Link>
                              </span>
                            ) : null}
                            {action.actionType === "PROJECTS" ? (
                              <span>
                                PROJECT:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(`/projects/${action.projectId}`);
                                  }}
                                >
                                  {action.projectTitle}
                                </Link>
                              </span>
                            ) : null}
                            {action.actionType === "CONTACTS" ? (
                              <span>
                                CONTACT:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(`/contacts/${action.contactId}`);
                                  }}
                                >
                                  {action.contactName}
                                </Link>
                              </span>
                            ) : null}
                            {action.actionType === "LEADS" ? (
                              <span>
                                LEAD:{"  "}
                                <Link
                                  sx={{ fontWeight: "bold" }}
                                  onClick={() => {
                                    navigate(
                                      `/Leads/${action.leadId}/${action.contactId}`
                                    );
                                  }}
                                >
                                  {action.leadSummary}
                                </Link>
                              </span>
                            ) : null}
                            <br />
                            <span>
                              Due Date:{"  "}
                              <span style={{ fontWeight: "bold" }}>
                                {dateChange(action.dueDate)}
                              </span>
                            </span>
                            {/* <Grid
                              container
                              spacing={0}
                              // sx={{ marginTop: "8px" }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    action.actionType === "CONTACTS" &&
                                      navigate(`/contacts/${action.contactId}`);
                                    action.actionType === "LEADS" &&
                                      navigate(
                                        `/Lead/${action.leadId}/${action.contactId}`
                                      );
                                    action.actionType === "PROJECTS" &&
                                      navigate(`/projects/${action.projectId}`);
                                  }}
                                >
                                  Go To Page
                                </Button>
                              </Grid>
                            </Grid> */}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </ListItem>
              );
            }
          })}
        </FormGroup>
      </List>
    </Box>
  );

  useEffect(() => {
    if (currentUser && currentUser.role !== "SUPER_ADMIN") {
      fetchActions();
    }
  }, [currentUser]);

  const handleSidebarClose = () => {
    setOpen(false);
    setDeskTopClick(false);
  };
  console.log(currentUser);
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={handleSidebarClose}
        onOpenSidebar={() => setOpen(true)}
        setDeskTopClick={setDeskTopClick}
        deskTopClick={deskTopClick}
      />
      <MainStyle>
        {currentUser?.role !== "SUPER_ADMIN" &&
          ["right"].map((anchor) => (
            <Fragment key={anchor}>
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
              <Fab
                color="primary"
                aria-label="add"
                size="medium"
                style={style}
                onClick={toggleDrawer(anchor, true)}
              >
                {/* <AddIcon /> */}
                {/* <PlaylistAddCheckIcon /> */}

                <PendingActionsIcon />
              </Fab>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </Fragment>
          ))}
        {/* <Fab color="primary" aria-label="add" size="small" style={style}>
          <AddIcon />
        </Fab> */}
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
