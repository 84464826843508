import React, { useEffect, useState } from "react";
import { useDynamicLeadTitle } from "../../../pages/Leads/Leads";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDynamicContactTitle } from "../../../pages/Contacts/Contacts";
import { getTechStacks } from "../../../services/projects.service";
import { toast } from "react-toastify";
import { removeDuplicateSkills } from "../../../utils/skills.utils";

export default function ProjectStaticAttribute({
  lead,
  projectData,
  setProjectData,
  skills,
  setSkills,
  techStacks,
  setTechStacks,
}) {
  let toastId = null;
  const [randomKey, setRandomKey] = useState("hpltbioqwerty");

  const contactDynamicTitle = useDynamicContactTitle();
  const leadDynamicTitle = useDynamicLeadTitle();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData({
      ...projectData,
      [name]: value,
    });
  };

  const fetchTechStacks = async () => {
    try {
      const response = await getTechStacks();
      if (response.data.status) {
        const techStacks = removeDuplicateSkills(response.data.data);
        setSkills(techStacks);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setTechStacks(lead?.skills || []);
    fetchTechStacks();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="demo-lead-select-label" required>
            {`${leadDynamicTitle?.displayName.singularName} Summary`}
          </InputLabel>
          <Select
            inputProps={{ readOnly: true }}
            labelId="demo-lead-select-label"
            id="demo-lead-select"
            label={`${leadDynamicTitle?.displayName.singularName} Summary`}
            name="leadId"
            value={lead.summary}
            onChange={handleChange}
          >
            <MenuItem key={"index"} value={lead.summary}>
              {lead.summary}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          label={`${contactDynamicTitle?.displayName.singularName} Name`}
          variant="outlined"
          placeholder={`${contactDynamicTitle?.displayName.singularName} Name`}
          type="text"
          name="name"
          required
          value={lead.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          label="Title"
          variant="outlined"
          placeholder="Title"
          type="text"
          name="title"
          required
          value={projectData.title}
          fullWidth
          onChange={(event) => {
            if (event.target.value && event.target.value.length > 80) {
              // if (toastId == null) {
              //   toastId = toast.warning(
              //     "Title should not be more then 30 characters."
              //   );
              //   console.log(toastId);
              // } else {
              //   toast.update(
              //     toastId,
              //     "Title should not be more then 30 characters."
              //   );
              // }
              toast.warning("Title should not be more then 80 characters.");
              //  toast.warning("Summary should not be more then 80 characters.");
            } else if (event.target.value && !event.target.value.trim()) {
              toast.warning("Title can't Contain Only Spaces");
              return;
            } else {
              handleChange(event);
            }
          }}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Autocomplete
            readOnly={true}
            multiple
            clearOnBlur
            freeSolo
            key={randomKey}
            id="techStackAutoComplete"
            noOptionsText={
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      size: "large",
                    }}
                    // onClick={handleClickOpen}
                  >
                    ADD NEW
                  </Button>
                </Grid>
              </Grid>
            }
            options={skills}
            getOptionLabel={(option) => option?.name || option}
            renderOption={(props, option) => {
              console.log("Option", option);
              return (
                <Grid container spacing={0}>
                  {option.name === "+ Add" ? (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          size: "large",
                        }}
                        // onClick={handleClickOpen}
                      >
                        ADD NEW
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <li {...props} style={{ borderColor: "white" }}>
                        {option.name}
                      </li>
                    </Grid>
                  )}
                </Grid>
              );
            }}
            value={techStacks}
            filterSelectedOptions={true}
            onChange={(event, newValue, reason) => {
              event.stopPropagation();

              function setChips(newValueArray) {
                setTechStacks((prevState) => [...prevState, ...newValueArray]);
              }
              switch (reason) {
                case "createOption":
                  console.log("");
                  newValue.forEach((e) => {
                    if (typeof e === "string" && e.trim() !== "") {
                      let newData = { name: e };
                      newValue.pop(e);
                      setChips([...newValue, newData]);
                    }
                  });
                  break;
                case "removeOption":
                case "selectOption":
                case "clear":
                  setChips(newValue);
                  break;
                default:
                  break;
              }
            }}
            renderInput={(params) => (
              <TextField
                id="textFieldValue"
                {...params}
                label="Techstacks"
                // placeholder="Click to Add"
              />
            )}
          />
          <span
            style={{
              fontSize: "10px",
              marginLeft: "8px",
              color: "#808080",
            }}
          >
            {/* *To Add An Unregistered Tech Stack, Write Its Name & Hit Enter */}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          label="Specification"
          id="demo-userId-select"
          variant="outlined"
          placeholder="Specification"
          type="text"
          name="specification"
          required
          value={projectData.specification}
          fullWidth
          onChange={(event) => {
            if (event.target.value && event.target.value.length > 170) {
              // if (toastId == null) {
              //   toastId = toast.warning(
              //     "Specification should not be more then 50 characters."
              //   );
              //   console.log(toastId);
              // } else {
              //   toast.update(
              //     toastId,
              //     "Specification should not be more then 50 characters."
              //   );
              // }
              //  toast.warning("Summary should not be more then 80 characters.");
              toast.warning(
                "Specification should not be more then 170 characters."
              );
            } else if (event.target.value && !event.target.value.trim()) {
              toast.warning("Specification can't Contain Only Spaces");
              return;
            } else {
              handleChange(event);
            }
          }}
        ></TextField>
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          label="Details"
          variant="outlined"
          required
          placeholder="Details"
          type="text"
          name="details"
          multiline
          rows={2}
          value={projectData.details}
          fullWidth
          onChange={(event) => {
            if (event.target.value && event.target.value.length > 350) {
              // if (toastId == null) {
              //   toastId = toast.warning(
              //     "Details should not be more then 150 characters."
              //   );
              //   console.log(toastId);
              // } else {
              //   toast.update(
              //     toastId,
              //     "Details should not be more then 150 characters."
              //   );
              // }
              toast.warning("Details should not be more then 350 characters.");
              //  toast.warning("Summary should not be more then 80 characters.");
            } else if (event.target.value && !event.target.value.trim()) {
              toast.warning("Details can't Contain Only Spaces");
              return;
            } else {
              handleChange(event);
            }
          }}
        ></TextField>
      </Grid>
    </Grid>
  );
}
