import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useDynamicLeadTitle } from "../../pages/Leads/Leads";
import { useDynamicCompanyTitle } from "../../pages/Companies/Companies";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth.context";

export default function SplitButton({ project, navigate }) {
  const { currentUser } = React.useContext(AuthContext);
  const leadDynamicTitle = useDynamicLeadTitle();
  const companyDynamicTitle = useDynamicCompanyTitle();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  React.useEffect(() => {
    if (currentUser.role === "DELIVERY_LEAD") {
      setSelectedIndex(0);
    }
  }, [currentUser.role]);
  const options = [
    `View ${companyDynamicTitle?.displayName.singularName}`,
    `View ${leadDynamicTitle?.displayName.singularName}`,
  ];
  if (currentUser.role === "DELIVERY_LEAD") {
    options.splice(0, 1);
  }
  console.log(currentUser.role, currentUser, "fddf0okgmfk");
  const handleClick = () => {
    console.info(`You clicked `, project);
    if (
      options[selectedIndex] ===
      `View ${leadDynamicTitle?.displayName.singularName}`
    ) {
      if (project.leadId && project.contactId) {
        navigate(`/leads/${project.leadId}/${project.contactId}`);
        return;
      }
      toast.warn("you can't redirect to this lead at this moment");
      return;
    }

    if (
      options[selectedIndex] ===
      `View ${companyDynamicTitle?.displayName.singularName}`
    ) {
      if (project.leadId && project.contactId && project?.contact.company) {
        // http://localhost:3000/companies/view/65f15aefa358419048693bed
        //   navigate(`/leads/${project.leadId}/${project.contactId}`);
        navigate(`/companies/view/${project?.contact.company}`);
        return;
      }
      toast.warn("you can't redirect to this lead at this moment");
      return;
    }
  };

  const handleMenuItemClick = (event, index) => {
    ///
    console.log(project.contactId, project.leadId, "hkloop");

    ///
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        size="large"
      >
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
