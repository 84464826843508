import React, { useEffect, useMemo, useState } from "react";
import Page from "../../components/UI/Page";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import {
  createEmailTemplet,
  fecthModuleAndEvents,
  updateEmailTemplate,
} from "../../services/common.service";
import { toast } from "react-toastify";
import EventBus from "../../components/texteditor/EventBus";
import ObjectViewAccordion from "./ObjectViewAccordion";
import DropOnTextField from "../../components/DropContainers/DropOnTextField";
import DropOnEditor from "../../components/DropContainers/DropOnEditor";
import { useLocation, useNavigate } from "react-router-dom";
import { getUsersByRoles } from "../../services/users.service";
import { htmlToNormalTextConverter } from "../../utils/convertHTMLtoNormalText";
import Editor from "../../components/texteditor/Editor";

function CreateEmailTemplate() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const storedData = useMemo(() => {
    // Store the data in a local state variable
    return state || {};
  }, [state]);
  console.log(
    state?.[0]?.moduleName,
    state?.[0]?.checked,
    state,
    storedData,
    "dfghdgfhdg"
  );
  const [emailTemplet, setEmailTemplet] = useState(
    state?.isEdit
      ? { ...state.emailTemplateData }
      : {
          moduleName:
            storedData?.[0]?.moduleName?.toUpperCase() === "ALL"
              ? "COMPANIES"
              : storedData?.[0]?.moduleName?.toUpperCase() || "COMPANIES",
          eventName: "",
          to: [],
          subject: "",
          body: "",
        }
  );

  const [moduleEvents, setModuleEvents] = useState([]);
  const [adminUser, setAdminUser] = useState(
    state?.isEdit ? [...state?.emailTemplateData.users] : []
  );
  // const [userName, setUserName] = useState([]);
  const [userData, setUserData] = useState([]);

  let objects = [
    {
      title: "Companies",
      accessTo: ["companies", "contacts", "projects", "leads"],
      keys: [
        "name",
        "linkedIn",
        "address",
        "country",
        "website",
        {
          title: "action",
          keys: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
        },
        {
          title: "comment",
          keys: ["comment", "createdBy"],
        },
      ],
    },
    {
      title: "Contacts",
      accessTo: ["contacts", "leads", "projects"],
      keys: [
        "name",
        "email",
        "phone",
        "alternateContact",
        "linkedin",
        "address",
        "country",
        "sourceDetails",
        {
          title: "salesOwner",
          keys: ["name", "email"],
        },
        {
          title: "action",
          keys: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
        },
        {
          title: "comment",
          keys: ["comment", "createdBy"],
        },
      ],
    },
    {
      title: "Leads",
      accessTo: ["leads", "projects"],
      keys: [
        "summary",
        "status",
        "description",
        "reason",
        {
          title: "salesOwner",
          keys: ["name", "email"],
        },
        {
          title: "techOwner",
          keys: ["name", "email"],
        },
        {
          title: "action",
          keys: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
        },
        {
          title: "comment",
          keys: ["comment", "createdBy"],
        },
      ],
    },
    {
      title: "Projects",
      accessTo: ["projects"],
      keys: [
        "title",
        "details",
        "specification",
        "status",
        {
          title: "salesOwner",
          keys: ["name", "email"],
        },
        {
          title: "techOwner",
          keys: ["name", "email"],
        },
        {
          title: "action",
          keys: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
        },
        {
          title: "comment",
          keys: ["comment", "createdBy"],
        },
        {
          title: "timeLine",
          keys: ["committedOn", "deadline"],
        },
      ],
    },
  ];

  // const [isReduce, setIsReduce] = useState(false);

  const [recipient] = useState({
    dashboard: [],
    timeline: [],
    roles: [],
    companies: ["admin", "action holder"],
    contacts: ["admin", "salesOwner", "action holder"],
    leads: ["admin", "techOwner", "salesOwner", "action holder", "watcher"],
    projects: ["admin", "techOwner", "salesOwner", "action holder"],
    report: [],
    email: [],
    archives: [],
    call: [],
    auth: ["currentUser"],
  });

  const handleChange = (event) => {
    setEmailTemplet((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === "moduleName") {
      setEmailTemplet((prev) => ({
        ...prev,
        eventName: "",
        to: [],
        subject: "",
        body: "",
      }));
      EventBus.dispatch("EditorBus");
    }
    if (
      event.target.name === "object" &&
      "clipboard" in navigator &&
      event.target.value !== "actions" &&
      event.target.value !== "comments" &&
      event.target.value !== "timeline"
    ) {
      // setIsReduce((prev) => (prev = false));
      navigator.clipboard.writeText(
        "${" +
          `${emailTemplet.moduleName.toLowerCase()}.` +
          event.target.value +
          "}"
      );
      toast("Text copied to clipboard");
    }
    if (
      event.target.value === "actions" ||
      event.target.value === "comments" ||
      event.target.value === "timeline"
    ) {
      // setIsReduce((prev) => (prev = true));
    }
    if (event.target.name === "nestedObject" && "clipboard" in navigator) {
      navigator.clipboard.writeText(
        "${" +
          `${emailTemplet.moduleName.toLowerCase()}.` +
          `${emailTemplet.object.toLowerCase()}.` +
          event.target.value +
          "}"
      );
      toast("Text copied to clipboard");
    }
  };

  const handleEvents = () => {
    let keys = [];
    let nestedKey = {
      actions: [],
      comments: [],
      timeline: [],
    };
    const result = moduleEvents.find(
      (ele) => ele.moduleName === emailTemplet.moduleName
    );
    if (result) {
      result.keys.forEach((ele) => {
        if (typeof ele === "object") {
          Object.keys(ele).forEach((childEle) => {
            keys.push(childEle);
          });
          if (Object.keys(ele).includes(emailTemplet.object)) {
            nestedKey[Object.keys(ele)[0]] = Object.values(ele)[0];
          }
        } else {
          keys.push(ele);
        }
      });
      return { events: result.events, objects: keys, nestedKey: nestedKey };
    }
    return [];
  };

  const validate = (key, data) => {
    if (key === "body" && !htmlToNormalTextConverter(data)?.trim().length) {
      toast(`${key.toUpperCase()} can not be empty`);
      return false;
    }
    if (data === undefined || data === null || data === "" || !data?.length) {
      toast(`${key.toUpperCase()} can not be empty`);
      return false;
    }
    return true;
  };

  function goBack() {
    navigate("/customize", { state: { emailTempletTab: 2 } });
  }

  async function templateUpdater(payload) {
    try {
      console.log(payload);
      const response = await updateEmailTemplate(payload);
      if (response.data.status) {
        toast("Template Updated Successfully");
        goBack();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createTemplet = () => {
    let filteredUserData = userData.filter((user) =>
      adminUser.includes(user._id)
    );
    let userEmailArray = filteredUserData.map((user) => user.email);
    let userIDArray = filteredUserData.map((user) => user._id);
    console.log("filteredUserData", filteredUserData, userEmailArray);
    delete emailTemplet.users;
    const validations = Object.keys(emailTemplet);
    let validator;
    if (
      emailTemplet.object === "actions" ||
      emailTemplet.object === "comments"
    ) {
      validator = validations.map((ele) => {
        return validate(ele, emailTemplet[ele]);
      });
    } else {
      validator = validations
        .filter((ele) => ele !== "nestedObject")
        .map((ele) => {
          return validate(ele, emailTemplet[ele]);
        });
    }

    if (emailTemplet.to.includes("${admin}")) {
      if (userEmailArray.length === 0) {
        toast("Admin Users can not be empty");
        return;
      }
    }

    if (!validator.includes(false)) {
      let payload = null;

      if (emailTemplet.to.includes("${admin}")) {
        if (emailTemplet.to.length === 1) {
          payload = {
            ...emailTemplet,
            to: userEmailArray[0],
            CC: [
              ...userEmailArray.slice(1),
              // eslint-disable-next-line no-template-curly-in-string
              ...emailTemplet.to.filter((email) => email !== "${admin}"),
            ],
            BCC: [],
            users: [...userIDArray],
          };
        } else {
          payload = {
            ...emailTemplet,

            to: userEmailArray[0],

            CC: [
              ...userEmailArray.slice(1),

              ...emailTemplet.to.filter((email) => email !== "${admin}"),
            ],
            users: [...userIDArray],
          };
        }
      } else {
        if (emailTemplet.to.length === 1) {
          payload = {
            ...emailTemplet,
            to: emailTemplet.to[0],
            CC: [],
            BCC: [],
            // users: [...userIDArray],
            users: [],
          };
        } else if (emailTemplet.to.includes("${techOwner}")) {
          payload = {
            ...emailTemplet,

            to: "${techOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${techOwner}"),
            ],
            users: [...userIDArray],
          };
        } else if (emailTemplet.to.includes("${salesOwner}")) {
          payload = {
            ...emailTemplet,

            to: "${salesOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${salesOwner}"),
            ],
            users: [...userIDArray],
          };
        } else if (emailTemplet.to.includes("${action holder}")) {
          payload = {
            ...emailTemplet,

            to: "${action holder}",

            CC: [
              ...emailTemplet.to.filter(
                (email) => email !== "${action holder}"
              ),
            ],
            users: [...userIDArray],
          };
        } else if (emailTemplet.to.includes("${watcher}")) {
          payload = {
            ...emailTemplet,

            to: "${watcher}",

            CC: [...emailTemplet.to.filter((email) => email !== "${watcher}")],
            users: [...userIDArray],
          };
        } else if (
          emailTemplet.to.includes("${watcher}") &&
          emailTemplet.to.includes("${action holder}")
        ) {
          payload = {
            ...emailTemplet,

            to: "${action holder}",

            CC: [
              ...emailTemplet.to.filter(
                (email) => email !== "${action holder}"
              ),
            ],
            users: [...userIDArray],
          };
        } else if (
          emailTemplet.to.includes("${watcher}") &&
          emailTemplet.to.includes("${salesOwner}")
        ) {
          payload = {
            ...emailTemplet,

            to: "${salesOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${salesOwner}"),
            ],
            users: [...userIDArray],
          };
        } else if (
          emailTemplet.to.includes("${watcher}") &&
          emailTemplet.to.includes("${techOwner}")
        ) {
          payload = {
            ...emailTemplet,

            to: "${techOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${techOwner}"),
            ],
            users: [...userIDArray],
          };
        } else if (
          emailTemplet.to.includes("${action holder}") &&
          emailTemplet.to.includes("${salesOwner}")
        ) {
          payload = {
            ...emailTemplet,

            to: "${salesOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${salesOwner}"),
            ],
            users: [...userIDArray],
          };
        } else if (
          emailTemplet.to.includes("${action holder}") &&
          emailTemplet.to.includes("${techOwner}")
        ) {
          payload = {
            ...emailTemplet,

            to: "${techOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${techOwner}"),
            ],
            users: [...userIDArray],
          };
        } else if (
          emailTemplet.to.includes("${salesOwner}") &&
          emailTemplet.to.includes("${techOwner}")
        ) {
          payload = {
            ...emailTemplet,
            to: "${techOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${techOwner}"),
            ],
            users: [...userIDArray],
          };
        } else {
          payload = {
            ...emailTemplet,

            to: "${techOwner}",

            CC: [
              ...emailTemplet.to.filter((email) => email !== "${techOwner}"),
            ],
            users: [...userIDArray],
          };
        }
      }

      console.log("payload", payload);
      if (state?.isEdit) {
        templateUpdater(payload);
        return;
      }

      createEmailTemplet(payload)
        .then((res) => {
          if (res.data.status) {
            if (typeof res.data.data === "string") {
              toast.error(res.data.data);
              return;
            }
            toast.success("Template Created Successfully");
            EventBus.dispatch("EditorBus");
            setEmailTemplet((prev) => ({
              ...prev,
              moduleName: "",
              eventName: "",
              to: [],
              subject: "",
              body: "",
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fecthEvents = () => {
    fecthModuleAndEvents()
      .then((res) => {
        if (res.status) {
          setModuleEvents((prev) => (prev = res.data.data));
        }
      })
      .catch((err) => toast(err));
  };

  function convertToTitleCase(str) {
    if (str === "toDO") {
      return str;
    }
    return str.replace(/([A-Z])/g, " $1").trim();
  }

  const handleDrop = (item, name) => {
    let { title = "", label = "", currentModule = "" } = item;
    console.log(title, label, currentModule);
    switch (name) {
      case "subject":
        const textField = document.getElementById("subjectTextField"); // Set the actual ID of your TextField
        const cursorPosition = textField.selectionStart; // Get the cursor position

        if (title && label && currentModule) {
          let newText =
            title?.toLowerCase() === emailTemplet.moduleName?.toLowerCase()
              ? emailTemplet.subject.slice(0, cursorPosition) +
                " ${" +
                title[0].toLowerCase() +
                title.substr(1) +
                "." +
                label +
                "} " +
                emailTemplet.subject.slice(cursorPosition)
              : emailTemplet.subject.slice(0, cursorPosition) +
                " ${" +
                currentModule.toLowerCase() +
                "." +
                title[0].toLowerCase() +
                title.substr(1) +
                "." +
                label +
                "} " +
                emailTemplet.subject.slice(cursorPosition);
          if (
            emailTemplet.subject?.length >= 100 ||
            emailTemplet.subject?.length + newText?.length > 100
          ) {
            toast("Subject can not be more than 100 charcters");
            return;
          }
          setEmailTemplet((prev) => ({
            ...prev,
            subject: newText,
          }));
        }
        break;
      case "body":
        if (title && label && currentModule) {
          let newText = "";

          if (emailTemplet.body.length) {
            newText =
              title?.toLowerCase() === emailTemplet.moduleName?.toLowerCase()
                ? emailTemplet.body
                    ?.trim()
                    .slice(0, emailTemplet.body.length - 4) +
                  " ${" +
                  title[0].toLowerCase() +
                  title.substr(1) +
                  "." +
                  label +
                  "} " +
                  emailTemplet.body?.trim().slice(emailTemplet.body.length - 4)
                : emailTemplet.body
                    ?.trim()
                    .slice(0, emailTemplet.body.length - 4) +
                  " ${" +
                  currentModule.toLowerCase() +
                  "." +
                  title[0].toLowerCase() +
                  title.substr(1) +
                  "." +
                  label +
                  "} " +
                  emailTemplet.body?.trim().slice(emailTemplet.body.length - 4);
            setEmailTemplet((prev) => ({
              ...prev,
              body: newText,
            }));
            return;
          }
          newText =
            title?.toLowerCase() === emailTemplet.moduleName?.toLowerCase()
              ? // eslint-disable-next-line no-useless-concat
                "<p>" + "${" + title + "." + label + "}" + "<p/>"
              : "<p>" +
                "${" +
                currentModule.toLowerCase() +
                "." +
                title[0].toLowerCase() +
                title.substr(1) +
                "." +
                label +
                "}" +
                "<p/>";
          setEmailTemplet((prev) => ({
            ...prev,
            body: newText,
          }));
        }
        break;

      default:
        break;
    }
  };

  const handleChangeAdminUser = (event) => {
    const {
      target: { value },
    } = event;
    setAdminUser(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    fecthEvents();
  }, []);
  React.useEffect(() => {
    getUsersByRoles(6).then((res) => {
      if (res.data.status) {
        const name = res.data.data.map(({ name, ...rest }) => name);
        console.log("role datasds", res.data, name);
        setUserData(res.data.data);
        // setUserName(name);
      }
    });
  }, []);
  console.log(
    "emailTemplet.to",
    // emailTemplet.to,
    // emailTemplet.to[0] === "${admin}",
    // adminUser,
    adminUser,
    userData
  );
  return (
    <Page title="Email Templet">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={5} variant="outlined">
              <CardContent>
                <Grid container spacing={1}>
                  {/* <Grid item xs={isReduce ? 2.4 : 3}> */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Modules</InputLabel>
                      <Select
                        label="Modules"
                        name="moduleName"
                        value={emailTemplet.moduleName}
                        onChange={handleChange}
                      >
                        {moduleEvents.map((ele) => (
                          <MenuItem key={ele} value={ele.moduleName}>
                            {ele.moduleName.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={isReduce ? 2.4 : 3}> */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Event</InputLabel>
                      <Select
                        label="Event"
                        name="eventName"
                        value={emailTemplet.eventName}
                        onChange={handleChange}
                        readOnly={!emailTemplet.moduleName.length}
                      >
                        {emailTemplet.moduleName.length
                          ? handleEvents().events?.map((ele) => (
                              <MenuItem key={ele} value={ele}>
                                {convertToTitleCase(ele).toUpperCase()}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={isReduce ? 2.4 : 3}> */}
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Recipient</InputLabel>
                      <Select
                        multiple
                        label="Recipient"
                        readOnly={!emailTemplet.moduleName.length}
                        name="to"
                        input={<OutlinedInput label="Recipient" />}
                        value={emailTemplet.to || []}
                        renderValue={(selected) =>
                          selected.map((sl) => sl?.toUpperCase()).join(", ")
                        }
                        onChange={(event) => {
                          let { target: { value } = {} } = event;
                          if (!value.includes("${admin}")) {
                            setAdminUser((prev) => (prev = []));
                          }

                          console.log("valuevalue", value);
                          setEmailTemplet((prev) => ({
                            ...prev,
                            to: [...value],
                          }));
                        }}
                      >
                        {emailTemplet.moduleName
                          ? recipient[
                              emailTemplet.moduleName.toLowerCase()
                            ].map((ele, ind) => (
                              // <MenuItem key={ind} value={"${" + ele + "}"}>
                              <MenuItem key={ind} value={"${" + ele + "}"}>
                                <Checkbox
                                  checked={emailTemplet.to.includes(
                                    "${" + ele + "}"
                                  )}
                                />
                                <ListItemText primary={ele?.toUpperCase()} />
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    {
                      // eslint-disable-next-line no-template-curly-in-string
                      emailTemplet.to.includes("${admin}") && (
                        <FormControl fullWidth>
                          <InputLabel>Admin User</InputLabel>
                          <Select
                            label="Admin User"
                            id="demo-multiple-checkbox"
                            multiple
                            value={adminUser}
                            onChange={handleChangeAdminUser}
                            input={<OutlinedInput label="Admin User" />}
                            renderValue={(selected) => {
                              return userData
                                .filter(({ _id }) => {
                                  return selected.includes(_id);
                                })
                                .map(({ name }) => name)
                                .join(", ");
                            }}
                            // MenuProps={MenuProps}
                          >
                            {userData.map(({ _id, name }, index) => (
                              <MenuItem key={index} value={_id}>
                                <Checkbox
                                  checked={adminUser.indexOf(_id) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )
                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={5} variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      {objects
                        .filter((obj) =>
                          obj.accessTo.includes(
                            emailTemplet.moduleName?.toLowerCase()
                          )
                        )
                        .map((obj, index) => (
                          <Grid item xs={12}>
                            <ObjectViewAccordion
                              object={obj}
                              key={index}
                              setEmailTemplet={setEmailTemplet}
                              currentModule={emailTemplet.moduleName}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <DropOnTextField
                          fullWidth
                          id="subjectTextField"
                          label="Subject"
                          placeholder="Subject"
                          name="subject"
                          value={emailTemplet.subject}
                          handleDrop={handleDrop}
                          onChange={(e) => {
                            let {
                              target: { name, value },
                            } = e;
                            if (value && !value?.trim()) {
                              toast("Subject can not contain only spaces");
                              return;
                            }
                            if (value && value?.length > 100) {
                              toast(
                                "Subjact can not be more than 100 characters"
                              );
                              return;
                            }
                            setEmailTemplet((prev) => ({
                              ...prev,
                              [name]: value,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DropOnEditor
                          Container={
                            <Editor
                              id="bodyEditor"
                              key="00999911"
                              style={{
                                width: "100%",
                                fontSize: "24px",
                                border: "1px solid",
                                borderRadius: 10,
                                whiteSpace: "nowrap",
                              }}
                              name="body"
                              value={emailTemplet.body}
                              callback={(det) => {
                                setEmailTemplet((prev) => ({
                                  ...prev,
                                  body: det,
                                }));
                              }}
                              onChange={handleChange}
                            />
                          }
                          id="bodyEditor"
                          handleDrop={handleDrop}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "right",
                        }}
                      >
                        {state?.isEdit ? (
                          <Stack
                            columnGap={2}
                            direction="row"
                            flexDirection="row-reverse"
                          >
                            <Button variant="contained" onClick={createTemplet}>
                              SAVE
                            </Button>
                            <Button variant="contained" onClick={goBack}>
                              CANCEL
                            </Button>
                          </Stack>
                        ) : (
                          <Button variant="contained" onClick={createTemplet}>
                            CREATE
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default CreateEmailTemplate;
