import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CountAndCountByShowingTable({
  tableHeaders = [],
  tableData = [],
  numberOfTopCategoryToShow = 0,
  setNumberOfTopCategoryToShow = () => {},
}) {
  const [page, setPage] = React.useState(0);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel id={`${tableHeaders[0]?.toLowerCase()}-select-label`}>
            {`Number of top ${tableHeaders[0]?.toLowerCase()} to display`}
          </InputLabel>
          <Select
            labelId={`${tableHeaders[0]?.toLowerCase()}-select-label`}
            value={numberOfTopCategoryToShow}
            onChange={(e) => {
              setPage(0);
              setNumberOfTopCategoryToShow(e.target.value);
            }}
            label={`Number of top ${tableHeaders[0]?.toLowerCase()} to display`}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableHeaders?.map((header) => (
                  <StyledTableCell>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                ?.slice(page * 5, page * 5 + 5)
                ?.map(({ category, count }) => (
                  <StyledTableRow key={category || 0}>
                    <StyledTableCell component="th" scope="row">
                      {category || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {count || "N/A"}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            disabled={!!numberOfTopCategoryToShow}
            page={page}
            count={tableData.length}
            rowsPerPage={[5]}
            rowsPerPageOptions={[5, 10]}
            onPageChange={(_, newPage) => setPage(newPage)}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default CountAndCountByShowingTable;
