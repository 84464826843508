import React, { useState } from "react";
import "./Lead.row.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import DOMPurify from "dompurify";
const parse = require("html-react-parser");
const { convert } = require("html-to-text");

const LeadRow = ({ contactInfoObj, leadDynamicTitle }) => {
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });
  //   const data = [{ skills: "skills", description: "desc", summary: "summary" }];

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const handleChange = (id) => (event, isExpanded) => {
    // console.log("isexpanded", isExpanded);
    setOpen(isExpanded);
    setExpanded(isExpanded ? id : false);
  };
  const navigate = useNavigate();

  const shrinkText = (text, count, insertDots) => {
    return (
      text.slice(0, count) + (text.length > count && insertDots ? "..." : "")
    );
  };

  const HTMLPartToTextPart = (HTMLPart) =>
    HTMLPart.replace(/\n/gi, "")
      .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
      .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
      .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
      .replace(/<\/\s*(?:p|div)>/gi, "\n")
      .replace(/<br[^>]*\/?>/gi, "\n")
      .replace(/<[^>]*>/gi, "")
      .replace("&nbsp;", " ")
      .replace(/[^\S\r\n][^\S\r\n]+/gi, " ");

  console.log("Contactobj", contactInfoObj);
  let obj = contactInfoObj.leads || [];
  console.log("obj contact view", obj);
  if (obj.length > 0) {
    return (
      <Grid container>
        {obj.map((x, index) => (
          <Grid key={index} item xs={12} sm={12}>
            {console.log("X", x)}
            <Accordion
              expanded={expanded === x._id}
              onChange={handleChange(x._id)}
              elevation={3}
              variant="outlined"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ width: "83%", flexShrink: 0 }}>
                  Summary: {x.summary}
                </Typography>
                <Typography>
                  {expanded === x._id ? null : `Status: ${x.status}`}
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <Typography>Skills:</Typography>
                <Typography>
                  {x.skills
                    .map((skill) => {
                      return skill.name;
                    })
                    .join(",")}
                </Typography>
                <br />
                <Typography>Status:</Typography>
                <Typography>{x.status}</Typography>
                <br />

                <Typography>Description: </Typography>
                <Typography>
                  {/* {shrinkText(HTMLPartToTextPart(x.description), 206, true)} */}

                  <div
                    className="text"
                    dangerouslySetInnerHTML={sanitizedData(x.description)}
                  />
                </Typography>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <Button
                    onClick={() => {
                      // navigate(`/contacts/lead/${contactInfoObj._id}/${x._id}`);
                      navigate(`/Leads/${x._id}/${contactInfoObj._id}`);
                    }}
                  >
                    Show More
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <span display="inline-block">
            {`No ${leadDynamicTitle} listed. Add leads to list here.`}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography></Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default LeadRow;
