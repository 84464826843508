import React, { useMemo } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InvoiceActionContextMenu from "./InvoiceActionContextMenu";
import { months } from "../../../constants";
import { useState } from "react";

function InvoiceManageTable({
  invoiceDetails,
  //Menu Actions
  payInvoiceAPI,
  cancelInvoiceAPI,
  showLogs,
  regenerateInvoiceAPI,
  leadId,
  getInvoiceDetails,
  gstValues,
  costInforMations,
  costAssignmentState,
  parentCompanyDetails: clientCompany,
  setLoaderDialog,
}) {
  const renderData = useMemo(() => {
    if (invoiceDetails && invoiceDetails.length) {
      const data = invoiceDetails.reduce((accumulator, invoice) => {
        accumulator.push({
          _id: invoice._id,
          invoiceNumber: invoice.invoiceNumber,
          pdfUrl: invoice.pdfUrl,
          date: new Date(invoice.invoiceDate).toDateString(),
          // monthYear: `${new Date(invoice.invoiceDate).getFullYear()} ${
          //   months[new Date(invoice.invoiceDate).getMonth()]
          // }`,
          monthYear: invoice.monthOfInvoice,
          amount: invoice.amountTotal,
          activeStatus: invoice.activeStatus,
          paid: invoice.paid,
          currency: invoice.costs[0].currency,
          dueAmount: invoice.dueAmount,
          isCancelled: invoice.isCancelled,
        });
        return accumulator;
      }, []);

      return data;
    }
    return [];
  }, [invoiceDetails]);

  const [handlePagination, setHandlePagination] = useState({
    rowsPerPage: 5,
    page: 0,
    handleChangePage: (event, newPage) => {
      setHandlePagination((prev) => ({
        ...prev,
        page: +newPage,
        rowsPerPage: 5,
      }));
    },
    handleChangeRowsPerPage: (event) => {
      setHandlePagination((prev) => ({
        ...prev,
        page: 0,
        rowsPerPage: +event.target.value,
      }));
    },
  });

  // const totalCalculations = invoiceDetails.reduce(
  //   (accumulator, report) => {
  //     if (report) {
  //       let { amountTotal, paid, dueAmount, activeStatus, isCancelled } =
  //         report;

  //       // if (activeStatus === false && paid === 0) {
  //       if (isCancelled) {
  //         accumulator.chargableTotal += 0;
  //         accumulator.collectedAmount += paid;
  //         accumulator.pendingAmount += 0;
  //         return accumulator;
  //       } else {
  //         accumulator.chargableTotal += amountTotal;
  //         accumulator.collectedAmount += paid;
  //         accumulator.pendingAmount += dueAmount;
  //         return accumulator;
  //       }
  //     }
  //   },
  //   {
  //     chargableTotal: 0,
  //     collectedAmount: 0,
  //     pendingAmount: 0,
  //   }
  // );
  const totalCalculations = invoiceDetails.reduce(
    (accumulator, report) => {
      if (report && !report.isCancelled) {
        let { amountTotal, paid, dueAmount } = report;

        accumulator.chargableTotal += amountTotal;
        accumulator.collectedAmount += paid;
        accumulator.pendingAmount += dueAmount;
      }
      console.log("costState", costAssignmentState);
      console.log("costState", invoiceDetails);

      return accumulator;
    },
    {
      chargableTotal: 0,
      collectedAmount: 0,
      pendingAmount: 0,
    }
  );
  const formatDate = (dateString) => {
    const inputDate = new Date(dateString);
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return inputDate.toLocaleDateString("en-US", options);
  };
  console.log("amount", costAssignmentState, costAssignmentState.totalCOst);
  let amount = parseInt(costAssignmentState.totalCost);
  function calculateTax(amount, percentage) {
    console.log("amount", amount, percentage, (amount * percentage) / 100);
    return (amount * percentage) / 100;
  }
  function calculateGST(amount) {
    const igstAmount = calculateTax(amount, provideGstPercentage().IGST);
    const cgstAmount = calculateTax(amount, provideGstPercentage().CGST);
    const sgstAmount = calculateTax(amount, provideGstPercentage().SGST);

    return {
      igstAmount: igstAmount.toFixed(2),
      cgstAmount: cgstAmount.toFixed(2),
      sgstAmount: sgstAmount.toFixed(2),
    };
  }
  const { igstAmount, cgstAmount, sgstAmount } = calculateGST(amount);
  // console.log("amount", costAssignmentState.totalCost);
  // console.log("amount", igstAmount);
  // console.log("amount", cgstAmount);
  // console.log("amount", sgstAmount);
  const totalAmountWithGST =
    amount +
    parseFloat(igstAmount) +
    parseFloat(cgstAmount) +
    parseFloat(sgstAmount);
  console.log("amount", totalAmountWithGST);
  function provideGstPercentage() {
    if (
      clientCompany?.companyCountry?.toLowerCase() === "india" &&
      costAssignmentState?.homeCompanyCountry?.toLowerCase() === "india"
    ) {
      if (clientCompany?.companyState === costInforMations?.homeCompanyState) {
        return {
          IGST: 0,
          CGST: gstValues?.cgst,
          SGST: gstValues.sgst,
        };
      }
      if (clientCompany?.companyState !== costInforMations?.homeCompanyState) {
        return {
          IGST: gstValues?.igst,
          CGST: 0,
          SGST: 0,
        };
      }
    }
    return {
      IGST: 0,
      CGST: 0,
      SGST: 0,
    };
  }
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Month - Year</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Pending Amount</TableCell>
              <TableCell>Collected Amount</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Log</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData
              .slice(
                handlePagination.page * handlePagination.rowsPerPage,
                handlePagination.page * handlePagination.rowsPerPage +
                  handlePagination.rowsPerPage
              )
              .map((details, index) => {
                return (
                  <TableRow key={index}>
                    {console.log("kjkj", details)}
                    <TableCell>{details.invoiceNumber}</TableCell>
                    <TableCell>{formatDate(details.date)}</TableCell>
                    <TableCell>{details.monthYear}</TableCell>
                    <TableCell>{details.amount}</TableCell>
                    <TableCell>{details.dueAmount}</TableCell>
                    <TableCell>{details.paid}</TableCell>
                    <TableCell>
                      <InvoiceActionContextMenu
                        key={details._id}
                        invoiceIndex={index}
                        invoiceId={details._id}
                        downloadUrl={details.pdfUrl}
                        payInvoiceAPI={payInvoiceAPI}
                        cancelInvoice={cancelInvoiceAPI}
                        regenerateInvoiceAPI={regenerateInvoiceAPI}
                        activeStatus={details.activeStatus}
                        isCancelled={details.isCancelled}
                        leadId={leadId}
                        invoiceNumber={details.invoiceNumber}
                        amount={details.amount}
                        date={details.date}
                        paid={details.paid}
                        currency={details.currency}
                        getInvoiceDetails={getInvoiceDetails}
                        dueAmount={details.dueAmount}
                        setLoaderDialog={setLoaderDialog}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          showLogs(details._id);
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={renderData.length}
        rowsPerPage={handlePagination.rowsPerPage}
        page={handlePagination.page}
        onPageChange={handlePagination.handleChangePage}
        onRowsPerPageChange={handlePagination.handleChangeRowsPerPage}
      />
      <Grid item xs={12} sm={12}>
        <Card elevation={3} variant="outlined">
          <CardContent>
            {costAssignmentState.mode === "fixed" ? (
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Stack direction="row" spacing={0.5}>
                    <Typography width="40%" color="#2065d1">
                      Total Amount :
                    </Typography>
                    <Typography width="60%" fontWeight={550}>
                      {isNaN(amount?.toFixed(2)) ? 0 : amount?.toFixed(2)}
                    </Typography>
                  </Stack>
                  {costInforMations.gstApplicable && (
                    <Typography variant="caption" sx={{ fontSize: 13.5 }}>
                      (GST Excl.){" "}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={costAssignmentState.mode === "fixed" ? 3 : 6}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ justifyContent: "center" }}
                  >
                    <Typography
                      width={
                        costAssignmentState.mode === "fixed" ? "51%" : "25%"
                      }
                      color="#2065d1"
                    >
                      Collected Amount :
                    </Typography>
                    <Typography
                      width={
                        costAssignmentState.mode === "fixed" ? "49%" : "5%"
                      }
                      fontWeight={550}
                      color="green"
                    >
                      {totalCalculations.collectedAmount.toFixed(2)}
                    </Typography>
                  </Stack>
                  {costInforMations.gstApplicable && (
                    <Typography variant="caption" sx={{ fontSize: 13.5 }}>
                      (GST Incl.){" "}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={costAssignmentState.mode === "fixed" ? 3 : 6}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ justifyContent: "center" }}
                  >
                    <Typography
                      width={
                        costAssignmentState.mode === "fixed" ? "48%" : "25%"
                      }
                      color="#2065d1"
                    >
                      Pending Amount :
                    </Typography>
                    <Typography
                      width={
                        costAssignmentState.mode === "fixed" ? "52%" : "5%"
                      }
                      fontWeight={550}
                      color="red"
                    >
                      {costAssignmentState.mode === "fixed"
                        ? isNaN(
                            totalCalculations.pendingAmount -
                              (invoiceDetails[0].paid === 0 &&
                              !invoiceDetails[0].isCancelled
                                ? invoiceDetails[0].dueAmount
                                : 0)
                          )
                          ? 0
                          : (
                              totalCalculations.pendingAmount -
                              (invoiceDetails[0].paid === 0 &&
                              !invoiceDetails[0].isCancelled
                                ? invoiceDetails[0].dueAmount
                                : 0)
                            ).toFixed(2)
                        : isNaN(totalCalculations.pendingAmount?.toFixed(2))
                        ? 0
                        : totalCalculations.pendingAmount?.toFixed(2)}
                    </Typography>
                  </Stack>
                  {costInforMations.gstApplicable && (
                    <Typography variant="caption" sx={{ fontSize: 13.5 }}>
                      (GST Incl.){" "}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="row" spacing={0.5}>
                    <Typography width="78%" color="#2065d1">
                      Pending Collection Amount :
                    </Typography>
                    <Typography width="22%" fontWeight={550} color="#2065d1">
                      {invoiceDetails[0].paid === 0 &&
                      invoiceDetails[0].isCancelled === false
                        ? invoiceDetails[0].dueAmount.toFixed(2)
                        : (0).toFixed(2)}
                    </Typography>
                  </Stack>
                  {costInforMations.gstApplicable && (
                    <Typography variant="caption" sx={{ fontSize: 13.5 }}>
                      (GST Incl.){" "}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={1}></Grid>
                <Grid
                  item
                  xs={5}
                  // sx={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Stack
                    direction="column"
                    spacing={0.5}
                    // sx={{ justifyContent: "center" }}
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ justifyContent: "center" }}
                    >
                      <Typography color="#2065d1">
                        Collected Amount :
                      </Typography>
                      <Typography fontWeight={550} color="green">
                        {totalCalculations.collectedAmount.toFixed(2)}
                      </Typography>
                    </Stack>
                    {costInforMations.gstApplicable && (
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ fontSize: 13.5, textAlign: "center" }}
                        >
                          (GST Incl.){" "}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={5}>
                  <Stack
                    direction="column"
                    spacing={0.5}
                    // sx={{ justifyContent: "center" }}
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ justifyContent: "center" }}
                    >
                      <Typography color="#2065d1">Pending Amount :</Typography>
                      <Typography fontWeight={550} color="red">
                        {costAssignmentState.mode === "fixed"
                          ? isNaN(
                              totalCalculations.pendingAmount -
                                (invoiceDetails[0].paid === 0 &&
                                !invoiceDetails[0].isCancelled
                                  ? invoiceDetails[0].dueAmount
                                  : 0)
                            )
                            ? 0
                            : (
                                totalCalculations.pendingAmount -
                                (invoiceDetails[0].paid === 0 &&
                                !invoiceDetails[0].isCancelled
                                  ? invoiceDetails[0].dueAmount
                                  : 0)
                              ).toFixed(2)
                          : isNaN(totalCalculations.pendingAmount?.toFixed(2))
                          ? 0
                          : totalCalculations.pendingAmount?.toFixed(2)}
                      </Typography>
                    </Stack>
                    {costInforMations.gstApplicable && (
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{ justifyContent: "center" }}
                      >
                        {" "}
                        <Typography variant="caption" sx={{ fontSize: 13.5 }}>
                          (GST Incl.){" "}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default InvoiceManageTable;
