import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FileItem from "../FileItem/FileItem";
var regex = /^[a-zA-Z ]*$/;
const FileList = ({
  files,
  setFiles,
  removeFile,
  checked,
  setChecked,
  fromEmailDialog,
  descriptionObj,
  setDescriptionObj,
  fromLeadViewPage,
  fromContactViewPage,
  contact,
  lead,
  currentUser,
  project,
  fromProjectViewPage,
  fromCompanyPage,
  fromPriceNegotiationCreateTime,
  openProjectStatusDialouge,
  signedContractFiles,
  setSignedContractFiles,
  isFromComment,
  fromContactListPage,
}) => {
  console.log(isFromComment, "isFromm");
  // for (let index = 0; index < files.length; index++) {
  //   files.push({ checked: false })
  // }

  // let tempArray = [...files];

  // tempArray.map((file, index) => {
  //   return (
  //     file.checked = false
  //   )
  // })
  // setFiles(tempArray);

  const handleFileDescription = (e, i) => {
    const value = regex.test(e.target.value);
    if (e.target.value.length > 14) {
      toast.warning("Descritpion should not be more than 14 characters.");
      return;
    } else if (!value) {
      toast.error("Numbers and special characters are not allowed");
      return;
    } else {
      setDescriptionObj((prev) => {
        const temp = { ...prev };
        temp[i] = e.target.value;
        return temp;
      });
    }
  };

  const handleCheckBoxChange = (event, index) => {
    console.log("Hanlde checkbox change");
    let temp = [...files];
    temp[index].checked = event.target.checked;
    setFiles(temp);
  };

  return (
    <>
      {openProjectStatusDialouge ? (
        <ul className="file-list">
          <div style={{ marginTop: "10px" }}>
            <Grid container spacing={1}>
              {signedContractFiles &&
                signedContractFiles?.map((f, i) => (
                  <>
                    <Grid item xs={12} sm={12}>
                      <FileItem
                        file={f}
                        fromEmailDialog={fromEmailDialog}
                        fromPriceNegotiationCreateTime={
                          fromPriceNegotiationCreateTime
                        }
                        deleteFile={() => {
                          removeFile(i);
                        }}
                        openProjectStatusDialouge={openProjectStatusDialouge}
                        setSignedContractFiles={setSignedContractFiles}
                        isFromComment={isFromComment}
                      />
                    </Grid>
                  </>
                ))}
            </Grid>
          </div>
        </ul>
      ) : (
        <ul
          className="file-list"
          style={{ padding: fromContactListPage ? 0 : null }}
        >
          <div style={{ marginTop: "10px" }}>
            <Grid container spacing={1}>
              {files &&
                files?.map((f, i) => (
                  <>
                    {fromEmailDialog || fromPriceNegotiationCreateTime ? (
                      <>
                        <Grid item xs={6} sm={6}>
                          <FileItem
                            file={f}
                            fromEmailDialog={fromEmailDialog}
                            fromPriceNegotiationCreateTime={
                              fromPriceNegotiationCreateTime
                            }
                            deleteFile={() => {
                              removeFile(i);
                            }}
                            isFromComment={isFromComment}
                          />
                        </Grid>
                      </>
                    ) : fromContactListPage ? (
                      <Grid item xs={6} sm={6}>
                        <FileItem
                          index={i}
                          file={f}
                          deleteFile={() => {
                            removeFile(i);
                          }}
                          fromContactListPage={fromContactListPage}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={9} sm={9}>
                          <FileItem
                            index={i}
                            file={f}
                            descriptionObj={descriptionObj}
                            handleFileDescription={(e) => {
                              handleFileDescription(e, i);
                            }}
                            deleteFile={() => {
                              removeFile(i);
                            }}
                            isFromComment={isFromComment}
                          />
                        </Grid>
                        <Grid item xs={0.5} sm={0.5}></Grid>
                        <Grid
                          item
                          xs={2.5}
                          sm={2.5}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {fromLeadViewPage ? (
                            currentUser.role === "ADMIN" ||
                            currentUser._id === lead.salesOwner ? (
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={files[i].checked}
                                      onChange={(e) => {
                                        handleCheckBoxChange(e, i);
                                      }}
                                    />
                                  }
                                  label="Private"
                                />
                              </FormGroup>
                            ) : null
                          ) : null}
                          {fromProjectViewPage ? (
                            currentUser.role === "ADMIN" ||
                            currentUser._id === project.techOwner ||
                            currentUser.role === "DELIVERY_LEAD" ? (
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={files[i].checked}
                                      onChange={(e) => {
                                        handleCheckBoxChange(e, i);
                                      }}
                                    />
                                  }
                                  label="Private"
                                />
                              </FormGroup>
                            ) : null
                          ) : null}
                          {fromContactViewPage ? (
                            currentUser.role === "ADMIN" ||
                            currentUser._id === contact.owner ||
                            currentUser.role === "DELIVERY_LEAD" ? (
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={files[i].checked}
                                      onChange={(e) => {
                                        handleCheckBoxChange(e, i);
                                      }}
                                    />
                                  }
                                  label="Private"
                                />
                              </FormGroup>
                            ) : null
                          ) : null}

                          {fromCompanyPage ? (
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={files[i].checked}
                                    onChange={(e) => {
                                      handleCheckBoxChange(e, i);
                                    }}
                                  />
                                }
                                label="Private"
                              />
                            </FormGroup>
                          ) : null}
                        </Grid>
                      </>
                    )}
                  </>
                ))}
            </Grid>
          </div>
        </ul>
      )}
    </>
  );
};

export default FileList;
