import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth.context";
import { getCallHippoCredential } from "../../services/callHippoCredentials.service";

function CallDialer() {
  const { currentUser, hideCallDialer } = useContext(AuthContext);
  const initialCredentials = {
    apiToken: "",
    emailId: "",
  };
  const [credentialDetails, setCredentialDetails] =
    useState(initialCredentials);
  const fetchCredentials = async () => {
    try {
      const response = await getCallHippoCredential();
      if (response.data.status) {
        const { apiToken, emailId, _id } = response.data.data;
        console.log(response.data, "data");
        setCredentialDetails((prev) => ({
          apiToken,
          emailId,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const InitializeCallHippo = () => {
    // useEffect(() => {
    const chWidgetDiv = document.createElement("div");
    chWidgetDiv.id = "ch-dialer-container";

    if (hideCallDialer) {
      chWidgetDiv.style.display = "none";
    }

    document.body.appendChild(chWidgetDiv);

    window.TOKEN = credentialDetails.apiToken;
    window.EMAIL = credentialDetails.emailId;
    console.log("windowToken", window.TOKEN, window.EMAIL);
    window.REGION = "global";

    const callhippoScript = document.createElement("script");
    callhippoScript.type = "text/javascript";
    callhippoScript.async = true;
    callhippoScript.src =
      "https://d1x9dsge91xf6g.cloudfront.net/callhippo/files/ch-dialer.js";

    document.body.appendChild(callhippoScript);

    return () => {
      document.body.removeChild(chWidgetDiv);
      document.body.removeChild(callhippoScript);
    };
    // }, [hideCallDialer, credentialDetails.apiToken, credentialDetails.emailId]);

    return null;
  };
  useEffect(() => {
    if (currentUser && currentUser?.role !== "SUPER_ADMIN") {
      fetchCredentials();
    }
  }, [currentUser]);

  // useEffect(() => {
  //   const cleanup = InitializeCallHippo();
  //   return cleanup;
  // }, []);

  return <div id="root1">{InitializeCallHippo()}</div>;
}

export default CallDialer;
