import { Container, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import MultiActionAreaCard from "../../components/Cards/MultiActionAreaCard";
import Page from "../../components/UI/Page";
import { useEffect } from "react";
import { getAllScanVisitingCard } from "../../services/contacts.service";
import { AuthContext } from "../../context/auth.context";

export default function ScanVisitingCard() {
  const { currentUser } = useContext(AuthContext);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [visistingCards, setVisistingCards] = useState([]);
  const handleCardSelect = (index) => {
    setSelectedCardIndex(index);
  };

  const getAllScanVisitingCards = async () => {
    try {
      const response = await getAllScanVisitingCard(currentUser._id);
      if (response.data.status) {
        setVisistingCards(response.data.data.urlList);
        console.log(response.data.data.urlList, "7878jujh");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllScanVisitingCards();
  }, []);
  console.log(visistingCards, "7878jujh1");

  return (
    <>
      <Page title="Visisting Card">
        <Container>
          <Grid container spacing={1}>
            {visistingCards.map((ele, index) => (
              <Grid item xs={3}>
                <MultiActionAreaCard
                  index={index}
                  isSelected={selectedCardIndex === index}
                  onCardSelect={handleCardSelect}
                  Image={ele}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Page>
    </>
  );
}
