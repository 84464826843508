import * as apis from "./api.requests";

const path = "/sadmin/v1";

async function getSuperAdminDetailsDetailsById(_id, isTenant = true) {
  try {
    return await apis.hitGetApi(`${path}/tenant/${_id}?isTenant=${isTenant}`);
  } catch (error) {
    throw new Error(
      "Something went wrong for fetching The Super Admin Details"
    );
  }
}

async function getTenants(isTenant = true) {
  return await apis.hitGetApi(`${path}/tenants?isTenant=${isTenant}`);
}

async function addTenant(tenantData, isTenant = true) {
  return await apis.hitPostApi(
    `${path}/addTenant?isTenant=${isTenant}`,
    tenantData
  );
}

async function changeStatusBySuperAdmin(__tenantId, data, isTenant = true) {
  return await apis.hitPutApi(
    `${path}/tenant/${__tenantId}/status?isTenant=${isTenant}`,
    data
  );
}

async function updateTenant(__tenantId, tenantData, isTenant = true) {
  return await apis.hitPutApi(
    `${path}/tenant/${__tenantId}?isTenant=${isTenant}`,
    tenantData
  );
}

async function searchTenants(key, value, isTenant = true) {
  return await apis.hitGetApi(
    `${path}/search/tenant?searchKey=${key}&searchValue=${value}&isTenant=${isTenant}`
  );
}

async function directChangePasswordForSuperAdmin(body, isTenant = true) {
  return await apis.hitPutApi(
    `/sadmin/v1/directChangePassword?isTenant=${isTenant}`,
    body
  );
}

export {
  getSuperAdminDetailsDetailsById,
  getTenants,
  addTenant,
  changeStatusBySuperAdmin,
  updateTenant,
  searchTenants,
  directChangePasswordForSuperAdmin,
};
