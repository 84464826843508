import React from "react";
import { useDrag } from "react-dnd";
import { Chip } from "@mui/material";

const DraggableCopyableElement = ({ label, title, currentModule }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "copyableElement",
    item: { label, title, currentModule },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Chip
      ref={drag}
      sx={{ m: "0 4px 6px", cursor: "move", opacity: isDragging ? 0.5 : 1 }}
      label={label}
    />
  );
};

export default DraggableCopyableElement;
