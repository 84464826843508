import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import Editor from "../../components/texteditor/Editor";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import {
  createProposal,
  getAllProposal,
  updateProposalbyId,
  addProposalTemplatebyId,
  updateProposalTemplatebyId,
  removeProposalTemplatebyId,
} from "../../services/proposal.service";
import ReactQuill from "react-quill";
import EventBus from "../../components/texteditor/EventBus";
export default function ProposalTemplate() {
  const [isOutlineOpen, setIsOutlineOpen] = useState(false);
  const [isTemplateEdit, setIsTemplateEdit] = useState(false);
  const [isTemplateDelete, setIsTemplateDelete] = useState(false);
  const [isAddTemplate, setIsAddTemplate] = useState(false);
  const [templateIndex, settemplateIndex] = useState(0);
  const [isLoaderEnable, setIsLoaderEnable] = useState(false);

  const [Expanded, setExpanded] = useState(false);
  const initialProposalState = {
    outlineName: "",
    templateName: "",
    description: "",
  };

  const [proposalDetails, setProposalDetails] = useState(initialProposalState);
  const [proposalId, setProposalId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [outline, setOutline] = useState({
    outlineName: "",
  });
  const [template, setTemplate] = useState({
    templateName: "",
    description: "",
  });

  const [allProposalDetails, setAllProposalDetails] = useState([]);

  const [isDialougeOpen, setIsDialougeOpen] = useState(false);

  const fetchProposalDetails = async () => {
    setIsLoaderEnable(true);
    try {
      const response = await getAllProposal();
      if (response.data.status) {
        console.log(response.data, "data");
        setIsLoaderEnable(false);
        setAllProposalDetails([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProposalDetails();
  }, []);

  const sanitizedData = (result) => ({
    __html: DOMPurify.sanitize(result ? result : null),
  });

  const outlineHandel = (proposalId) => {
    setProposalId(proposalId);
    allProposalDetails.find((elem) => {
      if (elem._id === proposalId) {
        setOutline((prev) => ({
          outlineName: elem.outlineName,
        }));
      }
    });
    setIsOutlineOpen((prev) => !prev);
  };
  const handelDialouge = () => {
    setIsDialougeOpen((prev) => !prev);
    setProposalDetails(initialProposalState);
    EventBus.dispatch("EditorBus");
  };
  const handelEditTemplateDialouge = () => {
    setIsTemplateEdit((prev) => !prev);
    setTemplate((prev) => ({
      templateName: "",
      description: "",
    }));
    EventBus.dispatch("EditorBus");
  };
  const handelEditTemplate = (templateId) => {
    setIsTemplateEdit((prev) => !prev);
    console.log(templateId, "proposa");
    setTemplateId(templateId);
    const proposalId = allProposalDetails[templateIndex]._id;
    setProposalId(proposalId);

    allProposalDetails[templateIndex].templates.find((elem) => {
      if (elem._id === templateId) {
        console.log(elem, "propo");
        setTemplate((prev) => ({
          templateName: elem.templateName,
          description: elem.description,
        }));
      }
    });
  };
  const handelDeleteDialouge = (templateId) => {
    const proposalId = allProposalDetails[templateIndex]._id;
    setProposalId(proposalId);
    setTemplateId(templateId);
    setIsTemplateDelete((prev) => !prev);
  };
  const handelAddTemplateDialouge = () => {
    const proposalId = allProposalDetails[templateIndex]._id;
    console.log(proposalId, "pro");
    setProposalId(proposalId);
    setIsAddTemplate((prev) => !prev);
    setTemplate((prev) => ({
      templateName: "",
      description: "",
    }));
    EventBus.dispatch("EditorBus");
  };
  const handleAccordionChange = (id) => (event, isExpanded) => {
    // console.log("isexpanded", isExpanded);
    // setOpen(isExpanded);
    setExpanded(isExpanded ? id : false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value && !value?.trim()) {
      toast.warn("Outline & Template name can not contain only space");
      return;
    }

    if (name === "outlineName" && value.length > 30) {
      toast.warn("Outline name should not cross 30 characters");
      return;
    } else if (name === "templateName" && value.length > 35) {
      toast.warn("Template name should not cross 35 characters");
      return;
    }
    setProposalDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleOutlineChange = (e) => {
    const { name, value } = e.target;
    if (value && !value?.trim()) {
      toast.warn("Outline name can not contain only space");
      return;
    }

    if (name === "outlineName" && value.length > 30) {
      toast.warn("Outline name should not cross 30 characters");
      return;
    }

    setOutline((prev) => ({
      [name]: value,
    }));
  };
  const handleAddTemplateChange = (e) => {
    const { name, value } = e.target;
    if (value && !value?.trim()) {
      toast.warn("Template name can not contain only space");
      return;
    }

    if (name === "templateName" && value.length > 35) {
      toast.warn("Template name should not cross 35 characters");
      return;
    }
    setTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // checkPureData

  const checkPureData = (data) => {
    if (
      data !== undefined &&
      data !== null &&
      typeof data === "string" &&
      data.trim() !== ""
    ) {
      return true;
    }

    return false;
  };
  const htmlString = (txt) => {
    const htmlString = txt;

    const parser = new DOMParser();

    const dom = parser.parseFromString(htmlString, "text/html");

    const plainText = dom.body.textContent;

    return plainText;
  };

  const handelCreate = async () => {
    const templateArr = [];

    Object.keys(proposalDetails).map((ele, ind) => {
      if (!checkPureData(proposalDetails[ele])) {
        if (ele === "outlineName") {
          toast(`Outline Name  can not be empty`);
        } else if (ele === "templateName") {
          toast(` Template Name can not be empty`);
        }
      }
    });

    if (!htmlString(proposalDetails.description)?.trim()) {
      toast("Description can not be empty");
      return;
    }

    const temp = { ...proposalDetails };
    const { outlineName, description, templateName } = temp;
    templateArr.push({ templateName, description });
    const data = {
      outlineName,
      templates: templateArr,
    };

    try {
      console.log(data, "data");
      const response = await createProposal(data);
      if (response.data.status) {
        fetchProposalDetails();
        toast("Proposal Create Successfully");
        setProposalDetails(initialProposalState);
        setIsDialougeOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelEditProposalTemplate = async () => {
    try {
      Object.keys(template).map((ele, ind) => {
        if (!checkPureData(template[ele])) {
          if (ele === "templateName") {
            toast(` Template Name can not be empty`);
          }
        }
      });

      if (!htmlString(template.description)?.trim()) {
        toast("Description can not be empty");
        return;
      }

      const response = await updateProposalTemplatebyId(
        proposalId,
        templateId,
        template
      );
      if (response.data.status) {
        fetchProposalDetails();
        toast("Template Updated Successfully");
        handelEditTemplateDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handelAddProposalTemplate = async () => {
    try {
      Object.keys(template).map((ele, ind) => {
        if (!checkPureData(template[ele])) {
          if (ele === "templateName") {
            toast(` Template Name can not be empty`);
          }
        }
      });

      if (!htmlString(template.description)?.trim()) {
        toast("Description can not be empty");
        return;
      }

      const response = await addProposalTemplatebyId(proposalId, template);
      if (response.data.status) {
        fetchProposalDetails();
        toast("Template Added Successfully");
        handelAddTemplateDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeProposalTemplate = async () => {
    try {
      const response = await removeProposalTemplatebyId(proposalId, templateId);
      if (response.data.status) {
        fetchProposalDetails();
        toast("Template Removed Successfully");
        handelDeleteDialouge();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditOutline = async () => {
    console.log("proposal");
    try {
      if (outline.outlineName === "") {
        toast("Outline Name can not be empty");
        return;
      }

      const response = await updateProposalbyId(proposalId, outline);
      if (response.data.status) {
        fetchProposalDetails();
        toast("Outline Updated Successfully");
        setProposalDetails({ outlineName: "" });
        setIsOutlineOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(allProposalDetails, proposalId, template, "proposalDet");

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography component="div"> Outlines :</Typography>
          {/* <LinearProgress /> */}
        </Grid>

        <Grid item xs={9}>
          {/* <Paper component="ul"> */}
          {isLoaderEnable ? (
            <LinearProgress sx={{ marginTop: "25px" }} />
          ) : !isLoaderEnable && allProposalDetails.length > 0 ? (
            allProposalDetails.map((data, i) => {
              const { _id, templates } = data;
              let icon;

              if (data.label === "React") {
                icon = "hello";
              }

              return (
                <Badge
                  key={i}
                  badgeContent={templates.length ? templates.length : 0}
                  color="secondary"
                  sx={{
                    margin: "1%",
                    border:
                      templateIndex === i
                        ? "3px solid #3366FF"
                        : "1px solid #ccc",
                    borderRadius: "62px",
                  }}
                >
                  <Icon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      borderRadius: "62px",
                      borderTopLeftRadius: "16px",
                      borderBottomLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomRightRadius: "16px",
                      padding: "18px",
                      // marginRight: "2px",

                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" style={{ marginRight: "8px" }}>
                      {data.outlineName}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => settemplateIndex(i)}
                    >
                      <InfoIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{ pr: 0 }}
                      onClick={() => outlineHandel(_id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Icon>
                </Badge>
              );
            })
          ) : (
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <Typography textAlign="center" fontWeight="700" color="#857C7A">
                {`No Outlines are available, please add outlines.`}
              </Typography>
            </Grid>
          )}
          {/* </Paper> */}
        </Grid>

        <Grid item xs={2}>
          <IconButton onClick={handelDialouge}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Grid>

        <Grid
          item
          xs={1}
          sx={{
            mt: 1.3,
          }}
        >
          <Typography> Templates :</Typography>
        </Grid>
        <Grid item xs={9}>
          {isLoaderEnable ? (
            <LinearProgress sx={{ marginTop: "25px" }} />
          ) : !isLoaderEnable &&
            allProposalDetails[templateIndex]?.templates.length > 0 ? (
            allProposalDetails[templateIndex]?.templates?.map((elem, i) => {
              const { _id, templateName, description } = elem;
              return (
                <>
                  <Accordion
                    key={i}
                    expanded={Expanded === i}
                    onChange={handleAccordionChange(i)}
                    sx={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <b> {allProposalDetails[templateIndex].outlineName} </b>
                        : {templateName}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={0.5}>
                        <Grid item xs={12}>
                          <Typography>
                            <div
                              id="desc"
                              dangerouslySetInnerHTML={sanitizedData(
                                description
                              )}
                            />
                            {/* {description} */}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}></Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <IconButton
                            onClick={() => {
                              const descriptionValue =
                                window.document.getElementById(
                                  "desc"
                                ).textContent;
                              toast.success("Template copied!");
                              navigator.clipboard.writeText(descriptionValue);
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>

                          <IconButton onClick={() => handelEditTemplate(_id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handelDeleteDialouge(_id)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })
          ) : (
            <Grid item xs={12} sx={{ marginTop: "12px" }}>
              <Typography textAlign="center" fontWeight="700" color="#857C7A">
                {`No Templates are available, please add Templates.`}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handelAddTemplateDialouge()}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Grid>

        <Dialog maxWidth="md" open={isDialougeOpen} onClose={() => {}}>
          <DialogTitle>CREATE OUTLINE</DialogTitle>
          <DialogContent>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Outline Name"
                        variant="outlined"
                        placeholder="Outline Name"
                        type="text"
                        name="outlineName"
                        required
                        value={proposalDetails.outlineName}
                        fullWidth
                        onChange={handleChange}
                        // inputProps={{ maxLength: 2 }}
                        // helperText="It should not cross more than 80 character*"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Template Name"
                        variant="outlined"
                        placeholder="Template Name"
                        type="text"
                        name="templateName"
                        required
                        value={proposalDetails.templateName}
                        fullWidth
                        onChange={handleChange}
                        // inputProps={{ maxLength: 2 }}
                        // helperText="It should not cross more than 80 character*"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h4">Description</Typography>
                    </Grid>
                    <Grid item>
                      <Editor
                        style={{
                          width: "100%",
                          fontSize: "24px",
                          marginTop: 6,
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingRight: 12,
                          paddingLeft: 12,
                          border: "1px solid",
                          borderRadius: 10,
                        }}
                        callback={(det) => {
                          setProposalDetails((prevState) => ({
                            ...prevState,
                            description: det,
                          }));
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handelDialouge}>
              CANCEL
            </Button>
            <Button disabled={false} onClick={handelCreate}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth="lg" open={isOutlineOpen} onClose={() => {}}>
          <DialogTitle>Edit Outline</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Outline Name"
                  variant="outlined"
                  placeholder="Outline Name"
                  type="text"
                  name="outlineName"
                  required
                  value={outline.outlineName}
                  fullWidth
                  onChange={handleOutlineChange}
                  // inputProps={{ maxLength: 2 }}
                  // helperText="It should not cross more than 80 character*"
                  sx={{ mt: 1, maxWidth: "700px" }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={outlineHandel}>
              CANCEL
            </Button>
            <Button disabled={false} onClick={handleEditOutline}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="md" open={isTemplateEdit} onClose={() => {}}>
          <DialogTitle>EDIT Template</DialogTitle>
          <DialogContent>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2.5}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Template Name"
                        variant="outlined"
                        placeholder="Template Name"
                        type="text"
                        name="templateName"
                        required
                        value={template.templateName}
                        onChange={handleAddTemplateChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h4">Description</Typography>
                    </Grid>
                    <Grid item>
                      <Editor
                        style={{
                          width: "100%",
                          fontSize: "24px",
                          marginTop: 6,
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingRight: 12,
                          paddingLeft: 12,
                          border: "1px solid",
                          borderRadius: 10,
                        }}
                        value={template.description}
                        callback={(det) => {
                          setTemplate((prevState) => ({
                            ...prevState,
                            description: det,
                          }));
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handelEditTemplateDialouge}>
              CANCEL
            </Button>
            <Button disabled={false} onClick={handelEditProposalTemplate}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth="md" open={isTemplateDelete} onClose={() => {}}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <Typography>Do you want to delete template?</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={() => handelDeleteDialouge(templateId)}
            >
              CANCEL
            </Button>
            <Button disabled={false} onClick={removeProposalTemplate}>
              AGREE
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog maxWidth="md" open={isAddTemplate} onClose={() => {}}>
          <DialogTitle>Add Template</DialogTitle>
          <DialogContent>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Template Name"
                        variant="outlined"
                        placeholder="Template Name"
                        type="text"
                        name="templateName"
                        required
                        value={template.templateName}
                        fullWidth
                        onChange={handleAddTemplateChange}
                        // inputProps={{ maxLength: 2 }}
                        // helperText="It should not cross more than 80 character*"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h4">Description</Typography>
                    </Grid>
                    <Grid item>
                      <Editor
                        style={{
                          width: "100%",
                          fontSize: "24px",
                          marginTop: 6,
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingRight: 12,
                          paddingLeft: 12,
                          border: "1px solid",
                          borderRadius: 10,
                        }}
                        callback={(det) => {
                          setTemplate((prevState) => ({
                            ...prevState,
                            description: det,
                          }));
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handelAddTemplateDialouge}>
              CANCEL
            </Button>
            <Button disabled={false} onClick={handelAddProposalTemplate}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
