import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Paper,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useLeadStatusLogs } from "../../context/leadStatusLogs.context";

const LeadStatusLogsDialog = () => {
  const {
    leadStatusLogs,
    logPagination,
    handleChangePage,
    handleCloseStatusLogsDialog,
  } = useLeadStatusLogs();

  return (
    <Dialog
      fullWidth
      open={leadStatusLogs.openLogsDialog}
      onClose={handleCloseStatusLogsDialog}
    >
      <DialogTitle>Lead Status Logs</DialogTitle>
      <DialogContent dividers>
        {leadStatusLogs.loaderEnable ? (
          <LinearProgress />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Updated By</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadStatusLogs.logs.length > 0 ? (
                  leadStatusLogs.logs
                    .slice(
                      logPagination.page * logPagination.rowsPerPage,
                      logPagination.page * logPagination.rowsPerPage +
                        logPagination.rowsPerPage
                    )
                    .map((log, index) => {
                      const { status, userName, createdAt } = log;
                      const date = new Date(createdAt);
                      return (
                        <TableRow key={index}>
                          <TableCell>{status}</TableCell>
                          <TableCell>{userName ?? "By Automation"}</TableCell>
                          <TableCell>{date.toLocaleDateString()}</TableCell>
                          <TableCell>{date.toLocaleTimeString()}</TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Paper
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "16px",
                        }}
                      >
                        <Typography variant="subtitle1">No Logs!</Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          component="div"
          rowsPerPageOptions={[5]}
          count={leadStatusLogs.logs.length}
          rowsPerPage={logPagination.rowsPerPage}
          page={logPagination.page}
          onPageChange={handleChangePage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseStatusLogsDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadStatusLogsDialog;
