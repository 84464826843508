import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../components/UI/Page";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CompanyInfo from "../../components/Companies/CompanyInfo";
import {
  getActionDoers,
  getCompanyById,
  uploadCompanyDocument,
} from "../../services/companies.service";
import ActionSection from "../../components/Shared/Action/ActionSection";
import constants from "../../constants";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentSection from "../../components/Shared/Comments/CommentSection";
import FileUpload from "../../components/Fileupload";
import FileList from "../../components/FileList";
import { toast } from "react-toastify";
import Info from "../../components/Contacts/Info";
import { useDynamicCompanyTitle } from "./Companies";
import { useDynamicContactTitle } from "../Contacts/Contacts";
import getAttributesByEntity from "../../services/entity.attribute.services";
import EventBus from "../../components/texteditor/EventBus";
import { createContact } from "../../services/contacts.service";

// let hitApi = true;
let afterCreate = false;
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,7})*$/;
function CompanyView() {
  const companyDynamicTitle = useDynamicCompanyTitle();
  const contactDynamicTitle = useDynamicContactTitle();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { currentUser } = useContext(AuthContext);
  const params = useParams();
  const { id } = params;
  const initialCompanyState = {
    _id: "",
    name: "",
    createdBy: "",
    linkedIn: "",
    customerId: "",
    address: "",
    country: "",
    website: "",
    actions: [],
    documents: [],
    state: "",
    gstNumber: "",
    contacts: [],
    attributes: [],
  };
  const [customAttributes, setCustomAttributes] = useState([]);
  const [customContactAttributes, setCustomContactAttributes] = useState([]);
  const [actionDoers, setActionDoers] = useState([]);
  // const [isContactFetched, setIsContactFetched] = React.useState(false);

  const initialCommentState = {
    comment: "",
    createdBy: currentUser._id,
  };

  const initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    // dueDate: new Date(new Date().setHours(0, 0, 0, 0)),
    dueDate: new Date(),
    meetLink: "",
    // dueTime: new Date(),
  };

  //info edit
  const [companyData, setCompanyData] = useState(initialCompanyState);
  const [tempCompanyData, setTempCompanyData] = useState(initialCompanyState);
  const [toEdit, setToEdit] = useState(true);
  const [saveButton, setSaveButton] = useState(false);

  //action
  const [loadedActions, setLoadedActions] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [action, setAction] = React.useState(initialActionState);
  const [open, setOpen] = useState(false);

  //comment
  const [loadedComments, setLoadedComments] = useState([]);
  const [comment, setComment] = useState(initialCommentState);

  //files
  const [files, setFiles] = useState([]);
  const [descriptionObj, setDescriptionObj] = useState({});
  const [loaderDialog, setLoaderDialog] = useState(false);
  const [docOpen, setDocOpen] = React.useState(false);
  const [isViewDocuments, setIsViewDocuments] = useState(false);
  const handleClickOpen = (fromViewDoc) => {
    setOpen((prev) => (prev = true));
    setIsViewDocuments(fromViewDoc);
  };
  const handleDocClickOpen = () => {
    setDocOpen(true);
  };

  const initialContactStage = {
    name: "",
    company: "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    source: "",
    salesOwner: "",
  };
  //contact
  const [contactInfo, setContactInfo] = useState(initialContactStage);

  const handleClose = () => {
    setFiles((prev) => {
      return isViewDocuments ? prev : (prev = []);
    });
    setOpen(false);
    setIsViewDocuments(false);
    setChecked(false);
    setDescriptionObj((prev) => (prev = {}));
  };
  const handleDocClose = () => {
    setDescriptionObj({});
    setFiles((prev) => {
      return (prev = []);
    });
    setDocOpen(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleAddClickOpen = () => {
    console.log("companyData::", companyData);
    setContactInfo((prev) => ({
      ...prev,
      company: companyData._id,
    }));
    setDialogOpen(true);
  };
  const handleAddClickClose = () => {
    setContactInfo(initialContactStage);
    setDialogOpen(false);
  };

  const handleChange = (event) => {
    // if (event.target.name === "name") {
    //   let regex = /^[a-zA-Z ]*$/;
    //   const value = regex.test(event.target.value);
    //   console.log("valuefdf", event.target.name);
    //   if (!value) {
    //     toast("Numbers and special characters are not allowed");
    //     return;
    //   }
    // }
    if (event.target.name === "name") {
      if (event.target.value.length > 50) {
        toast.warn("Name should not be greater than 50 characters");
        return;
      }
    }

    if (event.target.name === "gstNumber") {
      const alphanumericPattern = /^[a-zA-Z0-9]{17,20}$/;
      const value = alphanumericPattern.test(event.target.value);
      if (value) {
        toast.warning(
          "Please enter a valid alphanumeric GST Number between 16-20 characters"
        );
        return;
      }
    }
    if (event.target.name === "gstNumber") {
      const alphanumericPattern = /^[a-zA-Z0-9\s]*$/;
      const value = alphanumericPattern.test(event.target.value);
      if (!value) {
        toast.warning("Special characters are not allowed");
        return;
      }
    }

    if (event.target.name === "gstNumber") {
      const alphanumericPattern = /^[a-zA-Z0-9]+$/;
      const value = alphanumericPattern.test(event.target.value);
      console.log(
        "fgdfgdf",
        event.target.value === "",
        event.target.value,
        companyData.gstNumber
      );
      if (event.target.value === "") {
        setCompanyData((prev) => {
          const temp = { ...prev };
          temp[event.target.name] = "";
          return temp;
        });
        return;
      }
      if (!value) {
        toast.warning("Spaces are not allowed");

        return;
      }
    }

    if (event.target.name === "website") {
      if (event.target.value.length > 100) {
        toast.warn("Website should not be greater than 100 characters");
        return;
      }
    }

    if (event.target.name === "address") {
      if (event.target.value.length > 250) {
        toast.warn("Address should not be greater than 250 characters");
        return;
      }
    }

    // if (event.target.name === "linkedIn") {
    //   if (event.target.value.length > 100) {
    //     toast.warn("LinkedIn should not be greater than 100 characters");
    //     return;
    //   }
    // }

    if (event.target.name === "customerId") {
      const alphanumericPattern = /^[a-zA-Z0-9\s]*$/;
      const value = alphanumericPattern.test(event.target.value);
      if (!value) {
        toast.warning("Special characters are not allowed");
        return;
      }
    }
    if (event.target.name === "customerId") {
      const alphanumericPattern = /^[a-zA-Z0-9]+$/;
      const value = alphanumericPattern.test(event.target.value);

      if (event.target.value === "") {
        setCompanyData((prev) => {
          const temp = { ...prev };
          temp[event.target.name] = "";
          return temp;
        });
        return;
      }

      if (!value) {
        toast.warning("Spaces are not allowed");

        return;
      }
      if (event.target.value.length > 10) {
        toast.warn("Customer Id should not be greater than 10 characters");
        return;
      }
    }

    setCompanyData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  //get company by id
  const fetchCompanyById = async () => {
    try {
      const response = await getCompanyById(id);
      if (response.data.status) {
        setCustomAttributes(
          JSON.parse(JSON.stringify(response.data.data.attributes))
        );
        setCompanyData((prev) => ({ ...prev, ...response.data.data }));
        setTempCompanyData((prev) => ({ ...prev, ...response.data.data }));
      }
    } catch (error) {
      alert(error);
    }
  };

  const uploadDocument = async () => {
    setLoaderDialog((prev) => (prev = true));
    setOpen((prev) => (prev = false));

    Object.keys(descriptionObj).forEach((ele) => {
      // if (descriptionObj[ele]) {
      //   setFiles((prev) => {
      //     const temp = [...prev];
      //     temp[ele]["description"] = descriptionObj[ele];
      //     return temp;
      //   });
      // }
      files[ele]["description"] = descriptionObj[ele];
    });
    try {
      const response = await uploadCompanyDocument(id, files);
      if (response.data.status) {
        toast("Document Uploaded Successfully");
        fetchCompanyById();
        // setOpen((prev) => (prev = false));
        // setFiles((prev) => (prev = []));
        // setDescriptionObj((prev) => (prev = {}));
        handleDocClose();
        setLoaderDialog((prev) => (prev = false));
      }
    } catch (error) {
      setOpen((prev) => (prev = false));
      setFiles((prev) => (prev = []));
      setDescriptionObj((prev) => (prev = {}));
      setLoaderDialog((prev) => (prev = false));
      toast("Document didn't Uploaded ");
    }
  };

  // const attributeValidateFunction = (customAttributes) => {
  //   let flag = true;
  //   customAttributes.forEach((ele) => {
  //     if (ele.isRequired && !ele["selectedValues"][0]) {
  //       toast(
  //         `${companyDynamicTitle?.displayName.singularName} ${ele.name} can not be empty`
  //       );
  //       flag = false;
  //     }
  //     if (ele.uiType === "TextField") {
  //       let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
  //       console.log("eleereer", isvalidelengthString);
  //       if (isvalidelengthString) {
  //         toast(`${ele.name} should not be more than 30 characters`);
  //         flag = false;
  //       }
  //     }

  //     if (ele.uiType === "multiLineTextfield") {
  //       let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

  //       if (isvalidelengthString) {
  //         toast(`${ele.name} should not be more than 100 characters`);
  //         flag = false;
  //       }
  //     }
  //   });
  //   return flag;
  // };

  const contactAttributeValidateFunction = (customContactAttributes) => {
    let flag = true;
    customContactAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(
          `${contactDynamicTitle?.displayName.singularName} ${ele.name} can not be empty`
        );
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`Text should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`Text should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const addContact = async (e) => {
    let hitApi = true;
    if (!contactInfo.name) {
      toast(
        `${contactDynamicTitle?.displayName.singularName} name can not be empty`
      );
      hitApi = false;
    }
    if (!contactInfo.email) {
      toast(
        `${contactDynamicTitle?.displayName.singularName} email can not be empty`
      );
      hitApi = false;
    }
    if (!contactInfo.country) {
      toast(
        `${contactDynamicTitle?.displayName.singularName} country can not be empty`
      );
      hitApi = false;
    }
    if (!contactInfo.salesOwner) {
      toast("SalesOwner can not be empty");
      hitApi = false;
    }
    const attributeValidateContact = contactAttributeValidateFunction(
      customContactAttributes
    );
    if (!attributeValidateContact) {
      return;
    }
    let temp = {
      name: contactInfo.name,
      email: contactInfo.email,
      country: contactInfo.country,
      company: contactInfo.company,
      phone: contactInfo.phone,
      alternateContact: contactInfo.alternateContact,
      linkedIn: contactInfo.linkedIn,
      address: contactInfo.address,
      source: contactInfo?.source?.name,
      salesOwner: contactInfo.salesOwner,
    };

    if (temp.email && !emailRegex.test(temp.email)) {
      toast("Please enter a valid email address");
      return;
    }

    try {
      setSaveButton(true);
      console.log("temp:::", temp, contactInfo, hitApi);
      const response = hitApi
        ? await createContact({
            ...temp,
            attributes: [...customContactAttributes],
          })
        : null;
      if (response.data.status) {
        // setIsContactFetched(false);
        fetchCompanyById(id);
        toast(
          `${contactDynamicTitle?.displayName.singularName} added successfully`
        );
        setContactInfo(initialContactStage);
        afterCreate = !afterCreate;
        EventBus.dispatch("EditorBus");
        handleAddClickClose();
        setSaveButton(false);
      }
    } catch (error) {
      setSaveButton(false);

      toast.warn(error.response.data.message);
    }
  };

  async function actionDoerFetcher(params) {
    try {
      const response = await getActionDoers(id);
      if (response.data.status) {
        setActionDoers([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    actionDoerFetcher();
    fetchCompanyById();
    // setIsContactFetched(false);
  }, []);
  useEffect(() => {
    setCustomContactAttributes([]);
    (async () => {
      try {
        const response = await getAttributesByEntity("Contacts");
        if (response.data.status) {
          setCustomContactAttributes(response.data.data);
          // setIsContactFetched(true);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [afterCreate]);
  const submitDocComment = async () => {
    // setFromCommentCreate(false);
    setOpen(false);
  };

  return (
    <Page>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${companyDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CompanyInfo
                      currentUser={currentUser}
                      company={companyData}
                      setCompany={setCompanyData}
                      tempCompanyData={tempCompanyData}
                      fromCompanyViewPage={true}
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      handleChange={handleChange}
                      fetchCompanyById={fetchCompanyById}
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      fromCompanyCreate={true}
                      actionHolders={actionDoers}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${contactDynamicTitle?.displayName.singularName.toUpperCase()}`}
                    </Typography>
                  </Grid>
                  {!["ADMIN", "SALES"].includes(currentUser.role) &&
                  actionDoers.includes(currentUser._id) ? null : (
                    <Grid
                      item
                      xs={9}
                      sx={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={handleAddClickOpen}
                        data-testid="company-contact-add-button"
                      >
                        Add
                      </Button>
                      <Dialog
                        maxWidth="lg"
                        open={dialogOpen}
                        onClose={handleAddClickClose}
                      >
                        <DialogTitle>
                          {`${contactDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                        </DialogTitle>
                        <DialogContent>
                          <Info
                            contact={contactInfo}
                            setContact={setContactInfo}
                            tempCompanyData={tempCompanyData}
                            customAttributes={customContactAttributes}
                            fromCompanyContactViewPage={true}
                            setCustomAttributes={setCustomContactAttributes}
                            afterCreate={afterCreate}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button color="error" onClick={handleAddClickClose}>
                            CANCEL
                          </Button>
                          <Button disabled={saveButton} onClick={addContact}>
                            SAVE
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {companyData.contacts.length ? (
                      companyData.contacts?.map((ele, index) => {
                        return (
                          <Accordion
                            elevation={3}
                            variant="outlined"
                            key={index}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                                Name: {ele.name}
                              </Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                              <Typography>Name:</Typography>
                              <Typography>{ele.name}</Typography>
                              <br />
                              <Typography>Email:</Typography>
                              <Typography>{ele.email}</Typography>
                              <br />
                              <Typography>Country:</Typography>
                              <Typography>{ele.country}</Typography>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row-reverse",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    navigate(`/contacts/${ele._id}`);
                                  }}
                                  size="medium"
                                  variant="outlined"
                                >
                                  Show More
                                </Button>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                    ) : (
                      <Typography
                        variant="h6"
                        color="#857C7A"
                        textAlign="center"
                      >
                        {/* {`No  Listed.Add ${contactDynamicTitle?.displayName.singularName
                          ?.toLowerCase()
                          ?.trim()}s to list here.`} */}
                        {`You have no ${contactDynamicTitle?.displayName.singularName} listed. To add a new one, click the 'Add' button.`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTIONS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ActionSection
                      action={action}
                      setAction={setAction}
                      initialActionState={initialActionState}
                      isEditPage={true}
                      loadedActions={loadedActions}
                      setLoadedActions={setLoadedActions}
                      actionType={constants.COMPANIES}
                      companyId={id}
                      checked={checked}
                      setChecked={setChecked}
                      fromCompanyViewPage={true}
                      state={state}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      COMMENTS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CommentSection
                      commentType={constants.COMPANIES}
                      loadedComments={loadedComments}
                      setLoadedComments={setLoadedComments}
                      id={id}
                      comment={comment}
                      setComment={setComment}
                      fromCompanyViewPage={true}
                      initialCommentState={initialCommentState}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      descriptionObj={descriptionObj}
                      files={files}
                      setDescriptionObj={setDescriptionObj}
                      setFiles={setFiles}
                      setLoaderDialog={setLoaderDialog}
                      allDoers={actionDoers}
                    />
                  </Grid>
                </Grid>
                <Dialog maxWidth="md" open={open} onClose={handleClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      checked={checked}
                      setChecked={setChecked}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      currentUser={currentUser}
                      fromCompanyPage={true}
                      isFromComment={true}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      color="error"
                    >
                      CANCEL
                    </Button>
                    <Button onClick={submitDocComment}>SAVE</Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={9} sm={9}>
                    <Typography variant="h5" gutterBottom color="primary">
                      DOCUMENTS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <div className="file-cards">
                      <div className="file-inputs">
                        {/* <input
                          type="file"
                          multiple="multiple"
                          onChange={(event) => uploadHandler(event)}
                        /> */}
                        <Button
                          variant="contained"
                          size="medium"
                          startIcon={<AddIcon />}
                          onClick={handleDocClickOpen}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}>
                            <FileList
                              fileList={companyData.documents}
                              currentUser={currentUser}
                              fromCompanyViewPage={true}
                              companyId={id}
                              fetchCompanyById={fetchCompanyById}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Dialog maxWidth="md" open={docOpen} onClose={handleDocClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      checked={checked}
                      setChecked={setChecked}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      currentUser={currentUser}
                      fromCompanyPage={true}
                      isFromComment={false}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        handleDocClose();
                      }}
                      color="error"
                    >
                      CANCEL
                    </Button>
                    <Button onClick={uploadDocument}>SAVE</Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default CompanyView;
