const apis = require("./api.requests");
const FileDownload = require("js-file-download");
const axios = require("axios");

const commonPath = "/common/v1";
const openPath = "/open";
const metaEntityPath = "/metaEntities/v1/";
const attributePath = "/attributes/v1/";
const automationPath = "/automation/v1/";
const sharedPath = "/gst/v1/";
const TAG = "attributes.service.js";

async function createAttribute(body) {
  return await apis.hitPostApi(`${attributePath}`, body);
}

async function getAttributes() {
  return await apis.hitGetApi(`${attributePath}all`);
}

async function getAttributeById() {
  return await apis.hitGetApi(`${attributePath}/countrys`);
}

async function removeAttribute(metaEntityId, attributeId) {
  return await apis.hitDeleteApi(
    `${attributePath}${metaEntityId}/${attributeId}`
  );
}

async function updateAttribute(attributeId, metaEntityId, body) {
  return await apis.hitPutApi(
    `${attributePath}${metaEntityId}/${attributeId}`,
    body
  );
}

async function getAttributesByEntity(entityName) {
  return await apis.hitGetApi(
    `${attributePath}/entityAttributes/${entityName}`
  );
}

//META ENTITY CRUDL
async function createMetaEntity(body) {
  return await apis.hitPostApi(`${metaEntityPath}`, body);
}

async function getMetaEntites() {
  return await apis.hitGetApi(`${metaEntityPath}/all`);
}

async function getMetaEntitesByEntity() {
  return await apis.hitGetApi(`${metaEntityPath}/:`);
}

async function getMetaEntityById(metaEntityId) {
  return await apis.hitGetApi(`${metaEntityPath}/${metaEntityId}/details`);
}

async function removeMetaEntity(metaEntityId) {
  return await apis.hitDeleteApi(`${metaEntityPath}/${metaEntityId}`);
}
async function updateMetaEntity(metaEntityId, body) {
  return await apis.hitPutApi(`${metaEntityPath}${metaEntityId}`, body);
}

//Automation rule api

async function createAutomation(body) {
  return await apis.hitPostApi(`${automationPath}`, body);
}

async function getAutomationRule() {
  return await apis.hitGetApi(`${automationPath}/all`);
}

async function getAutomationTriggers() {
  return await apis.hitGetApi(`${commonPath}/automationTriggers`);
}

async function getAutomationRuleById(automationRuleId) {
  return await apis.hitGetApi(`${automationPath}/${automationRuleId}/details`);
}

async function getAutomationRuleByModuleNameEventName(metaEntityId) {
  return await apis.hitGetApi(`${automationPath}/${automationPath}/details`);
}

async function removeAutomationRule(automationRuleId) {
  return await apis.hitDeleteApi(`${automationPath}/${automationRuleId}`);
}

async function updateAutomation(automationRuleId, body) {
  return await apis.hitPutApi(`${automationPath}${automationRuleId}`, body);
}

async function getGst() {
  return await apis.hitGetApi(`${sharedPath}/getGst`);
}
async function updateGst(body) {
  return await apis.hitPutApi(`${sharedPath}/updateGst`, body);
}
async function getEmailTemplateByEventName(moduleName, eventName) {
  return await apis.hitGetApi(
    `${commonPath}/emailTemplateByEventName?moduleName=${moduleName}&eventName=${eventName}`
  );
}

export {
  createAttribute,
  getAttributes,
  getAttributeById,
  removeAttribute,
  updateAttribute,
  getAttributesByEntity,
  createMetaEntity,
  getMetaEntites,
  getMetaEntitesByEntity,
  getMetaEntityById,
  removeMetaEntity,
  updateMetaEntity,
  createAutomation,
  getAutomationRule,
  getAutomationRuleById,
  removeAutomationRule,
  updateAutomation,
  getAutomationTriggers,
  getGst,
  updateGst,
  getEmailTemplateByEventName,
};
