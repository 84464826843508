import { useContext, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Button,
} from "@mui/material";
// components
import MenuPopover from "../../components/UI/MenuPopover";
// mocks_
import account from "../../_mock/account";
import { AuthContext } from "../../context/auth.context";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { deleteDataInIndexedDb } from "../../utils/indexedDb";
import { logout } from "../../auth/auth";

// ----------------------------------------------------------------------

const MENU_OPTIONS_ADMIN = [
  {
    label: "Home",
    icon: "eva:home-fill",
    linkTo: "/dashboard",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "profile",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    linkTo: "settings",
  },
  {
    label: "Users",
    icon: "eva:settings-2-fill",
    linkTo: "users",
  },
  {
    label: "Roles",
    icon: "eva:settings-2-fill",
    linkTo: "roles",
  },
  {
    label: "Archives",
    icon: "eva:settings-2-fill",
    linkTo: "archives",
  },
  {
    label: "Activity Logs",
    icon: "eva:settings-2-fill",
    linkTo: "logs",
  },
];
const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    linkTo: "/dashboard",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "profile",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    linkTo: "settings",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const {
    currentUser,
    isAuthenticated,
    setIsAuthenticated,
    setEmailPassword,
    setCurrentUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    //navigate("/login", { replace: true });

    setOpen(null);
  };
  const idbClear = async () => {
    await deleteDataInIndexedDb();
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "150%",
              borderRadius: "100%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
        <ArrowDropDownIcon />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {currentUser.role}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {currentUser.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {currentUser.role === "ADMIN"
            ? MENU_OPTIONS_ADMIN.map((option) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose}
                >
                  {option.label}
                </MenuItem>
              ))
            : MENU_OPTIONS.map((option) => (
                <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose}
                >
                  {option.label}
                </MenuItem>
              ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            logout();
            // localStorage.clear();
            idbClear();
            setIsAuthenticated(false);
            setCurrentUser({});
            setEmailPassword("");
          }}
          sx={{ m: 1 }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
