import React, { useState, useEffect, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { CallingContext } from "../context/calling.context";
import { requestForToken, onMessageListener, getFcmToken } from "./firebase";
import CallIncomingDialog from "../components/Dialogs/CallIncomingDialog";
import useSound from "use-sound";
import ringtone from "../sounds/ringtone.mp3";
import { AuthContext } from "../context/auth.context";

const Notification = () => {
  const {
    openCall,
    setOpenCall,
    callTo,
    setCallTo,
    setCallState,
    callState,
    openCallIncomingDialog,
    setOpenCallIncomingDialog,
    startTimerForDialog,
    notification,
    setNotification,
    callFrom,
    setCallFrom,
    setCallDisability,
  } = useContext(CallingContext);
  const { currentUser, fcmToken, setFcmToken } = useContext(AuthContext);

  const [play, { stop }] = useSound(ringtone);
  const notify = () => toast(<ToastDisplay />);
  let tempToken;
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
      if (notification.title === "Outgoing call alert") {
        console.log("Outgoing Use effect called");
        if (notification.body === "Call is answered") {
          console.log("Call is answered");
          setCallState((prev) => ({
            ...prev,
            isRinging: false,
            isPickedUp: true,
            isConnected: false,
          }));
          // handleCallPickedUpDialog();
        }
        if (notification.body === "Call is completed") {
          console.log("Call is completed");
          setCallDisability(false);
          setCallState((prev) => ({
            ...prev,
            isRinging: false,
            isPickedUp: false,
            isConnected: false,
          }));
          // handleCallPickedUpDialog();
        }
        if (notification.body === "Call is busy") {
          console.log("Call is busy");
          setCallDisability(false);
          setCallState((prev) => ({
            ...prev,
            isRinging: false,
            isPickedUp: false,
            isConnected: false,
          }));
          // handleCallPickedUpDialog();
        }
        if (notification.body === "Call is unanswered") {
          console.log("Call is unanswered");
          setCallDisability(false);
          setCallState((prev) => ({
            ...prev,
            isRinging: false,
            isPickedUp: false,
            isConnected: false,
          }));
          // handleCallPickedUpDialog();
        }
      }
      if (notification.title === "Incoming call alert") {
        console.log("Incoming Use effect called");
        notify();
        setCallFrom(notification?.body);
        setOpenCallIncomingDialog(true);
        play();
      }
    }
  }, [notification]);
  requestForToken().then((token) => {
    tempToken = getFcmToken();
    console.log("Temp Token", tempToken);
    // tempToken = requestForToken();
    if (tempToken !== "") {
      setFcmToken(tempToken);
    }
  });

  useEffect(() => {
    console.log("FCM Token", fcmToken);
  }, [fcmToken]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <Toaster />
      <CallIncomingDialog />
    </>
  );
};

export default Notification;
