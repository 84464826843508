import React, { useEffect, useState } from "react";
import Page from "../../components/UI/Page";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import Iconify from "../../components/UI/Iconify";
import { useMatchCollectionName } from "../../utils/filterObject";
import ActionDialog from "./ActionDialouge";
import Scrollbar from "../../components/UI/Scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import {
  getAllEmailCampaigns,
  updateEmailCampaign,
} from "../../services/email.campaign.service";
import { createSalesCampaign } from "../../services/salesCampaign.service";
import { toast } from "react-toastify";
import {
  fetchContactEmails,
  getContacts,
} from "../../services/contacts.service";
import { getCurrentDateOnly } from "../../utils/dateUtils";
import SearchNotFound from "../../components/SearchNotFound";

const TABLE_HEAD = [
  { id: "campaign", label: "Campaign", alignRight: false },
  { id: "sent", label: "Sent", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

export const useDynamicCompanyTitle = () => {
  let matchedCollection = useMatchCollectionName("companies");
  return matchedCollection;
};
const getNextDay = () => {
  const now = new Date();
  const nextDay = new Date(now);
  nextDay.setDate(now.getDate() + 1);
  nextDay.setHours(0, 0, 0, 0); // Midnight
  return nextDay;
};

const emailCampaignStructure = {
  name: "",
  recipientsNo: "",
  subject: "",
  body: "",
  scheduleTime: "",
  emails: [],
};

function SalesCampaign() {
  const [searchText, setSearchText] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaignList, setCampaignList] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openCampaignDialog, setOpenCampaignDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [stableContactEmails, setStableContactEmails] = useState([]);
  const [contactEmail, setcontactEmail] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [checked, setChecked] = useState([]);
  const [emails, setEmails] = useState([]);
  const [formData, setFormData] = useState({
    campaignName: "",
    maxCapacity: "",
    subject: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [status, setStatus] = useState("");
  const [loaderDialog, setLoaderDialog] = useState(false);
  const [sendingDate, setSendingDate] = useState(getNextDay());
  const [sendingTime, setSendingTime] = useState(getNextDay());
  const [emailContent, setEmailContent] = useState("");
  const [previousData, setPreviousData] = useState(emailCampaignStructure);
  const handleOpenEmailDialog = () => setOpenEmailDialog(true);
  const handleCloseEmailDialog = () => setOpenEmailDialog(false);
  const steps = [
    "Select Campaign Setting",
    "Email Template",
    "Add Contact's Email",
    "Review and Confirm",
  ];
  const handelClear = () => {
    setFormData((prev) => ({
      ...prev,
      campaignName: "",
      maxCapacity: "",
      subject: "",
    }));
    setSelectedCampaign("");
    setEmailContent("");
    setChecked([]);
    setSelectedEmails([]);
    setActiveStep(0);
    setStatus("");
    setIsEdit(false);
    setSendingDate(getNextDay());
    setSendingTime(getNextDay());
    setcontactEmail(stableContactEmails);
  };
  const handleOpenCampaignDialog = () => setOpenCampaignDialog(true);
  const handleCloseCampaignDialog = () => {
    getCoantactEmails();
    setOpenCampaignDialog(false);
    setIsView(false);
    handelClear();
  };

  const handleEmailAction = () => {
    // Handle the action for Email Exclusion List
    setOpenEmailDialog(false);
    // Add your navigation or other logic here
  };
  const getExclusionEmail = async () => {
    try {
      let page;
      const response = await getContacts((page = 0));

      if (response.data.status) {
        const data = response.data.data.allContacts
          .map(({ name, email, _id, isExcluded }) => {
            return { name, email, _id, isExcluded };
          })
          .filter((item) => item.isExcluded === true);

        setEmails(() => [...data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getExclusionEmail();
  }, []);

  function extractDateTime(isoString) {
    // Create a Date object from the ISO string
    const date = new Date(isoString);

    // Extract the date components
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    // Format the date and time as needed
    const formattedDate = `${year}-${month}-${day}`;

    // Extract the time components
    // Options for toLocaleString to get the desired format
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Kolkata",
      timeZoneName: "short",
    };

    // Format the date and time using the time zone
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(date);

    // Create an object to store the formatted parts
    const dateTimeParts = {};
    parts.forEach(({ type, value }) => {
      dateTimeParts[type] = value;
    });

    // Manually format the time zone to include the full name
    const timeZoneOffset = "+0530";
    const timeZoneName = "India Standard Time";

    // Construct the final formatted date and time string
    const formattedDateTime = `${dateTimeParts.weekday} ${dateTimeParts.month} ${dateTimeParts.day} ${dateTimeParts.year} ${dateTimeParts.hour}:${dateTimeParts.minute}:${dateTimeParts.second} GMT${timeZoneOffset} (${timeZoneName})`;

    setSendingDate(formattedDate);
    setSendingTime(formattedDateTime);
  }

  function convertDateToISOFormat(dateString, timeString) {
    // Parse the date and time components from the input strings
    const date = new Date(dateString);
    const time = new Date(timeString);

    // Extract the local date and time components
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    // Create a new Date object with the combined date and time components in local time
    const combinedDate = new Date(year, month, day, hours, minutes, seconds);

    // Convert the combined date to UTC
    const utcDate = new Date(combinedDate.toISOString());

    // Convert the UTC date to ISO 8601 format
    const isoString = utcDate.toISOString();

    return isoString;
  }

  function checkValidTime() {
    const todayDate = getCurrentDateOnly();
    const selectedDate = new Date(sendingDate);
    const [date, month, year] = [
      selectedDate.getDate(),
      selectedDate.getMonth(),
      selectedDate.getFullYear(),
    ];
    if (todayDate === `${date}-${month + 1}-${year}`) {
      const [currentHour, currentMinute] = [
        new Date().getHours(),
        new Date().getMinutes(),
      ];
      const [selectedHour, selectedMinute] = [
        new Date(sendingTime).getHours(),
        new Date(sendingTime).getMinutes(),
      ];

      if (currentHour > selectedHour) {
        toast.error(
          "You can't schedule an event in the past.Please choose a future time."
        );
        return false;
      }

      if (currentHour === selectedHour && currentMinute > selectedMinute) {
        toast.error(
          "You can't schedule an event in the past.Please choose a future time."
        );
        return false;
      }

      if (currentHour === selectedHour && currentMinute === selectedMinute) {
        toast.error("Selected time is the same.");
        return false;
      }

      return true;
    }

    return true;
  }

  const handleCampaignAction = async (status) => {
    try {
      if (!checkValidTime()) return;
      setLoaderDialog(true);

      const body = {
        name: formData.campaignName.trim(),
        recipientsNo: formData.maxCapacity,
        subject: formData.subject.trim(),
        body: cleanHtmls(emailContent).trim(),
        scheduleTime: convertDateToISOFormat(sendingDate, sendingTime),
        status: status === "Save As Draft" ? "Save As Draft" : "scheduled",
        emails: selectedEmails,
      };
      const result = await createSalesCampaign(body);
      // Handle the action for Create Campaign
      if (result.status) {
        await getAllEmailCampaignsHandler();
        setOpenCampaignDialog(false);
        getCoantactEmails();
        // setcontactEmail(stableContactEmails);
        handelClear();
        setLoaderDialog(false);
      }
      // Add your navigation or other logic here
    } catch (error) {
      setLoaderDialog(false);
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function checkForUpdate(newObj, oldObj) {
    const temp = {};
    Object.keys(newObj).forEach((key) => {
      if (Array.isArray(oldObj[key])) {
        if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
          temp[key] = newObj[key];
        }
      } else if (typeof newObj[key] === "string") {
        if (newObj[key] !== oldObj[key]) {
          temp[key] = newObj[key];
        }
      }
    });

    return temp;
  }
  const cleanHtmls = (html) => {
    const cleanTag = "<p><br></p>";

    // Trim cleanTag from the beginning
    while (html.startsWith(cleanTag)) {
      html = html.substring(cleanTag.length).trim();
    }

    // Trim cleanTag from the end
    while (html.endsWith(cleanTag)) {
      html = html.substring(0, html.length - cleanTag.length).trim();
    }

    return html;
  };
  async function handleUpdate(status) {
    if (!checkValidTime()) return;
    const temp = {
      name: formData.campaignName.trim(),
      recipientsNo: formData.maxCapacity,
      subject: formData.subject.trim(),
      body: cleanHtmls(emailContent).trim(),
      scheduleTime: convertDateToISOFormat(sendingDate, sendingTime),
      emails: selectedEmails,
    };
    const result = checkForUpdate(temp, previousData);
    if (!Object.keys(result).length) {
      toast("No changes detected to update");
      return;
    }
    setLoaderDialog(true);

    try {
      const response = await updateEmailCampaign(selectedCampaign, {
        ...temp,
        status: status === "Save As Draft" ? "Save As Draft" : "scheduled",
      });
      if (response.data.status) {
        toast("Campaign updated successfully");
        await getAllEmailCampaignsHandler();
        setOpenCampaignDialog(false);
        getCoantactEmails();
        // setcontactEmail(stableContactEmails);
        handelClear();
      }
    } catch (error) {
      console.log(error);
      toast("Error occured while updating campaign");
    } finally {
      setLoaderDialog(false);
    }
  }

  function handleEditButtonClick({
    body = "",
    name = "",
    recipientsNo = "",
    status = "",
    emails = [],
    subject = "",
    _id = "",
    scheduleTime = "",
  }) {
    setIsEdit(true);
    setFormData((prev) => ({
      ...prev,
      campaignName: name,
      subject,
      maxCapacity: recipientsNo,
    }));
    setSelectedCampaign(_id);
    setEmailContent(body);
    setSelectedEmails(emails.map(({ contactId, to }) => ({ contactId, to })));
    setChecked(emails.map(({ contactId }) => contactId));
    extractDateTime(scheduleTime);
    setStatus(status?.toLowerCase() === "save as draft" ? "scheduled" : status);
    const allContactEmails = [...contactEmail];
    emails.forEach(({ contactId }) => {
      const index = allContactEmails.findIndex(
        (item) => item._id === contactId
      );
      if (index > -1) {
        const [splisedEmail] = allContactEmails.splice(index, 1);
        allContactEmails.unshift(splisedEmail);
      }
    });
    setcontactEmail(structuredClone(allContactEmails));
    setStableContactEmails(structuredClone(allContactEmails));
    setPreviousData((prev) => ({
      ...prev,
      name,
      subject,
      recipientsNo,
      body,
      emails: emails.map(({ contactId, to }) => ({ to, contactId })),
      scheduleTime,
    }));
    handleOpenCampaignDialog();
  }

  async function getAllEmailCampaignsHandler() {
    try {
      const response = await getAllEmailCampaigns();
      if (response.data?.status) {
        setCampaignList(response.data?.data);
        setCount(response.data?.data?.length);
        setTableLoading(false);
      } else setTableLoading(false);
    } catch (error) {
      console.log(error);
      setCampaignList([]);
      setCount(0);
      setTableLoading(false);
    }
  }

  const getCoantactEmails = async () => {
    try {
      const result = await fetchContactEmails();

      if (result) {
        setcontactEmail(() => structuredClone([...result.data.data]));
        setStableContactEmails(structuredClone([...result.data.data]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleViewButtonClick = ({
    body = "",
    name = "",
    recipientsNo = "",
    status = "",
    emails = [],
    subject = "",
    _id = "",
    scheduleTime,
  }) => {
    setIsEdit(false); // Disable editing
    setIsView(true);
    setFormData((prev) => ({
      ...prev,
      campaignName: name,
      subject,
      maxCapacity: recipientsNo,
    }));
    setSelectedCampaign(_id);
    setEmailContent(body);
    setSelectedEmails(emails.map(({ contactId, to }) => ({ contactId, to })));
    setChecked(emails.map(({ contactId }) => contactId));
    extractDateTime(scheduleTime);
    setStatus(status);
    const allContactEmails = [...contactEmail];
    emails.forEach(({ contactId }) => {
      const index = allContactEmails.findIndex(
        (item) => item._id === contactId
      );
      if (index > -1) {
        const [splisedEmail] = allContactEmails.splice(index, 1);
        allContactEmails.unshift(splisedEmail);
      }
    });
    setcontactEmail(allContactEmails);
    setStableContactEmails(allContactEmails);
    handleOpenCampaignDialog();
  };

  useEffect(() => {
    getAllEmailCampaignsHandler();
    getCoantactEmails();
  }, []);

  return (
    <>
      <Page>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={0} variant="outlined">
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}></Grid>
                    <Grid
                      item
                      xs={4.4}
                      sx={{
                        display: "flex",
                        //   flexDirection: "row-reverse",
                        justifyContent: "right",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleOpenEmailDialog}
                      >
                        Email Exclusion List
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={1.6}
                      sx={{
                        display: "flex",
                        //   flexDirection: "row-reverse",
                        justifyContent: "right",
                      }}
                    >
                      <Button
                        variant="contained"
                        // startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleOpenCampaignDialog}
                      >
                        {`Create Campaign`}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card
                elevation={0}
                variant="outlined"
                sx={{ paddingLeft: 2, paddingRight: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                          <UserListHead headLabel={TABLE_HEAD} />
                          {tableLoading || !campaignList.length ? (
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={6}
                                  sx={{ py: 3 }}
                                >
                                  {tableLoading ? (
                                    <CircularProgress />
                                  ) : (
                                    <SearchNotFound />
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : (
                            <TableBody>
                              {campaignList
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((campaign) => (
                                  <TableRow key={campaign._id}>
                                    <TableCell>{campaign.name}</TableCell>
                                    <TableCell>
                                      {campaign.emails.length}
                                    </TableCell>

                                    <TableCell>
                                      <div
                                        style={{
                                          backgroundColor:
                                            campaign.status?.toLowerCase() ===
                                            "executed"
                                              ? "green"
                                              : "#2165d1",
                                          width: "80px",
                                          height: "36px",
                                          borderRadius: "10px",
                                          color: "white",

                                          textAlign: "center",
                                          padding: "5px",
                                          alignContent: "center",
                                        }}
                                      >
                                        {campaign.status?.toLowerCase() ===
                                        "save as draft"
                                          ? "Drafted"
                                          : campaign.status
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                            campaign.status?.substring(1)}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      {campaign.status?.toLowerCase() ===
                                      "executed" ? (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            handleViewButtonClick(campaign)
                                          }
                                        >
                                          View
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            handleEditButtonClick(campaign);
                                          }}
                                        >
                                          {campaign.status?.toLowerCase() ===
                                          "schedule"
                                            ? "Reschedule"
                                            : "Edit"}
                                        </Button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Scrollbar>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
      <ActionDialog
        open={openEmailDialog}
        onClose={handleCloseEmailDialog}
        title="Email ID Exclusion List"
        content="Content for Email Exclusion List"
        emails={emails}
        setEmails={setEmails}
        actionText="Confirm"
        onAction={handleEmailAction}
        showEmailList={true}
        getExclusionEmail={getExclusionEmail}
      />
      <ActionDialog
        open={openCampaignDialog}
        onClose={handleCloseCampaignDialog}
        title={
          isEdit
            ? "Update Campaign"
            : isView
            ? "View Campaign"
            : "Create Campaign"
        }
        content="Content for Create Campaign"
        actionText={isEdit ? "Update" : "Schedule"}
        icon="eva:plus-fill"
        onAction={isEdit ? handleUpdate : handleCampaignAction}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        formData={formData}
        setFormData={setFormData}
        emailContent={emailContent}
        setEmailContent={setEmailContent}
        contactEmail={contactEmail}
        setcontactEmail={setcontactEmail}
        stableContactEmails={stableContactEmails}
        setStableContactEmails={setStableContactEmails}
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        sendingDate={sendingDate}
        setSendingDate={setSendingDate}
        sendingTime={sendingTime}
        setSendingTime={setSendingTime}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
        checked={checked}
        setChecked={setChecked}
        loaderDialog={loaderDialog}
        setLoaderDialog={setLoaderDialog}
        isEdit={isEdit}
        isView={isView}
        steps={steps}
        getExclusionEmail={getExclusionEmail}
        searchText={searchText}
        setSearchText={setSearchText}
        getCoantactEmails={getCoantactEmails}
      />
    </>
  );
}

export default SalesCampaign;
