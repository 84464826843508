import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";

const ReportTabPanel = ({ propsValue }) => {
  const {
    handleNavigateToLead,
    changeTitle,
    reportData,
    leadDynamicTitel,
    page,
    rowsPerPage,
    count,
    handlePageChange,
  } = propsValue;
  console.log(reportData, "reports detail outside");
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{` ${leadDynamicTitel?.displayName?.singularName}`}</TableCell>
              <TableCell align="left">Country</TableCell>
              <TableCell align="left">Skill</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">{changeTitle}</TableCell>
              <TableCell align="left">Sales Owner</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((detail, index) => {
                console.log("reports detail", detail);
                return (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      cursor: "pointer",
                    }}
                    hover
                    onClick={(event) => {
                      handleNavigateToLead(
                        event,
                        detail?._id,
                        detail?.contact_id
                      );
                    }}
                  >
                    <TableCell align="left">{detail?.summary}</TableCell>
                    <TableCell align="left">{detail?.country}</TableCell>
                    <TableCell align="left">
                      {(Array.isArray(detail?.skillsArray)
                        ? detail?.skillsArray?.join(",")
                        : detail?.skillsArray) || "-"}
                    </TableCell>
                    <TableCell align="left">{detail?.status}</TableCell>
                    <TableCell align="left">
                      {new Date(detail?.updatedAt).toDateString()}
                    </TableCell>
                    <TableCell align="left">{detail?.salesOwner}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default ReportTabPanel;
