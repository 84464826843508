import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React from "react";

function IndivisualView({
  name,
  country,
  state,
  address,
  data,
  handleEditIconClick,
  handleShowDetails,
}) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={9.75}>
              <Typography fontSize="24px" gutterBottom>
                {name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ alignItems: "start" }}
              display="flex"
              flexDirection="row"
            >
              <IconButton onClick={handleShowDetails}>
                <RemoveRedEyeIcon sx={{ color: "#4f86da" }} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={1.25}
              sx={{ alignItems: "start" }}
              display="flex"
              flexDirection="row-reverse"
            >
              <IconButton onClick={handleEditIconClick}>
                <ModeEditOutlineIcon sx={{ color: "#4f86da" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>{`${country} ${state}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography fontWeight="800">Address</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>{address}</Typography>
            </Grid>
            {data?.map((detail, index) => {
              if (index <= 3) {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={5}>
                      <Typography fontWeight="800">{`${detail.key}: `}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>{detail.value}</Typography>
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </Grid>
        {/* {data?.length > 3 ? ( */}
        {/* <Grid item xs={12} display="flex" flexDirection="row-reverse">
          <Button
            size="small"
            sx={{ ":hover": { backgroundColor: "white" } }}
            onClick={handleShowDetails}
          >
            SHOW MORE
          </Button>
        </Grid> */}
        {/* ) : null} */}
      </Grid>
    </>
  );
}

export default IndivisualView;
