import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./FileItem.css";
import { TextField, Tooltip } from "@mui/material";

const FileItem = ({
  index,
  file,
  deleteFile,
  showDescription,
  handleFileDescription,
  fromEmailDialog,
  descriptionObj,
  fromPriceNegotiationCreateTime,
  openProjectStatusDialouge,
  setSignedContractFiles,
  isFromComment,
  fromContactListPage,
}) => {
  console.log(isFromComment, "isFrom");
  const handleDescriptionText = (value) => {
    if (value === undefined || value === "" || value === null) {
      return "";
    }
    return value;
  };
  return (
    <>
      {openProjectStatusDialouge ? (
        <div className="parent-container">
          <div className="li" key={file.name}>
            <FontAwesomeIcon icon={faFileAlt} style={{ color: "#2065d1" }} />
            <Tooltip title={file.name}>
              <p
                style={{
                  flex: "1",
                  fontSize: "0.9rem",
                  marginLeft: "1em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {file ? file.name : null}
              </p>
            </Tooltip>
            <div className="actions">
              <div>
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{
                    color: "#f55e30",
                    fontSize: "1.2em",
                  }}
                  onClick={() => {
                    setSignedContractFiles((prev) => {
                      return (prev = []);
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : fromContactListPage ? (
        <div className="parent-container">
          <div className="li" key={file.name}>
            <FontAwesomeIcon icon={faFileAlt} style={{ color: "#2065d1" }} />
            <Tooltip title={file.name}>
              <p
                style={{
                  flex: "1",
                  fontSize: "0.9rem",
                  marginLeft: "1em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {file.name}
              </p>
            </Tooltip>
            <div className="actions">
              {file.isUploading && (
                <div>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin "
                    style={{
                      color: "#f55e30",
                      fontSize: "1.2em",
                    }}
                    onClick={() => deleteFile(file.name)}
                  />
                </div>
              )}
              {!file.isUploading && (
                <div>
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      color: "#f55e30",
                      fontSize: "1.2em",
                    }}
                    onClick={() => deleteFile(file.name)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="parent-container">
          <div className="li" key={file.name}>
            <FontAwesomeIcon icon={faFileAlt} style={{ color: "#2065d1" }} />
            <Tooltip title={file.name}>
              <p
                style={{
                  flex: "1",
                  fontSize: "0.9rem",
                  marginLeft: "1em",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {file.name}
              </p>
            </Tooltip>
            <div className="actions">
              {file.isUploading && (
                <div>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-spin "
                    style={{
                      color: "#f55e30",
                      fontSize: "1.2em",
                    }}
                    onClick={() => deleteFile(file.name)}
                  />
                </div>
              )}
              {!file.isUploading && (
                <div>
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      color: "#f55e30",
                      fontSize: "1.2em",
                    }}
                    onClick={() => deleteFile(file.name)}
                  />
                </div>
              )}
            </div>
          </div>
          {fromEmailDialog ||
          fromPriceNegotiationCreateTime ? null : !isFromComment ? (
            <TextField
              sx={{ paddingTop: "3px" }}
              fullWidth
              type="search"
              variant="standard"
              size="small"
              value={handleDescriptionText(descriptionObj[index])}
              onChange={handleFileDescription}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default FileItem;
