export default function isArrayEquals(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  const _array1 = JSON.parse(JSON.stringify(array1));
  const _array2 = JSON.parse(JSON.stringify(array2));

  return _array1.every((element) =>
    _array2.find(
      (ele) => ele.key === element.key && ele.value === element.value
    )
  );
}

export function getUniqueArrayElements(arr, key) {
  const uniqueArray = [];
  for (let i = 0; i < arr.length; i++) {
    const isExists = uniqueArray?.some(
      (obj) => obj[key]?.toLowerCase() === arr[i][key]?.toLowerCase()
    );
    if (!isExists) {
      uniqueArray.push(arr[i]);
    }
  }

  return uniqueArray;
}
