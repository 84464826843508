import React, { useState } from 'react'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, InputLabel, Select, TextField, Typography } from '@mui/material';
import DOMPurify from 'dompurify'

export default function EmailBody({ data, emails, response, index }) {


    const [loaderDialog, setLoaderDialog] = React.useState(false);

    const result = emails[index];

    console.log('result', result);
    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(result ? result.html : null)
    })

    return (
        <Grid container spacing={0}>
            <Dialog open={loaderDialog}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box sx={{ display: 'flex', justifyContent: "center" }}>
                            {/* <CircularProgress /> */}
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px" }}>
                    Loading... Please Wait
                </DialogActions>
            </Dialog>


            <Grid item xs={12}>
                <FormControl>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* <Typography>{result ? result.from : null}</Typography> */}
                            {/* <Typography>{result ? result.date : null}</Typography> */}
                            {/* <Typography>{result.subject}</Typography> */}
                            {/* <Typography>{result.About}</Typography> */}
                            <div
                                dangerouslySetInnerHTML={sanitizedData()}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
        </Grid>
    )
}
