import userActions from "../actions/action-types";

const initialState = [];

export default function getUsersReducer(state = initialState, action) {
  switch (action.type) {
    case userActions.GET_USERS:
      return [...state, action.payload];

    default:
      return state;
  }
}
