import { createStore, combineReducers } from "redux";
import getUsersReducer from "./reducers/user.reducer";
// import { getUsersAction } from "./actions/user.action";

const rootReducer = combineReducers({
  getUsersReducer,
});

const store = createStore(rootReducer);

export default store;
