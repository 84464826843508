import { useState } from "react";
import "./index.css";
import FileUpload from "./FileUpload/FileUpload";
import FileList from "./FileList/FileList";
import { Grid } from "@mui/material";

function Index({
  files,
  setFiles,
  checked,
  setChecked,
  descriptionObj,
  setDescriptionObj,
  lead,
  currentUser,
  project,
  contact,
  fromLeadViewPage,
  fromProjectViewPage,
  fromContactViewPage,
  fromCompanyPage,
  fromPriceNegotiationCreateTime,
  openProjectStatusDialouge,
  signedContractFiles,
  setSignedContractFiles,
  isFromComment,
}) {
  // const [files, setFiles] = useState([]);
  console.log(isFromComment, "isFrom");
  const removeFile = (index) => {
    console.log("2/1/23", index);
    // console.log("Files in app.js", Array.from(files)[0]);
    // setFiles(Array.from(files)?.filter((file) => file.name !== filename));
    setFiles((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  return (
    <Grid container spacing={2} sx={{ width: 600 }}>
      <Grid item xs={12} sx={{ width: 1200 }}>
        <div className="title">Upload file</div>
        <FileUpload
          files={files}
          setFiles={setFiles}
          removeFile={removeFile}
          checked={checked}
          setChecked={setChecked}
          openProjectStatusDialouge={openProjectStatusDialouge}
          signedContractFiles={signedContractFiles}
          setSignedContractFiles={setSignedContractFiles}
        />
        {/* <FileList files={files} removeFile={removeFile} /> */}
      </Grid>
      <Grid item xs={12}>
        <FileList
          files={files}
          setFiles={setFiles}
          removeFile={removeFile}
          checked={checked}
          setChecked={setChecked}
          descriptionObj={descriptionObj}
          setDescriptionObj={setDescriptionObj}
          lead={lead}
          contact={contact}
          currentUser={currentUser}
          project={project}
          fromLeadViewPage={fromLeadViewPage}
          fromContactViewPage={fromContactViewPage}
          fromProjectViewPage={fromProjectViewPage}
          fromCompanyPage={fromCompanyPage}
          fromPriceNegotiationCreateTime={fromPriceNegotiationCreateTime}
          openProjectStatusDialouge={openProjectStatusDialouge}
          signedContractFiles={signedContractFiles}
          setSignedContractFiles={setSignedContractFiles}
          isFromComment={isFromComment}
        />
      </Grid>
    </Grid>
  );
}

export default Index;
