import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Page from "../../components/UI/Page";
import { getUserById, updateUser } from "../../services/users.service";
// import Typography from "../theme/overrides/Typography";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@mui/material";
import { AuthContext } from "../../context/auth.context";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { getRoles, getRolesName } from "../../services/roles.service";
import { updateTenant } from "../../services/superAdmin.service";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

var regex = /^[a-zA-Z ]*$/;
var regexPhone = /^\+?\d*$/;

const UserView = () => {
  const { state } = useLocation();
  console.log(state);
  const { userId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { isTenant } = currentUser;
  const initialUserState = {
    name: "",
    email: "",
    gender: "",
    dob: "",
    phone: "",
    role: "",
    password: "",
  };

  const navigate = useNavigate();
  const [userState, setUserState] = useState(initialUserState);

  const [tempUserState, setTempUserState] = useState(initialUserState);

  const [updateUserCalled, setUpdateUserCalled] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    try {
      const response = await getRolesName();
      if (response.data.status) {
        setRoles(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!updateUserCalled && currentUser?.role !== "SUPER_ADMIN") {
      getUserById(userId, true)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            const keys = Object.keys(initialUserState);
            console.log("keys", keys);
            const temp = {};
            keys.forEach((key) => {
              temp[key] = response.data.data[key];
            });
            console.log("temp", temp);
            setUserState(temp);
            setTempUserState(temp);
            console.log("userState", userState);
            setUpdateUserCalled(true);
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
      fetchRoles();
    } else if (currentUser?.role === "SUPER_ADMIN") {
      if (!state) navigate(`/users`);
      setUserState({ ...state });
      setTempUserState({ ...state });
    }
  }, [updateUserCalled]);

  const handleChange = (e) => {
    if (e.target.name === "name") {
      const value = regex.test(e.target.value);
      if (!value) {
        toast("Numbers and special characters are not allowed");
        return;
      }
      if (e.target.value.trim().length > 40) {
        toast("Name can not be more than 40");
        return;
      }
      if (e.target.value && !e.target.value?.trim().length) {
        toast("Only Spaces not allowed for this field");
        return;
      }
      // else { setName(e.target.value) }
    }
    if (e.target.name === "phone") {
      const value = regexPhone.test(e.target.value);
      if (!value) {
        toast(
          "Alphabets, spaces and special characters except '+' are not allowed"
        );
        return;
      }
      // else { setPhoneNumber(e.target.value) }
    }
    console.log(e.target.name, e.target.value);
    setUserState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(userState);
  };

  const CheckForUpdate = () => {
    const keys = Object.keys(userState);
    const res = {};
    console.log(tempUserState);
    console.log(userState);
    keys.forEach((key) => {
      if (userState[key] !== tempUserState[key]) res[key] = userState[key];
    });
    return res;
  };

  const isValidDateFormat = (date) => {
    let parsedDate = new Date(date);

    return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  };
  const validateAge = (data) => {
    console.log("getTime", new Date(data).getTime());
    var diff = Date.now() - new Date(data).getTime();
    console.log("diff", diff);
    var diffInYear = new Date(diff);
    console.log(diffInYear);
    console.log(Math.abs(diffInYear.getUTCFullYear() - 1970));
    return Math.abs(diffInYear.getUTCFullYear() - 1970) >= 18 ? true : false;
  };
  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };
  const validateYearOfBirth = (year) => {
    const currentYear = new Date(year).getFullYear();

    if (currentYear < 1900) {
      return true;
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    const temp = { ...userState };

    const result = CheckForUpdate();

    if (Object.keys(result).length > 0) {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!validate(temp.name)) {
        toast("Name can not be empty");
        return;
      }
      if (!validate(temp.email)) {
        toast("Email can not be empty");
        return;
      }
      if (temp.email && !emailRegex.test(temp.email)) {
        toast("Please enter a valid email address.");
        return;
      }

      if (!isValidDateFormat(temp.dob)) {
        toast.warn(
          "Invalid date format. Please enter the Date of Birth in the format mmm/dd/yyyy(e.g., Jan/01/2000)."
        );

        return;
      }
      if (validateYearOfBirth(temp.dob)) {
        toast.warn("Year of birth cannot be less than 1900.");
        return;
      }
      if (!validateAge(temp.dob)) {
        toast("User must be 18 years old");
        return;
      }
      try {
        let response;
        let userStateCopy = { ...userState };
        userStateCopy.name = userState.name?.trim() || "";
        userStateCopy.email = userStateCopy.email?.toLowerCase()?.trim() || "";

        if (currentUser?.role === "SUPER_ADMIN") {
          response = await updateTenant(userId, userStateCopy, isTenant);
        } else {
          response = await updateUser(userId, userStateCopy);
        }

        if (response.data.status) {
          setTempUserState(temp);
          console.log(response.data);
          toast("User updated successfully");
          setIsEdit(false);
          setUpdateUserCalled(false);
          if (currentUser?.role === "SUPER_ADMIN") {
            if (!state) navigate(`/users`);
            setUserState({
              ...state,
              name: state?.name?.trim() || "",
              email: state?.email?.toLowerCase()?.trim() || "",
            });
            setTempUserState({
              ...state,
              name: state?.name?.trim() || "",
              email: state?.email?.toLowerCase().trim() || "",
            });
          }
        }
      } catch (error) {
        console.log(error);
        toast(error.response.data.message);
        setIsEdit(true);
      }
    } else {
      toast("Nothing to update");
      setIsEdit(true);
    }
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    console.log("date", date);
    console.log(
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/")
    );
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }

  console.log(userState, tempUserState);

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Card
          sx={{ backgroundColor: "#f1f2f6" }}
          elevation={5}
          variant="outlined"
        >
          <CardContent>
            <Typography variant="h5" gutterBottom color="primary">
              USER DETAILS
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card elevation={3} variant="outlined">
                  <CardContent>
                    <Grid container spacing={2.5}>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", flexDirection: "row-reverse" }}
                      >
                        {isEdit ? (
                          <>
                            <Button
                              variant="contained"
                              onClick={handleSave}
                              startIcon={<SaveIcon />}
                            >
                              Save
                            </Button>
                            <div>&nbsp;</div>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setUserState({ ...tempUserState });
                                setIsEdit(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={handleEdit}
                            startIcon={<EditIcon />}
                            disabled={
                              currentUser?.role === "SUPER_ADMIN"
                                ? false
                                : userState.role === "ADMIN"
                                ? userId !== currentUser._id
                                : false
                            }
                          >
                            Edit
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <TextField
                          id="outlined-basic-name"
                          disabled={!isEdit}
                          fullWidth
                          label="Name"
                          value={userState.name}
                          variant="outlined"
                          type="text"
                          name="name"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic-email"
                          disabled={!isEdit}
                          fullWidth
                          label="Email"
                          value={userState.email}
                          variant="outlined"
                          type="email"
                          name="email"
                          // onChange={({ target: { value } = {} }) => {
                          //   console.log(value, value && !value?.trim());
                          //   if (value && !value?.trim()) {
                          //     console.log("::::");
                          //     toast("Only spaces are not allowed");
                          //     return;
                          //   }
                          //   setUserState((prev) => {
                          //     return {
                          //       ...prev,
                          //       email: value?.trim(),
                          //     };
                          //   });
                          // }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Gender
                        </FormLabel>
                        <RadioGroup
                          row
                          disabled={!isEdit}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={userState.gender}
                          name="gender"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="F"
                            //name="gender"
                            disabled={!isEdit}
                            control={<Radio />}
                            label="Female"
                          />
                          <FormControlLabel
                            value="M"
                            //name="gender"
                            disabled={!isEdit}
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="O"
                            //name="gender"
                            disabled={!isEdit}
                            control={<Radio />}
                            label="Other"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12}>
                        {/* <FormLabel id="outlined-basic-name">
                          Date of Birth
                        </FormLabel>
                        <TextField
                          sx={{ marginTop: "10px" }}
                          id="outlined-basic-name"
                          disabled={!isEdit}
                          fullWidth
                          variant="outlined"
                          type="date"
                          name="dob"
                          value={formatDate(new Date(userState.dob))}
                          onChange={handleChange}
                        /> */}
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          required
                        >
                          <DesktopDatePicker
                            required
                            fullWidth
                            label="Date of Birth *"
                            inputFormat="MMM/dd/yyyy"
                            disabled={!isEdit}
                            // minDate={}
                            // minDate={}
                            value={userState.dob}
                            name="dob"
                            onChange={(newValue) => {
                              console.log("e.target.name", newValue);
                              setUserState((prevState) => ({
                                ...prevState,
                                dob: newValue,
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic-name"
                          disabled={!isEdit}
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Phone Number"
                          name="phone"
                          value={userState.phone}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-country-select-label">
                            Role
                          </InputLabel>
                          <Select
                            // disabled={toEdit}
                            // inputProps={{ readOnly: true }}
                            // inputProps={
                            //   isEditPage ? { readOnly: inputProps } : fromLeadViewPage ? { readOnly: true } : inputProps
                            // }
                            disabled={
                              currentUser?.role === "SUPER_ADMIN"
                                ? true
                                : !isEdit
                            }
                            labelId="demo-role-select-label"
                            id="demo-role-select"
                            label="Role"
                            name="role"
                            value={userState.role}
                            onChange={handleChange}
                          >
                            {roles.map((role, index) => {
                              return (
                                <MenuItem key={index} value={role.roleName}>
                                  {role.roleName}
                                </MenuItem>
                              );
                            })}
                            {currentUser?.role === "SUPER_ADMIN" ? (
                              !isTenant ? (
                                <MenuItem key={0} value={"SUPER_ADMIN"}>
                                  {"Super Admin"}
                                </MenuItem>
                              ) : (
                                <MenuItem key={0} value={"ADMIN"}>
                                  {"Admin"}
                                </MenuItem>
                              )
                            ) : null}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default UserView;
