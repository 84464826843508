import { useContext } from "react";
import { AuthContext } from "../context/auth.context";
import { pluralize } from "./convertToPlural";
import { Tooltip } from "@mui/material";

export const useMatchCollectionName = (name) => {
  const { entityName, setEntityName } = useContext(AuthContext);

  for (let i = 0; i < entityName?.length; i++) {
    let obj = entityName[i];

    if (obj?.collectionName.toLowerCase() === name?.toLowerCase()) {
      return obj;
    }
  }

  return null;
};

export const useFindMatchTitle = (navConfig) => {
  const { entityName, setEntityName } = useContext(AuthContext);
  const result = navConfig.map((item) => {
    const match = entityName.find(
      (entity) => entity.collectionName === item.title
    );

    return match
      ? {
          ...item,
          // title: pluralize(match.displayName.singularName.trim()),
          title: match.displayName.singularName.trim(),
          icon: (
            <>
              <Tooltip
                // title={pluralize(match.displayName.singularName.trim())}
                title={match.displayName.singularName.trim()}
                placement="right-start"
              >
                {item.icon.props.children}
              </Tooltip>
            </>
          ),
        }
      : item;
  });

  return result;
};

export const useSearchCollectionName = (searchValue) => {
  const { entityName, setEntityName } = useContext(AuthContext);
  for (let i = 0; i < entityName.length; i++) {
    let obj = entityName[i];

    if (
      obj.displayName.singularName === searchValue ||
      obj.displayName.pluralName === searchValue
    ) {
      return obj.collectionName === "Companies"
        ? "Company"
        : obj.collectionName;
    }
  }

  return searchValue;
};
