import React, { useContext } from "react";
import {
  Grid,
  Card,
  Box,
  Button,
  Typography,
  Container,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import EventBus from "../../components/texteditor/EventBus";
import Page from "../../components/UI/Page";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateAction from "../../components/Shared/Action/Actions.Create";

import { AuthContext } from "../../context/auth.context";
import ProjectInfo from "../../components/Projects/ProjectInfo";
import FileUpload from "../../components/Fileupload";
import FileList from "../../components/FileList";
import AddIcon from "@mui/icons-material/Add";

import { createProject } from "../../services/projects.service";

import CircularProgress from "@mui/material/CircularProgress";
import { useDynamicProjectTitle } from "./Projects";
import { useDynamicLeadTitle } from "../Leads/Leads";
import { useDynamicContactTitle } from "../Contacts/Contacts";
import { useState } from "react";

let afterCreate = false;
const ProjectCreate = () => {
  const leadDynamicTitle = useDynamicLeadTitle();
  const contactDynamicTitle = useDynamicContactTitle();
  const projectDynamicTitle = useDynamicProjectTitle();
  const { currentUser } = useContext(AuthContext);
  let initialLeadState = {
    summary: "",
    skills: "",
    techOwner: "",
    description: "",
    files: [],
  };
  //TODO: Remove the curly braces
  const [lead, setLead] = React.useState(initialLeadState);
  const [customAttributes, setCustomAttributes] = useState([]);

  let initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    dueDate: new Date(),
    // dueTime: new Date(),
  };
  const [action, setAction] = React.useState(initialActionState);

  const initialContactState = {
    summary: "",
    _id: "",
    contactId: "",
    contact_name: "",
  };

  const [contact, setContact] = React.useState(initialContactState);
  const [files, setFiles] = React.useState([]);

  const initialCommentState = {
    comment: "",
    createdBy: "",
  };

  const [comment, setComment] = React.useState(initialCommentState);
  const [resource, setResource] = React.useState([]);

  const [initialProjectState, setInitialProjectState] = React.useState({
    contactId: "",
    leadId: "",
    techOwner: "",
    title: "",
    details: "",
    timeline: {
      noOfRes: 0,
      committedOn: new Date(),
      deadline: new Date().setDate(new Date().getDate() + 1),
    },
    techStack: [],
    team: [],
    specification: "",
    documents: [],
    action: {
      toDo: "",
      doer: "",
      assignedBy: currentUser._id,
      dueDate: new Date(),
    },
    status: "Waiting to start",
  });

  let hitApi = true;

  const navigate = useNavigate();

  const [project, setProject] = React.useState(initialProjectState);

  const [toEdit, setToEdit] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [clearDocument, setClearDocument] = React.useState(false);
  const [descriptionObj, setDescriptionObj] = React.useState({});

  const ResetForm = () => {
    setContact(initialContactState);
    setLead(initialLeadState);
    setAction(initialActionState);
    EventBus.dispatch("EditorBus");
    // project.timeline.noOfRes = 0;
    // project.timeline.committedOn = new Date();
    // project.timeline.deadline = new Date().setDate(new Date().getDate() + 1);
    setProject((prev) => ({ ...prev, ...initialProjectState }));

    setResource([]);
  };

  const [isEditPage] = React.useState(false);
  const [createContactShowTimer, setCreateContactShowTimer] =
    React.useState(false);
  const notify = () =>
    toast(
      `${projectDynamicTitle?.displayName.singularName} successfully created!`
    );

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function dateChange(date) {
    console.log(date);
    const dateTimeInSeconds = new Date(date).getTime();
    console.log(dateTimeInSeconds);
    const offSet = new Date().getTimezoneOffset();
    console.log(offSet);
    const offSetInSeconds = offSet * 60 * 1000;
    console.log(offSetInSeconds);
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    console.log(newDateInSeconds);
    const newDate = new Date(newDateInSeconds);
    console.log(newDate);
    const newDateFormat = formatDate(newDate);
    console.log(newDateFormat);
    return newDateFormat;
  }

  function formatDate(date) {
    console.log(date);
    if (!createContactShowTimer) {
      date = new Date(date);
    }
    date = new Date(date);

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":") +
      ":00" +
      ".000Z"
    );
  }

  // const handleDateFormat = (dueDate) => {
  //   var updatedDateFormat = new Date(dueDate);
  //   var month = updatedDateFormat.getMonth() + 1;
  //   var date = updatedDateFormat.getDate();
  //   var year = updatedDateFormat.getFullYear();
  //   var updatedDate = "" + month + "/" + date + "/" + year;
  //   return updatedDate;
  // };

  // const handleTimeFormat = (dueTime) => {
  //   let modifiedHour = "";
  //   let timeDesignator;
  //   var updatedTimeFormat = new Date(dueTime);
  //   var hour = updatedTimeFormat.getHours();
  //   modifiedHour = hour > 12 ? hour - 12 : hour;
  //   timeDesignator = hour >= 12 ? "PM" : "AM";
  //   var minute = updatedTimeFormat.getMinutes();
  //   var updatedTime =
  //     "" +
  //     (modifiedHour >= 10 ? modifiedHour : "0" + modifiedHour) +
  //     ":" +
  //     (minute >= 10 ? minute : "0" + minute) +
  //     " " +
  //     timeDesignator;
  //   console.log("updatedTime", updatedTime);
  //   return updatedTime;
  // };

  const uploadHandler = (event) => {
    // const file = event.target.files[0];
    // const file = Array.from(event.target.files);
    let tempFiles = [];
    [...event.target.files].map((file, i) => {
      file["description"] = "";
      tempFiles.push(file);
    });
    setFiles((prev) => {
      return [...prev, ...tempFiles];
    });
    setOpen(() => {
      return true;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFiles((prev) => (prev = []));
    setDescriptionObj({});
    setOpen(false);
  };

  const submitFile = async () => {
    // try {
    //   const response = await uploadDocument(contactId, id, files);
    //   console.log("Response file upload", response);
    //   if (response && response.data.status) {
    //     toast("Documents uploaded sucessfully");
    //     handleClose();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    const temp = { ...project };
    Object.keys(descriptionObj).forEach((eleInd) => {
      files[parseInt(eleInd)].description =
        descriptionObj[parseInt(eleInd)].trim();
    });
    temp.documents.length > 0
      ? temp.documents.push(...files)
      : temp.documents.push(...files);
    setProject(temp);
    handleClose();
  };

  const validate = (data) => {
    return data === "" ||
      data === undefined ||
      data === null ||
      data.length === 0
      ? false
      : true;
  };

  const validateAction = (toDo, doer) => {
    let result;
    if (toDo === "" && doer === "") {
      result = true;
    } else if (toDo !== "" && doer !== "") {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const validateDate = (startDate, dueDate) => {
    return null;
  };

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const handleCreate = async () => {
    if (!validate(project.leadId)) {
      toast(
        `${leadDynamicTitle?.displayName.singularName} summary can not be empty`
      );
      hitApi = false;
    }
    if (!validate(contact.contact_name)) {
      toast(
        `${contactDynamicTitle?.displayName.singularName} name can not be empty`
      );
      hitApi = false;
    }
    if (!validate(project.title)) {
      toast("Title can not be empty");
      hitApi = false;
    }
    if (!validate(project.techStack)) {
      toast("Techstack can not be empty");
      hitApi = false;
    }
    if (!validate(project.details)) {
      toast("Details can not be empty");
      hitApi = false;
    }
    if (!validate(project.specification)) {
      toast("Specification can not be empty");
      hitApi = false;
    }
    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      return;
    }
    if (
      validate(project.leadId) &&
      validate(contact.contact_name) &&
      validate(project.title) &&
      validate(project.techStack) &&
      validate(project.details) &&
      validate(project.specification) &&
      validateAction(action.toDo, action.doer)
    ) {
      hitApi = true;
    }
    console.log("Contact", contact);
    console.log("Button clicked");
    console.log("Documents", project.documents);
    let temp = { ...project, attributes: [...customAttributes] };
    console.log("Temp", temp);
    temp.contactId = contact.contact_id;
    temp.team = [...resource];
    // temp.documents = [...project.documents];
    temp.timeline.noOfRes = temp.team.length;
    temp.timeline.committedOn = formatDate(temp.timeline.committedOn);
    temp.timeline.deadline = formatDate(temp.timeline.deadline);
    temp.techStackArray = [...temp.techStack];
    delete temp.techStack;
    if (
      action.toDo !== null &&
      action.doer !== null &&
      action.dueDate !== null &&
      action.toDo !== undefined &&
      action.doer !== undefined &&
      action.dueDate !== undefined &&
      action.toDo !== "" &&
      action.doer !== "" &&
      action.dueDate !== ""
    ) {
      if (!createContactShowTimer) {
        // action.dueDate = action.dueDate.toDateString();
        action.dueDate = new Date(action.dueDate).toDateString();
      }
      temp = { ...temp, action };
      temp.action.dueDate = dateChange(temp.action.dueDate);
      temp.action.schedule = checked;
    } else {
      delete temp.action;
    }
    console.log("Temp", temp);
    const data = new FormData();
    let flag = false;
    if (temp.documents.length > 0) {
      let indexes = [];
      let descriptionFiles = [];
      let keys = Object.keys(temp);
      keys.forEach((key) => {
        console.log(key);
        if (key !== "documents")
          data.append(
            key,
            typeof temp[key] === "object"
              ? JSON.stringify(temp[key])
              : temp[key]
          );
      });
      temp.documents.map((file, index) => {
        if (file.checked) {
          indexes.push(index);
        }
        if (file.description?.length) {
          descriptionFiles.push({
            fileIndex: index,
            description: file.description,
          });
        }
        data.append("docFile", file);
      });
      data.append("checkedIndexes", JSON.stringify(indexes));
      data.append("descriptionFiles", JSON.stringify(descriptionFiles));
      flag = true;
    } else {
      delete temp.documents;
    }
    // data.set("timeline", JSON.stringify(data.get("timeline")));
    try {
      if (hitApi) {
        setSaveButton(true);
        setLoaderDialog(true);
      }
      console.log("Inside try");
      const response = hitApi
        ? await createProject(flag ? data : temp, flag)
        : null;
      if (response.data.status) {
        setCustomAttributes([]);
        setClearDocument(true);
        ResetForm();
        afterCreate = !afterCreate;
        notify();
        setSaveButton(false);
        setLoaderDialog(false);
      }
      console.log(response);
      ResetForm();
      setChecked(false);
      console.log(temp);
    } catch (error) {
      console.log(error);
      setSaveButton(false);
      setLoaderDialog(false);
      if (error.response.data.message) {
        toast(error.response.data.message);
      }
      toast(error.response.data.data[0]);
      //   if (
      //     error.response.data.data[0] ===
      //     '"lead.summary" is not allowed to be empty'
      //   ) {
      //     toast('"summary" is not allowed to be empty');
      //   } else {
      //     toast(error.response.data.data[0]);
      //   }
      // }
    }
  };

  const removeFile = (index) => {
    console.log("2/1/23", index);
    setProject((prev) => {
      const temp = { ...prev };
      temp.documents.splice(index, 1);
      return temp;
    });
  };

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={4}>
          <Grid item>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${projectDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ProjectInfo
                      setProject={setProject}
                      project={project}
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      canEdit={isEditPage}
                      fromCreatePage={true}
                      resource={resource}
                      setResource={setResource}
                      contact={contact}
                      setContact={setContact}
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      afterCreate={afterCreate}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTION
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <CreateAction
                          createContactShowTimer={createContactShowTimer}
                          setCreateContactShowTimer={setCreateContactShowTimer}
                          setAction={setAction}
                          action={action}
                          isEditPage={isEditPage}
                          setPage={() => {}}
                          checked={checked}
                          setChecked={setChecked}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={9} sm={9}>
                    <Typography variant="h5" gutterBottom color="primary">
                      DOCUMENTS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <div className="file-cards">
                      <div className="file-inputs">
                        {/* <input
                          type="file"
                          multiple="multiple"
                          onChange={uploadHandler}
                        /> */}

                        <Button
                          variant="contained"
                          size="medium"
                          startIcon={<AddIcon />}
                          onClick={handleClickOpen}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}>
                            <FileList
                              // files={project.documents}
                              clearDocument={clearDocument}
                              fileList={project.documents}
                              fromProjectCreatePage={true}
                              removeFile={removeFile}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Dialog maxWidth="md" open={open} onClose={handleClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {/* <Button>Save</Button> */}
                    <Button onClick={submitFile}>Save</Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
          <Dialog open={loaderDialog}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              Loading... Please Wait
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
      {/* <Card style={{marginTop:"20px",display:"flex",flexDirection:"row-reverse"}}> */}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          disabled={saveButton}
          onClick={handleCreate}
          style={{ marginRight: "28px" }}
          variant="contained"
        >
          CREATE
        </Button>
      </div>
      {/* </Card> */}
    </Page>
  );
};

export default ProjectCreate;
