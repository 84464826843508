module.exports = {
    getServerUrl: (env) => {
        if (env == "STAGING")
            return "https://leadstaging.utkallabs.com/api"
        else if (env == "PRODUCTION")
            return "https://leads.utkallabs.com/api"
        else if (env == "CLIENT")
            return "https://tryulalpha.utkallabs.com/api"
        else
            return "http://localhost:3022/api"
    },
    getGoogleClient: () => {
        return "589317535878-rn7dgmfidamuuvrqgmemhetr90uvmv6g.apps.googleusercontent.com";
    }
}