import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NameViewCard from "./NameViewCard";
import {
  createDynamicActionName,
  deleteActionNamePermanently,
  getActionNames,
  updateDynamicActionName,
} from "../../services/common.service";
import { toast } from "react-toastify";
import DeleteConfirmationDialog from "../Dialogs/DeleteConfirmationDialog";

var nameReg = /^[a-zA-Z\s]+$/;

function ActionNameTab() {
  const [actionNames, setActionNames] = useState({
    static: [],
    dynamic: [],
  });

  const [loaderDialog, setLoaderDialog] = useState(false);

  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    actionNameId: "",
    onCancel: () => {
      setDeleteDialogState((prev) => ({
        ...prev,
        open: false,
        actionNameId: "",
      }));
    },
  });

  const [dialogState, setDialogState] = useState({
    open: false,
    allowOpen: () => {
      setDialogState((prev) => ({ ...prev, open: true }));
    },
    actionName: "",
    actionId: "",
    isEditButtonClicked: false,
    onEditButtonClick: (actionNameDetails) => {
      if (actionNameDetails) {
        setDialogState((prev) => ({
          ...prev,
          open: true,
          isEditButtonClicked: true,
          actionId: actionNameDetails._id,
          actionName: actionNameDetails.actionName,
        }));
      }
    },
    onClose: () => {
      setDialogState((prev) => ({
        ...prev,
        open: false,
        actionName: "",
        actionId: "",
      }));
      setTimeout(() => {
        setDialogState((prev) => ({
          ...prev,
          isEditButtonClicked: false,
        }));
      }, 300);
    },
  });

  const handleActionName = (event) => {
    let {
      target: { name, value },
    } = event;
    if (value && !value?.trim()) {
      toast.warn("Action Name can not contain only space");
      return;
    }
    if (value && value?.trim()) {
      if (nameReg.test(value)) {
        if (value.length > 30) {
          toast("Action Name can not be more than 30");
          return;
        }
        setDialogState((prev) => ({ ...prev, [name]: value }));
        return;
      }
      toast("Numbers and Special Characters not allowed");
      return;
    }
    setDialogState((prev) => ({ ...prev, [name]: value }));
  };

  function checkNameBeforeExistsOrNot() {
    let temp = [...actionNames.dynamic, ...actionNames.static];
    let existingNameIndex = temp.findIndex(
      (action) => action.actionName === dialogState.actionName?.trim()
    );
    if (existingNameIndex == -1) {
      return true;
    }
    return false;
  }

  async function handleEdit() {
    const allowToProgress = checkNameBeforeExistsOrNot();
    if (!allowToProgress) {
      toast("Action Name Already Exists");
      return;
    }
    setLoaderDialog(true);
    try {
      const response = await updateDynamicActionName(dialogState.actionId, {
        actionName: dialogState.actionName,
      });
      if (response) {
        toast("Action Name Updated Successfully");
        getAllActionNames();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoaderDialog(false);
      dialogState.onClose();
    }
  }

  const checkActionNameExistsOrNot = () => {
    let prevActionName = actionNames.dynamic.find(
      (an) => an._id === dialogState.actionId
    );
    if (
      prevActionName?.actionName &&
      prevActionName?.actionName === dialogState.actionName
    ) {
      toast("Nothing To Update");
      throw new Error("Already Exists");
    }
    handleEdit();
  };

  async function handleSave() {
    if (!dialogState.actionName) {
      toast("Action Name can not be empty");
      return;
    }
    if (dialogState.isEditButtonClicked) {
      checkActionNameExistsOrNot();
      return;
    }
    const allowToProgress = checkNameBeforeExistsOrNot();
    if (!allowToProgress) {
      toast("Action Name Already Exists");
      return;
    }
    try {
      setLoaderDialog(true);
      const result = await createDynamicActionName({
        actionName: dialogState.actionName,
      });
      if (result.data.status) {
        toast("Action Name Created Successfully");
        getAllActionNames();
      }
    } catch (error) {
      console.log(error);
    } finally {
      dialogState.onClose();
      setLoaderDialog(false);
    }
  }

  async function getAllActionNames() {
    try {
      const response = await getActionNames();
      if (response.data.status) {
        setActionNames((prev) => ({
          ...prev,
          static: [...response.data.data.filter((action) => action.isStatic)],
          dynamic: [...response.data.data.filter((action) => !action.isStatic)],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteActionName() {
    setLoaderDialog(true);
    try {
      const response = await deleteActionNamePermanently(
        deleteDialogState.actionNameId
      );
      if (response.data.status) {
        toast("Action Name Deleted Successfully");
        getAllActionNames();
      }
    } catch (error) {
      console.log(error);
    } finally {
      deleteDialogState.onCancel();
      setLoaderDialog(false);
    }
  }

  useEffect(() => {
    getAllActionNames();
  }, []);

  return (
    <>
      {!actionNames.dynamic.length && !actionNames.static.length ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" fontFamily="inherit">
              Static Action Names
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {actionNames.static.map((action, index) => (
                <Grid item xs={2.4} key={index}>
                  <NameViewCard name={action.actionName} key={index} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Typography variant="h5" fontFamily="inherit" flexGrow={1}>
              Dynamic Action Names
            </Typography>
            <Button variant="contained" onClick={dialogState.allowOpen}>
              CREATE ACTION NAME
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {actionNames.dynamic.length ? (
                actionNames.dynamic.map((action, index) => (
                  <Grid item xs={4} key={index}>
                    <NameViewCard
                      name={action.actionName}
                      key={index}
                      fromDynamic={true}
                      onEditButtonClick={() => {
                        dialogState.onEditButtonClick(action);
                      }}
                      onDeleteButtonClick={() => {
                        setDeleteDialogState((prev) => ({
                          ...prev,
                          open: true,
                          actionNameId: action._id,
                        }));
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography textAlign="center" variant="h6">
                    No dynamic action names have been created so far.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Dialog open={dialogState.open} fullWidth maxWidth="xs">
            <DialogTitle>Action Name</DialogTitle>
            <DialogContent dividers>
              <TextField
                autoComplete="off"
                label="Action Name"
                placeholder="Action Name"
                fullWidth
                name="actionName"
                value={dialogState.actionName}
                onChange={handleActionName}
              />
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={dialogState.onClose}>
                CANCEL
              </Button>
              <Button onClick={handleSave}>
                {dialogState.isEditButtonClicked ? "SAVE" : "CREATE"}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={loaderDialog}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              Loading... Please Wait
            </DialogActions>
          </Dialog>
          <DeleteConfirmationDialog
            opened={deleteDialogState.open}
            title={"Action Name"}
            handleCancel={deleteDialogState.onCancel}
            handleDelete={deleteActionName}
          />
        </Grid>
      )}
    </>
  );
}

export default ActionNameTab;
