import React, { useEffect } from "react";
import Page from "../components/UI/Page";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { getUsersByRoles } from "../services/users.service";
import InvoiceReportFilterOptions from "../components/Reports/InvoiceReportFilterOptions";
import InvoiceReportTable from "../components/Reports/InvoiceReportTable";
import { toast } from "react-toastify";
import { getInvoiceReport } from "../services/report.service";
import SummaryTable from "./SummaryTable";

const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const headingsCol = ["Total chargable", "Total Collected", "Total Pending"];
function InvoiceReport() {
  const [state, setState] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    salesOwners: [],
    users: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [invoiceReports, setInvoiceReports] = useState([]);

  const handleChange = (event) => {
    let {
      target: { name, value },
    } = event;
    if (Array.isArray(value)) {
      if (state.users.length < value.length) {
        setState((prev) => ({
          ...prev,
          [name]: [],
        }));
        return;
      }

      if (value?.includes("ALL")) {
        let allIds = state.users.reduce((accumulator, user) => {
          accumulator.push(user._id);
          return accumulator;
        }, []);
        setState((prev) => ({
          ...prev,
          [name]: allIds,
        }));
        return;
      }
    }
    if (name === "month") {
      const monthIndex = allMonths.indexOf(value);
      if (monthIndex != -1) {
        setState((prev) => ({ ...prev, [name]: monthIndex }));
        return;
      }
    }
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const renderName = (userIds) => {
    if (state.users.length == state.salesOwners.length) return ["ALL"];
    const names = state.users.reduce((acc, user) => {
      if (userIds?.includes(user?._id + "")) {
        acc.push(user.name);
      }
      return acc;
    }, []);
    return names.join(", ");
  };

  const formatMonthAndYear = () => {
    let { month, year } = state;
    return {
      from: new Date(`01-${allMonths[month]}-${year}`),
      to: new Date(`31-${allMonths[month]}-${year}`),
    };
  };

  const handleFetch = async () => {
    try {
      if (!state.salesOwners.length) {
        toast("Sales Owner can not be empty");
        return;
      }
      const interval = formatMonthAndYear(state.interval);
      const response = await getInvoiceReport(interval, state.salesOwners);
      if (response.data.status) {
        setPage(0);
        if (response.data.data?.length) {
          setInvoiceReports([...response.data.data]);
          return;
        }
        setInvoiceReports([]);
        toast("No Invoice Reports Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const provideMonthAndYearListing = (type) => {
    switch (type) {
      case "month":
        if (state.year < new Date().getFullYear()) {
          return allMonths;
        }
        return allMonths.slice(0, new Date().getMonth() + 1);
        break;

      case "year":
        let numberOfIndex = 0;
        let years = [];
        while (!years.includes(2022)) {
          years.push(new Date().getFullYear() - numberOfIndex);
          numberOfIndex++;
        }
        return years;
        break;
      default:
        return [];
        break;
    }
  };

  const totalCalculations = invoiceReports.reduce(
    (accumulator, report) => {
      if (report) {
        let { amountTotal, paid, dueAmount } = report;
        accumulator.chargableTotal += amountTotal;
        accumulator.collectedAmount += paid;
        accumulator.pendingAmount += dueAmount;
        return accumulator;
      }
    },
    {
      chargableTotal: 0,
      collectedAmount: 0,
      pendingAmount: 0,
    }
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsersByRoles(3);
        if (response.data.status) {
          setState((prev) => ({ ...prev, users: [...response.data.data] }));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  console.log("invoiceReports", invoiceReports);
  return (
    <Page title="Invoice Report">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <InvoiceReportFilterOptions
                  allMonths={allMonths}
                  state={state}
                  monthYearListing={provideMonthAndYearListing}
                  handleChange={handleChange}
                  renderName={renderName}
                  handleFetch={handleFetch}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ paddingLeft: 2, paddingRight: 2 }}>
              {invoiceReports.length ? (
                <InvoiceReportTable
                  dataView={invoiceReports}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              ) : (
                <Typography variant="h6" textAlign="center" m={"20px"}>
                  No Collection Reports
                </Typography>
              )}
            </Card>
          </Grid>
          {invoiceReports.length ? (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={4}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography width="52%" color="#2065d1">
                          Total Chargable Amount:
                        </Typography>
                        <Typography
                          textAlign="left"
                          width="48%"
                          fontWeight={550}
                        >
                          {isNaN(totalCalculations.chargableTotal?.toFixed(2))
                            ? 0
                            : totalCalculations.chargableTotal?.toFixed(2)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography width="51%" color="#2065d1">
                          Total Collected Amount:
                        </Typography>
                        <Typography width="49%" fontWeight={550} color="green">
                          {totalCalculations.collectedAmount || 0}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography width="48%" color="#2065d1">
                          Total Pending Amount:
                        </Typography>
                        <Typography width="52%" fontWeight={550} color="red">
                          {isNaN(totalCalculations.pendingAmount?.toFixed(2))
                            ? 0
                            : totalCalculations.pendingAmount?.toFixed(2)}
                        </Typography>
                      </Stack>
                    </Grid> */}

                    <SummaryTable invoiceReports={invoiceReports} />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Page>
  );
}

export default InvoiceReport;
