import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Checkbox,
  ListItemText,
  Chip,
  Autocomplete,
  ButtonGroup,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { getIncludedCountries } from "../../services/contacts.service";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
export default function FilterEmail({
  contactEmail,
  setcontactEmail,
  activeStep,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showChip,
  setShowChip,
  setCustomDate,
  initialIntervals,
  intervals,
  setIntervals,
  setPopup,
  getfilterContactEmails,
  country,
  setCountry,
  setChecked = [],
  setIsLoading,
  interval,
  setInterval,
  searchText,
  setSearchText,
  page,
  setPage,
  stableContactEmails,
  getCoantactEmails,
}) {
  const [countries, setCountries] = useState([]);

  const [isEmpty, setIsEmpty] = React.useState(true);

  const getAllCountries = async () => {
    try {
      const response = await getIncludedCountries();
      if (response.data.status) {
        const countries = response.data.data.filter(
          (country) => country !== ""
        );
        const uniqueCountries = [...new Set(countries)];
        setCountries(uniqueCountries);
        setCountry(uniqueCountries); // Set all countries selected by default
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeStep === 2) getAllCountries();
  }, [activeStep]);

  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("ALL")) {
      setCountry(countries.length === country.length ? [] : countries);
    } else {
      setCountry(typeof value === "string" ? value.split(",") : value);
    }
  };
  const formatCustomDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };
  const afterRemoveChip = () => {
    setCustomDate(false);
    setShowChip(false);
    setStartDate(new Date().setDate(new Date().getDate() - 1));
    setEndDate(new Date());
    setIntervals(initialIntervals[0]);
    // setPage(0);
    setInterval("");
  };
  const clearSelectColumn = async () => {
    setIntervals(() => initialIntervals[0]);
    getAllCountries();
    setIsEmpty(true);
    afterRemoveChip();
    setChecked([]);
    setSearchText("");
    setIsLoading(true);
    // getfilterContactEmails(true);
    getCoantactEmails();
    setIsLoading(false);
  };

  const allSelected = country.length === countries.length;
  const handleIntervalChange = (e, newvalue) => {
    if (newvalue.value !== "Custom" && interval === "Custom") {
      toast("Close the chip to select another interval");
      return;
    }
    if (newvalue.value === "Custom") {
      setPopup(true);
    }
    setIntervals(() => newvalue);
    setInterval(newvalue.value);
  };
  const handleClear = () => {
    setcontactEmail(stableContactEmails);
    setSearchText("");
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={2.5}>
        <FormControl fullWidth size="small">
          <InputLabel>Countries</InputLabel>
          <Select
            sx={{ maxHeight: "40px" }}
            multiple
            name="country"
            label="Countries"
            value={country}
            renderValue={(selected) =>
              selected.length === countries.length ? "ALL" : selected.join(", ")
            }
            onChange={handleCountryChange}
          >
            <MenuItem value="ALL">
              <Checkbox checked={allSelected} />
              <ListItemText primary="ALL" />
            </MenuItem>
            {countries.map((c, index) => (
              <MenuItem key={index} value={c}>
                <Checkbox checked={country.includes(c)} />
                <ListItemText primary={c} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2.5}>
        {showChip ? (
          <Chip
            size="medium"
            label={`${formatCustomDate(
              new Date(startDate).toDateString()
            )} - ${formatCustomDate(new Date(endDate).toDateString())}`}
            variant="outlined"
            onDelete={afterRemoveChip}
          />
        ) : (
          <Autocomplete
            id="size-small-outlined"
            size="small"
            options={initialIntervals}
            getOptionLabel={(option) => option.label}
            value={intervals}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Interval"
                placeholder="Select Interval"
                name="interval"
              />
            )}
            onChange={handleIntervalChange}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <ButtonGroup variant="contained" aria-label="Basic button group">
          <Button
            variant="contained"
            // startIcon={<ClearIcon />}
            onClick={clearSelectColumn}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            // startIcon={<SearchIcon />}
            onClick={() => {
              if (country.length) {
                setIsLoading(true);
                getfilterContactEmails(true);

                setPage(0);
                return;
              }
              toast.error("Please select at least one country");
            }}
          >
            Fetch
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={5}>
        <TextField
          size="small"
          fullWidth
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={(e) => {
            if (!e.target.value) {
              setcontactEmail(stableContactEmails);
            }
            setPage(0);
            setSearchText(e.target.value);
          }}
          InputProps={{
            endAdornment: searchText ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClear}
                  edge="end"
                  disabled={!searchText} // Disable the button if the field is already empty
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : (
              ""
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
