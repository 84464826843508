import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Badge,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { capitalizedString } from "../../utils/capitalizedString";
import { toast } from "react-toastify";
import { updateEntityName } from "../../services/common.service";
import { hasSpecialCharacter } from "../../utils/hasSpecialCharacter";
import { validateStringLength } from "../../utils/validateStringLength";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createAttribute,
  removeAttribute,
  updateAttribute,
} from "../../services/attributes.service";
export default function AttributeTab({
  entityName,
  attributeList,
  setAttributeList,
  isEntityDialougeOpne,
  setIsEntityDialougeOpne,
  getEntityname,
  inputChangeData,
  setInputChangeData,
  hitApi,
  setIsAttributeListFetched,
}) {
  console.log("entityNamedhfjdhf", entityName);
  const navigate = useNavigate();

  const initialAttributeForm = {
    name: "",
    values: [],
    uiType: "",
    // dataType: "",
    isRequired: false,
    entityName: "",
  };

  const initialEntityName = {
    collectionName: "",
    displayName: {
      singularName: "",
      pluralName: "",
      editedName: "",
    },
    id: "",
    toEdit: false,
    entityId: "",
  };
  const [entityNameForAttributeCreation, setEntityNameForAttributeCreation] =
    useState("");
  const [isEditAttributeData, setIsEditAttributeData] = useState(false);
  const [attributeForm, setAttributeForm] = useState(initialAttributeForm);
  const [hoveredIcon, setHoveredIcon] = useState(false);
  const [openAttributeDialog, setOpenAttributeDialog] = useState(false);
  const [viewCollectionName, setViewCollectionName] = useState("");

  const [delteAttributrdata, setDelteAttributrdata] = useState(null);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [attributeDialog, setAttributeDialog] = useState(false);
  const [editEntityForm, setEditEntityForm] = useState(initialEntityName);
  const [editAttributeForm, setEditAttributeForm] =
    useState(initialAttributeForm);
  const [expanded, setExpanded] = React.useState(null);
  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const handleEditEntity = (event, entity, index) => {
    setIsEntityDialougeOpne(true);

    const temp = { ...editEntityForm };
    temp.collectionName = entity.collectionName;
    temp.displayName.singularName = entity.displayName.singularName;
    temp.displayName.editedName = entity.displayName.singularName;
    temp.displayName.pluralName = entity.displayName.pluralName;
    temp.toEdit = true;
    temp.entityId = entity.entityId;
    setEditEntityForm(temp);
  };

  const handleEntityOnchange = (e, index) => {
    setEditEntityForm((prev) => {
      const temp = { ...prev, editEntityForm };

      if (temp.collectionName === e.target.name) {
        temp.displayName.editedName = e.target.value;
      }
      return temp;
    });
  };

  const handleSaveEntity = async (e, index) => {
    const inputData = editEntityForm.displayName.editedName.trim();
    if (inputData === "" || inputData === undefined || inputData === null) {
      toast("Empty space are not allowed");
      return;
    }
    if (
      editEntityForm.displayName.singularName ===
      editEntityForm.displayName.editedName
    ) {
      toast("Nothig to update");
      return;
    }

    let id = editEntityForm.entityId;
    let bodyData = {
      [editEntityForm.collectionName]: {
        singularName: editEntityForm?.displayName.editedName,
        pluralName: editEntityForm?.displayName.singularName,
      },
    };

    try {
      const response = await updateEntityName(id, bodyData);
      console.log("response1234", response);
      if (response.data.status) {
        getEntityname();
        toast("Entity name updated successfully");
        setIsEntityDialougeOpne(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleCancelEntity = (index) => {
    setIsEntityDialougeOpne(false);
  };

  // Attribute

  const handleChangeAttribute = (e) => {
    let name = e.target.name;

    if (!isEditAttributeData) {
      setAttributeForm((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }

    setEditAttributeForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputChange = (event, newInputValue) => {
    setInputChangeData(newInputValue);
  };

  const handleEditAttribute = (attribute, index) => {
    setIsEditAttributeData(true);
    const temp = { ...editAttributeForm };
    temp.entityName = attribute.entityName;
    temp.isRequired = attribute.isRequired;
    temp.name = attribute.name;
    temp.uiType = attribute.uiType;
    temp.values = attribute.values;
    temp._id = attribute._id;
    temp.metaEntityId = attribute.metaEntityId;
    setEditAttributeForm(temp);
    setOpenAttributeDialog(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputChangeData.trim() !== "") {
      let triminputdata = inputChangeData.trim();
      let lowerAttributeValue = attributeForm.values.map((ele) =>
        ele.toLowerCase()
      );
      if (lowerAttributeValue.includes(triminputdata.toLocaleLowerCase())) {
        toast("You can not add tags with duplicate names");
        setInputChangeData("");
      } else {
        setEditAttributeForm((prev) => ({
          ...prev,
          values: inputChangeData.trim(),
        }));
        setInputChangeData("");
      }
    }
  };

  const handleSaveAttribute = async () => {
    function removeExtraWhiteSpace(inputValue) {
      const trimmedValue = inputValue.trim().replace(/\s+/g, " ");

      if (trimmedValue === "") {
        return undefined;
      }

      return trimmedValue;
    }
    let value = removeExtraWhiteSpace(attributeForm.name);
    if (!validate(value)) {
      toast("Attribute name can not be empty");
      hitApi = false;
      return;
    }

    const hasContainSpecialChar = hasSpecialCharacter(attributeForm.name);
    if (hasContainSpecialChar) {
      toast("Special characters are not allowed");
      hitApi = false;
      return;
    }

    const checkMinMaxLengthOfString = validateStringLength(
      attributeForm.name,
      2,
      25
    );

    if (!checkMinMaxLengthOfString) {
      toast("Name should be in between 2 to 25 character");
      return;
    }
    if (!validate(attributeForm.isRequired)) {
      toast("Attribute isRequired can not be empty");
      hitApi = false;
    }

    if (!validate(attributeForm.uiType)) {
      toast("Select Display Type can not be empty");
      hitApi = false;
      return;
    }
    // console.log("handel att", attributeForm);

    if (
      attributeForm.uiType === "AutoCompleteSingle" ||
      attributeForm.uiType === "AutoCompleteMulti" ||
      attributeForm.uiType === "SingleSelectDropdown" ||
      attributeForm.uiType === "MultiSelectDropdown" ||
      attributeForm.uiType === "RadioButton"
    ) {
      if (attributeForm.values.length == 0) {
        toast("Attribute values can not be empty");
        hitApi = false;
        return;
      }
      const hasContainSpecialCharInValues = hasSpecialCharacter(
        attributeForm.values
      );
      if (hasContainSpecialCharInValues) {
        toast("Special characters are not allowed");
        hitApi = false;
        return;
      }
    }
    console.log("handel att", attributeForm);
    try {
      setAttributeForm((prev) => ({
        ...prev,
        entityName: entityNameForAttributeCreation,
      }));
      const response = await createAttribute(attributeForm);
      if (response.data.status) {
        setAttributeForm(initialAttributeForm);
        setIsAttributeListFetched(false);
        setOpenAttributeDialog(false);
        toast("Attribute created successfully");
      }
    } catch (error) {
      if (error.response.data.message === "Attribute Name already Exists") {
        toast("Attribute Name already Exists");
      }
      console.log(error);
    }
  };

  const handleCloseAttributeDialog = () => {
    setEditAttributeForm(initialAttributeForm);
    setAttributeForm(initialAttributeForm);
    setOpenAttributeDialog(false);
    setIsEditAttributeData(false);
  };

  const handleUpdateAttribute = async () => {
    function removeExtraWhiteSpace(inputValue) {
      const trimmedValue = inputValue.trim().replace(/\s+/g, " ");

      if (trimmedValue === "") {
        return undefined;
      }

      return trimmedValue;
    }
    let editedValue = removeExtraWhiteSpace(editAttributeForm.name);
    if (!validate(editedValue)) {
      toast("Attribute name can not be empty");
      hitApi = false;
      return;
    }
    const hasContainSpecialChar = hasSpecialCharacter(editAttributeForm.name);
    if (hasContainSpecialChar) {
      toast("Special characters are not allowed");
      hitApi = false;
      return;
    }

    if (
      editAttributeForm.values.length == 0 &&
      attributeForm.uiType === "AutoComplete"
    ) {
      toast("Autocomplete  can not be empty");
      hitApi = false;
      return;
    }

    const checkMinMaxLengthOfString = validateStringLength(
      editAttributeForm.name,
      3,
      25
    );

    if (!checkMinMaxLengthOfString) {
      toast("Name should be in between 3 to 25 character update");
      return;
    }

    if (
      editAttributeForm.values.length == 0 &&
      attributeForm.uiType === "SingleSelectDropdown"
    ) {
      toast("Single select dropDown can not be empty");
      hitApi = false;
      return;
    }

    try {
      const attributeId = editAttributeForm._id;
      const metaEntityId = editAttributeForm.metaEntityId;
      const updatedFields = {};

      for (const key in editAttributeForm) {
        if (
          editAttributeForm.hasOwnProperty(key) &&
          editAttributeForm[key] !== attributeForm[key]
        ) {
          updatedFields[key] = editAttributeForm[key];
        }
      }

      console.log(
        "updatedFields",
        updatedFields,
        attributeForm,
        editAttributeForm
      );

      if (Object.keys(updatedFields).length === 0) {
        toast("Nothing to update");
        return;
      }
      const response = await updateAttribute(
        attributeId,
        metaEntityId,
        updatedFields
      );
      // const response = null;

      if (response?.data.status) {
        setEditAttributeForm(initialAttributeForm);
        setIsAttributeListFetched(false);
        setOpenAttributeDialog(false);
        toast("Attribute updated successfully");
        setIsEditAttributeData(false);
      }
    } catch (error) {
      if (error.response.data.message === "Attribute Name already Exists") {
        toast("Attribute Name already Exists");
      }
      console.log(error);
    }
  };

  const handleDeleteAttribute = async () => {
    console.log("delteAttributrdata", delteAttributrdata);
    let attribute = delteAttributrdata;
    let metaEntityId = attribute.metaEntityId;
    try {
      // let response;
      const response = await removeAttribute(metaEntityId, attribute._id);
      if (response.data.status) {
        console.log(
          "response.data.data._id",
          response,
          response.data.data[0]._id
        );
        const updatedAttributeList = attributeList.filter(
          (item) => item._id !== response.data.data[0]._id
        );
        setAttributeList(updatedAttributeList);
        toast("Attributes deleted successfully");
        setDeletePopupOpen(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleOpenDeleteDialog = (attribute) => {
    setDelteAttributrdata(attribute);

    setDeletePopupOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeletePopupOpen(false);
  };
  const handelAttributeDialog = (name) => {
    console.log("kjkghentityName1", name);
    setViewCollectionName(name);
    setAttributeDialog((prev) => !prev);
  };
  function cal(attributeList) {
    let companyCount = 0;
    let leadCount = 0;
    let contactCount = 0;
    let projectCount = 0;

    // Iterate through the data array
    attributeList.forEach((item) => {
      switch (item.entityName) {
        case "Companies":
          companyCount++;
          break;
        case "Leads":
          leadCount++;
          break;
        case "Contacts":
          contactCount++;
          break;
        case "Projects":
          projectCount++;
          break;
        default:
          break;
      }
    });

    // Find the smallest count
    const smallestCount = Math.min(
      companyCount,
      leadCount,
      contactCount,
      projectCount
    );
    return smallestCount;
  }

  const handleIconClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  return (
    <Grid container spacing={1}>
      {entityName?.map((entity, index) => (
        <>
          <Grid item xs={12}>
            <Card variant="outlined">
              {/* <CardContent sx={{ width: "100%", height: "100%" }}> */}
              <Accordion
                expanded={expanded === index}
                sx={{ "&:hover": { cursor: "default" } }}
              >
                <AccordionSummary
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  expandIcon={
                    <ExpandMoreIcon
                      onClick={(event) => handleIconClick(index)}
                      onMouseEnter={() => setHoveredIcon(true)}
                      onMouseLeave={() => setHoveredIcon(false)}
                    />
                  }
                >
                  <Grid container>
                    <Grid
                      item
                      xs={1.3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography fontWeight="bold">Entity Name</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1.1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {entity.collectionName}
                    </Grid>

                    <Grid
                      item
                      xs={1.3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography fontWeight="bold">Display Name</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6.5}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {entity.displayName.singularName}
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip
                        title={
                          <Typography variant="body2" sx={{ fontSize: "13px" }}>
                            View Static and Custom attribute
                          </Typography>
                        }
                        arrow
                        placement="top"
                        sx={{ fontSize: "10rem" }}
                      >
                        <IconButton
                          sx={{
                            "&:hover": {
                              color: "#2056D1",
                              background: "transparent",
                            },
                            pl: 0,
                            pr: 0,
                          }}
                          onClick={() =>
                            handelAttributeDialog(entity.collectionName)
                          }
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {entity.collectionName == "Companies" ? (
                        <Tooltip
                          title={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "13px" }}
                            >
                              No Automation rule avalabel for this module
                            </Typography>
                          }
                          arrow
                          placement="top"
                          sx={{ fontSize: "10rem" }}
                        >
                          <span>
                            <IconButton
                              sx={{
                                "&:hover": {
                                  color: "#2065D1",
                                  backgroundColor: "transparent",
                                },
                                pl: 0,
                                pr: 0,
                              }}
                              disabled
                            >
                              <SettingsIcon
                                onClick={(event) =>
                                  navigate(
                                    `/customize/${entity.collectionName}`
                                  )
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <IconButton
                          sx={{
                            "&:hover": {
                              color: "#2065D1",
                              backgroundColor: "transparent",
                            },
                            pl: 0,
                            pr: 0,
                          }}
                        >
                          <SettingsIcon
                            //  navigate(`/Leads/${x._id}/${contactInfoObj._id}`);
                            onClick={(event) =>
                              navigate(`/customize/${entity.collectionName}`)
                            }
                          />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        sx={{
                          "&:hover": {
                            color: "#2065D1",
                            backgroundColor: "transparent",
                          },
                        }}
                        data-testid="companyDisplayName"
                      >
                        <EditIcon
                          onClick={(event) =>
                            handleEditEntity(event, entity, index)
                          }
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={1}
                    sx={
                      {
                        // maxHeight: 32 * cal(attributeList),
                        // overflowY: "auto",
                        // overflowX: "hidden",
                        // maxWidth: 600,
                      }
                    }
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        color="#2056D1"
                        fontWeight="bold"
                        sx={{ mt: 1 }}
                      >
                        Static Attributes
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ mt: 2 }} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={
                        {
                          // display: "flex",
                          // alignItems: "center",
                        }
                      }
                    >
                      {attributeList.map((attribute, index) => {
                        if (
                          attribute.entityName === entity.collectionName &&
                          attribute.isPredefinedAttribute
                        ) {
                          return (
                            <Badge
                              key={index}
                              badgeContent={entity.length ? entity.length : 0}
                              color="secondary"
                              sx={{
                                margin: "1%",

                                borderRadius: "62px",
                              }}
                            >
                              <Icon
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  border: "1px solid #ccc",
                                  borderRadius: "62px",
                                  borderTopLeftRadius: "16px",
                                  borderBottomLeftRadius: "16px",
                                  borderTopRightRadius: "16px",
                                  borderBottomRightRadius: "16px",
                                  padding: "18px",
                                  // marginRight: "2px",

                                  width: "100%",
                                }}
                              >
                                <Typography
                                  component="div"
                                  sx={{
                                    color: "text.disabled",
                                    pointerEvents: attribute.name
                                      ? "auto"
                                      : "none",
                                  }}
                                >
                                  {capitalizedString(attribute.name)}
                                </Typography>
                              </Icon>
                            </Badge>
                          );
                        }
                      })}
                    </Grid>

                    <Grid xs={12} item>
                      <Divider sx={{ mt: 1 }} />
                    </Grid>
                    <Grid
                      xs={11.1}
                      item
                      sx={{
                        // ml: 1,

                        // backgroundColor: "red",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        color="#2056D1"
                        fontWeight="bold"
                        // sx={{ mt: 0, p: 0 }}
                      >
                        Custom Attributes
                      </Typography>
                    </Grid>
                    <Grid
                      xs={0.9}
                      sx={{
                        // ml: 3.2,
                        mt: 1,
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        sx={{ color: "#2056D1" }}
                        onClick={() => {
                          setOpenAttributeDialog(true);
                          setAttributeForm((prev) => ({
                            ...prev,
                            entityName: entity.collectionName,
                          }));
                          setEntityNameForAttributeCreation(
                            entity.collectionName
                          );
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {attributeList.map((attribute, index) => {
                          if (
                            attribute.entityName === entity.collectionName &&
                            !attribute.isPredefinedAttribute
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={9.9}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {attribute.name}
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sx={{
                                    display: "flex",
                                    // alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    sx={{ "&:hover": { color: "#2056D1" } }}
                                    onClick={() => {
                                      handleEditAttribute(attribute, index);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Grid>
                                <Grid
                                  item
                                  xs={0.6}
                                  sx={{
                                    display: "flex",

                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    sx={{ "&:hover": { color: "#FF4842" } }}
                                    onClick={() => {
                                      handleOpenDeleteDialog(attribute);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </>
                            );
                          }
                        })}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            p: 1.5,
                          }}
                        ></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Grid>
        </>
      ))}
      <Dialog open={isEntityDialougeOpne} onClose={() => {}}>
        <DialogTitle>Edit Display Name</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={3.5} style={{ marginTop: "10px" }}>
              <Typography>Entity Name :</Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: "10px" }}>
              <Typography variant="subtitle1" color="textSecondary">
                {editEntityForm.collectionName}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <TextField
                label="Display Name"
                fullWidth
                // autoFocus
                variant="outlined"
                name={editEntityForm?.collectionName}
                value={editEntityForm?.displayName.editedName}
                onChange={(e) => handleEntityOnchange(e)}
                // disabled={!item.toEdit}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCancelEntity}>
            CANCEL
          </Button>
          <Button onClick={handleSaveEntity} color="primary">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      {/* attribute dialouge */}
      <Dialog open={openAttributeDialog} onClose={() => {}}>
        <DialogTitle>
          {isEditAttributeData ? `Edit Attribute` : `Add Attribute`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={
                  isEditAttributeData
                    ? editAttributeForm?.name
                    : attributeForm?.name
                }
                fullWidth
                label="Attribute Name"
                variant="outlined"
                name="name"
                style={{ marginTop: "10px" }}
                onChange={handleChangeAttribute}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  {" "}
                  <Typography sx={{ ml: 1.5 }}>Attribute Type : </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl
                    component="fieldset"
                    disabled={isEditAttributeData ? true : false}
                  >
                    <RadioGroup
                      row
                      name="isRequired"
                      value={
                        isEditAttributeData
                          ? editAttributeForm?.isRequired
                          : attributeForm.isRequired
                      }
                      onChange={handleChangeAttribute}
                    >
                      <FormControlLabel
                        readOnly={isEditAttributeData ? true : false}
                        value="true"
                        control={<Radio color="primary" />}
                        label="Mandatory"
                      />
                      <FormControlLabel
                        readOnly={isEditAttributeData ? true : false}
                        value="false"
                        control={<Radio color="primary" />}
                        label="Optional"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                disabled={isEditAttributeData ? true : false}
              >
                <Select
                  disabled={isEditAttributeData ? true : false}
                  value={
                    isEditAttributeData
                      ? editAttributeForm?.uiType
                      : attributeForm.uiType
                  }
                  name="uiType"
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  onChange={handleChangeAttribute}
                  fullWidth
                >
                  <MenuItem value="">Select Display Type</MenuItem>
                  <MenuItem value={"AutoCompleteSingle"}>
                    Auto Complete Single
                  </MenuItem>

                  <MenuItem value={"AutoCompleteMulti"}>
                    Auto Complete Multi
                  </MenuItem>
                  {/* <MenuItem value={"CheckBox"}>Check Box</MenuItem> */}
                  <MenuItem value={"SingleSelectDropdown"}>
                    Single Select Dropdown
                  </MenuItem>
                  <MenuItem value={"MultiSelectDropdown"}>
                    Multi Select Dropdown
                  </MenuItem>
                  <MenuItem value={"TextField"}>Text Field</MenuItem>
                  <MenuItem value={"multiLineTextfield"}>
                    Multi Line Text Field
                  </MenuItem>
                  <MenuItem value={"NumberField"}>Number Field</MenuItem>
                  <MenuItem value={"DatePicker"}>Date Picker</MenuItem>
                  <MenuItem value={"TimePicker"}>Time Picker</MenuItem>
                  <MenuItem value={"RadioButton"}>Radio Button</MenuItem>
                  <MenuItem value={"FloatField"}>Float Field</MenuItem>
                  <MenuItem value={"DecimalField"}>Decimal Field</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {!isEditAttributeData ? (
              attributeForm.uiType === "AutoCompleteSingle" ||
              attributeForm.uiType === "AutoCompleteMulti" ||
              attributeForm.uiType === "SingleSelectDropdown" ||
              attributeForm.uiType === "MultiSelectDropdown" ||
              attributeForm.uiType === "RadioButton" ? (
                <Grid item xs={12} spacing={3} style={{ marginTop: "10px" }}>
                  <Autocomplete
                    readOnly={isEditAttributeData ? true : false}
                    fullWidth
                    clearIcon={false}
                    multiple
                    value={attributeForm.values}
                    onChange={(event, newValue, reason) => {
                      if (
                        reason === "createOption" &&
                        hasSpecialCharacter(newValue)
                      ) {
                        toast.warning("Special characters are not allowed.");
                        return;
                      }
                      if (
                        reason === "createOption" &&
                        !validateStringLength(inputChangeData.trim(), 1, 15)
                      ) {
                        toast.warning("Tag length between 1 to 15");
                        return;
                      }
                      setAttributeForm((prev) => ({
                        ...prev,
                        values: newValue,
                      }));
                    }}
                    inputValue={inputChangeData}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        option.toLowerCase().trim() ==
                        value.toLowerCase().trim()
                      );
                    }}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    options={[]}
                    freeSolo
                    renderTags={(value, props) => {
                      return value.map((option, index) => {
                        if (option.trim() !== "") {
                          return (
                            <Chip
                              key={index}
                              label={option}
                              {...props({ index })}
                            />
                          );
                        } else {
                          return null;
                        }
                      });
                    }}
                    renderInput={(params) => (
                      <TextField label="Add Values" {...params} />
                    )}
                  />
                  <span
                    style={{
                      fontSize: "15px",
                      marginLeft: "8px",
                      color: "#808080",
                    }}
                  >
                    *To insert a value, write its name and hit Enter
                  </span>
                </Grid>
              ) : null
            ) : editAttributeForm.uiType === "AutoCompleteSingle" ||
              editAttributeForm.uiType === "AutoCompleteMulti" ||
              editAttributeForm.uiType === "SingleSelectDropdown" ||
              editAttributeForm.uiType === "MultiSelectDropdown" ||
              editAttributeForm.uiType === "RadioButton" ? (
              <Grid item xs={12} spacing={3} style={{ marginTop: "10px" }}>
                <Autocomplete
                  readOnly={isEditAttributeData ? true : false}
                  clearIcon={false}
                  options={editAttributeForm.values}
                  // options={['US', 'UK', 'India']}
                  value={editAttributeForm.values}
                  freeSolo
                  multiple
                  renderTags={(value, props) => {
                    return value.map((option, index) => {
                      if (option.trim() !== "") {
                        return <Chip label={option} {...props({ index })} />;
                      } else {
                        return null;
                      }
                    });
                  }}
                  onChange={(event, newValue) => {
                    setEditAttributeForm((prev) => ({
                      ...prev,
                      values: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField label="Add Values" {...params} />
                  )}
                />
                <span
                  style={{
                    fontSize: "15px",
                    marginLeft: "8px",
                    color: "#808080",
                  }}
                >
                  *To insert a value, write its name and hit Enter
                </span>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCloseAttributeDialog}>
            CANCEL
          </Button>
          <Button
            onClick={() => {
              isEditAttributeData
                ? handleUpdateAttribute()
                : handleSaveAttribute();
            }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deletePopupOpen} onClose={() => {}}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you wish to delete this attribute?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
          <Button onClick={handleDeleteAttribute} sx={{ color: "#FF4842" }}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={attributeDialog} onClose={() => {}}>
        <DialogTitle>Attributes</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography
                // component="div"
                variant="subtitle1"
              >
                {capitalizedString("Static")}
              </Typography>
              <Grid item xs={12}>
                <Divider sx={{ m: 1 }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {attributeList.map((attribute, index) => {
              if (
                attribute.entityName === viewCollectionName &&
                attribute.isPredefinedAttribute
              ) {
                return (
                  <>
                    <Chip
                      key={index}
                      label={capitalizedString(attribute.name)}
                      style={{ margin: "4px" }}
                    />
                  </>
                );
              }
            })}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ m: 1 }} />
          </Grid>
          {/* Render chips for each static attribute */}

          {/* <Grid container> */}

          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {capitalizedString("Custom")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ m: 1 }} />
          </Grid>

          {attributeList
            .filter(
              (attribute) =>
                attribute.entityName === viewCollectionName &&
                !attribute.isPredefinedAttribute
            )
            .map((attribute, index) => (
              <Chip
                key={index}
                label={capitalizedString(attribute.name)}
                style={{ margin: "4px" }}
              />
            )).length > 0 ? (
            attributeList
              .filter(
                (attribute) =>
                  attribute.entityName === viewCollectionName &&
                  !attribute.isPredefinedAttribute
              )
              .map((attribute, index) => (
                <Grid item xs={12}>
                  <Chip
                    key={index}
                    label={capitalizedString(attribute.name)}
                    style={{ margin: "4px" }}
                  />
                </Grid>
              ))
          ) : (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography variant="body2">
                Custom attributes currently unavailable.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ m: 1 }} />
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* Add any additional actions or buttons if needed */}
          <Button onClick={handelAttributeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
