import axiosInstance from "axios";
import { getCallHippoCredential } from "./callHippoCredentials.service";

const API_BASE_URL = "https://web.callhippo.com/v1";

const getActivityFeed = async () => {
  try {
    const credentialResponse = await getCallHippoCredential();
    if (!credentialResponse.data.status) {
      console.log("error");
    }
    const { apiToken } = credentialResponse.data.data;
    const headers = {
      accept: "application/json",
      apiToken: apiToken,
      "Content-Type": "application/json",
    };
    const response = await axiosInstance.post(
      `${API_BASE_URL}/activityfeed`,
      {},
      {
        headers,
      }
    );

    return Promise.resolve({
      isSuccess: true,
      data: response.data,
      message: "Activity Feed Fetched Successfully",
    });
  } catch (err) {
    console.error(err);

    return Promise.resolve({
      isSuccess: false,
      data: null,
      message: "Failed to fetch activity feed",
    });
  }
};

export default getActivityFeed;
