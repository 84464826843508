import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "../../theme/overrides/Typography";
import { AuthContext } from "../../context/auth.context";
const ITEM_HEIGHT = 48;

const ContextMenu = ({
  handleClick,
  handleDelete,
  handleClickMail,
  handleEnable,
  index,
  _id,
  active_status,
  email,
  setContextMailClick,
  setContextMailName,
  userDetails,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { currentUser } = useContext(AuthContext);
  const handleClickMenu = (event) => {
    console.log("====3 dots clicked====");
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ height: 20, transform: "translateZ(0px)", flexGrow: 1 }}>
      {console.log("Table cell action box2 index", index, _id)}
      <IconButton
        sx={{ bottom: 0, height: 1, left: 18 }}
        aria-label="more"
        id="long-button"
        aria-controls={openMenu ? "long-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        {console.log("Table cell action before icon index", index, _id)}
        <MoreVertIcon />
        {console.log("Table cell action after icon index", index, _id)}
      </IconButton>
      {console.log("Table cell action before menu index", index, _id)}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.6,
            width: "12ch",
          },
        }}
      >
        {console.log(
          "Table cell action after menu before list index",
          index,
          _id
        )}
        {/* <Paper sx={{ width: 20 }}> */}
        {/* <MenuList fullWidth> */}
        <MenuItem
          fullwidth
          onClick={() => {
            handleClick(_id, userDetails);
            handleCloseMenu();
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <VisibilityIcon label="view" fontSize="small" color="primary" />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                paddingLeft: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>View</span>
            </Grid>
          </Grid>
        </MenuItem>
        {currentUser.role === "SUPER_ADMIN" ? null : (
          <>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
              }}
            >
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <CallIcon fontSize="small" color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>Call</span>
                </Grid>
              </Grid>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setContextMailName(email);
                handleClickMail(email);
                handleCloseMenu();
                setContextMailClick(true);
              }}
            >
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <MailIcon fontSize="small" color="primary" /> &nbsp;&nbsp;
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>Email</span>
                </Grid>
              </Grid>
            </MenuItem>
          </>
        )}
        {console.log("active_status", active_status)}
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            if (active_status) {
              handleDelete(_id, !active_status);
            } else {
              handleEnable(_id, !active_status);
            }
          }}
        >
          <Grid container spacing={0}>
            {active_status ? (
              <Fragment>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <PersonRemoveIcon
                    fontSize="medium"
                    size="large"
                    color="error"
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {active_status ? "Disable" : "Enable"}
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <PersonAddIcon fontSize="small" color="primary" />
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {active_status ? "Disable" : "Enable"}
                </Grid>
              </Fragment>
            )}
          </Grid>
        </MenuItem>
        {/* </MenuList> */}
        {/* </Paper> */}
      </Menu>
    </Box>
  );
};

export default ContextMenu;
