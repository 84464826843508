import {
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Grid,
  Card,
  CardContent,
  InputLabel,
  FormControl,
  Stack,
  Box,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import React from "react";
import { getUsers, getUsersByRoles } from "../../services/users.service";
import {} from "../../services/contacts.service";
import { toast } from "react-toastify";
import { createAction as createContactAction } from "../../services/contacts.service";
import { createAction as createLeadAction } from "../../services/leads.service";
import {
  addResource,
  createAction as createProjectAction,
} from "../../services/projects.service";

import constants from "../../constants";
import AddIcon from "@mui/icons-material/Add";
import { DateTimePicker, TimePicker } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";

function AssignToDropdown(props) {
  const [value, setValue] = React.useState(props.list[0]);
  const handleChange = (event) => {
    setValue(event.target.value);
    props.setDropdownValue(event.target.value);
  };
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleChange}
      sx={{ width: "200px" }}
    >
      {props.list.map((element) => (
        <MenuItem value={element}>{element}</MenuItem>
      ))}
    </Select>
  );
}

function CreateTeam({
  action,
  setAction,
  id,
  leadId,
  projectId,
  initialActionState,
  actionType,
  loadedActions,
  setLoadedActions,
  isEditPage,
  setPageCount,
  FetchActions,
  setPage,
  addedActionCounter,
  setAddedActionCounter,
  setCreateContactShowTimer,
  teamSingle,
  setTeamSingle,
  setIsProjectFetched,
  currentUser,
  project,
  setIactionedApiCalled,
}) {
  console.log("Team single", teamSingle);
  const {
    status: { name },
  } = project;
  // console.log("action", action, setAction, isEditPage);
  React.useEffect(() => {
    getUsers(0, true).then((results) => {
      if (results.data.status) {
        const usernames = [];
        console.log(results.data.data, "data");
        results.data.data.forEach((user) => {
          console.log(user);
          usernames.push({ name: user.name, id: user._id });
        });
        setList([...usernames]);
        setDropdownValue(usernames[0]);
      }
      fetchUsersTL();
    });
  }, []);
  let toastId = null;
  const [showTimer, setShowTimer] = React.useState(false);

  const [list, setList] = React.useState([]);
  //console.log(isEditPage);
  const [dropDownValue, setDropdownValue] = React.useState(list[0]);
  // const [action, setAction] = React.useState("");
  // const [dueDate, setDueDate] = React.useState(null);
  const [actionObj, setActionObj] = React.useState([]);
  const [usersTL, setUsersTL] = React.useState([]);

  const handleChange = (e) => {
    setTeamSingle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //console.log("action type", actionType);
  const [value, setValue] = React.useState(new Date());
  const timing = [
    { name: "Full Time", value: "FULL_TIME" },
    { name: "Part Time", value: "PART_TIME" },
  ];

  const roles = [
    { name: "Project Manager", value: "PM" },
    { name: "Team Lead", value: "TL" },
    { name: "Developer", value: "DEV" },
    { name: "Quality Assurance", value: "QA" },
  ];
  const fetchUsersTL = async () => {
    try {
      const response = await getUsersByRoles(4);
      if (response.data.status) {
        setUsersTL(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ResetForm = () => {
    const temp = { ...teamSingle };
    temp._id = "";
    temp.userId = "";
    temp.assignedRole = "";
    temp.contribution = "";
    setTeamSingle(temp);
  };

  const AddResource = async (e) => {
    // console.log("Add action triggered from" + actionType);
    if (
      teamSingle.userId !== null &&
      teamSingle.assignedRole !== null &&
      teamSingle.contribution !== null &&
      teamSingle.userId !== undefined &&
      teamSingle.assignedRole !== undefined &&
      teamSingle.contribution !== undefined &&
      teamSingle.userId !== "" &&
      teamSingle.assignedRole !== "" &&
      teamSingle.contribution !== ""
    ) {
      try {
        let response;
        const tempObj = {};
        tempObj["team"] = [];
        const temp = { ...teamSingle };
        delete temp._id;
        tempObj.team.push(temp);
        response = await addResource(projectId, tempObj);
        setIsProjectFetched(false);
        if (response.data.status) {
          setIactionedApiCalled(true);
          toast("Resource added successfully");
          ResetForm();
          setShowTimer(false);
          setCreateContactShowTimer(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={3.5}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-userId-select-label">
                    Resource Name
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    // inputProps={isEditPage ? { readOnly: true } : inputProps}
                    labelId="demo-userId-select-label"
                    id="demo-userId-select"
                    // value={contact.data.country}
                    label="Resource Name"
                    name="userId"
                    value={teamSingle.userId}
                    onChange={handleChange}
                  >
                    {usersTL.length > 0
                      ? usersTL.map((user, index) => {
                          return (
                            <MenuItem key={index} value={user._id}>
                              {user.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-assignedRole-select-label">
                    Resource Role
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    // inputProps={
                    //   isEditPage ? { readOnly: inputProps } : inputProps
                    // }
                    labelId="demo-assignedRole-select-label"
                    id="demo-assignedRole-select"
                    // value={contact.data.country}
                    label="Resource Role"
                    name="assignedRole"
                    value={teamSingle.assignedRole}
                    onChange={handleChange}
                  >
                    {roles.length > 0
                      ? roles.map((user, index) => {
                          return (
                            <MenuItem key={index} value={user.value}>
                              {user.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3.5}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-contribution-select-label">
                    Resource Time
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    // inputProps={
                    //   isEditPage ? { readOnly: inputProps } : inputProps
                    // }
                    labelId="demo-contribution-select-label"
                    id="demo-contribution-select"
                    // value={contact.data.country}
                    label="Resource Contribution"
                    name="contribution"
                    value={teamSingle.contribution}
                    onChange={handleChange}
                  >
                    {timing.length > 0
                      ? timing.map((time, index) => {
                          return (
                            <MenuItem key={index} value={time.value}>
                              {time.name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {!isEditPage ? (
              currentUser.role === "ADMIN" ||
              currentUser._id === project.techOwner ||
              currentUser._id === project.salesOwner ? (
                <Grid
                  item
                  xs={6}
                  sm={0.75}
                  // sx={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={AddResource}
                    startIcon={<AddIcon />}
                    sx={{ maxHeight: "45px" }}
                    disabled={name === "Closed" ? true : false}
                  >
                    Add
                  </Button>
                </Grid>
              ) : null
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateTeam;
