import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React from "react";
import AssociateEmailIndivisual from "./AssociateEmailIndivisual";
import { toast } from "react-toastify";

export default function AssociateEmailPrivate({
  allFiles,
  setAssociateEmailListDialog,
  descriptionObj,
  setDescriptionObj,
}) {
  var regex = /^[a-zA-Z ]*$/;
  const handleCheckBoxChange = (event, index) => {
    let tempFile = [...allFiles];
    tempFile[index].checked = event.target.checked;
    setAssociateEmailListDialog((prev) => {
      const temp = { ...prev };
      temp.attachments = [...tempFile];
      return temp;
    });
  };

  const handleChange = (event, index) => {
    const value = regex.test(event.target.value);
    if (event.target.value.length > 14) {
      toast.warning("Descritpion should not be more than 14 characters.");
      return;
    } else if (!value) {
      toast.error("Numbers and special characters are not allowed");
      return;
    } else {
      setDescriptionObj((prev) => {
        const temp = { ...prev };
        temp[parseInt(index)] = event.target.value;
        return temp;
      });
    }
  };
  console.log(allFiles);
  return (
    <Grid container spacing={0} alignItems="center">
      {allFiles.length &&
        allFiles?.map((file, index) => {
          return (
            <>
              <Grid item xs={9.5}>
                <AssociateEmailIndivisual
                  file={file}
                  handleChange={handleChange}
                  descriptionObj={descriptionObj}
                  index={index}
                />
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={file.checked}
                        onChange={(e) => {
                          handleCheckBoxChange(e, index);
                        }}
                      />
                    }
                    label="Private"
                  />
                </FormGroup>
              </Grid>
            </>
          );
        })}
    </Grid>
  );
}
