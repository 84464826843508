import "../../../style/comment/comment.view.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/auth.context";
import ReactQuill from "react-quill";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DOMPurify from "dompurify";
import { checkActionHolder } from "../../../utils/handleButttonByRoleAccess";
import { element } from "prop-types";

function CommentView(props) {
  const [isReadMore, setIsReadMore] = useState(true);
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });
  // console.log("props name", props);
  // let initilas = props.name.charAt(0);
  // console.log("initials", initilas);
  const [open, setOpen] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const { currentUser, isAuthenticated } = useContext(AuthContext);

  const handleClickOpenDocument = () => {
    setOpenDocument(true);
  };

  const handleClickCloseDocument = () => {
    setOpenDocument(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  console.log("final result", props);
  const uploadDocumentAwsS3Url =
    props?.uploadDocumentAwsS3Url &&
    props?.uploadDocumentAwsS3Url?.map((document) => {
      // console.log("--->test 1", currentUser.role !== "ADMIN");
      // console.log("--->test 2", currentUser._id !== props.salesOwner);
      // console.log("--->test 3", currentUser._id !== props.techOwner);
      // console.log("--->test 4", document.isPrivate === false);
      if (
        currentUser.role === "ADMIN" ||
        currentUser._id === props.salesOwner ||
        currentUser._id === props.techOwner
      ) {
        // console.log("---->test doc", document);
        return (
          <>
            <div
              className="file-item"
              style={{
                listStyle: "none",
                backgroundColor: "#2065d1 38",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DescriptionIcon sx={{ color: "#2065d1" }} />

              <p
                style={{
                  flex: 1,
                  fontSize: "0.9rem",
                  paddingLeft: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="fileName"
              >
                {document.location.split("-").pop()}
              </p>

              <div
                className="actions"
                style={{
                  justifySelf: "flex-end",
                  cursor: "pointer",
                }}
              >
                <IconButton size="medium" disableRipple="true">
                  <DownloadIcon
                    icon={faDownload}
                    style={{ color: "#f55e30" }}
                    onClick={() => {
                      window.open(document.location, "Download");

                      // download(file.location, fileName);
                    }}
                  />
                </IconButton>
                {/* <div className="loading"></div> */}
              </div>
            </div>
            {/* <li style={{ alignItems: "flex-start" }}>
              <DescriptionIcon sx={{ color: "#2065d1" }} />

              <IconButton size="medium" disableRipple="true">
                <DownloadIcon
                  icon={faDownload}
                  style={{ color: "#f55e30" }}
                  onClick={() => {
                    window.open(document.location, "Download");

                    // download(document.location, fileName);
                  }}
                />
                <h6>{document.location.split("-").pop()} </h6>
              </IconButton>
          
            </li> */}
          </>
        );
      } else if (document.isPrivate === false) {
        console.log("else running");
        return (
          <>
            <div
              className="file-item"
              style={{
                listStyle: "none",
                backgroundColor: "#2065d1 38",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DescriptionIcon sx={{ color: "#2065d1" }} />

              <p
                style={{
                  flex: 1,
                  fontSize: "0.9rem",
                  paddingLeft: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="fileName"
              >
                {document.location.split("-").pop()}
              </p>

              <div
                className="actions"
                style={{
                  justifySelf: "flex-end",
                  cursor: "pointer",
                }}
              >
                <IconButton size="medium" disableRipple="true">
                  <DownloadIcon
                    icon={faDownload}
                    style={{ color: "#f55e30" }}
                    onClick={() => {
                      window.open(document.location, "Download");

                      // download(file.location, fileName);
                    }}
                  />
                </IconButton>
                {/* <div className="loading"></div> */}
              </div>
            </div>

            {/* <li style={{ alignItems: "flex-start" }}>
              <IconButton size="medium" disableRipple="true">
                <DownloadIcon
                  icon={faDownload}
                  style={{ color: "#f55e30" }}
                  onClick={() => {
                    window.open(document.location, "Download");

                    // download(document.location, fileName);
                  }}
                />
                <h6>{document.location.split("-").pop()} </h6>
              </IconButton>
            </li> */}
          </>
        );
      }
      // return document.location;
    });
  // console.log(props.uploadDocumentAwsS3Url, "s3url");
  return (
    <>
      <Grid container spacing={2}>
        <Grid item={1}>
          <Avatar sx={{ width: "40px", height: "40px" }}>
            {props.name && props.name.charAt(0)}
          </Avatar>
        </Grid>
        <Grid
          item
          xs={11}
          // sx={{ backgroundColor: "red" }}
          justifyContent="left"
        >
          <div className="material-textfield">
            <div className="commentContainer">
              {/* document */}
              <div className="documentListContainer">
                {currentUser.role === "ADMIN" ||
                currentUser._id === props.salesOwner ? (
                  props?.uploadDocumentAwsS3Url &&
                  props?.uploadDocumentAwsS3Url?.length ? (
                    <IconButton onClick={handleClickOpenDocument}>
                      <SourceOutlinedIcon
                        style={{
                          color: "#2065D1",
                        }}
                      />
                    </IconButton>
                  ) : null
                ) : currentUser._id === props.techOwner ? (
                  props?.uploadDocumentAwsS3Url &&
                  props?.uploadDocumentAwsS3Url?.filter(
                    (elem) => elem.isPrivate === false
                  ).length ? (
                    <IconButton onClick={handleClickOpenDocument}>
                      <SourceOutlinedIcon
                        style={{
                          color: "#2065D1",
                        }}
                      />
                    </IconButton>
                  ) : null
                ) : checkActionHolder(props.allDoers, currentUser) ? (
                  props?.uploadDocumentAwsS3Url &&
                  props?.uploadDocumentAwsS3Url?.filter(
                    (elem) => elem.isPrivate === false
                  ).length ? (
                    <IconButton onClick={handleClickOpenDocument}>
                      <SourceOutlinedIcon
                        style={{
                          color: "#2065D1",
                        }}
                      />
                    </IconButton>
                  ) : null
                ) : null}
              </div>

              <label
                style={{
                  position: "static",
                  marginLeft: "0",
                  paddingLeft: "0",
                }}
              >
                <Typography
                  display="inline"
                  style={{
                    color: "#2065D1",
                  }}
                >
                  {props.name}{" "}
                </Typography>

                <span style={{ color: "black", fontWeight: "bold" }}>
                  says on
                </span>
                <span>&nbsp;&nbsp;</span>
                <Typography
                  variant="caption"
                  display="inline"
                  sx={{ color: "#808080", marginTop: "10px" }}
                  gutterBottom={false}
                >
                  {new Date(props.createdAt).toDateString()}
                </Typography>
              </label>
              <div className="commentText ql-editor">
                <div
                  dangerouslySetInnerHTML={sanitizedData(
                    isReadMore && props.comment.length >= 170
                      ? props.comment.slice(0, 205) + "..."
                      : props.comment
                  )}
                />
                <span onClick={toggleReadMore} className="read-or-hide">
                  {/* {isReadMore ? "...read more" : " show less"} */}
                  {props.comment.length >= 150
                    ? isReadMore
                      ? "...read more"
                      : " show less"
                    : null}
                </span>
              </div>
            </div>
          </div>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Price Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span>
                The saved price is: <strong>{props.price}</strong>
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        {/* open dialoguge for document list */}
        <Dialog open={openDocument} onClose={handleClickCloseDocument}>
          <DialogTitle>Documents</DialogTitle>
          <DialogContent>
            <ul
              style={{
                listStyleType: "none",
                // display: "flex",
                // height: "200px",
              }}
            >
              {uploadDocumentAwsS3Url}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseDocument}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      {/* <Divider variant="fullWidth" style={{ margin: "30px 0" }} /> */}
    </>
  );
}
export default CommentView;
