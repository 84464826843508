import { Grid } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function PriceNegotiationChart({
  detailsWithTotalPrice,
  deatilsWithPrice,
}) {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "apexchart-example",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        format: "dd/MM/yy HH:mm",
        type: "date",
      },
      yaxis: {
        title: {
          text: "PRICE",
          style: {
            color: "#2065D1",
            fontSize: "18px",
            fontFamily: "Public Sans,sans-serif",
          },
        },
      },
      tooltip: {
        z: {
          //   title: "Description: ",
          title: "Schedule: ",
        },
      },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000000",
          fontSize: "24px",
          fontFamily: "Helvetica",
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
  });

  return (
    <>
      <Grid container spacing={2} columns={11}>
        <Grid item xs={12}>
          <Chart
            options={chartData.options}
            series={[
              { name: "Total Price", data: detailsWithTotalPrice },
              { name: "Unit Price", data: deatilsWithPrice },
            ]}
            type="area"
            width={"100%"}
            height={"350px"}
          />
        </Grid>
      </Grid>
    </>
  );
}
