import React, { useEffect } from "react";
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  createFilterOptions,
  ListItemText,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { toast } from "react-toastify";
import { hasSpecialCharacter } from "../../../utils/hasSpecialCharacter";
import { validateStringLength } from "../../../utils/validateStringLength";
const regex = /^[a-zA-Z ]*$/;
const decimalRegex = /^(?:\d*\.\d{1,2}|\d+)$/;

function entityAttributeFields({
  componentIndex,
  componentDeatils,
  setterFunction,
  entityName,
  fromCompanyViewPage,
  isEditPage,
  toEdit,
  afterLeadCreate,
  fromLeadViewPage,
}) {
  const filter = createFilterOptions();
  let toastId = null;
  const handleChange = (event) => {
    // if (event.target.value === "") {
    //   console.log("empty");
    //   setterFunction((prev) => {
    //     const temp = [...prev];
    //     temp[componentIndex]["selectedValues"] = [];
    //     return temp;
    //   });
    //   return;
    // }

    console.log(event.target.value);

    setterFunction((prev) => {
      const temp = [...prev];
      temp[componentIndex]["selectedValues"][0] = event.target.value;
      return temp;
    });
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function dateChange(date) {
    const dateTimeInSeconds = new Date(date).getTime();
    const offSet = new Date().getTimezoneOffset();
    const offSetInSeconds = offSet * 60 * 1000;
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    const newDate = new Date(newDateInSeconds);
    const newDateFormat = formatDate(newDate);
    return newDateFormat;
  }

  function formatDate(date) {
    date = new Date(date);
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":") +
      ":00" +
      ".000Z"
    );
  }

  function handleReadOnlyByEntity(entityName) {
    switch (entityName) {
      case "Company":
        return fromCompanyViewPage && toEdit;

      case "Contact":
        return fromLeadViewPage
          ? true
          : isEditPage
          ? toEdit
            ? true
            : false
          : false;

      case "Lead":
        return isEditPage ? (toEdit ? true : false) : false;

      case "Project":
        return toEdit;
      default:
        return true;
        break;
    }
  }
  // if (afterLeadCreate) {
  //   componentDeatils.selectedValues[0] = "";
  // }

  const handelfilterOption = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value

    const isExisting = options.some((option) => inputValue === option.name);
    // if (inputValue !== "" && !isExisting) {
    //   filtered.push({
    //     inputValue,
    //     name: `Add "${inputValue}"`,
    //   });
    // }
    // if (inputValue !== "" && !isExisting) {
    //   filtered.push({
    //     inputValue,
    //     name: `Add "${inputValue}"`,
    //   });
    // }
    return filtered;
  };

  const handelOptionalLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      // console.log("Returns string");
      return option;
    }
    if (typeof option.name === "undefined") {
      // console.log("Returns empty string");
      return "";
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      // console.log("Returns empty value");
      return option.inputValue;
    }
    // Regular option
    // console.log("Returns option.name");
    return option.name;
  };

  const handleNullSource = (data) => {
    console.log("data", data);
    if (data) {
      console.log("Inside data");
      if (typeof data !== undefined) {
        return data[0];
      } else {
        return "SELECT";
      }
    }
  };

  const isAllSelected =
    componentDeatils?.values.length > 0 &&
    componentDeatils.selectedValues.length === componentDeatils?.values.length;

  const hendelMltiSelectDropdown = (event) => {
    const value = event.target.value;
    console.log("value[", value[value.length - 1] === "All");
    if (value[value.length - 1] === "All") {
      setterFunction((prev) => {
        const temp = [...prev];
        if (
          componentDeatils.selectedValues.length ===
          componentDeatils.values.length
        ) {
          // temp[componentIndex]["selectedValues"] = [];
          componentDeatils.selectedValues = [];
        } else {
          componentDeatils.selectedValues = componentDeatils.values;
        }
        return temp;
      });
      return;
    }
    setterFunction((prev) => {
      const temp = [...prev];
      temp[componentIndex]["selectedValues"] = value;
      return temp;
    });
  };

  switch (componentDeatils?.uiType.toLowerCase()) {
    case "floatfield":
      return (
        <TextField
          InputProps={{ readOnly: handleReadOnlyByEntity(entityName) }}
          label={componentDeatils?.name.trim()}
          variant="outlined"
          placeholder={componentDeatils?.name.trim()}
          type="number"
          name={componentDeatils.name}
          value={componentDeatils.selectedValues[0]}
          onChange={(e) => {
            if (e.target.value < 0) return;
            handleChange(e);
          }}
          required={componentDeatils.isRequired}
          fullWidth
        />
      );
      break;
    case "decimalfield":
      return (
        <TextField
          InputProps={{ readOnly: handleReadOnlyByEntity(entityName) }}
          label={componentDeatils?.name.trim()}
          variant="outlined"
          placeholder={componentDeatils?.name.trim()}
          type="number"
          name={componentDeatils.name}
          value={componentDeatils.selectedValues[0]}
          onChange={(e) => {
            if (e.target.value < 0) return;
            const validDecimal = decimalRegex.test(e.target.value);
            if (!validDecimal && e.target.value) {
              toast("Enter a valid decimal number");
              return;
            }
            setterFunction((prev) => {
              const temp = [...prev];
              temp[componentIndex]["selectedValues"][0] = e.target.value;
              return temp;
            });
          }}
          required={componentDeatils.isRequired}
          fullWidth
        />
      );
      break;
    case "numberfield":
      return (
        <TextField
          InputProps={{ readOnly: handleReadOnlyByEntity(entityName) }}
          label={componentDeatils?.name.trim()}
          variant="outlined"
          placeholder={componentDeatils?.name.trim()}
          type="number"
          name={componentDeatils.name}
          value={componentDeatils.selectedValues[0]}
          onChange={(e) => {
            if (e.target.value < 0 || e.target.value.length > 14) {
              return;
            }
            handleChange(e);
          }}
          required={componentDeatils.isRequired}
          fullWidth
        />
      );
    case "textfield":
      return (
        <TextField
          InputProps={{ readOnly: handleReadOnlyByEntity(entityName) }}
          label={componentDeatils?.name.trim()}
          variant="outlined"
          placeholder={componentDeatils?.name.trim()}
          type={componentDeatils?.dataType}
          name={componentDeatils.name}
          value={componentDeatils.selectedValues[0]}
          onChange={(event) => {
            if (event.target.value && !event.target.value.trim()) {
              toast.warning(
                `${componentDeatils.name} cannot contain only spaces`
              );
            }
            if (event.target.value.length > 30) {
              toast.warning(
                `${componentDeatils.name} should not be more then 30 characters.`
              );
              return;
            }
            //  toast.warning("Summary should not be more then 80 characters.");
            else {
              handleChange(event);
            }
          }}
          required={componentDeatils.isRequired}
          fullWidth
        />
      );
      break;
    case "multilinetextfield":
      return (
        <TextField
          multiline
          row={2}
          InputProps={{ readOnly: handleReadOnlyByEntity(entityName) }}
          label={componentDeatils?.name.trim()}
          variant="outlined"
          placeholder={componentDeatils?.name.trim()}
          type={componentDeatils?.dataType}
          name={componentDeatils.name}
          value={
            // componentDeatils.selectedValues[0] === undefined
            //   ? (componentDeatils.selectedValues = [])
            //   : componentDeatils.selectedValues[0]
            componentDeatils.selectedValues[0]
          }
          onChange={(event) => {
            // if (event.target.value.length > 100) {
            //   toast.warning("Text should not be more than 100 characters.");
            //   return;
            // }

            handleChange(event);
          }}
          required={componentDeatils.isRequired}
          fullWidth
        />
      );
      break;
    case "singleselectdropdown":
      return (
        <FormControl fullWidth required={componentDeatils.isRequired}>
          <InputLabel required={componentDeatils.isRequired}>
            {componentDeatils?.name.trim()}
          </InputLabel>
          <Select
            readOnly={handleReadOnlyByEntity(entityName)}
            label={componentDeatils?.name.trim()}
            name={componentDeatils?.name.toLowerCase()}
            onChange={handleChange}
            value={componentDeatils.selectedValues[0]}
            required={componentDeatils.isRequired}
          >
            {componentDeatils?.values.map((ele) => (
              <MenuItem value={ele}>{ele}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case "multiselectdropdown":
      return (
        <FormControl fullWidth required={componentDeatils.isRequired}>
          <InputLabel required={componentDeatils.isRequired}>
            {componentDeatils?.name.trim()}
          </InputLabel>
          <Select
            readOnly={handleReadOnlyByEntity(entityName)}
            label={componentDeatils?.name.trim()}
            name={componentDeatils?.name.toLowerCase()}
            multiple
            onChange={hendelMltiSelectDropdown}
            value={componentDeatils.selectedValues}
            required={componentDeatils.isRequired}
            renderValue={(selected) =>
              selected.length === componentDeatils?.values.length
                ? ["All"]
                : selected.join(", ")
            }
          >
            <MenuItem value="All">
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  componentDeatils.selectedValues > 0 &&
                  componentDeatils.selectedValues <
                    componentDeatils?.values.length
                }
              />

              <ListItemText primary="All" />
            </MenuItem>
            {componentDeatils?.values.map((ele, index) => (
              <MenuItem key={index} value={ele}>
                {/* {ele} */}
                <Checkbox
                  checked={
                    isAllSelected
                      ? true
                      : componentDeatils.selectedValues.indexOf(ele) > -1
                  }
                />

                <ListItemText primary={ele} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case "autocompletesingle":
      return (
        <>
          <Autocomplete
            readOnly={handleReadOnlyByEntity(entityName)}
            id="tags-filled"
            options={componentDeatils?.values.map((option) => option)}
            freeSolo
            value={handleNullSource(componentDeatils.selectedValues)}
            filterOptions={handelfilterOption}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionLabel={handelOptionalLabel}
            renderOption={(props, option) => {
              console.log("propsoption", option);
              return (
                <li {...props} style={{ borderColor: "white" }}>
                  {/* {handleNullSource(option.name)} */}
                  {typeof option === "object" ? option.name : option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={componentDeatils?.name.trim()}
                placeholder={`Select ${componentDeatils?.name.trim()}`}
                required={componentDeatils.isRequired}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    return setterFunction((prev) => {
                      const temp = [...prev];
                      temp[componentIndex]["selectedValues"] = [];
                      return temp;
                    });
                  }
                }}
              />
            )}
            onChange={(event, newValue, reason) => {
              console.log("reason", reason);
              if (reason === "createOption") {
                return;
              }
              if (typeof newValue === "object") {
                if (newValue && newValue.inputValue) {
                  if (hasSpecialCharacter(newValue?.inputValue.trim())) {
                    toast.warning("Special characters are not allowed.");
                    return;
                  }

                  if (
                    reason === "selectOption" &&
                    !validateStringLength(newValue?.inputValue, 2, 5)
                  ) {
                    toast.warning("Tag length between 1 to 3");
                    return;
                  }
                  setterFunction((prev) => {
                    const temp = [...prev];
                    temp[componentIndex]["selectedValues"][0] =
                      newValue?.inputValue;
                    return temp;
                  });
                } else {
                  if (hasSpecialCharacter(newValue?.name.trim())) {
                    toast.warning("Special characters are not allowed.");
                    return;
                  }

                  setterFunction((prev) => {
                    const temp = [...prev];
                    temp[componentIndex]["selectedValues"][0] = newValue?.name;
                    return temp;
                  });
                }
              } else {
                if (hasSpecialCharacter(newValue.trim())) {
                  toast.warning("Special characters are not allowed.");
                  return;
                }
                if (
                  reason === "createOption" &&
                  !validateStringLength(newValue?.trim(), 2, 15)
                ) {
                  toast.warning("Tag length between 2 to 15");
                  return;
                }
                setterFunction((prev) => {
                  const temp = [...prev];
                  temp[componentIndex]["selectedValues"][0] = newValue;
                  return temp;
                });
              }
            }}
          />
          {/* <FormHelperText>
            {`Note: To Add an Unregistered ${componentDeatils?.name.trim()}, Write its name and hit enter`}
          </FormHelperText> */}
        </>
      );
      break;
    case "autocompletemulti":
      return (
        <>
          <Autocomplete
            readOnly={handleReadOnlyByEntity(entityName)}
            multiple
            id="tags-filled"
            options={componentDeatils?.values.map((option) => option)}
            freeSolo
            value={componentDeatils.selectedValues.filter(
              (ele) => ele && ele.trim() !== ""
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={componentDeatils?.name.trim()}
                // placeholder={`Add ${componentDeatils?.name.trim()}`}
                placeholder={
                  componentDeatils.selectedValues.length === 0
                    ? `Select ${componentDeatils?.name.trim()}`
                    : null
                }
                required={componentDeatils.isRequired}
              />
            )}
            onChange={(event, newValue, reason) => {
              if (reason === "createOption") {
                return;
              }
              const properValue = newValue.filter(
                (ele) => ele && ele.trim() !== ""
              );
              if (reason === "createOption" && hasSpecialCharacter(newValue)) {
                toast.warning("Special characters are not allowed.");
                return;
              }

              if (
                reason === "createOption" &&
                !validateStringLength(event.target.value?.trim(), 2, 15)
              ) {
                toast.warning("Tag length between 2 to 15");
                return;
              }
              setterFunction((prev) => {
                const temp = [...prev];
                temp[componentIndex]["selectedValues"] = [...properValue];
                return temp;
              });
            }}
          />
          {/* <FormHelperText>
            {`Note: To Add an Unregistered ${componentDeatils?.name.trim()}, Write its name and hit enter`}
          </FormHelperText> */}
        </>
      );
      break;
    case "datepicker":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            readOnly={handleReadOnlyByEntity(entityName)}
            fullWidth
            label={componentDeatils?.name.trim()}
            inputFormat="MMM/dd/yyyy"
            name={componentDeatils.name.toLowerCase()}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required={componentDeatils.isRequired}
              />
            )}
            value={componentDeatils.selectedValues[0]}
            onChange={(newValue) => {
              const date = dateChange(newValue);
              setterFunction((prev) => {
                const temp = [...prev];
                temp[componentIndex]["selectedValues"][0] = date;
                return temp;
              });
            }}
          />
        </LocalizationProvider>
      );
      break;
    case "timepicker":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            readOnly={handleReadOnlyByEntity(entityName)}
            fullWidth
            label={componentDeatils?.name.trim()}
            name={componentDeatils.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required={componentDeatils.isRequired}
              />
            )}
            value={componentDeatils.selectedValues[0]}
            onChange={(newValue) => {
              const time = dateChange(newValue);
              setterFunction((prev) => {
                const temp = [...prev];
                temp[componentIndex]["selectedValues"][0] = newValue;
                return temp;
              });
            }}
          />
        </LocalizationProvider>
      );
      break;
    case "radiobutton":
      return (
        <FormControl
          disabled={handleReadOnlyByEntity(entityName)}
          required={componentDeatils.isRequired}
        >
          <FormLabel
            id="demo-radio-buttons-group-label"
            required={componentDeatils.isRequired}
          >
            {componentDeatils.name}
          </FormLabel>
          <RadioGroup
            row
            onChange={handleChange}
            value={componentDeatils.selectedValues[0]}
          >
            {componentDeatils.values.map((ele) => (
              <FormControlLabel value={ele} control={<Radio />} label={ele} />
            ))}
          </RadioGroup>
        </FormControl>
      );
      break;
    default:
      break;
  }
}

export default entityAttributeFields;
