import { Grid, TextField, Typography } from "@mui/material";
import React from "react";

export default function AssociateEmailIndivisual({
  file,
  descriptionObj,
  handleChange,
  index,
}) {
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} p="5px">
          <Typography>{file.disposition.params.filename}</Typography>
        </Grid>
        <Grid item xs={12} p="2px">
          <TextField
            variant="standard"
            fullWidth
            value={descriptionObj[index]}
            onChange={(e) => {
              handleChange(e, index);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
