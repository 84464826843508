import Action from "../Shared/Action/Action.View";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import constants from "../../constants";
function DashboardAction(props) {
  const rtype = props.targetType;
  const rname = props.targetInfo;
  const {
    isDone,
    doneOn,
    doer,
    doneBy,
    toDo,
    dueDate,
    actionType,
    fetchActions,
    id,
    leadId,
    projectId,
    actionId,
    contactName,
    leadSummary,
    projectTitle,
    companyId,
    companyName,
  } = props;
  const vcolor =
    // props.targetType == "LEAD" ? "rgba(1,57,155,0.3)" : "rgba(0,77,64,0.3)";
    actionType == constants.LEADS ? "rgba(1,57,155,0.3)" : "rgba(0,77,64,0.3)";

  return (
    <Action
      id={id}
      leadId={leadId}
      actionId={actionId}
      projectId={projectId}
      FetchActions={fetchActions}
      actionType={actionType}
      from={constants.DASHBOARD}
      handleRemove={props.handleRemove}
      targetKey={props.targetKey}
      toDo={toDo}
      dueDate={dueDate}
      isDone={isDone}
      doneOn={doneOn}
      doer={doer}
      doneBy={doneBy}
      date={props.date}
      targetType={rtype}
      contactName={contactName}
      leadSummary={leadSummary}
      projectTitle={projectTitle}
      companyId={companyId}
      companyName={companyName}
      fromLeadPage={false}
    />
  );
}

export default DashboardAction;
