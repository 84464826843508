import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import { toast } from "react-toastify";
const monthsInTheWorld = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function CostCalculation({
  costDialogOpen,
  setCostDialogOpen,
  costInforMations,
  gstValues,
  projectId,
  handleCostDialouge,
  selectedCostData,
  createInvoiceData,
  getInvoiceDetails,
  setLoaderDialog,
  numberOfMileStone,
  parentCompanyDetails: clientCompany,
  setIndexes,
  setIsEdit,
  isEdit,
  isEditIcon,
  dueAmountDetails,
  setDueAmountDetails,
}) {
  console.log("objectsss", selectedCostData);

  const [selectedCost, setSelectedCost] = useState([]);
  const [totalDays, setTotalDays] = useState("");
  const [defaultMonthlyDays, setDefaultMonthlyDays] = useState(22);
  const [defaultYearlyDays, setDefaultYearlyDays] = useState(360);
  const [fileUpload, setFileUpload] = useState([]);
  const [handleFileView, setHandleFileView] = useState({});
  const [fileData, setFileData] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [invoiceTerm, setInvoiceTerm] = useState("Net 7");
  const [workingDays, setWorkingDays] = useState(0);
  const [showContactName, setShowContactName] = useState(false);
  const checkprevYear = () => {
    const currentDate = new Date();
    let lastYear = currentDate.getFullYear() - 1;
    let lastMonth = currentDate.getMonth() - 1;

    if (lastMonth === -1) {
      lastMonth = 11;
    }
    return { lastYear, lastMonth };
  };

  const [monthAndYear, setMonthAndYear] = useState({
    month: 0,
    year: 0,
  });

  useEffect(() => {
    console.log("hello", isEdit);
    setMonthAndYear((prev) => ({
      ...prev,
      month:
        new Date().getMonth() === 0
          ? checkprevYear().lastMonth
          : new Date().getMonth() - 1,
      year:
        new Date().getMonth() === 0
          ? checkprevYear().lastYear
          : new Date().getFullYear(),
    }));
    // calculateWorkingDays(monthAndYear.year, monthAndYear.month);
  }, [isEdit, selectedCostData]);
  //state
  console.log("selectedCost", selectedCost);
  useEffect(() => {
    calculateWorkingDays(monthAndYear.year, monthAndYear.month);
  }, [monthAndYear.month, selectedCostData, isEdit]);

  const calculateWorkingDays = (year, month) => {
    console.log("monthandyear", year, month);
    const startDate = moment([year, month]);
    const endDate = moment(startDate).endOf("month");
    let count = 0;
    while (startDate.isSameOrBefore(endDate)) {
      if (startDate.isoWeekday() <= 5) {
        count++;
      }
      startDate.add(1, "day");
    }
    const parsedValue = parseInt(count, 10);
    setWorkingDays(() => parsedValue);
  };
  console.log("monthandyear", workingDays);
  let netAmountDetails = {};
  const bank = costInforMations.bank;
  const companyType = costInforMations.companyType;

  const handleGenerateInvoice = async () => {
    const previousModifiedData = selectedCost.reduce((accumulator, ele) => {
      let totalCost;
      if (ele.paymentCycle?.toLowerCase() === "monthly") {
        totalCost = calculateTotalCost(ele);
        ele.totalCost = totalCost;
        ele.chargableCost = totalCost;
        ele.currency = costInforMations.currency;
      } else if (ele.paymentCycle?.toLowerCase() === "daily") {
        totalCost = calculateTotalCost(ele);
        ele.totalCost = totalCost;
        ele.chargableCost = totalCost;
        ele.currency = costInforMations.currency;
      } else if (ele.paymentCycle?.toLowerCase() === "hourly") {
        totalCost = calculateTotalCost(ele);
        ele.totalCost = totalCost;
        ele.chargableCost = totalCost;
        ele.currency = costInforMations.currency;
      } else if (ele.paymentCycle?.toLowerCase() === "yearly") {
        totalCost = calculateTotalCost(ele);
        ele.totalCost = totalCost;
        ele.chargableCost = totalCost;
        ele.currency = costInforMations.currency;
      }
      accumulator.push({ ...ele });
      return accumulator;
    }, []);
    console.log(previousModifiedData);
    setSelectedCost(previousModifiedData);

    setLoaderDialog(true);
    const isValid = selectedCost.every((item) => {
      const emptyField = Object.keys(item).find(
        (key) => item[key] === 0 || item[key] === null
      );
      if (emptyField) {
        if (emptyField === "totalDays") {
          toast.warn(` Charging period can not be 0.`);
          setLoaderDialog(false);

          return false;
        } else if (emptyField === "chargableCost") {
          toast.warn(` Chargeable Cost can not be 0.`);
          setLoaderDialog(false);

          return false;
        }
        // toast.warn(` ${emptyField} Cost can not be 0.`);
        // setLoaderDialog(false);

        // return false;
      }
      return true;
    });
    if (!isValid) {
      // Return or handle the validation error
      return;
    }
    if (netAmountDetails.netAmount < 0) {
      toast.warn("Net Amount cannot be negative. Please review your costs.");
      setLoaderDialog(false);
      return;
    }

    netAmountDetails.monthsOfInvoice =
      monthsInTheWorld[monthAndYear.month] + " " + monthAndYear.year;
    netAmountDetails.dueAmountDetails = dueAmountDetails;
    console.log("Data is valid. Sending to API:", selectedCost);
    const formData = new FormData();

    fileUpload.map((ele) => {
      console.log("ele", ele);
      formData.append("file", ele);
    });
    formData.append("costInfo", JSON.stringify(selectedCost));
    formData.append("projectId", projectId);
    formData.append("netAmountDetails", JSON.stringify(netAmountDetails));
    formData.append("bank", bank);
    formData.append("companyType", companyType);
    formData.append("showContactName", JSON.stringify(showContactName));

    let body = { projectId: projectId, costs: selectedCostData };
    console.log(body, "body");
    const result = await createInvoiceData(formData);
    console.log("result", result);
    if (result) {
      setShowContactName(false);
      setFileUpload((prev) => {
        return (prev = []);
      });
      setFileData([]);
      setHandleFileView({});
      setDefaultMonthlyDays(22);
      setWorkingDays(0);
      setDiscount(0);

      setCostDialogOpen(false);

      setMonthAndYear((prev) => ({
        ...prev,
        month: new Date().getMonth() - 1,
      }));
      getInvoiceDetails();
    }
  };

  const handleInvoiceTermChange = (event) => {
    const selectedInvoiceTerm = event.target.value;
    setInvoiceTerm(selectedInvoiceTerm);
  };

  const handleFixedGenerateInvoice = async () => {
    setLoaderDialog(true);
    const formData = new FormData();
    const selectedCostWithStatus = selectedCost.map((row) => ({
      ...row,
      invoiceStatus: true,
      currency: costInforMations.currency,
    }));
    if (netAmountDetails.netAmount < 0) {
      toast.warn("Net Amount cannot be negative. Please review your costs.");
      setLoaderDialog(false);
      return;
    }
    netAmountDetails.monthsOfInvoice =
      monthsInTheWorld[monthAndYear.month] + " " + monthAndYear.year;
    netAmountDetails.dueAmountDetails = dueAmountDetails;
    formData.append("costInfo", JSON.stringify(selectedCostWithStatus));
    formData.append("projectId", projectId);
    formData.append("netAmountDetails", JSON.stringify(netAmountDetails));
    formData.append("bank", bank);
    formData.append("companyType", companyType);
    formData.append("showContactName", JSON.stringify(showContactName));
    const result = await createInvoiceData(formData);
    console.log("result", result);
    if (result) {
      setShowContactName(false);
      setDiscount(0);
      // setDueAmountDetails((prev) => ({
      //   ...prev,
      //   isSelected: false,
      //   Amount: 0,
      //   AmountType: "",
      // }));
      setCostDialogOpen(false);
      setMonthAndYear((prev) => ({
        ...prev,
        month: new Date().getMonth() - 1,
      }));
    }
  };

  const handleTotalDays = (event, index, paymentCycle) => {
    const inputValue = event.target.value.trim();

    if (paymentCycle?.toLowerCase() === "hourly") {
      if (inputValue?.includes(".")) {
        const afterDot = inputValue?.split(".").pop();
        if (afterDot.length > 2) {
          toast.warn("Only 2 digits are allowed after the decimal point");
          return;
        }
      }
    }
    if (paymentCycle?.toLowerCase() != "hourly" && inputValue.length > 4) {
      toast.warn("Charging period should not exceed 4 numbers");
      return;
    }
    // if (chargableCost) {
    //   const chargeableAmount = parseInt(event.target.value);
    //   const costData = selectedCost[index];
    //   if (chargeableAmount > costData?.totalCost) {
    //     toast.warn("Chargable amount can not be greater than total cost");
    //     return;
    //   }

    //   setSelectedCost((prevSelectedCost) => {
    //     return prevSelectedCost.map((elem, i) => {
    //       if (i === index) {
    //         return {
    //           ...elem,
    //           chargableCost: chargeableAmount,
    //           currency: costInforMations.currency,
    //         };
    //       }
    //       return elem;
    //     });
    //   });
    //   return;
    // }
    // const parsedValue =
    //   event.target.value !== "" ? parseInt(event.target.value, 10) : 0;

    console.log("hourly", event.target.value);
    const parsedValue =
      event.target.value !== ""
        ? paymentCycle?.toLowerCase() === "hourly"
          ? parseFloat(event.target.value)
          : parseInt(event.target.value, 10)
        : 0;

    let totalCost = calculateTotalCost({
      ...selectedCost[index],
      totalDays: parsedValue,
    });
    if (totalCost === undefined) {
      totalCost = 0;
    }
    setSelectedCost((prevSelectedCost) => {
      const temp = [...prevSelectedCost];
      temp[index].totalDays = parsedValue;
      Object.assign(temp[index], {
        totalCost: parseFloat(totalCost).toFixed(2),
        chargableCost: parseFloat(totalCost).toFixed(2),
        currency: costInforMations.currency,
      });
      return temp;
    });
    setDefaultMonthlyDays(parsedValue);
    if (paymentCycle === "Monthly") {
      // setWorkingDays(parsedValue);
      setSelectedCost((prevSelectedCost) => {
        const temp = [...prevSelectedCost];
        temp[index].totalDays = parsedValue;
        Object.assign(temp[index], {
          totalCost: parseFloat(totalCost).toFixed(2),
          chargableCost: parseFloat(totalCost).toFixed(2),
          currency: costInforMations.currency,
        });
        return temp;
      });
    } else if (paymentCycle === "Yearly") {
      setSelectedCost((prevSelectedCost) => {
        const temp = [...prevSelectedCost];
        temp[index].totalDays = parsedValue;
        Object.assign(temp[index], {
          totalCost: parseFloat(totalCost).toFixed(2),
          chargableCost: parseFloat(totalCost).toFixed(2),
          currency: costInforMations.currency,
        });
        return temp;
      });
    }
  };
  const fileHandler = (event, index) => {
    const file = Array.from(event.target.files);
    setFileUpload((prev) => {
      return [...prev, ...file];
    });
    setHandleFileView((prev) => {
      return { ...prev, [index]: true };
    });

    setFileData((prev) => [
      ...prev,
      ...file.map((e) => ({ name: e.name, index })),
    ]);
    // setHandleFileView((prev) =>
    //   index >= 0 && index < prev.length
    //     ? { ...prev, [index]: true }
    //     : (console.error("Invalid index:", index), prev)
    // );
  };
  console.log("fileUpload", fileUpload, handleFileView);
  const handleDelete = (index) => {
    setHandleFileView((prev) => ({ ...prev, [index]: false }));

    setFileUpload((prev) => {
      const files = [...prev];
      const fileIndex = fileData.findIndex((file) => file.index === index);
      console.log("fileUpload", fileIndex);
      if (fileIndex !== -1) {
        setFileData((prev) => prev.filter((file) => file.index !== index));
        files.splice(fileIndex, 1);
      }

      return files;
    });
  };
  useEffect(() => {
    setSelectedCost((prevSelectedCost) => {
      return prevSelectedCost.map((item) => {
        if (item.paymentCycle === "Monthly") {
          return {
            ...item,
            totalDays: workingDays,
            totalCost: calculateTotalCost({
              ...item,
              totalDays: workingDays,
            }),
            chargableCost: 0,
          };
        } else if (item.paymentCycle === "Yearly") {
          return {
            ...item,
            totalDays: defaultYearlyDays,
            totalCost: calculateTotalCost({
              ...item,
              totalDays: defaultYearlyDays,
            }),
            chargableCost: 0,
          };
        }
        return item;
      });
    });
  }, [workingDays, monthAndYear.month]);

  useEffect(() => {
    setSelectedCost(() => {
      let result = selectedCostData.map((item) => {
        if (item.paymentCycle === "Monthly") {
          return {
            ...item,
            totalDays: workingDays,
            totalCost: calculateTotalCost({
              ...item,
              totalDays: workingDays,
            }),
            chargableCost: 0,
          };
        } else if (item.paymentCycle === "Yearly") {
          return {
            ...item,
            totalDays: defaultYearlyDays,
            totalCost: calculateTotalCost({
              ...item,
              totalDays: defaultYearlyDays,
            }),
            chargableCost: 0,
          };
        }
        return { ...item, totalDays: 0, chargableCost: 0 };
      });
      return [...result];
    });
    setDefaultMonthlyDays(22);
    // setYearMonth(inintialYearMonth);
    return () => {
      setSelectedCost(() => {
        let result = selectedCostData.map((item) => {
          if (item.paymentCycle === "Monthly") {
            return { ...item, totalDays: workingDays };
          } else if (item.paymentCycle === "Yearly") {
            return { ...item, totalDays: defaultYearlyDays };
          }
          return { ...item, totalDays: 0 };
        });
        return [...result];
      });
    };
  }, [selectedCostData]);

  useEffect(() => {
    setIndexes([]);
    setIsEdit(false);
  }, [isEditIcon]);
  function calculateTotalCost(ele, index) {
    console.log(ele);
    let totalCost = 0;
    if (ele.paymentCycle === "Hourly") {
      console.log("Hourly");
      totalCost =
        parseFloat(parseFloat(ele.cost) * parseFloat(ele.totalDays)).toFixed(
          2
        ) || 0;
      return totalCost;
    } else if (ele.paymentCycle === "Daily") {
      console.log("Daily");
      totalCost =
        parseFloat(parseFloat(ele.cost) * parseFloat(ele.totalDays)).toFixed(
          2
        ) || 0;
      return totalCost;
    } else if (ele.paymentCycle === "Monthly" && ele.totalDays) {
      console.log("Monthly");
      let result =
        (parseFloat(ele.cost) / workingDays) * workingDays +
        (parseFloat(ele.cost) / workingDays) *
          (parseFloat(ele.totalDays) - workingDays);
      console.log("fd", ele.cost, parseFloat(ele.totalDays));
      totalCost = parseFloat(result).toFixed(2);
      return totalCost;
    } else if (ele.paymentCycle === "Yearly" && ele.totalDays) {
      console.log("Monthly");
      let result =
        (parseFloat(ele.cost) / defaultYearlyDays) * defaultYearlyDays +
        (parseFloat(ele.cost) / defaultYearlyDays) *
          (parseFloat(ele.totalDays) - defaultYearlyDays);
      console.log("fd", ele.cost, parseFloat(ele.totalDays));
      totalCost = parseFloat(result).toFixed(2);
      return totalCost;
    } else {
      totalCost = 0;
    }
  }

  // ...

  let totalCostSum;
  if (numberOfMileStone) {
    totalCostSum = selectedCost.reduce(
      (accumulator, currentCost) =>
        accumulator + parseFloat(currentCost.cost || 0),
      0
    );
  } else {
    totalCostSum = selectedCost.reduce(
      (accumulator, currentCost) =>
        accumulator + parseFloat(currentCost.totalCost || 0),
      0
    );
  }
  //check amount type,

  const dueAmount = parseFloat(dueAmountDetails.Amount) || 0;

  // if (dueAmountDetails.AmountType === "Outstanding") {
  //   totalCostSum = totalCostSum + dueAmount;
  // } else {
  //   totalCostSum = totalCostSum - dueAmount;
  // }

  let gstSum;
  if (
    clientCompany?.companyCountry?.toLowerCase() === "india" &&
    costInforMations?.homeCompanyCountry?.toLowerCase() === "india"
  ) {
    if (clientCompany?.companyState === costInforMations?.homeCompanyState) {
      gstSum = gstValues?.cgst + gstValues.sgst;
    } else if (
      clientCompany?.companyState !== costInforMations?.homeCompanyState
    ) {
      gstSum = gstValues?.igst;
    }
  } else {
    gstSum = 0;
  }

  if (costInforMations.gstApplicable && !costInforMations.gstIncluded) {
    const gstDecimal = 1 + gstSum / 100;
    totalCostSum = totalCostSum / gstDecimal;
  }

  let amountPendingDetails = { ...dueAmountDetails };

  //outstanding amount calculation logic
  // let outstandingAmount = 0;

  // if (
  //   costInforMations.gstApplicable &&
  //   // !costInforMations.gstIncluded &&
  //   dueAmountDetails.Amount !== 0 &&
  //   dueAmountDetails.AmountType === "Outstanding"
  // ) {
  //   const gstDecimal = 1 + gstSum / 100;
  //   outstandingAmount = (amountPendingDetails.Amount / gstDecimal).toFixed(2);
  // } else if (
  //   dueAmountDetails.Amount !== 0 &&
  //   dueAmountDetails.AmountType === "Outstanding"
  // ) {
  //   outstandingAmount = amountPendingDetails.Amount;
  // }
  const handleDiscountChange = (event) => {
    const enteredDiscount = event.target.value;

    if (!isNaN(enteredDiscount)) {
      if (enteredDiscount >= 0 && enteredDiscount <= totalCostSum) {
        setDiscount(enteredDiscount);
      } else {
        toast.warn("Discount amount must be lower than the total cost");
      }
    } else {
      toast.warn("Please enter a valid number for the discount");
    }
  };
  // const postDiscountAmount =
  //   totalCostSum + parseFloat(outstandingAmount) - discount;
  const postDiscountAmount = totalCostSum - discount;
  console.log(
    "post",
    postDiscountAmount,
    totalCostSum,
    // typeof outstandingAmount,
    discount
  );

  function calculateTax(amount, percentage) {
    return (amount * percentage) / 100;
  }
  function calculateGST(amount) {
    const igstAmount = calculateTax(amount, provideGstPercentage().IGST);
    const cgstAmount = calculateTax(amount, provideGstPercentage().CGST);
    const sgstAmount = calculateTax(amount, provideGstPercentage().SGST);

    return {
      igstAmount: igstAmount.toFixed(2),
      cgstAmount: cgstAmount.toFixed(2),
      sgstAmount: sgstAmount.toFixed(2),
    };
  }
  const { igstAmount, cgstAmount, sgstAmount } =
    calculateGST(postDiscountAmount);
  let netAmountWithGST = (
    postDiscountAmount +
    parseFloat(igstAmount) +
    parseFloat(cgstAmount) +
    parseFloat(sgstAmount)
  ).toFixed(2);
  //ADDVANCE AMOUNT LOGIC
  // let advanceAmount = 0;
  // if (
  //   dueAmountDetails.Amount !== 0 &&
  //   dueAmountDetails.AmountType === "Advance"
  // ) {
  //   netAmountWithGST = (netAmountWithGST - dueAmountDetails.Amount).toFixed(2);
  // }

  const roundedNetAmountWithGST = Math.ceil(netAmountWithGST).toFixed(2);

  const roundedAmountValue = parseFloat(
    roundedNetAmountWithGST - netAmountWithGST
  ).toFixed(2);
  netAmountDetails = {
    totalCostSum: isNaN(totalCostSum) ? 0 : totalCostSum.toFixed(2),
    igst: igstAmount,
    cgst: cgstAmount,
    sgst: sgstAmount,
    discount: discount,
    roundUpValue: roundedAmountValue,
    netAmount: roundedNetAmountWithGST,
    invoiceTerm: invoiceTerm,
    // outstandingAmount: outstandingAmount,
  };

  function provideGstPercentage() {
    if (
      clientCompany?.companyCountry?.toLowerCase() === "india" &&
      costInforMations?.homeCompanyCountry?.toLowerCase() === "india"
    ) {
      if (clientCompany?.companyState === costInforMations?.homeCompanyState) {
        return {
          IGST: 0,
          CGST: gstValues?.cgst,
          SGST: gstValues.sgst,
        };
      }
      if (clientCompany?.companyState !== costInforMations?.homeCompanyState) {
        return {
          IGST: gstValues?.igst,
          CGST: 0,
          SGST: 0,
        };
      }
    }
    return {
      IGST: 0,
      CGST: 0,
      SGST: 0,
    };
  }

  //Year month
  function isCurrentDateWithinTwoMonthsAgo() {
    const currentDate = new Date("2023-02-11");
    // const currentDate = new Date();
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

    if (currentDate.getFullYear() === twoMonthsAgo.getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  //month three
  function returnMonth(month) {
    // debugger;
    console.log("month === new Date().getMonth() 0", month);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date("2023-02-11");
    // const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    console.log(
      "monthyuyt",
      // month === currentMonth + 1,
      month,
      currentMonth
    );
    if (month === currentMonth) {
      const threeMonthsAgo = [];
      for (let i = 0; i <= month; i++) {
        threeMonthsAgo.push(months[i]);
      }

      return threeMonthsAgo;
    }
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }

  // From

  const provideYear = () => {
    //   if (new Date("2023-02-11").getMonth() <= 1) {
    //     return [2022, 2023];
    //   }
    //   return [2023];
    // };

    if (new Date().getMonth() <= 1) {
      return [new Date().getFullYear() - 1, new Date().getFullYear()];
    }
    return [new Date().getFullYear()];
  };

  const provideMonth = () => {
    // if (new Date("2023-02-11").getFullYear() > monthAndYear.year) {
    //   return monthsInTheWorld;
    // }
    if (new Date().getFullYear() > monthAndYear.year) {
      return monthsInTheWorld;
    }
    const tempMonths = [...monthsInTheWorld];
    // return tempMonths.slice(0, new Date("2023-02-11").getMonth() + 1);
    return tempMonths.slice(0, new Date().getMonth() + 1);
  };

  const handleMonthAndYear = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "month") {
      const indexOfTheMonth = monthsInTheWorld.indexOf(value);
      setMonthAndYear((prev) => ({ ...prev, [name]: indexOfTheMonth }));
      // setMonthAndYear((prev) => ({ ...prev, [name]: value }));
      return;
    }
    setMonthAndYear((prev) => ({ ...prev, [name]: value }));
  };

  //To

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={costDialogOpen}
        onClose={() => {
          handleCostDialouge();
        }}
      >
        <DialogTitle>Cost Calculation</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2.5}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      label="Year"
                      name="year"
                      value={monthAndYear.year}
                      onChange={handleMonthAndYear}
                    >
                      {provideYear().map((year, index) => {
                        return (
                          <MenuItem value={year} key={index}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                  {" "}
                  <FormControl fullWidth>
                    <InputLabel>Month</InputLabel>
                    <Select
                      name="month"
                      label="Month"
                      value={monthsInTheWorld[monthAndYear.month]}
                      onChange={handleMonthAndYear}
                    >
                      {provideMonth().map((month, index) => (
                        <MenuItem value={month} key={index}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                  <FormControl fullWidth>
                    <InputLabel>Invoice Term</InputLabel>
                    <Select
                      name="Invoice Term"
                      label="Invoice Term"
                      value={invoiceTerm}
                      onChange={handleInvoiceTermChange}
                    >
                      <MenuItem value="Net 7">Net 7</MenuItem>
                      <MenuItem value="Net 30">Net 30</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2.5}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Tooltip title="If you check this option, the contact person's name will appear in the 'To' section of the invoice, above the company's name.">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showContactName}
                            onChange={({ target: { checked } = {} }) => {
                              setShowContactName(checked);
                            }}
                          />
                        }
                        label="Show Contact Name"
                      />
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div>
                <>
                  <Grid container spacing={1.2}>
                    {
                      <Grid item mt={1} xs={12}>
                        <Grid container spacing={1.2}>
                          <>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Schedule</TableCell>
                                    {!numberOfMileStone && (
                                      <TableCell>Resource</TableCell>
                                    )}
                                    <TableCell>Cost</TableCell>
                                    <TableCell>Currency</TableCell>
                                    {!numberOfMileStone && (
                                      <TableCell>Charging Period</TableCell>
                                    )}
                                    {!numberOfMileStone && (
                                      <TableCell>Total Cost</TableCell>
                                    )}
                                    {!numberOfMileStone && (
                                      <TableCell>Chargeable Cost</TableCell>
                                    )}
                                    {!numberOfMileStone && (
                                      <TableCell>Timesheet</TableCell>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedCost.map((ele, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {numberOfMileStone
                                          ? ele.milestoneName
                                          : ele.paymentCycle}
                                      </TableCell>{" "}
                                      {!numberOfMileStone && (
                                        <TableCell>
                                          {ele.resourceName}
                                        </TableCell>
                                      )}{" "}
                                      <TableCell>{ele.cost}</TableCell>
                                      <TableCell>
                                        {costInforMations.currency}
                                      </TableCell>
                                      {!numberOfMileStone && (
                                        <TableCell key={index + 1}>
                                          <TextField
                                            id="outlined-basic"
                                            label={
                                              ele.paymentCycle === "Hourly"
                                                ? "Charging Hours"
                                                : "Charging Days"
                                            }
                                            variant="outlined"
                                            type="number"
                                            name="totalDays"
                                            sx={{ width: "145px" }}
                                            value={
                                              ele.paymentCycle === "Monthly"
                                                ? ele.totalDays
                                                : ele.totalDays !== undefined
                                                ? ele.totalDays
                                                : totalDays
                                            }
                                            onChange={(e) =>
                                              handleTotalDays(
                                                e,
                                                index,
                                                ele.paymentCycle
                                              )
                                            }
                                            onClick={(e) => {
                                              if (e.target.value === "0") {
                                                e.target.value = "";
                                              }
                                            }}
                                          />
                                        </TableCell>
                                      )}
                                      {!numberOfMileStone && (
                                        <TableCell key={index}>
                                          {isNaN(calculateTotalCost(ele, index))
                                            ? 0
                                            : calculateTotalCost(ele, index)}
                                        </TableCell>
                                      )}
                                      {!numberOfMileStone && (
                                        <TableCell key={index}>
                                          <TextField
                                            id="outlined-basic"
                                            label="Chargeable
                                          Cost"
                                            variant="outlined"
                                            type="number"
                                            sx={{ width: "145px" }}
                                            value={
                                              isNaN(
                                                calculateTotalCost(ele, index)
                                              )
                                                ? 0
                                                : calculateTotalCost(ele, index)
                                            }
                                            disabled
                                          ></TextField>
                                        </TableCell>
                                      )}
                                      {!numberOfMileStone && (
                                        <TableCell>
                                          {handleFileView[index] ? (
                                            <FormControl variant="outlined">
                                              <OutlinedInput
                                                readOnly
                                                id="outlined-adornment-password"
                                                type="text"
                                                sx={{ width: "290px" }}
                                                endAdornment={
                                                  <InputAdornment position="end">
                                                    <IconButton
                                                      aria-label="toggle password visibility"
                                                      onClick={() =>
                                                        handleDelete(index)
                                                      }
                                                      edge="end"
                                                    >
                                                      <DeleteIcon
                                                        sx={{ color: "red" }}
                                                      />
                                                    </IconButton>
                                                  </InputAdornment>
                                                }
                                                value={
                                                  fileData.find(
                                                    (file) =>
                                                      file.index === index
                                                  )?.name || ""
                                                }
                                              />
                                            </FormControl>
                                          ) : (
                                            <TextField
                                              type="file"
                                              sx={{ width: "290px" }}
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <UploadFileIcon />
                                                  </InputAdornment>
                                                ),
                                                inputProps: {
                                                  accept: ".xlsx, .xls",
                                                },
                                              }}
                                              InputLabelProps={{
                                                shrink: false,
                                              }}
                                              onChange={(event) => {
                                                fileHandler(event, index);
                                              }}
                                            />
                                          )}
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        </Grid>
                        {/* {dueAmountDetails.Amount !== 0 && (
                          <Grid
                            item
                            xs={12}
                            spacing={1}
                            sx={{ marginTop: "16px" }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <Typography>
                                  <span>
                                    <b>{`${dueAmountDetails.AmountType} Amount`}</b>
                                  </span>{" "}
                                  : {dueAmountDetails.Amount}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )} */}

                        <Grid
                          item
                          xs={12}
                          spacing={1}
                          sx={{ marginTop: "16px" }}
                        >
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                          >
                            {console.log("second UI +2")}
                            <Grid item xs={3.3}>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>SUBTOTAL </b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>
                                    {isNaN(totalCostSum.toFixed(2))
                                      ? 0
                                      : totalCostSum.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                {/* {dueAmountDetails.Amount !== 0 &&
                                  dueAmountDetails.AmountType ===
                                    "Outstanding" && (
                                    <>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <b>Outstanding(+)</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Typography>
                                          {outstandingAmount}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                    </>
                                  )} */}
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>Discount</b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    id="standard-number"
                                    type="number"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant="standard"
                                    value={discount}
                                    style={{ width: "60px" }}
                                    onChange={handleDiscountChange}
                                    onClick={(e) => {
                                      if (e.target.value === "0") {
                                        e.target.value = "";
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>
                                      {/* IGST@ {costInforMations.gst?.igst ?? 0}%{" "} */}
                                      IGST@ {provideGstPercentage().IGST}%{" "}
                                    </b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  {isNaN(igstAmount) ? 0 : igstAmount}
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>
                                      {/* CGST@ {costInforMations.gst?.cgst ?? 0}%{" "} */}
                                      CGST@ {provideGstPercentage().CGST}%{" "}
                                    </b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>
                                    {isNaN(cgstAmount) ? 0 : cgstAmount}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>
                                      {/* SGST@ {costInforMations.gst?.sgst ?? 0}%{" "} */}
                                      SGST@ {provideGstPercentage().SGST}%{" "}
                                    </b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>
                                    {isNaN(sgstAmount) ? 0 : sgstAmount}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                {/* {dueAmountDetails.Amount !== 0 &&
                                  dueAmountDetails.AmountType === "Advance" && (
                                    <>
                                      <Grid item xs={6}>
                                        <Typography>
                                          <b>Advance(-)</b>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <Typography>
                                          {dueAmountDetails.Amount}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}></Grid>
                                    </>
                                  )} */}
                                <Grid item xs={6}>
                                  <Typography>
                                    <b>Round-off(+)</b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>
                                    {isNaN(roundedAmountValue)
                                      ? 0
                                      : roundedAmountValue}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}></Grid>

                                <Grid item xs={6}>
                                  <Typography>
                                    <b>Net Amount </b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>
                                    {isNaN(roundedNetAmountWithGST)
                                      ? 0
                                      : roundedNetAmountWithGST}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={8.7}></Grid>
                          </Grid>
                        </Grid>
                        {!numberOfMileStone && (
                          <Grid>
                            <Typography
                              variant="caption"
                              sx={{ fontSize: 11.5 }}
                            >
                              P.S: Please upload an Excel file (.xls or .xlsx
                              format).*
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    }
                  </Grid>
                </>
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setSelectedCost([]);
              setCostDialogOpen(false);
              setFileUpload((prev) => {
                return (prev = []);
              });
              setFileData([]);
              setDiscount(0);
              setDefaultMonthlyDays(22);
              setWorkingDays(0);
              setHandleFileView({});
              setMonthAndYear((prev) => ({
                ...prev,
                month: new Date().getMonth() - 1,
              }));
              setIsEdit(false);
              setIndexes([]);
              // setSelectedCost([]);
            }}
            color="error"
          >
            CANCEL
          </Button>

          <Button
            onClick={
              numberOfMileStone
                ? handleFixedGenerateInvoice
                : handleGenerateInvoice
            }
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CostCalculation;
