import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Page from "../components/UI/Page";
import { AuthContext } from "../context/auth.context";

import { directChangePassword } from "../services/users.service";
import Iconify from "../components/UI/Iconify";
import {
  clearPassword,
  createDataInIndexedDb,
  deleteDataInIndexedDb,
  getPassword,
  insertDataInIndexedDb,
} from "../utils/indexedDb";
import { encryption } from "../utils/encryption";
import { MailSyncContext } from "../context/mailSync.context";
import { directChangePasswordForSuperAdmin } from "../services/superAdmin.service";
let hitApi = true;

function Settings() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { handlegetAllContactEmail, mailSync } = useContext(MailSyncContext);
  const { currentUser, emailPassword, setEmailPassword } =
    useContext(AuthContext);

  const initialPasswordObj = {
    oldPassword: "",
    newPassword: "",
    rePassword: "",
  };
  const [passwordObj, setPasswordObj] = useState(initialPasswordObj);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleOldPasswordShowHide = () => {
    setShowOldPassword((show) => !show);
  };

  const handleNewPasswordShowHide = () => {
    setShowNewPassword((show) => !show);
  };

  const handleConfirmPasswordShowHide = () => {
    setShowConfirmPassword((show) => !show);
  };

  //consent email_password

  const [showConsentPassword, setShowConsentPassword] = useState(false);
  const [isAuthFailed, setIsAuthFailed] = useState(false);
  const [consentFullView, setConsentFullView] = useState(false);
  const [formValue, setFormValue] = useState({
    email: currentUser.email,
    email_password: "",
  });

  const handleConsentPasswordShowHide = () => {
    setShowConsentPassword((show) => !show);
  };

  const handleChangeConsentEmail = (e) => {
    setIsAuthFailed(false);
    setFormValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleConsentEmailClick = async () => {
    if (!formValue.email_password) {
      toast("Enter Your Email's Password");
      return;
    }
    setFormValue((prev) => ({
      ...prev,
      email: currentUser.email,
    }));
    const encryptPassword = encryption(formValue.email_password);
    createDataInIndexedDb();
    const insertData = await insertDataInIndexedDb(null, encryptPassword);
    console.log(
      "insertData",
      insertData,
      "formValue",
      formValue.email_password
    );
    const emailFetch = await handlegetAllContactEmail();
    console.log("emailFetch", emailFetch, mailSync);
    if (emailFetch === "Aunthetication failed") {
      setIsAuthFailed(true);
    } else {
      setEmailPassword(formValue.email_password);
      setConsentFullView(false);
    }
  };

  const handleRemovePassword = async () => {
    setOpen(false);
    clearPassword();
    formValue.email_password = "";
    setEmailPassword("");
    await deleteDataInIndexedDb();
  };

  const handleChange = (e) => {
    setPasswordObj((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(passwordObj);
  };

  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const handlePasswordChange = async () => {
    if (!validate(passwordObj.oldPassword)) {
      toast("Old Password can not be empty");
      hitApi = false;
    }
    if (!validate(passwordObj.newPassword)) {
      toast("New Passwprd can not be empty");
      hitApi = false;
    }
    if (!validate(passwordObj.rePassword)) {
      toast("Confirm Password can not be empty");
      hitApi = false;
    }
    if (passwordObj.newPassword !== passwordObj.rePassword) {
      toast("New Password and Confirm Password don't match");
      hitApi = false;
    }
    if (passwordObj.oldPassword === passwordObj.newPassword) {
      toast("Old Password and New Password can not be same");
      hitApi = false;
    }
    if (
      validate(passwordObj.oldPassword) &&
      validate(passwordObj.newPassword) &&
      validate(passwordObj.rePassword) &&
      passwordObj.newPassword === passwordObj.rePassword &&
      passwordObj.oldPassword !== passwordObj.newPassword
    ) {
      hitApi = true;
    }
    console.log("Handle password change called");
    if (passwordObj.newPassword === passwordObj.rePassword) {
      try {
        let temp = {};
        let response;
        temp = { ...passwordObj, userId: currentUser._id };
        delete temp.rePassword;
        console.log(temp);
        // const response = hitApi ? await directChangePassword(temp) : null;
        if (hitApi) {
          if (currentUser.role === "SUPER_ADMIN") {
            response = await directChangePasswordForSuperAdmin(temp);
          } else {
            response = await directChangePassword(temp);
          }
        }
        console.log(response);
        if (response.data.status === true) {
          toast("Password changed successfully");
          setPasswordObj(initialPasswordObj);
        } else {
          toast(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast(error.response.data.data[0]);
      }
    }
  };
  //monitor state var emailpassword

  useEffect(() => {
    console.log("Email Password");
  }, [emailPassword]);

  const password = async () => {
    const getpass = await getPassword();
    console.log("getpass", getpass);
    if (getpass !== undefined && getpass !== null && getpass !== "") {
      setEmailPassword(getpass);
      console.log("UseEffect triggered");
    }
  };

  // getpassword from IndexedDB
  useEffect(() => {
    password();
  }, [emailPassword]);
  console.log("Email Password", emailPassword);
  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    // justifyContent="center"
                    // alignItems="center"
                    minHeight="50vh"
                  >
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        {/* <Grid container spacing={2}> */}
                        <TextField
                          label="Current Password"
                          variant="outlined"
                          placeholder="Current Password"
                          type={showOldPassword ? "text" : "password"}
                          name="oldPassword"
                          required
                          value={passwordObj.oldPassword}
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleOldPasswordShowHide}
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showOldPassword
                                        ? "eva:eye-fill"
                                        : "eva:eye-off-fill"
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ marginTop: "30px" }}
                        ></TextField>
                        <TextField
                          label="New Password"
                          variant="outlined"
                          placeholder="New Password"
                          type={showNewPassword ? "text" : "password"}
                          name="newPassword"
                          required
                          value={passwordObj.newPassword}
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleNewPasswordShowHide}
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showNewPassword
                                        ? "eva:eye-fill"
                                        : "eva:eye-off-fill"
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ marginTop: "15px" }}
                        ></TextField>
                        <TextField
                          label="Confirm Password"
                          variant="outlined"
                          placeholder="Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          name="rePassword"
                          required
                          value={passwordObj.rePassword}
                          fullWidth
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleConfirmPasswordShowHide}
                                  edge="end"
                                >
                                  <Iconify
                                    icon={
                                      showConfirmPassword
                                        ? "eva:eye-fill"
                                        : "eva:eye-off-fill"
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ marginTop: "15px" }}
                        ></TextField>
                        <Button
                          onClick={handlePasswordChange}
                          variant="contained"
                          // fullWidth
                          sx={{
                            marginTop: "45px",
                            marginBottom: "20px",
                            float: "right",
                          }}
                        >
                          SAVE
                        </Button>

                        {/* </Grid> */}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {currentUser?.role === "SUPER_ADMIN" ? null : (
            <Grid item xs={6}>
              <Card
                sx={{ backgroundColor: "#f1f2f6" }}
                elevation={5}
                variant="outlined"
              >
                <CardContent>
                  <Grid container spacing={0}>
                    <Grid item xs={12} display="flex" minHeight="50vh">
                      <Card
                        elevation={3}
                        variant="outlined"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  lineHeight: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                Give consent to access your email so that we can
                                pull your email and access your inbox and help
                                to associate with the lead. It will ask you
                                password and evaluate your password and start
                                syncing emails for the first time. Afterword you
                                need to sync the emails from the lead list page
                                and email list page.
                              </Typography>
                            </Grid>
                            {consentFullView ? (
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography
                                      sx={{ textAlign: "center" }}
                                      color="primary"
                                    >
                                      By clicking on "SAVE", you agree that we
                                      can read your mails and automate mails
                                      from your email.
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={6}>
                                        <TextField
                                          margin="dense"
                                          label="Email Address"
                                          type="email"
                                          name="email"
                                          value={currentUser.email}
                                          fullWidth
                                          variant="outlined"
                                          disabled
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          margin="dense"
                                          label="Enter Your Password"
                                          type={
                                            showConsentPassword
                                              ? "text"
                                              : "password"
                                          }
                                          name="email_password"
                                          value={
                                            isAuthFailed
                                              ? ""
                                              : formValue.email_password
                                          }
                                          onChange={handleChangeConsentEmail}
                                          fullWidth
                                          variant="outlined"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  onClick={
                                                    handleConsentPasswordShowHide
                                                  }
                                                  edge="end"
                                                >
                                                  <Iconify
                                                    icon={
                                                      showConsentPassword
                                                        ? "eva:eye-fill"
                                                        : "eva:eye-off-fill"
                                                    }
                                                  />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sx={{}}>
                                        <Button
                                          sx={{
                                            marginTop: "16px",
                                            float: "right",
                                          }}
                                          variant="contained"
                                          onClick={handleConsentEmailClick}
                                        >
                                          VERIFY
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <Grid container spacing={3}>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{ marginTop: "135px" }}
                                  >
                                    {emailPassword !== "" ? (
                                      <>
                                        <Grid
                                          container
                                          spacing={0}
                                          sx={{ marginTop: "25px" }}
                                        >
                                          <Grid item xs={6}>
                                            <Typography
                                              variant="subtitle1"
                                              sx={{
                                                marginTop: "15px",
                                              }}
                                            >
                                              Email Password Consent
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <Tooltip title="Click here To remove your password">
                                              <Button
                                                onClick={handleClickOpen}
                                                sx={{
                                                  cursor: "pointer",
                                                  float: "right",
                                                }}
                                                variant="contained"
                                              >
                                                DEACTIVATE
                                              </Button>
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid
                                          container
                                          spacing={0}
                                          sx={{ marginTop: "25px" }}
                                        >
                                          <Grid
                                            item
                                            xs={6}
                                            sx={{
                                              marginTop: "15px",
                                            }}
                                          >
                                            <Typography variant="subtitle1">
                                              Email Password Consent
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={6}>
                                            <Tooltip title="Click to add Email Password">
                                              <Button
                                                variant="contained"
                                                // fullWidth
                                                onClick={() => {
                                                  setConsentFullView(true);
                                                }}
                                                sx={{
                                                  cursor: "pointer",
                                                  float: "right",
                                                }}
                                              >
                                                ACTIVATE
                                              </Button>
                                            </Tooltip>
                                            {/* <ManageAccountsIcon /> */}
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deactvate email consnent?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to deactivate the syncing of emails into Ulalpha? You
              can reactivate it by clicking the activate button again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NO</Button>
            <Button onClick={handleRemovePassword} autoFocus>
              YES
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

export default Settings;
