import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MailIcon from "@mui/icons-material/Mail";
import emailCampaignImg from "../../images/undraw_email_campaign_re_m6k5.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import EmailList from "./EmailList";
import { useState } from "react";
import DropOnEditor from "../../components/DropContainers/DropOnEditor";
import Editor from "../../components/texteditor/Editor";
import { cleanHtml } from "../../utils/convertHTMLtoNormalText";
import DropOnTextField from "../../components/DropContainers/DropOnTextField";
import ObjectViewAccordion from "../EmailTemplate/ObjectViewAccordion";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getSalesCampaign } from "../../services/salesCampaign.service";
import { useEffect } from "react";

import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  getCurrentDate,
  getCurrentQuarterDate,
  getLastMonthDate,
  getLastQuarterDate,
  getLastTwoMonthsDate,
} from "../../utils/dateUtils";
import LoaderDialouge from "../../components/Dialogs/LoaderDialouge";
import ReactQuill from "react-quill";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #2165d1 0%, #2165d1 50%, #2165d1  100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #2165d1 0%, #2165d1 50%, #2165d1  100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #2165d1 0%, #2165d1 50%, #2165d1  100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #2165d1 0%, #2165d1 50%, #2165d1  100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <MailIcon />,
    4: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

// case 1

export default function SalesCampaignStepper({
  activeStep,
  setActiveStep,
  formData,
  setFormData,
  emailContent,
  setEmailContent,
  contactEmail,
  setcontactEmail,
  selectedEmails,
  setSelectedEmails,
  sendingDate,
  setSendingDate,
  sendingTime,
  setSendingTime,
  selectedCampaign,
  setSelectedCampaign,
  checked,
  setChecked,
  loaderDialog,
  setLoaderDialog,
  isEdit = false,
  isView,
  steps,
  getExclusionEmail,
  stableContactEmails = [],
  setStableContactEmails,
  searchText,
  setSearchText,
  getCoantactEmails,
}) {
  const maximumPeopleCapacityFieldRegex = /^\d+$/;
  const { state } = useLocation();
  const editor = React.useRef(null);

  const [campaigns, setCampaigns] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );

  const [interval, setInterval] = useState(
    new Date(new Date().setDate(new Date().getDate() - 60)).toDateString()
  );

  const [customDate, setCustomDate] = useState(false);
  const [showChip, setShowChip] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [popup, setPopup] = useState(false);
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const storedData = React.useMemo(() => {
    // Store the data in a local state variable
    return state || {};
  }, [state]);

  const [fromSalesCampaign, setFromSalesCampaign] = React.useState(true);
  const [emailTemplet, setEmailTemplet] = React.useState(
    state?.isEdit
      ? { ...state.emailTemplateData }
      : {
          moduleName:
            storedData?.[0]?.moduleName?.toUpperCase() === "ALL"
              ? "COMPANIES"
              : storedData?.[0]?.moduleName?.toUpperCase() || "COMPANIES",
          eventName: "",
          to: [],
          subject: "",
          body: "",
        }
  );

  const isValidStep = (step) => {
    console.log("jhj878034", activeStep, step);
    if (activeStep !== step && activeStep === 0) {
      console.log("jhj878034");
      if (step === 2) {
        return (
          cleanHtmls(emailContent.trim()) && formData.subject.trim() !== ""
        );
      } else if (step === 3) {
        return selectedEmails.length > 0;
      }
      return (
        formData.campaignName.trim() !== "" &&
        formData.maxCapacity.trim() !== ""
      );
    } else if (activeStep !== step && activeStep === 1) {
      console.log("jhj878034");
      console.log("rgtr" + emailContent);
      if (step === 0) {
        console.log("jhj8780340");
        return (
          formData.campaignName.trim() !== "" &&
          formData.maxCapacity.trim() !== ""
        );
      } else if (step === 2) {
        console.log("jhj8780341");
        return (
          cleanHtmls(emailContent.trim()) && formData.subject.trim() !== ""
        );
      } else if (step === 3) {
        console.log("jhj8780342");
        return selectedEmails.length > 0;
      }
      return true;
    } else if (activeStep !== step && activeStep === 2) {
      console.log("jhj878034");
      if (step === 0) {
        return (
          formData.campaignName.trim() !== "" &&
          formData.maxCapacity.trim() !== ""
        );
      } else if (step === 1) {
        return (
          cleanHtmls(emailContent.trim()) && formData.subject.trim() !== ""
        );
      }
      return selectedEmails.length > 0;
    }
    console.log("jhj878034");
    return true; // Or add more validations for other steps if needed
  };
  const cleanHtmls = (html) => {
    const cleanTag = "<p><br></p>";

    // Trim cleanTag from the beginning
    while (html.startsWith(cleanTag)) {
      html = html.substring(cleanTag.length).trim();
    }

    // Trim cleanTag from the end
    while (html.endsWith(cleanTag)) {
      html = html.substring(0, html.length - cleanTag.length).trim();
    }

    return html;
  };
  const handleStepClick = (step) => {
    if (!isValidStep(step)) {
      toast.warning(
        <>
          You must complete
          <strong style={{ color: "secondary" }}> current step </strong> before
          moving on.
        </>
      );
      return;
    } else {
      setActiveStep(step);
    }
  };
  const getCheckedEmails = () => {
    const checkedFilterEmails = stableContactEmails
      .filter(({ _id }) => checked.includes(_id))
      .map(({ _id, email }) => {
        return { to: email, contactId: _id };
      });
    setSelectedEmails(() => [...checkedFilterEmails]);
  };

  const initialIntervals = [
    {
      label: "Select Interval",
      value: "Select Interval",
    },
    {
      label: "Last Quarter",
      value: getLastQuarterDate(),
    },
    {
      label: "Current Quarter",
      value: getCurrentQuarterDate(),
    },
    {
      label: "Current Month",
      value: getCurrentDate(), // Current Date
    },
    {
      label: "Last 2 Months",
      value: getLastTwoMonthsDate(), // Date 60 days ago
    },
    {
      label: "Last Month",
      value: getLastMonthDate(), // Date 30 days ago
    },
    {
      label: "Custom",
      value: "Custom",
    },
  ];
  const [intervals, setIntervals] = useState(initialIntervals[0]);
  let objects = [
    {
      title: "Contacts",
      accessTo: ["contacts", "leads", "projects"],
      keys: [
        "name",
        "email",
        "phone",
        "alternateContact",
        "linkedin",
        "address",
        "country",
        {
          title: "salesOwner",
          keys: ["name", "email"],
        },
        {
          title: "action",
          keys: ["toDo", "assignedBy", "doer", "dueDate", "doneOn"],
        },
        {
          title: "comment",
          keys: ["comment", "createdBy"],
        },
      ],
    },
  ];

  const handleDrop = (item, name) => {
    let { title = "", label = "", currentModule = "" } = item;
    const cleanConvertedText = cleanHtml(emailContent);
    switch (name) {
      case "subject":
        const textField = document.getElementById("subjectTextField"); // Set the actual ID of your TextField
        const cursorPosition = textField.selectionStart; // Get the cursor position

        if (title && label) {
          let newText =
            formData.subject.slice(0, cursorPosition) +
            " ${" +
            title[0].toLowerCase() +
            title.substr(1) +
            "." +
            label +
            "} " +
            formData.subject.slice(cursorPosition);
          if (
            formData.subject?.length >= 100 ||
            formData.subject?.length + newText?.length > 100
          ) {
            toast("Subject can not be more than 100 charcters");
            return;
          }
          setFormData((prev) => ({
            ...prev,
            subject: newText,
          }));
        }
        break;
      case "body": {
        let newText = "";
        if (emailContent.length && cleanConvertedText) {
          newText =
            emailContent?.trim().slice(0, emailContent.length - 4) +
            " ${" +
            title[0].toLowerCase() +
            title.substr(1) +
            "." +
            label +
            "} " +
            emailContent?.trim().slice(emailContent.length - 4);
          setEmailContent(newText);
          return;
        }
        newText = "<p>" + "${" + title + "." + label + "}" + "<p/>";
        setEmailContent(newText);
      }
      default:
        break;
    }
  };
  const fetchSaleshCampaign = async () => {
    try {
      const result = await getSalesCampaign();
      if (result) {
        // console.log("df76", result.data.data);
        setCampaigns(() => [...result.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCampaignChange = (event) => {
    // event.target.value id

    const selectedId = event.target.value;
    const selectedCampaign = campaigns.find((item) => item._id === selectedId);

    if (selectedCampaign) {
      setSelectedCampaign(selectedId);
      setEmailContent(selectedCampaign.body);
      setFormData((prev) => ({
        ...prev,
        subject: selectedCampaign.subject,
      }));
    }
  };
  const handleEndDateChange = (e) => {
    console.log("e", e);
    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }
    if (startDate.getTime() < e) {
      setEndDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };
  const handleSet = () => {
    if (!isValidDate(startDate || endDate)) {
      toast.warn("Invalid  date format");
      return;
    }
    setShowChip(true);
    setPopup(() => false);
    setCustomDate(true);
  };
  const isValidDate = (date) => {
    console.log(date, "reset date ");
    let dates = new Date(date);

    // Check if date is a valid Date object and its year is greater than 1000
    return dates instanceof Date && !isNaN(dates) && dates.getFullYear() > 1000;
  };
  const handleStartDateChange = (e) => {
    console.log("e", e);

    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }

    if (endDate.getTime() > e) {
      setStartDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };
  const toggleCheck = (id) => {
    setChecked((prevChecked) =>
      prevChecked.includes(id)
        ? prevChecked.filter((item) => item !== id)
        : [...prevChecked, id]
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "campaignName" && value.length > 100) {
      toast.warning("Campaign name can not be more than 100 charcters");
      return;
    }
    if (name === "maxCapacity") {
      if (value && !maximumPeopleCapacityFieldRegex.test(value)) {
        toast.error("Maximum capacity should only be a number.");
        return;
      }
      if (value && Number(value) == 0) {
        toast.error("Maximum people capacity should be more than 0.");
        return;
      }
      if (value && Number(value) > 9999) {
        toast.error("Maximum people capacity can not be more than 4 digit");
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 2,
                  borderTop: "1px solid grey",
                  borderLeft: "1px solid grey",
                  borderRight: "1px solid grey",
                }}
                elevation={6}
              >
                <Grid item xs={6}>
                  <CardMedia
                    component="img"
                    src={emailCampaignImg}
                    alt="Email Campaign"
                    sx={{ height: "370px", width: "700px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <CardContent>
                    <Grid container spacing={2.5}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          size="small"
                          label="Name of the campaign"
                          variant="outlined"
                          fullWidth
                          name="campaignName"
                          value={formData.campaignName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          label="Maximum people capacity"
                          variant="outlined"
                          name="maxCapacity"
                          value={formData.maxCapacity}
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Campaign
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCampaign}
                  label="Select Campaign"
                  onChange={handleCampaignChange}
                >
                  {campaigns.length > 0 ? (
                    campaigns.map((campaign) => (
                      <MenuItem key={campaign._id} value={campaign._id}>
                        {campaign.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No Campaign</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ height: "5vh" }}>
                {objects.map((obj, index) => (
                  <ObjectViewAccordion
                    object={obj}
                    key={index}
                    setEmailTemplet={setEmailTemplet}
                    currentModule={emailTemplet.moduleName}
                    fromSalesCampaign={fromSalesCampaign}
                  />
                ))}
              </div>
            </Grid>
            <Grid item xs={12} aria-describedby="component-helper-text">
              <FormHelperText id="component-helper-text">
                <IconButton size="small">
                  <InfoOutlinedIcon />
                </IconButton>
                Ensure placeholder variables are enclosed in dollar "$" and
                double curly braces <code>${"{variableName}"}</code> in the
                subject or email body. For any questions or further assistance,
                please contact our support team.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <DropOnTextField
                id="subjectTextField"
                placeholder="Subject"
                value={formData.subject}
                handleDrop={handleDrop}
                onChange={({ target: { value } = {} }) => {
                  setFormData({ ...formData, subject: value });
                }}
                label="Subject"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DropOnEditor
                Container={
                  <Editor
                    id="991122EmailCampaignEditor"
                    value={emailContent}
                    callback={(det) => {
                      setEmailContent(det);
                    }}
                  />
                }
                handleDrop={handleDrop}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={3}>
            <EmailList
              stableContactEmails={stableContactEmails}
              setStableContactEmails={setStableContactEmails}
              contactEmail={contactEmail}
              setcontactEmail={setcontactEmail}
              activeStep={activeStep}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              showChip={showChip}
              setShowChip={setShowChip}
              setCustomDate={setCustomDate}
              initialIntervals={initialIntervals}
              intervals={intervals}
              setIntervals={setIntervals}
              setPopup={setPopup}
              toggleCheck={toggleCheck}
              checked={checked}
              setChecked={setChecked}
              formData={formData}
              getExclusionEmail={getExclusionEmail}
              interval={interval}
              setInterval={setInterval}
              searchText={searchText}
              setSearchText={setSearchText}
              getCoantactEmails={getCoantactEmails}
            />
          </Grid>
        );
      default:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                mb={2}
                mt={selectedEmails.length >= 4 ? 20 : 7}
              >
                <Typography variant="h6" style={{ marginRight: "8px" }}>
                  Campaign Name
                </Typography>

                <Typography variant="body2">{` ${formData.campaignName}`}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h6" style={{ marginRight: "15px" }}>
                  {/* Email included : {formData.maxCapacity} */}
                  Email Included
                </Typography>
                <Typography variant="body">
                  {/* Email included : {formData.maxCapacity} */}
                  {checked.length}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Selected Emails
              </Typography>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  maxHeight: 200,
                  overflow: "auto",
                }}
              >
                {selectedEmails.map((value, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText
                        id={index}
                        primary={`${index + 1} . ${value.to}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" mb={2}>
                Email Subject
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" mb={2}>
                {formData.subject}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" mb={2}>
                Email Content
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                value={emailContent}
                className={"app blurred-editor"}
                readOnly={activeStep === 3}
              />
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  disablePast
                  label="Date of Sending"
                  inputFormat="MM/dd/yyyy"
                  value={sendingDate}
                  onChange={handleSendingDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disabled={isView}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                dispaly: "flex",
                justifyContent: "flex-start",
                alignSelf: "start",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <TimePicker
                  label="Time of Sending"
                  value={sendingTime}
                  onChange={handleSendingTimeChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disabled={isView}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        );
    }
  };
  const handleSendingDateChange = (newDate) => {
    setSendingDate(newDate);
  };

  const handleSendingTimeChange = (newTime) => {
    setSendingTime(newTime);
  };
  const dynamicHeight = () => {
    if (activeStep === 0) {
      return "50vh";
    } else if (activeStep === 1) {
      return "90vh";
    } else if (activeStep === 2) {
      return "70vh";
    } else if (activeStep === 3) {
      return "120vh";
    } else {
      return "90vh";
    }
  };
  useEffect(() => {
    // Example use of getCheckedEmails
    getCheckedEmails();
  }, [checked]);

  useEffect(() => {
    if (activeStep === 1) {
      fetchSaleshCampaign();
    }
  }, [activeStep]);

  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleStepClick(index)}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div
          style={{
            width: "170vh",
            height: dynamicHeight(),
            display: "flex",
            alignItems: "center",
            // backgroundColor: "gray",
          }}
        >
          {getStepContent(activeStep)}
        </div>
      </Stack>
      <Dialog open={popup} onClose={() => {}}>
        <DialogTitle textAlign="center">Define Interval</DialogTitle>
        <DialogContent>
          <Grid container spacing={2.5}>
            <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  fullWidth
                  label="From"
                  inputFormat="MMM/dd/yyyy"
                  value={startDate}
                  maxDate={new Date().setDate(new Date().getDate() - 1)}
                  name="startDate"
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  required
                  fullWidth
                  label="To"
                  inputFormat="MMM/dd/yyyy"
                  // minDate={project.timeline.deadline}
                  // minDate={}
                  value={endDate}
                  name="endDate"
                  maxDate={new Date()}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPopup(() => false);
              setInterval(() => "");
              setIntervals(initialIntervals[3]);
              setStartDate(new Date().setDate(new Date().getDate() - 1));
            }}
            sx={{ color: "red" }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSet}>SET</Button>
        </DialogActions>
      </Dialog>
      <LoaderDialouge loaderDialog={loaderDialog} />
    </>
  );
}
