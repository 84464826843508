import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { filter } from "lodash";
import SyncIcon from "@mui/icons-material/Sync";
import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/UI/Page";
import SearchNotFound from "../../components/SearchNotFound";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import Scrollbar from "../../components/UI/Scrollbar";
import Iconify from "../../components/UI/Iconify";
import { updateContact } from "../../services/contacts.service";
import SearchLead from "../../components/SearchDropDown/SearchDropDownLead";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";

//---for call-email action
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import EmailDialog from "../../components/Dialogs/EmailDialog";
import { AuthContext } from "../../context/auth.context";
import { CallingContext } from "../../context/calling.context";
import { createOutboundCall } from "../../services/call.service";
import {
  fetchLeadStatusLogs,
  getAllContactEmail,
  getLeads,
} from "../../services/leads.service";
import { async } from "q";
import {
  createDataInIndexedDb,
  deleteDataInIndexedDb,
  getMyMails,
  getPassword,
  insertDataInIndexedDb,
} from "../../utils/indexedDb";
import { encryption } from "../../utils/encryption";
import { getAllMyMails } from "../../services/common.service";
import { isBase64KeyInPassword } from "../../utils/isBase64KeyInPassword";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core";
import { MailSyncContext } from "../../context/mailSync.context";
import { useMatchCollectionName } from "../../utils/filterObject";
import { useDynamicContactTitle } from "../Contacts/Contacts";
import getActivityFeed from "../../services/callHippo.service";
import { useLeadStatusLogs } from "../../context/leadStatusLogs.context";

export const useDynamicLeadTitle = () => {
  let matchedCollection = useMatchCollectionName("Leads");
  return matchedCollection;
};

const TABLE_HEAD = [
  { id: "contact_name", label: "Name", alignRight: false },
  { id: "summary", label: "Summary", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: "lastInteraction", label: "Last Interaction", alignRight: false },
  { id: "action", label: "Action", alignRight: true },
];
const TAG = "Leads.js";
//descendingComparator

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//getComparator
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

//applySortFilter
function applySortFilter(array, comparator, query) {
  console.log("query name", query);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      console.log("_user", _user.contact_name);
      return (
        _user.contact_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

let leadIdContainer;
function Leads() {
  const { handleOpenStatusLogsDialog } = useLeadStatusLogs();
  const contactDynamicTitle = useDynamicContactTitle();
  const [toEdit, setToEdit] = React.useState(false);
  const [leads, setLeads] = useState([]);
  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [order, setOrder] = useState("");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [column, setColumn] = useState("");
  const [typeText, setTypeText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [mailSync, setMailSync] = useState(false);
  //for Email call
  const [mailTo, setMailTo] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openCallDialouge, setOpenCallDialouge] = React.useState({
    state: false,
    contactId: "",
    index: "",
  });
  const [contactPhonNumber, setContactPhonNumber] = useState("");

  const [isAContactUpdated, setIsAContactUpdated] = useState(true);
  // const [openPasswordAlert, setOpenPasswordAlert] = useState(false);
  const [contactEmails, setContactEmails] = useState("");
  const [sortBySwitch, setSortBySwitch] = useState(true);
  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    console.log(event.target.value);
    setPage(0);
  };
  const {
    currentUser,
    toggleShowMailConsentPopup,
    emailPassword,
    setEmailPassword,
  } = useContext(AuthContext);
  const {
    handlegetAllContactEmail,
    mailSync,
    setMailSync,
    openPasswordAlert,
    setOpenPasswordAlert,
  } = useContext(MailSyncContext);
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const action = (
    <React.Fragment>
      <CircularProgress color="inherit" size="1.3rem" />
    </React.Fragment>
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order === "") setOrder("asc");
    if (order === "asc") setOrder("desc");
    setOrderBy(property);
    if (order === "desc") {
      setOrder("");
      setOrderBy("");
    }
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = leads.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id, contactId) => {
    navigate(`${id}/${contactId}`);
  };

  const handleChangePage = (event, newPage) => {
    console.log("Inside handle change page", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > leads.length &&
      leads.length < count
    ) {
      console.log("Fetching new data");
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  function FetchLeads() {
    setIsLoading((prev) => (prev = true));
    setLeads((prev) => (prev = []));
    if (!typeText) {
      getLeads(pageCount, sortBySwitch)
        // getAllLead(pageCount, sortBySwitch)
        .then((response) => {
          if (response.data.status) {
            console.log("lead response data", response);
            setLeads((prev) => (prev = []));
            setLeads((prev) => (prev = response.data.data.allLeads));
            // setLeads(response.data.data.allLeads);
            setCount(response.data.data.count);
            setIsLoading((prev) => (prev = false));
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
    }
  }

  const filteredLeads = applySortFilter(
    leads,
    getComparator(order, orderBy),
    filterName
  );

  //for mail-call action
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSentMail = (email, leadId) => {
    // setContextMailName(email);
    // setContactMailButtonClicked(true);
    if (emailPassword === "") {
      console.log("empty password");
      handleSetPasswordAgree();
    } else {
      leadIdContainer = leadId;
      setMailTo(email);
      handleClickOpen();
    }
  };

  //For phone call
  const {
    openCall,
    setOpenCall,
    callTo,
    setCallTo,
    setCallState,
    callState,
    setOpenCallPickedUpDialog,
    callId,
    setCallId,
    timeOut,
    checkCallStatDisabled,
    setCallDisability,
    callDisability,
  } = useContext(CallingContext);

  //check email password

  const [agreeSetPasswordDialog, setAgreeSetPasswordDialog] = useState(false);
  const [contextMailName, setContextMailName] = useState("");
  const [contactMailButtonClicked, setContactMailButtonClicked] =
    useState(false);
  const [isIdbCreated, setIsIdbcreated] = useState(false);
  const [latestInteractionDialogState, setLatestInteractionDialogState] =
    useState({
      open: false,
      summary: "",
      latestInteraction: {
        event: "",
        createdAt: "",
        interactedUser: "",
        interactedUserId: "",
      },
    });

  const handleSetPasswordAgree = () => {
    setAgreeSetPasswordDialog(true);
  };

  const handleClose = (event) => {
    setAgreeSetPasswordDialog(false);
    setOpenPasswordAlert(false);
    setMailSync(false);
  };

  const handleOnchagePassword = (event) => {
    setUserPassword(event.target.value);
  };
  const handleAddPassword = async () => {
    const encryptPassword = encryption(userPassword);
    createDataInIndexedDb();
    insertDataInIndexedDb(null, encryptPassword);
    setOpenPasswordAlert(false);
    const getpass = await getPassword();
    console.log("getpass", getpass);
    setEmailPassword(getpass);
    const isBase64Key = isBase64KeyInPassword(getpass);

    setMailSync(true);
    const response = await getAllMyMails(currentUser.email, isBase64Key);
    if (response.data.status) {
      setMailSync(false);
      console.log("response.data", response.data);
      const emailsArr = await getMyMails();

      if (emailsArr.length > 0) {
        console.log("email append");
        const lastIndex = emailsArr.length - 1;
        const insertDataResponse = insertDataInIndexedDb(response.data.data);
        setMailSync(false);
        toast("Email Sync Successfully");
      } else if (response.data.message === "Nothing to fetch") {
        setMailSync(false);
        toast("Email Not Found");
      } else if (
        response.data.message === "Timed out while authenticating with server"
      ) {
        setMailSync(false);
        toast("Timed out while authenticating with server");
      } else {
        insertDataInIndexedDb(response.data.data);
        setMailSync(false);
        toast("Email Sync Successfully");
      }
    } else {
      setMailSync(false);
      toast("Authentication Failed");
      setEmailPassword("");
      await deleteDataInIndexedDb();
    }
  };
  const handlePasswordAgree = () => {
    handleClose();
    toggleShowMailConsentPopup();
  };

  // const handleCall = async (phone, _id, contact_name, index, leadId) => {
  //   setTempPhoneNumber((prev) => {
  //     return (prev = phone);
  //   });
  //   if (phone) {
  //     setCallDisability((prev) => {
  //       return (prev = true);
  //     });
  //     setCallId((prev) => (prev = _id));
  //     setCallTo((prev) => (prev = contact_name));
  //     setCallState((prev) => ({
  //       ...prev,
  //       isRinging: true,
  //       isPickedUp: false,
  //       isConnected: false,
  //     }));

  //     const response = await createOutboundCall({
  //       to_number: phone,
  //       vonage: "13152035046",
  //       fromUser: currentUser._id,
  //       toUser: "63918f6cf1bc8fb5d0674590",
  //       leadId: leadId,
  //     });
  //   } else {
  //     const temp = { ...openCallDialouge };
  //     temp.state = true;
  //     temp.contactId = _id;
  //     temp.index = index;
  //     setOpenCallDialouge(temp);
  //   }
  // };
  const validatePhone = (data) => {
    console.log(data.length);
    let upperFlag = "Up";
    let lowerFlag = "Low";
    if (data.length > 0) {
      if (data.length >= 10 && data.length <= 14) {
        const regexPattern = /^\+?\d+$/;
        // return result = regexPattern.test(data);
        if (!regexPattern.test(data)) {
          return upperFlag;
        }
      } else {
        return lowerFlag;
      }
      // return data.regex(/^\+?\d+$/) ? true : false
    }
    return null;
  };
  const updatePhoneNumber = async (contactId) => {
    if (!contactPhonNumber.length) {
      toast("Please Enter Your Phone Number");
      return;
    }
    const flag = validatePhone(contactPhonNumber);
    if (flag === "Low") {
      toast("Invalid phone number length");
      setContactPhonNumber("");
      return;
    }
    console.log(`contact id,${contactId}`);
    const res = await updateContact(contactId, { phone: contactPhonNumber });
    if (res.data.status) {
      setLeads((prev) => {
        const temp = [...prev];
        for (let i = 0; i <= temp.length; i++) {
          // console.log("temp[i]", temp[i]);
          if (temp[i]) {
            if (temp[i].contact_id === contactId) {
              temp[i].phone = res.data.data.phone;
            }
          }
        }
        // temp[openCallDialouge.index].phone = res.data.data.phone;
        return temp;
      });

      toast(
        `${contactDynamicTitle?.displayname.singularName} successfully updated`
      );
      const temp = { ...openCallDialouge };
      temp.state = false;
      temp.contactId = "";
      setOpenCallDialouge(temp);
      setContactPhonNumber((prev) => (prev = ""));
      console.log("res.data.data.allLeads", res.data.data.phone);
      setIsAContactUpdated(false);
    }
  };
  let toastId = null;
  var regexPhone = /^\+?\d*$/;
  const handleChangePhone = (e) => {
    if (e.target.name === "phone") {
      const value = regexPhone.test(e.target.value);
      if (!value) {
        toast(
          "Alphabets, spaces and special characters except '+' are not allowed"
        );
        return;
      }
    }
    if (e.target.value.length > 14) {
      if (toastId == null) {
        toastId = toast.warning(
          "It should not cross more than 14digits Including Contry code"
        );
        return;
      } else {
        toast.update(
          toastId,
          "It should not cross more than 14digits Including Contry code"
        );
        return;
      }
    }
    setContactPhonNumber(e.target.value);
  };

  const handleCloseCallDialouge = () => {
    const temp = { ...openCallDialouge };
    temp.state = false;
    temp.contactId = "";
    setOpenCallDialouge(temp);
  };

  const handleCloseLastInteractionDialog = () => {
    setLatestInteractionDialogState((pre) => ({
      ...pre,
      open: false,
      summary: "",
      latestInteraction: {
        event: "",
        createdAt: "",
        interactedUser: "",
        interactedUserId: "",
      },
    }));
  };

  //style for sync icon rotate

  const useStyles = makeStyles(() =>
    createStyles({
      rotateIcon: {
        animation: "$spin 2s linear infinite",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(360deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
    })
  );

  let styleForSyncIcon = useStyles();

  // useEffect(() => {
  //   handlegetAllContactEmail()
  // })

  useEffect(() => {
    FetchLeads();
  }, [pageCount, typeText, sortBySwitch]);

  useEffect(() => {
    console.log("Email Password", emailPassword);
  }, [emailPassword]);

  const indexDbpassword = async () => {
    createDataInIndexedDb();
    const getpass = await getPassword();
    if (getpass !== undefined && getpass !== null && getpass !== "") {
      setEmailPassword(getpass);
      console.log("UseEffect triggered");
    }
  };

  //Sort By Switch

  const handleSortLeadByName = (el) => {
    setLeads((prev) => (prev = []));
    setColumn((prev) => (prev = ""));
    setTypeText((prev) => (prev = ""));
    setSortBySwitch((prev) => (prev = el.target.checked));
  };

  // useEffect(() => {
  //   FetchLeads();
  // }, [sortBySwitch]);

  useEffect(() => {
    createDataInIndexedDb().then(() => {
      setIsIdbcreated(true);
    });
  }, []);
  // getpassword from IndexedDB
  useEffect(() => {
    if (isIdbCreated) {
      indexDbpassword();
    }
  }, [isIdbCreated]);

  useEffect(() => {
    if (!isAContactUpdated) {
      setIsAContactUpdated(true);
    }
  }, [isAContactUpdated]);

  useEffect(() => {
    if (emailPassword !== "") {
      if (contactMailButtonClicked) {
        handleSentMail(contextMailName);
      }
    }
  }, []);
  const isUserNotFound = filteredLeads.length === 0;

  const leadDynamicTitel = useDynamicLeadTitle();

  const handleCall = (phoneNumber, contactId, contactName) => {
    console.log(`Initiating call to ${contactName} at ${phoneNumber}`);
    window.chCall(phoneNumber, { contactId, contactName });
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const [isTokenIncorrect, setIsTokenIncorrect] = useState(false);

  const [callDetailsAndManage, setCallDetailsAndManage] = useState({
    loaderEnable: true,
    data: [],
    callLogs: [],
    openLogsDialog: false,
    handleOpenLogsDialog: () => {
      setCallDetailsAndManage((prev) => ({ ...prev, openLogsDialog: true }));
      getCallActivityFeed();
    },
    handleCloseLogsDialog: () => {
      setCallDetailsAndManage((prev) => ({
        ...prev,
        openLogsDialog: false,
        callLogs: [],
      }));
      setHandleCallLogPagination((prev) => ({
        ...prev,
        rowsPerPage: 5,
        page: 0,
      }));
    },
    storeLogs: (logs) => {
      setCallDetailsAndManage((prev) => ({
        ...prev,
        callLogs: [...logs],
      }));
    },
  });

  const [handleCallLogPagination, setHandleCallLogPagination] = useState({
    rowsPerPage: 5,
    page: 0,
    handleChangePage: (event, newPage) => {
      setHandleCallLogPagination((prev) => ({
        ...prev,
        page: +newPage,
      }));
    },
  });


  function showLogs(to) {
    callDetailsAndManage.handleOpenLogsDialog();
    const callDetails = callDetailsAndManage.data.filter(
      (call) => call.to === to
    );
    // if (!callDetails || !callDetails.length) {
    //   toast("No Logs Found");
    //   return;
    // }
    console.log("callLogs", to);
    callDetailsAndManage.storeLogs([...callDetails]);
  }
  console.log("callLogs", callDetailsAndManage.callLogs);


  const getCallActivityFeed = async () => {
    try {
      setIsSyncing(true);

      const response = await getActivityFeed();
      if (response.isSuccess) {
        setCallDetailsAndManage((prev) => ({
          ...prev,
          data: [...response.data.data.callLogs],
          loaderEnable: false,
        }));
        setIsSyncing(false);

        return response;
      } else {
        setIsSyncing(false);
        setIsTokenIncorrect(true);
      }
    } catch (error) {
      setCallDetailsAndManage((prev) => ({
        ...prev,
        loaderEnable: false,
      }));
    }
  };

  // function timeToMinutes(time) {
  //   const [timePart, modifier] = time.split(" ");
  //   let [hours, minutes, seconds] = timePart.split(":");
  //   hours = parseInt(hours, 10);
  //   minutes = parseInt(minutes, 10);
  //   if (modifier === "PM" && hours < 12) {
  //     hours += 12;
  //   } else if (modifier === "AM" && hours === 12) {
  //     hours = 0;
  //   }
  //   return hours * 60 + minutes;
  // }
  // function convertTimeGMT4toIST(timeString) {
  //   let totalMinutes = timeToMinutes(timeString);
  //   totalMinutes += 4 * 60;
  //   totalMinutes += 5 * 60 + 30;

  //   totalMinutes %= 1440;

  //   const ISTHours = Math.floor(totalMinutes / 60);
  //   const ISTMinutes = totalMinutes % 60;

  //   const isPM = ISTHours >= 12;
  //   const adjustedHours = isPM ? ISTHours - 12 : ISTHours;
  //   const displayHours = adjustedHours === 0 ? 12 : adjustedHours;
  //   const formattedISTTime = `${String(displayHours).padStart(2, "0")}:${String(
  //     ISTMinutes
  //   ).padStart(2, "0")} ${isPM ? "PM" : "AM"}`;
  //   console.log("formatted", formattedISTTime);
  //   return formattedISTTime;
  // }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={callDetailsAndManage.openLogsDialog}
        onClose={callDetailsAndManage.handleCloseLogsDialog}
      >
        <DialogTitle>
          Call Logs
          <Button
            onClick={async () => {
              const res = await getCallActivityFeed();
              if (res) {
                showLogs(phoneNumber);
              }
            }}
          >
            <SyncIcon
            // className={
            //   callDetailsAndManage.loaderEnable
            //     ? styleForSyncIcon.rotateIcon
            //     : null
            // }
            />
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          {isTokenIncorrect ? (
            <Typography
              gutterBottom
              textAlign="center"
              fontWeight="700"
              color="#857C7A"
              variant="subtitle1"
              sx={{
                fontSize: "20px",
              }}
            >
              Please verify your Call Hippo Credential!{" "}
            </Typography>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Call Type</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Call Status</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Duration</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {callDetailsAndManage.loaderEnable ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          {" "}
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    ) : !callDetailsAndManage.loaderEnable &&
                      callDetailsAndManage.callLogs.length ? (
                      callDetailsAndManage.callLogs
                        ?.slice(
                          handleCallLogPagination.page *
                            handleCallLogPagination.rowsPerPage,
                          handleCallLogPagination.page *
                            handleCallLogPagination.rowsPerPage +
                            handleCallLogPagination.rowsPerPage
                        )
                        .map((log, index) => {
                          let {
                            callStatus,
                            callType,
                            from,
                            to,
                            date,
                            time,
                            callDuration,
                            caller,
                            callHangupTime,
                          } = log;
                          // const convertedTimeInIST = convertTimeGMT4toIST(time);
                          return (
                            <TableRow key={index}>
                              <TableCell>{date}</TableCell>
                              <TableCell>{callType}</TableCell>
                              <TableCell>{from}</TableCell>
                              <TableCell>{to}</TableCell>
                              <TableCell>{callStatus}</TableCell>
                              <TableCell>{time}</TableCell>
                              <TableCell>{callDuration}</TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell colSpan={3}>
                          <Paper
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              gutterBottom
                              textAlign="center"
                              fontWeight="700"
                              color="#857C7A"
                              variant="subtitle1"
                              sx={{
                                fontSize: "20px",
                              }}
                            >
                              No Call Logs!{" "}
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[5]}
                component="div"
                count={callDetailsAndManage.callLogs.length}
                rowsPerPage={handleCallLogPagination.rowsPerPage}
                page={handleCallLogPagination.page}
                onPageChange={handleCallLogPagination.handleChangePage}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={callDetailsAndManage.handleCloseLogsDialog}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      <Page title="Leads">
        <Container>
          <EmailDialog
            open={open}
            setOpen={setOpen}
            mailTo={mailTo}
            leadId={leadIdContainer}
            FetchLeads={FetchLeads}
          />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={5} variant="outlined">
                <CardContent>
                  {/* <Grid container spacing={0}>
                  <Grid
                    item
                    xs={9}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom="false"
                      color="primary"
                      sx={{ marginBottom: 0 }}
                    >
                      LEADS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to="createLeadPage"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setToEdit(true)}
                    >
                      Create Lead
                    </Button>
                    <Button onClick={handlegetAllContactEmail}>
                      <SyncIcon />
                    </Button>
                  </Grid>
                </Grid> */}
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <SearchLead
                        leads={leads}
                        setLeads={setLeads}
                        setCount={setCount}
                        column={column}
                        setColumn={setColumn}
                        typeText={typeText}
                        setTypeText={setTypeText}
                        setPageCount={setPageCount}
                        setPage={setPage}
                        setIsLoading={setIsLoading}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      {currentUser.role !== "DELIVERY_LEAD" ? (
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to="createLeadPage"
                          startIcon={<Iconify icon="eva:plus-fill" />}
                          onClick={() => setToEdit(true)}
                          data-testid="add-lead-button"
                        >
                          {`Create ${leadDynamicTitel?.displayName.singularName}`}
                        </Button>
                      ) : null}

                      <Button
                        onClick={mailSync ? null : handlegetAllContactEmail}
                      >
                        <SyncIcon
                          className={
                            mailSync ? styleForSyncIcon.rotateIcon : null
                          }
                        />
                      </Button>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sortBySwitch}
                            onChange={handleSortLeadByName}
                          />
                        }
                        label="Sort By Last Interaction"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                elevation={3}
                variant="outlined"
                sx={{ paddingRight: 2, paddingLeft: 2 }}
              >
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={count}
                        // numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredLeads
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((lead, index) => {
                            const {
                              _id,
                              contact_id,
                              contact_name,
                              summary,
                              email,
                              country,
                              status,
                              phone,
                              latestInteraction,
                            } = lead;

                            const isItemSelected =
                              selected.indexOf(contact_name) !== -1;

                            return (
                              <TableRow
                                sx={{ cursor: "pointer" }}
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                                onClick={() => {
                                  console.log(_id, contact_id);
                                  handleClick(_id, contact_id);
                                }}
                              >
                                <TableCell align="left">
                                  {contact_name}
                                </TableCell>
                                <TableCell align="left">{summary}</TableCell>
                                <TableCell align="left">{email}</TableCell>
                                <TableCell align="left">{country}</TableCell>
                                <TableCell align="left">
                                  {status}{" "}
                                  <Button
                                    color="primary"
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      height: "100%",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenStatusLogsDialog(
                                        contact_id,
                                        _id
                                      );
                                    }}
                                  >
                                    <InfoIcon />
                                  </Button>
                                </TableCell>
                                {/* <TableCell align="left">
                                {latestInteraction ? (
                                  <Stack direction="row" alignItems="center">
                                    <span>
                                      {new Date(
                                        latestInteraction.createdAt
                                      ).toDateString()}
                                    </span>
                                    <IconButton
                                      color="primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setLatestInteractionDialogState(
                                          (prev) => ({
                                            ...prev,
                                            open: true,
                                            summary: summary,
                                            latestInteraction:
                                              latestInteraction,
                                          })
                                        );
                                      }}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </Stack>
                                ) : (
                                  ""
                                )}
                              </TableCell> */}
                                <TableCell align="center">
                                  <Button
                                    color="primary"
                                    style={{ border: "none", outline: "none" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCall(
                                        phone,
                                        contact_id,
                                        contact_name
                                      );
                                    }}
                                  >
                                    <PhoneIcon />
                                  </Button>
                                  <Button
                                    color="primary"
                                    style={{ border: "none", outline: "none" }}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      showLogs(phone);
                                      setPhoneNumber(phone);
                                    }}
                                  >
                                    <InfoIcon />
                                  </Button>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSentMail(email, lead._id);
                                    }}
                                  >
                                    <MailIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isLoading || isUserNotFound ? (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              {isLoading ? (
                                <CircularProgress />
                              ) : (
                                <SearchNotFound searchQuery={filterName} />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>

            {/* <Box
            component={RouterLink}
            to="createLeadPage"
            onClick={() => setToEdit(true)}
            sx={{ position: "fixed", bottom: 16, right: 16 }}
          >
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Box> */}
          </Grid>
        </Container>
        <Dialog open={agreeSetPasswordDialog} keepMounted onClose={handleClose}>
          <DialogTitle>{"Email Password Not Set"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You haven't set a password for your email. Click 'AGREE' if you
              want to set one now
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              DISAGREE
            </Button>
            <Button onClick={handlePasswordAgree}>AGREE</Button>
          </DialogActions>
        </Dialog>
        {/* email password pop-up */}
        <Dialog open={openPasswordAlert} onClose={handleClose}>
          <DialogTitle>Please Add Your Email Password</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={handleOnchagePassword}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAddPassword}>Submit</Button>
          </DialogActions>
        </Dialog>
        {/* <Dialog open={mailSync}>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog> */}
        <Dialog open={openCallDialouge.state} onClose={handleCloseCallDialouge}>
          <DialogTitle>Phone Number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              It looks like this contact is missing a phone number. Click on
              SAVE to add one.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Phone Number"
              type="number"
              inputProps={{ maxLength: 14 }}
              fullWidth
              variant="standard"
              value={contactPhonNumber}
              onChange={handleChangePhone}
              placeholder="Ex: +919876543210"
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCallDialouge} color="error">
              CANCEL
            </Button>
            <Button
              onClick={() => {
                // handleCloseCallDialouge();
                updatePhoneNumber(openCallDialouge.contactId);
              }}
            >
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={mailSync}
        message={mailSync ? "Your Emails Are Fetching" : null}
        action={action}
      /> */}
        <Dialog
          open={latestInteractionDialogState.open}
          onClose={handleCloseLastInteractionDialog}
          maxWidth="sm"
        >
          <DialogTitle>{`${latestInteractionDialogState.summary}'s Latest Interaction`}</DialogTitle>
          <DialogContent dividers>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={1}>
                <Typography>Event: </Typography>
                <Typography>
                  {latestInteractionDialogState.latestInteraction.event}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography>Date: </Typography>
                <Typography>
                  {new Date(
                    latestInteractionDialogState.latestInteraction.createdAt
                  ).toDateString()}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography>Time: </Typography>
                <Typography>
                  {new Date(
                    latestInteractionDialogState.latestInteraction.createdAt
                  ).toLocaleTimeString()}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography>Interacted By: </Typography>
                <Typography>
                  {
                    latestInteractionDialogState.latestInteraction
                      .interactedUser
                  }
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLastInteractionDialog}>CLOSE</Button>
          </DialogActions>
        </Dialog>
      </Page>
    </>
  );
}

export default Leads;
