import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

function DeleteConfirmationDialog({
  opened,
  title,
  handleCancel,
  handleDelete,
}) {
  return (
    <Dialog open={opened}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <Typography
          fontFamily="inherit"
          variant="subtitle1"
        >{`Do you wish to delete this ${title}?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          CANCEL
        </Button>
        <Button color="error" onClick={handleDelete}>
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(DeleteConfirmationDialog);
