import { Box, Grid } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import CountAndCountByShowingTable from "./CountAndCountByShowingTable";

const DropReportTabPanel = ({ propsValue }) => {
  const { reportFigures } = propsValue;
  const [numberOfTopDropReasonToShow, setNumberOfTopDropReasonToShow] =
    React.useState(20);
  const [transformedData, setTransformedData] = React.useState([]);
  function toTransformData() {
    console.log("Calling toTransformData");
    const transformedData = Object.entries(reportFigures?.leadCountByReasons)
      ?.map(([key, value]) => ({
        category: key,
        count: Math.floor(value),
      }))
      ?.slice(0, numberOfTopDropReasonToShow);
    setTransformedData(transformedData);
  }
  React.useEffect(() => {
    if (numberOfTopDropReasonToShow === 20) {
      toTransformData();
      return;
    }
    const timeOut = setTimeout(toTransformData, 1500);
    return () => clearTimeout(timeOut);
  }, [numberOfTopDropReasonToShow]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3.5}>
        <CountAndCountByShowingTable
          tableHeaders={["Reason", "Count"]}
          tableData={[...transformedData]}
          numberOfTopCategoryToShow={numberOfTopDropReasonToShow}
          setNumberOfTopCategoryToShow={setNumberOfTopDropReasonToShow}
        />
      </Grid>
      <Grid item xs={12} md={8.5}>
        <Box sx={{ height: "450px" }}>
          <ResponsiveBar
            data={transformedData.reverse()}
            keys={["count"]}
            indexBy="category"
            margin={{ top: 0, right: 60, bottom: 40, left: 90 }} // Reduced top and bottom margins
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendPosition: "middle",
              legendOffset: 32, // Adjusted for reduced bottom margin
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Reasons",
              legendPosition: "middle",
              legendOffset: -80,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Bar chart showing lead count by skills"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DropReportTabPanel;
