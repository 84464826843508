import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Button, CardActionArea, CardActions, Checkbox } from "@mui/material";
import { useState } from "react";

export default function MultiActionAreaCard({
  index,
  isSelected,
  onCardSelect,
  Image,
}) {
  const handleCheckboxChange = () => {
    onCardSelect(isSelected ? null : index);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={`${Image?.location}`}
          alt="Visiting Card"
        />
      </CardActionArea>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Checkbox
          checked={isSelected}
          inputProps={{
            "aria-label": "Checkbox A",
          }}
          sx={{ p: 0 }}
          onChange={handleCheckboxChange}
        />
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={!isSelected}
        >
          Create contact
        </Button>
      </CardActions>
    </Card>
  );
}
