import { Button, Container, Divider, Grid } from "@mui/material";
import React from "react";
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CreateHorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import Page from "../../components/UI/Page";

export default function CreateAutomation() {
  const navigate = useNavigate();
  const { moduleName } = useParams();
  return (
    <Page>
      <Container>
        <Grid container spacing={5}>
          <Grid
            item
            xs={6}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <h2>Create a Rule</h2>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              onClick={(event) => navigate(`/customize/${moduleName}`)}
              variant="contained"
              size="large"
            >
              {`Cancel`}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <HorizontalNonLinearStepper moduleName={moduleName} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
