import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createInvoice } from "../../../services/projects.service";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CostCalculation from "./CostCalculation";
function CostInvoiceViewSection({
  data,
  numberOfMileStone,
  gstValues,
  costInforMations,
  projectId,
  fetchCostEstimation,
  project,
  getInvoiceDetails,
  setLoaderDialog,
  parentCompanyDetails,
  isEditIcon,
  invoiceDetailsAndManage,
}) {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(data);
  const [renderData, setRenderData] = useState(data);
  const [indexes, setIndexes] = useState([]);
  const [selectedCostData, setSelectedCostData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [costDialogOpen, setCostDialogOpen] = useState(false);
  const [dueAmountDetails, setDueAmountDetails] = useState({
    isSelected: true,
    AmountType: "",
    Amount: 0,
  });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    if (selected && selected.length) {
      console.log("rendehgfhfghfrData um");

      setSelected(selected);
    }
    setSelected([]);
  }, [indexes]);
  useEffect(() => {
    setRenderData(data);
  }, [data]);

  const handleSelect = (index) => {
    const selectedIndex = indexes.indexOf(index);
    let newSelected = [...indexes];

    if (selectedIndex === -1) {
      newSelected.push(index);
      // renderData[index].invoiceStatus = true;
    } else {
      newSelected.splice(selectedIndex, 1);
      // renderData[index].invoiceStatus = false;
    }

    setIndexes(newSelected);
  };

  // const createInvoiceData = async (body) => {
  //   if (body) {
  //     createInvoice(body)
  //       .then((response) => {
  //         if (response.data.status) {
  //           getInvoiceDetails();
  //           setLoaderDialog(false);
  //           toast(response.data.message);
  //           fetchCostEstimation(project.leadId);
  //           setIndexes([]);
  //           setIsEdit(false);
  //           window.open(response.data.data.pdfUrl);
  //         } else {
  //           setLoaderDialog(false);
  //           navigate(`/unauthorized`);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setLoaderDialog(false);
  //       });
  //   } else {
  //     return;
  //   }
  //   return true;
  // };

  console.log(invoiceDetailsAndManage, "dueAmount");

  // const totalCalculations = useMemo(() => {
  //   const data = invoiceDetailsAndManage.reduce(
  //     (accumulator, report) => {
  //       if (report) {
  //         let { amountTotal, paid, dueAmount, activeStatus, isCancelled } =
  //           report;
  //         if (isCancelled === true) {
  //           accumulator.chargableTotal += 0;
  //           accumulator.collectedAmount += paid;
  //           accumulator.pendingAmount += 0;
  //           return accumulator;
  //         } else {
  //           accumulator.chargableTotal += amountTotal;
  //           accumulator.collectedAmount += paid;
  //           accumulator.pendingAmount += dueAmount;
  //           return accumulator;
  //         }
  //       }
  //     },
  //     {
  //       chargableTotal: 0,
  //       collectedAmount: 0,
  //       pendingAmount: 0,
  //     }
  //   );
  //   return data;
  // }, [invoiceDetailsAndManage]);

  useEffect(() => {
    const totalCalculations = invoiceDetailsAndManage.reduce(
      (accumulator, report) => {
        if (report && !report.isCancelled) {
          let { amountTotal, paid, dueAmount } = report;

          accumulator.chargableTotal += amountTotal;
          accumulator.collectedAmount += paid;
          accumulator.pendingAmount += dueAmount;
        }

        return accumulator;
      },
      {
        chargableTotal: 0,
        collectedAmount: 0,
        pendingAmount: 0,
      }
    );
    console.log(totalCalculations, "dueAmount");

    setDueAmountDetails((prev) => ({
      ...prev,
      isSelected: true,
      Amount:
        totalCalculations.pendingAmount === 0
          ? 0
          : Math.abs(parseFloat(totalCalculations.pendingAmount)).toFixed(2),
      AmountType:
        totalCalculations.pendingAmount > 0
          ? "Outstanding"
          : totalCalculations.pendingAmount === 0
          ? ""
          : "Advance",
    }));
  }, [invoiceDetailsAndManage]);

  console.log(dueAmountDetails, "dueAmount");
  // const isDueAmountSelected = () => {
  //   setDueAmountDetails((prev) => ({
  //     ...prev,
  //     isSelected: !prev.isSelected,
  //   }));
  // };

  // useEffect(() => {
  //   setDueAmountDetails((prev) => ({
  //     ...prev,
  //     Amount: Math.abs(totalCalculations.pendingAmount),
  //     AmountType:
  //       totalCalculations.pendingAmount > 0 ? "Outstanding" : "Advance",
  //   }));
  // }, [totalCalculations]);

  const createInvoiceData = async (body) => {
    if (body) {
      try {
        const response = await createInvoice(body);
        if (response.data.status) {
          getInvoiceDetails();
          setLoaderDialog(false);
          toast(response.data.message);
          fetchCostEstimation(project.leadId);
          setIndexes([]);
          setIsEdit(false);
          // await new Promise((resolve) => setTimeout(resolve, 500));
          window.open(response.data.data.pdfUrl);
        } else {
          setLoaderDialog(false);
          navigate(`/unauthorized`);
        }
      } catch (error) {
        console.log(error);
        setLoaderDialog(false);
      }
    } else {
      return;
    }
    return true;
  };

  const handleGenerateInvoice = async () => {
    setLoaderDialog(true);
    const formData = new FormData();
    const selectedRows = indexes.map((index) => renderData[index]);
    console.log("Selected Rows:", selectedRows);
    const selectedRowsWithStatus = selectedRows.map((row) => ({
      ...row,
      invoiceStatus: true,
      currency: costInforMations.currency,
    }));
    // let body = { projectId: projectId, costs: selectedRowsWithStatus };
    formData.append("costInfo", JSON.stringify(selectedRowsWithStatus));
    formData.append("projectId", projectId);

    // console.log(body, "body");
    await createInvoiceData(formData);
  };

  const handleCostDialouge = async () => {
    console.log(parentCompanyDetails, "country check");
    if (
      parentCompanyDetails.companyCountry === "" ||
      parentCompanyDetails.companyCountry === undefined ||
      parentCompanyDetails.companyCountry === null
    ) {
      toast.warning(
        `${parentCompanyDetails.companyName}'s country name can not be empty! Please update it.`
      );
      return;
    }

    if (
      parentCompanyDetails.companyCountry?.toLowerCase() === "india" &&
      parentCompanyDetails.companyGstNumber === ""
    ) {
      toast.warning(
        `${parentCompanyDetails.companyName}'s  gst number can not be empty! Please update it.`
      );

      return;
    }
    if (
      parentCompanyDetails?.companyCountry?.toLowerCase() === "india" &&
      (parentCompanyDetails?.companyState === "" ||
        parentCompanyDetails?.companyState === null ||
        parentCompanyDetails?.companyState === undefined)
    ) {
      toast.warning(
        `${parentCompanyDetails.companyName}'s  state can not be empty! Please update it.`
      );

      return;
    }
    // if (
    //   parentCompanyDetails.companyCountry !== "" &&
    //   parentCompanyDetails.companyState === ""
    // ) {
    //   toast.warning(
    //     `${parentCompanyDetails.companyName}'s state can not be empty! Please update it.`
    //   );

    //   return;
    // }
    //todo

    setCostDialogOpen(!costDialogOpen);
    const selectedRows = indexes.map((index) => renderData[index]);

    setSelectedCostData(selectedRows);
  };
  const handleCheckEdit = async () => {
    // if (project.status.name === "Waiting to start") {
    //   toast.warn(
    //     "The invoice can not be generated as the project has not started yet."
    //   );
    //   return;
    // } else if (project.status.name.toLowerCase() === "closed") {
    //   toast.warn(
    //     "The invoice can not be generated as the project has been closed."
    //   );
    //   return;
    // }
    setIsEdit(!isEdit);
  };
  console.log(indexes, "indexrddddddd");
  let amountPendingDetails = { ...dueAmountDetails };
  let outstandingAmount = 0;
  let gstSum;
  if (
    parentCompanyDetails?.companyCountry?.toLowerCase() === "india" &&
    costInforMations?.homeCompanyCountry?.toLowerCase() === "india"
  ) {
    if (
      parentCompanyDetails?.companyState === costInforMations?.homeCompanyState
    ) {
      gstSum = gstValues?.cgst + gstValues.sgst;
    } else if (
      parentCompanyDetails?.companyState !== costInforMations?.homeCompanyState
    ) {
      gstSum = gstValues?.igst;
    }
  } else {
    gstSum = 0;
  }
  if (
    costInforMations.gstApplicable &&
    // !costInforMations.gstIncluded &&
    dueAmountDetails.Amount !== 0 &&
    dueAmountDetails.AmountType === "Outstanding"
  ) {
    const gstDecimal = 1 + gstSum / 100;
    outstandingAmount = (amountPendingDetails.Amount / gstDecimal).toFixed(2);
  } else if (
    dueAmountDetails.Amount !== 0 &&
    dueAmountDetails.AmountType === "Outstanding"
  ) {
    outstandingAmount = amountPendingDetails.Amount;
  }
  console.log(
    "parentCompanyDetails.companyCountry",
    parentCompanyDetails.companyCountry
  );
  return (
    <Grid container spacing={1}>
      <Grid container>
        {project.status.name === "Waiting to start" ? (
          <Grid item xs={6} sx={{ paddingLeft: "25px", marginTop: "16px" }}>
            <Typography variant="caption" sx={{ fontSize: 13 }}>
              P.S: The invoice can not be generated until the project has not
              been started.*
            </Typography>
          </Grid>
        ) : (
          <Grid
            item
            xs={6}
            sx={{ paddingLeft: "25px", marginTop: "16px" }}
          ></Grid>
        )}
        <Grid item xs={6} sx={{ paddingRight: "25px", marginTop: "16px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <>
              {isEdit ? (
                project.status.name === "Waiting to start" ||
                project.status.name === "Closed" ? (
                  <IconButton
                    sx={{
                      maxHeight: "45px",
                      "&:hover": { color: "#2065D1" },
                    }}
                    color="primary"
                    fontSize="medium"
                    disabled
                    onClick={handleCheckEdit}
                  >
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      maxHeight: "45px",
                      "&:hover": { color: "#2065D1" },
                    }}
                    color="primary"
                    fontSize="medium"
                    onClick={handleCheckEdit}
                  >
                    <CancelIcon />
                  </IconButton>
                )
              ) : project.status.name === "Waiting to start" ||
                project.status.name === "Closed" ? (
                <IconButton
                  sx={{
                    maxHeight: "45px",
                    "&:hover": { color: "#2065D1" },
                  }}
                  color="primary"
                  fontSize="medium"
                  disabled
                  onClick={handleCheckEdit}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    maxHeight: "45px",
                    "&:hover": { color: "#2065D1" },
                  }}
                  color="primary"
                  fontSize="medium"
                  onClick={handleCheckEdit}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          </div>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {!numberOfMileStone && <TableCell padding="checkbox"></TableCell>}
              {numberOfMileStone && <TableCell padding="checkbox"></TableCell>}
              {!numberOfMileStone && <TableCell>Schedule</TableCell>}
              {numberOfMileStone && <TableCell>Schedule</TableCell>}
              {!numberOfMileStone && <TableCell>Resource</TableCell>}
              <TableCell>Cost</TableCell>
              <TableCell>Currency</TableCell>
              {numberOfMileStone && <TableCell>Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {!renderData.length ? (
          <CircularProgress />
        ) : ( */}
            {!data?.length ? (
              <CircularProgress />
            ) : (
              data?.map((ele, index) => (
                <TableRow key={index}>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={ele.invoiceStatus || indexes.includes(index)}
                      onChange={() => handleSelect(index)}
                      disabled={ele.invoiceStatus}
                    />{" "}
                  </TableCell> */}
                  {numberOfMileStone && isEdit ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={ele.invoiceStatus || indexes.includes(index)}
                        onChange={() => handleSelect(index)}
                        disabled={ele.invoiceStatus}
                      />{" "}
                    </TableCell>
                  ) : (
                    <TableCell padding="checkbox">
                      {isEdit ? (
                        <Checkbox
                          key={index}
                          checked={indexes.includes(index)}
                          onChange={() => handleSelect(index)}
                        />
                      ) : null}{" "}
                    </TableCell>
                  )}
                  <TableCell>
                    {numberOfMileStone ? ele.milestoneName : ele.paymentCycle}
                  </TableCell>
                  {!numberOfMileStone && (
                    <TableCell>{ele.resourceName}</TableCell>
                  )}
                  <TableCell>{ele.cost}</TableCell>
                  <TableCell>{costInforMations.currency}</TableCell>
                  {numberOfMileStone && (
                    <TableCell>
                      {ele.invoiceStatus ? (
                        <Typography style={{ color: "green" }}>
                          Invoice generated{" "}
                        </Typography>
                      ) : (
                        <Typography color="warning">
                          Invoice not generated{" "}
                        </Typography>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isEdit && dueAmountDetails.Amount !== 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <Stack
                  rowGap="3px"
                  direction="row"
                  sx={{ paddingLeft: "6px", marginTop: "10px" }}
                >
                  {/* <Checkbox
                   
                    checked={true}
                   
                    disabled={true}
                  />
                  {dueAmountDetails.AmountType === "Outstanding" ? (
                    costInforMations.gstApplicable &&
                    dueAmountDetails.Amount !== 0 ? (
                      <Typography
                        sx={{
                          marginTop: "10px",
                        
                          flexShrink: 0,
                        }}
                      >
                        
                        <b>{`${dueAmountDetails.AmountType} Amount(GST Excl.)`}</b>{" "}
                        : {outstandingAmount}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          marginTop: "10px",
                        
                          flexShrink: 0,
                        }}
                      >
                        
                        <b>{`${dueAmountDetails.AmountType} Amount`}</b> :{" "}
                        {outstandingAmount}
                      </Typography>
                    )
                  ) : (
                    <Typography
                      sx={{
                        width: "17%",
                        marginTop: "10px",

                       
                        flexShrink: 0,
                      }}
                    >
                    
                      <b>{`${dueAmountDetails.AmountType} Amount`}</b> :{" "}
                      {dueAmountDetails.Amount}
                    </Typography>
                  )} */}
                  {/* <Typography
                    sx={{
                      width: "23%",
                      marginTop: "10px",
                      // backgroundColor: "red",
                      flexShrink: 0,
                    }}
                  >
                    <span>
                      <b> Amount </b>
                    </span>{" "}
                    : {dueAmountDetails.Amount}
                  </Typography> */}
                </Stack>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      ) : null}
      <Grid item xs={12} sm={12}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Grid item xs={1.6} sm={1.6}>
            {isEdit ? (
              <Button
                sx={{
                  maxHeight: "45px",
                  paddingX: "10px",
                  minWidth: "170px",
                }}
                fontSize="medium"
                variant="contained"
                size="large"
                // onClick={handleGenerateInvoice}
                // onClick={
                //   numberOfMileStone ? handleGenerateInvoice : handleCostDialouge
                // }
                onClick={handleCostDialouge}
                disabled={!indexes.length > 0}
              >
                Generate Invoice
              </Button>
            ) : null}
          </Grid>

          <Grid item xs={10.4} sm={10.4}></Grid>
          <CostCalculation
            costDialogOpen={costDialogOpen}
            setCostDialogOpen={setCostDialogOpen}
            costInforMations={costInforMations}
            gstValues={gstValues}
            projectId={projectId}
            project={project}
            handleCostDialouge={handleCostDialouge}
            selectedCostData={selectedCostData}
            createInvoiceData={createInvoiceData}
            getInvoiceDetails={getInvoiceDetails}
            setLoaderDialog={setLoaderDialog}
            setSelectedCostData={setSelectedCostData}
            numberOfMileStone={numberOfMileStone}
            parentCompanyDetails={parentCompanyDetails}
            setIndexes={setIndexes}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            dueAmountDetails={dueAmountDetails}
            setDueAmountDetails={setDueAmountDetails}
            isEditIcon={isEditIcon}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CostInvoiceViewSection;
