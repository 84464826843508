import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import Page from "../../components/UI/Page";
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import {
  getAutomationRule,
  getAutomationTriggers,
  removeAutomationRule,
  updateAutomation,
} from "../../services/attributes.service";
import Typography from "../../theme/overrides/Typography";
import DOMPurify from "dompurify";
import { styled } from "@mui/material/styles";

export default function Automation() {
  const [ruleData, setRueleData] = useState([]);
  const [isAutomationListFetched, setIsAutomationListFetched] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [ruleId, setRuelId] = useState("");
  const { moduleName } = useParams();
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [apiEnabled, setApiEnabled] = useState(true);

  //Item
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "whitesmoke",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "start",
    fontSize: "1rem",
    fontWeight: "bolder",
    color: "black",
  }));
  //Handel methods

  const hadelDeleteRule = async () => {
    try {
      // let response;
      const response = await removeAutomationRule(ruleId);

      if (response.data.status) {
        const updatedAutomationRuleList = ruleData.filter(
          (item) => item._id !== response.data.data._id
        );
        console.log("response", response.data.data._id, ruleId);
        setRueleData(updatedAutomationRuleList);
        toast("Automation rule deleted successfully");
        setDeletePopupOpen(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleOpenDeleteDialog = (ruleId) => {
    console.log("ruleId", ruleId);
    setRuelId(ruleId);
    setDeletePopupOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeletePopupOpen(false);
  };
  const getAllAutomationRules = async () => {
    try {
      const response = await getAutomationRule();
      if (response.data.status) {
        const data = response.data.data;

        setRueleData(data);
        setIsAutomationListFetched(true);
        console.log("data", data);
        if (data.length === 0) {
          toast("Automation rules are currently empty");
        } else {
          toast("Automation rule fetched successfully");
        }
      }
    } catch (error) {}
  };

  const handleSwitchChange = async (event, rule) => {
    let ruleId = rule._id;
    let body = rule;
    body.moduleName = body.moduleName.toUpperCase();
    body.isRuleRequired = !body.isRuleRequired;

    delete body._id;
    delete body.updatedAt;
    delete body.createdAt;
    delete body.__v;
    delete body.emails;

    try {
      const response = await updateAutomation(ruleId, body);
      console.log("response.data", response.data);
      if (response.data.status) {
        getAllAutomationRules();
        if (response.data.data.isRuleRequired) {
          toast("Automation rule enable successfully");
        } else {
          toast("Automation rule disable successfully");
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
    setApiEnabled((prevApiEnabled) => {
      return !prevApiEnabled;
    });
  };

  const findRuleByModuleName = () => {
    let filterresult = [];
    for (const rule of ruleData) {
      console.log(
        "rule.moduleName",
        ruleData,
        rule.moduleName,
        moduleName.toLocaleUpperCase()
      );
      if (rule.moduleName === moduleName.toLocaleUpperCase()) {
        filterresult.push(rule);
      }
    }
    console.log("filterresult", filterresult);
    if (filterresult.length > 0) {
      return filterresult;
    } else {
      return null;
    }
  };

  const foundRule = findRuleByModuleName();
  console.log("ruleData1", foundRule);
  React.useEffect(() => {
    if (!isAutomationListFetched) {
      getAllAutomationRules();
    }
  }, [isAutomationListFetched]);
  return (
    <Page>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ marginBottom: "40px" }}>
            <h2>Rules</h2>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginBottom: "40px",
            }}
          >
            <Button
              // onClick={handelAddTriggerButton}
              onClick={(event) => navigate(`/customize/create/${moduleName}`)}
              variant="contained"
              size="large"
            >
              <AddIcon /> {`Add Rule`}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {foundRule ? (
                foundRule.map((rule, index) => (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Item> {rule?.ruleName}</Item>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              sx={{ "&:hover": { color: "#2056D4" } }}
                              onClick={() => {
                                navigate(
                                  `/customize/edit/${moduleName}/${rule._id}`
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              sx={{ "&:hover": { color: "#FF4842" } }}
                              onClick={() => {
                                handleOpenDeleteDialog(rule._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              sx={{ "&:hover": { color: "#2056D1" } }}
                            >
                              <Switch
                                {...label}
                                checked={rule?.isRuleRequired}
                                onChange={(event) =>
                                  handleSwitchChange(event, rule)
                                }
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid item xs={6}>
                    Automation rules have not been configured
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={deletePopupOpen} onClose={() => {}}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you wish to delete this rule?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
            <Button onClick={hadelDeleteRule} sx={{ color: "#FF4842" }}>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
