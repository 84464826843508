import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import Page from "../UI/Page";
import { useState, useEffect } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { getActivityLogs } from "../../services/common.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { useDynamicLeadTitle } from "../../pages/Leads/Leads";

const intervals = [
  {
    label: "Last 2 Months",
    value: new Date(
      new Date().setDate(new Date().getDate() - 60)
    ).toDateString(),
  },
  {
    label: "Last Month",
    value: new Date(
      new Date().setDate(new Date().getDate() - 30)
    ).toDateString(),
  },

  { label: "Custom", value: "Custom" },
];

const ReportDrop = ({
  reportData,
  handleChange,
  interval,
  setInterval,
  reportFigures,
  popup,
  setPopup,
  showChip,
  setShowChip,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleIntervals,
  setIsSetClicked,
}) => {
  console.log(reportData);
  const [openReason, setOpenReason] = useState({
    state: false,
    value: "",
  });

  const [results, setResults] = useState([]);

  const formatDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };

  const shrinkText = (text, count, insertDots) => {
    return text
      ? text.slice(0, count)
      : null + (text ? text.length : null > count && insertDots ? "..." : "");
  };

  const handleStartDateChange = (e) => {
    console.log("e", e);
    if (endDate.getTime() > e) {
      setStartDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };
  const handleEndDateChange = (e) => {
    console.log("e", e);
    if (startDate.getTime() < e) {
      setEndDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };

  const handleClose = () => {
    setPopup(false);
    setOpenReason({ state: false, value: "" });
    // setDeletePopupOpen(false);
  };

  const handleReasonPopUp = (value) => {
    setOpenReason({ state: true, value: value });
  };

  const handleClick = (value) => {
    handleReasonPopUp(value);
  };

  const handleSet = () => {
    setIsSetClicked(true);
    setShowChip(true);
    handleClose();
    handleIntervals("Custom");
  };
  const leadDynamicTitel = useDynamicLeadTitle();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ maxHeight: "46vh", overflow: "auto" }}>
        <Grid container spacing={1}>
          <Grid item xs={2.25} sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Reporting Period</Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-dateRange-select-label">Interval</InputLabel>
              <Select
                labelId="demo-dateRange-select-label"
                id="demo-dateRange-select"
                label="Interval"
                name="interval"
                value={interval}
                onChange={handleChange}
              >
                {intervals.map((interval, index) => {
                  return (
                    <MenuItem key={index} value={interval.value}>
                      {interval.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={0.25}></Grid>

          {showChip ? (
            <Grid
              item
              xs={showChip ? 2.75 : 0}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Chip
                label={`${formatDate(
                  new Date(startDate).toDateString()
                )} - ${formatDate(new Date(endDate).toDateString())}`}
                variant="outlined"
                onDelete={() => {
                  setShowChip(false);
                  setInterval("");
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>

      <Grid item xs={3}>
        {`Total ${leadDynamicTitel?.displayName.singularName} Received`}
      </Grid>
      <Grid item xs={8}>
        {reportData.length}
      </Grid>
      <Grid item xs={3}>
        Drop
      </Grid>
      <Grid item xs={8}>
        {reportFigures?.count?.drop || 0}
      </Grid>
      <Grid item xs={12}>
        {reportData.length ? (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="medium"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Lead</TableCell>
                  <TableCell align="center">Dropped On</TableCell>
                  <TableCell align="center">Reason</TableCell>
                  <TableCell align="center">Sales Owner</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map((value, index) => {
                  if (value.status === "drop") {
                    return (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {value.summary}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(value.updatedAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => {
                              handleClick(value.reason);
                            }}
                          >
                            {shrinkText(value.reason, 30, true)}
                          </TableCell>
                          <TableCell align="center">
                            {value.salesOwner}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography>
                      {`No ${leadDynamicTitel?.displayName.singularName}
                      Records`}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Dialog open={openReason.state} onClose={() => {}}>
        <DialogTitle textAlign="center">Reason</DialogTitle>
        <DialogContent>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sx={{ alignItems: "center", marginTop: "10px" }}>
              <Typography>{openReason.value}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={popup} onClose={() => {}}>
        <DialogTitle textAlign="center">Define Interval</DialogTitle>
        <DialogContent>
          <Grid container spacing={2.5}>
            <Grid item xs={6} sm={6} sx={{ marginTop: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  fullWidth
                  label="From"
                  inputFormat="MMM/dd/yyyy"
                  value={startDate}
                  maxDate={new Date().setDate(new Date().getDate() - 1)}
                  name="startDate"
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} sx={{ marginTop: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  required
                  fullWidth
                  label="To"
                  inputFormat="MMM/dd/yyyy"
                  // minDate={project.timeline.deadline}
                  // minDate={}
                  value={endDate}
                  name="endDate"
                  maxDate={new Date()}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setInterval("");
            }}
            sx={{ color: "red" }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSet}>SET</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ReportDrop;
