import CryptoJS from 'crypto-js';
const TAG = 'Encryption'
export const encryption = (passData, encrypt) => {

    if (!!passData && (!encrypt)) {
        const ciphertext = CryptoJS.AES.encrypt(passData, '123').toString();
        console.log(`${TAG}.ciphertext`, ciphertext);
        return ciphertext;
    }
    // Decrypt
    if (!!encrypt && (!passData)) {
        const bytes = CryptoJS.AES.decrypt(encrypt, '123');

        const originalText = bytes.toString(CryptoJS.enc.Utf8);

        console.log(`${TAG}.originaltext`, originalText);
        return originalText;
    }


}