import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

const ContactWatcher = ({
  setIsWatcherDialogOnEdit,
  isWatcherDialogOnEdit,
  users,
  contactInfoObj,
  setContactInfoObj,
  salesOwnerDetails,
  setSalesOwnerDetails,
  currentUser,
}) => {
  // let temp = [...users, { name: "Add Custom Mail" }];
  console.log(currentUser);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          readOnly={!isWatcherDialogOnEdit ? true : false}
          multiple
          // freeSolo
          id="tags-outlined"
          options={users}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            console.log("Option", option);
            if (
              option.active_status !== false &&
              option.name !== salesOwnerDetails.name
            ) {
              return (
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <li {...props} style={{ borderColor: "white" }}>
                      {option.name}
                    </li>
                  </Grid>
                </Grid>
              );
            }
          }}
          value={contactInfoObj.watchers || []}
          filterSelectedOptions
          onChange={(event, newValue, reason) => {
            console.log("New Value", newValue);
            console.log("Watchers", contactInfoObj.watchers);

            console.log("New Value Length", newValue.length);
            console.log("Watchers Length", contactInfoObj.watchers.length);
            if (currentUser.role !== "ADMIN") {
              if (contactInfoObj.watchers.length > newValue.length) {
                let deletedItem = [...contactInfoObj.watchers].filter(
                  (x) => !newValue.includes(x)
                );
                if (deletedItem.length > 0) {
                  deletedItem.forEach((e) => {
                    if (e._id === currentUser._id) {
                      let indexOfDeletedItem =
                        contactInfoObj.watchers.indexOf(e);
                      setContactInfoObj((prevState) => {
                        let temp = { ...prevState };
                        temp.watchers.splice(indexOfDeletedItem, 1);
                        return temp;
                      });
                    } else {
                      toast("You can't remove another watcher");
                    }
                  });
                }
              }
            } else {
              setContactInfoObj((prevState) => ({
                ...prevState,
                watchers: [...newValue],
              }));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Platform Users"
              placeholder="Click to Add"
            />
          )}
        ></Autocomplete>
      </Grid>
    </Grid>
  );
};

export default ContactWatcher;
