import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import FileItem from "./FileItem/FileItem";

const FileList = ({
  fileList,
  removeFile,
  fromProjectCreatePage,
  leadId,
  contactId,
  setLead,
  setFiles,
  fromProjectViewPage,
  projectId,
  setIsProjectFetched,
  currentUser,
  salesOwner,
  techOwner,
  setUpdateLeadCalled,
  clearDocument,
  fromCompanyCreatePage,
  fromCompanyViewPage,
  companyId,
  fetchCompanyById,
  setIactionedApiCalled,
  forPriceNegotiation,
}) => {
  fileList = clearDocument ? (fileList = []) : fileList;
  console.log(
    "Files uploaded",
    fileList,
    contactId,
    leadId,
    fromCompanyCreatePage
  );
  const activeStatusList = [];
  const privateList = [];
  fileList.map((file, index) => {
    if (file.active_status) {
      activeStatusList.push(file);
    }
  });
  activeStatusList.map((file, index) => {
    if (file.isPrivate) {
      privateList.push(file);
    }
  });
  console.log("Temp list", activeStatusList);
  // const fileArray = files[0].location.split("/")[length - 1];
  // console.log("File Name", files[0].location.split("/").pop());
  return (
    <>
      {forPriceNegotiation ? (
        <Grid container spacing={1}>
          {fileList?.map((f, i) => {
            return (
              <Grid item xs={12}>
                <FileItem
                  key={i}
                  file={f}
                  forPriceNegotiation={forPriceNegotiation}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : !fromProjectCreatePage && !fromCompanyCreatePage ? (
        <ul className="file-list">
          <Grid container spacing={1}>
            {fileList.length > 0 ? (
              activeStatusList.length > 0 ? (
                privateList.length !== activeStatusList.length ? (
                  fileList &&
                  fileList?.map((f, i) => {
                    if (f.hasOwnProperty("active_status")) {
                      if (f.active_status) {
                        if (f.hasOwnProperty("isPrivate")) {
                          if (f.isPrivate) {
                            if (
                              currentUser.role === "ADMIN" ||
                              currentUser._id === salesOwner ||
                              currentUser._id === techOwner ||
                              currentUser.role === "DELIVERY_LEAD"
                            ) {
                              return (
                                <Grid item xs={6} sm={6}>
                                  <FileItem
                                    setIsProjectFetched={setIsProjectFetched}
                                    projectId={projectId}
                                    fromProjectCreatePage={
                                      fromProjectCreatePage
                                    }
                                    fromProjectViewPage={fromProjectViewPage}
                                    setFiles={setFiles}
                                    length={fileList.length}
                                    setLead={setLead}
                                    contactId={contactId}
                                    leadId={leadId}
                                    key={i}
                                    file={f}
                                    deleteFile={() => {
                                      removeFile(i);
                                    }}
                                    setUpdateLeadCalled={setUpdateLeadCalled}
                                    fromCompanyCreatePage={
                                      fromCompanyCreatePage
                                    }
                                    fromCompanyViewPage={fromCompanyViewPage}
                                    companyId={companyId}
                                    fetchCompanyById={fetchCompanyById}
                                    setIactionedApiCalled={
                                      setIactionedApiCalled
                                    }
                                  />
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return (
                              <Grid item xs={6} sm={6}>
                                <FileItem
                                  setIsProjectFetched={setIsProjectFetched}
                                  projectId={projectId}
                                  fromProjectCreatePage={fromProjectCreatePage}
                                  fromProjectViewPage={fromProjectViewPage}
                                  setFiles={setFiles}
                                  length={fileList.length}
                                  setLead={setLead}
                                  contactId={contactId}
                                  leadId={leadId}
                                  key={i}
                                  file={f}
                                  deleteFile={() => {
                                    removeFile(i);
                                  }}
                                  setUpdateLeadCalled={setUpdateLeadCalled}
                                  fromCompanyCreatePage={fromCompanyCreatePage}
                                  fromCompanyViewPage={fromCompanyViewPage}
                                  companyId={companyId}
                                  fetchCompanyById={fetchCompanyById}
                                  setIactionedApiCalled={setIactionedApiCalled}
                                />
                              </Grid>
                            );
                          }
                        } else {
                          return (
                            <Grid item xs={6} sm={6}>
                              <FileItem
                                setIsProjectFetched={setIsProjectFetched}
                                projectId={projectId}
                                fromProjectCreatePage={fromProjectCreatePage}
                                fromProjectViewPage={fromProjectViewPage}
                                setFiles={setFiles}
                                length={fileList.length}
                                setLead={setLead}
                                contactId={contactId}
                                leadId={leadId}
                                key={i}
                                file={f}
                                deleteFile={() => {
                                  removeFile(i);
                                }}
                                setUpdateLeadCalled={setUpdateLeadCalled}
                                fromCompanyCreatePage={fromCompanyCreatePage}
                                fromCompanyViewPage={fromCompanyViewPage}
                                fetchCompanyById={fetchCompanyById}
                                companyId={companyId}
                                setIactionedApiCalled={setIactionedApiCalled}
                              />
                            </Grid>
                          );
                        }
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <Grid item xs={6} sm={6}>
                          <FileItem
                            setIsProjectFetched={setIsProjectFetched}
                            projectId={projectId}
                            fromProjectCreatePage={fromProjectCreatePage}
                            fromProjectViewPage={fromProjectViewPage}
                            setFiles={setFiles}
                            length={fileList.length}
                            setLead={setLead}
                            contactId={contactId}
                            leadId={leadId}
                            key={i}
                            file={f}
                            deleteFile={() => {
                              removeFile(i);
                            }}
                            setUpdateLeadCalled={setUpdateLeadCalled}
                            fromCompanyCreatePage={fromCompanyCreatePage}
                            fromCompanyViewPage={fromCompanyViewPage}
                            fetchCompanyById={fetchCompanyById}
                            companyId={companyId}
                            setIactionedApiCalled={setIactionedApiCalled}
                          />
                        </Grid>
                      );
                    }
                  })
                ) : currentUser.role === "ADMIN" ||
                  currentUser._id === salesOwner ||
                  currentUser._id === techOwner ? (
                  fileList &&
                  fileList?.map((f, i) => {
                    if (f.hasOwnProperty("active_status")) {
                      if (f.active_status) {
                        if (f.hasOwnProperty("isPrivate")) {
                          if (f.isPrivate) {
                            if (
                              currentUser.role === "ADMIN" ||
                              currentUser._id === salesOwner ||
                              currentUser._id === techOwner
                            ) {
                              return (
                                <Grid item xs={6} sm={6}>
                                  <FileItem
                                    setIsProjectFetched={setIsProjectFetched}
                                    projectId={projectId}
                                    fromProjectCreatePage={
                                      fromProjectCreatePage
                                    }
                                    fromProjectViewPage={fromProjectViewPage}
                                    setFiles={setFiles}
                                    length={fileList.length}
                                    setLead={setLead}
                                    contactId={contactId}
                                    leadId={leadId}
                                    key={i}
                                    file={f}
                                    deleteFile={() => {
                                      removeFile(i);
                                    }}
                                    setUpdateLeadCalled={setUpdateLeadCalled}
                                    fromCompanyCreatePage={
                                      fromCompanyCreatePage
                                    }
                                    fromCompanyViewPage={fromCompanyViewPage}
                                    fetchCompanyById={fetchCompanyById}
                                    companyId={companyId}
                                    setIactionedApiCalled={
                                      setIactionedApiCalled
                                    }
                                  />
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return (
                              <Grid item xs={6} sm={6}>
                                <FileItem
                                  setIsProjectFetched={setIsProjectFetched}
                                  projectId={projectId}
                                  fromProjectCreatePage={fromProjectCreatePage}
                                  fromProjectViewPage={fromProjectViewPage}
                                  setFiles={setFiles}
                                  length={fileList.length}
                                  setLead={setLead}
                                  contactId={contactId}
                                  leadId={leadId}
                                  key={i}
                                  file={f}
                                  deleteFile={() => {
                                    removeFile(i);
                                  }}
                                  setUpdateLeadCalled={setUpdateLeadCalled}
                                  fromCompanyCreatePage={fromCompanyCreatePage}
                                  fromCompanyViewPage={fromCompanyViewPage}
                                  fetchCompanyById={fetchCompanyById}
                                  companyId={companyId}
                                  setIactionedApiCalled={setIactionedApiCalled}
                                />
                              </Grid>
                            );
                          }
                        } else {
                          return (
                            <Grid item xs={6} sm={6}>
                              <FileItem
                                setIsProjectFetched={setIsProjectFetched}
                                projectId={projectId}
                                fromProjectCreatePage={fromProjectCreatePage}
                                fromProjectViewPage={fromProjectViewPage}
                                setFiles={setFiles}
                                length={fileList.length}
                                setLead={setLead}
                                contactId={contactId}
                                leadId={leadId}
                                key={i}
                                file={f}
                                deleteFile={() => {
                                  removeFile(i);
                                }}
                                setUpdateLeadCalled={setUpdateLeadCalled}
                                fromCompanyCreatePage={fromCompanyCreatePage}
                                fromCompanyViewPage={fromCompanyViewPage}
                                fetchCompanyById={fetchCompanyById}
                                companyId={companyId}
                                setIactionedApiCalled={setIactionedApiCalled}
                              />
                            </Grid>
                          );
                        }
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <Grid item xs={6} sm={6}>
                          <FileItem
                            setIsProjectFetched={setIsProjectFetched}
                            projectId={projectId}
                            fromProjectCreatePage={fromProjectCreatePage}
                            fromProjectViewPage={fromProjectViewPage}
                            setFiles={setFiles}
                            length={fileList.length}
                            setLead={setLead}
                            contactId={contactId}
                            leadId={leadId}
                            key={i}
                            fromCompanyCreatePage={fromCompanyCreatePage}
                            file={f}
                            deleteFile={() => {
                              removeFile(i);
                            }}
                            setUpdateLeadCalled={setUpdateLeadCalled}
                            fromCompanyViewPage={fromCompanyViewPage}
                            fetchCompanyById={fetchCompanyById}
                            companyId={companyId}
                            setIactionedApiCalled={setIactionedApiCalled}
                          />
                        </Grid>
                      );
                    }
                  })
                ) : (
                  <Grid item xs={12}>
                    <Typography textAlign="center">
                      No documents uploaded
                    </Typography>
                  </Grid>
                )
              ) : (
                <Grid item xs={12}>
                  <Typography textAlign="center">No documents</Typography>
                </Grid>
              )
            ) : (
              <Grid item xs={12}>
                <Typography textAlign="center">
                  No documents uploaded
                </Typography>
              </Grid>
            )}
          </Grid>
        </ul>
      ) : (
        <Grid container spacing={1}>
          {fileList.length > 0 ? (
            fileList?.map((f, i) => {
              return (
                <Grid item xs={6} sm={6}>
                  <FileItem
                    fileList={fileList}
                    setIsProjectFetched={setIsProjectFetched}
                    projectId={projectId}
                    fromProjectCreatePage={fromProjectCreatePage}
                    fromProjectViewPage={fromProjectViewPage}
                    setFiles={setFiles}
                    length={fileList.length}
                    setLead={setLead}
                    contactId={contactId}
                    leadId={leadId}
                    key={i}
                    file={f}
                    removeFile={removeFile}
                    deleteFile={() => {
                      removeFile(i);
                    }}
                    setUpdateLeadCalled={setUpdateLeadCalled}
                    fromCompanyCreatePage={fromCompanyCreatePage}
                    fromCompanyViewPage={fromCompanyViewPage}
                    fetchCompanyById={fetchCompanyById}
                    companyId={companyId}
                    setIactionedApiCalled={setIactionedApiCalled}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Typography textAlign="center">No documents uploaded</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default FileList;
