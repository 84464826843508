import React, { useEffect } from "react";
import Page from "../../components/UI/Page";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CompanyInfo from "../../components/Companies/CompanyInfo";
import FileUpload from "../../components/Fileupload";
import CreateAction from "../../components/Shared/Action/Actions.Create";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import FileList from "../../components/FileList";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import Info from "../../components/Contacts/Info";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { createCompany } from "../../services/companies.service";
import { useDynamicCompanyTitle } from "./Companies";
import { useDynamicContactTitle } from "../Contacts/Contacts";
import { useNavigate } from "react-router-dom";
import getAttributesByEntity from "../../services/entity.attribute.services";

const param = window.location.pathname;
let afterCreate = false;
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,7})*$/;
function CreateCompany() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const initialCompanyState = {
    name: "",
    website: "",
    // email: "",
    // phone: "",
    // alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    // customerId: "",
    // companyAdmin: "",
    state: "",
    gstNumber: "",
    documents: [],
  };
  const [company, setCompany] = useState(initialCompanyState);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    company: company.name ? company.name : "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    // customerId: "",
    source: "",
    salesOwner: "",
    attributes: [],
  });
  const [tempContactInfo, setTempContactInfo] = useState({
    name: "",
    company: company.name ? company.name : "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    // customerId: "",
    source: "",
    salesOwner: "",
    attributes: [],
  });
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [loaderDialog, setLoaderDialog] = useState(false);

  const [customAttributes, setCustomAttributes] = useState([]);
  const [customContactAttributes, setCustomContactAttributes] = useState([]);

  //Handle Company Into
  const handleChange = (event) => {
    // debugger;
    function handleInputValidation(event, fieldName, companyDynamicTitle) {
      const value = event.target.value;
      if (event.target.name === fieldName && value && !value.trim()) {
        toast.warning(
          `${companyDynamicTitle?.displayName.singularName} ${fieldName} cannot contain only spaces`
        );
        return true;
      }
      //  if (event.target.name === fieldName && value && !value.trim()) {
      //    toast.warning(
      //      `${companyDynamicTitle?.displayName.singularName} ${fieldName} cannot contain only spaces`
      //    );
      //    return true;
      //  }
      return false;
    }

    if (
      handleInputValidation(event, "name", companyDynamicTitle) ||
      handleInputValidation(event, "website", companyDynamicTitle) ||
      handleInputValidation(event, "address", companyDynamicTitle) ||
      handleInputValidation(event, "linkedIn", companyDynamicTitle)
    ) {
      return;
    }
    if (event.target.name === "gstNumber") {
      const alphanumericPattern = /^[a-zA-Z0-9]{17,20}$/;
      const value = alphanumericPattern.test(event.target.value);
      if (value) {
        toast.warning(
          "Please enter a valid alphanumeric GST Number between 16-20 characters"
        );
        return;
      }
    }
    if (event.target.name === "gstNumber") {
      const alphanumericPattern = /^[a-zA-Z0-9\s]*$/;
      const value = alphanumericPattern.test(event.target.value);
      if (!value) {
        toast.warning("Special characters are not allowed");
        return;
      }
    }

    if (event.target.name === "gstNumber") {
      const alphanumericPattern = /^[a-zA-Z0-9]+$/;
      const value = alphanumericPattern.test(event.target.value);
      console.log(
        "fgdfgdf",
        event.target.value === "",
        event.target.value,
        company.gstNumber
      );
      if (event.target.value === "") {
        setCompany((prev) => {
          const temp = { ...prev };
          temp[event.target.name] = "";
          return temp;
        });
        return;
      }
      if (!value) {
        toast.warning("Spaces are not allowed");

        return;
      }
    }
    // if (event.target.name === "name") {
    //   let regex = /^[a-zA-Z ]*$/;
    //   const value = regex.test(event.target.value);
    //   console.log("valuefdf", event.target.name);
    //   if (!value) {
    //     toast("Numbers and special characters are not allowed");
    //     return;
    //   }
    // }

    if (event.target.name === "name") {
      if (event.target.value.length > 50) {
        toast.warn("Name should not be greater than 50 characters");
        return;
      }
    }
    if (event.target.name === "website") {
      if (event.target.value.length > 100) {
        toast.warn("Website should not be greater than 100 characters");
        return;
      }
    }

    if (event.target.name === "address") {
      if (event.target.value.length > 250) {
        toast.warn("Address should not be greater than 250 characters");
        return;
      }
    }

    // if (event.target.name === "linkedIn") {
    //   if (event.target.value.length > 100) {
    //     toast.warn("LinkedIn should not be greater than 100 characters");
    //     return;
    //   }
    // }

    if (event.target.name === "customerId") {
      const alphanumericPattern = /^[a-zA-Z0-9\s]*$/;
      const value = alphanumericPattern.test(event.target.value);
      if (!value) {
        toast.warning("Special characters are not allowed");
        return;
      }
    }
    if (event.target.name === "customerId") {
      const alphanumericPattern = /^[a-zA-Z0-9]+$/;
      const value = alphanumericPattern.test(event.target.value);
      if (event.target.value === "") {
        setCompany((prev) => {
          const temp = { ...prev };
          temp[event.target.name] = "";
          return temp;
        });
        return;
      }

      if (!value) {
        toast.warning("Spaces are not allowed");

        return;
      }
      if (event.target.value.length > 10) {
        toast.warn("Customer Id should not be greater than 10 characters");
        return;
      }
    }
    setCompany((prev) => {
      const temp = { ...prev };
      temp[event.target.name] = event.target.value;
      return temp;
    });
  };

  //Action
  const initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    dueDate: new Date(),
    schedule: false,
  };
  const [action, setAction] = React.useState(initialActionState);
  const [checked, setChecked] = React.useState(false);
  const [createCompanyShowTimer, setCreateCompanyShowTimer] =
    React.useState(false);

  //Files
  const [files, setFiles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [descriptionObj, setDescriptionObj] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const uploadHandler = (event) => {
    let tempFiles = [];
    [...event.target.files].map((file, i) => {
      file["description"] = "";
      tempFiles.push(file);
    });
    setFiles((prev) => {
      return [...prev, ...tempFiles];
    });
    setOpen(() => {
      return true;
    });
  };

  const handleClose = () => {
    setFiles((prev) => (prev = []));
    setOpen(false);
    setDescriptionObj({});
  };

  const submitFile = async () => {
    const temp = { ...company };
    Object.keys(descriptionObj).forEach((eleInd) => {
      files[parseInt(eleInd)].description =
        descriptionObj[parseInt(eleInd)].trim();
    });
    temp.documents.length > 0
      ? temp.documents.push(...files)
      : temp.documents.push(...files);
    setCompany(temp);
    handleClose();
  };

  const removeFile = (index) => {
    setCompany((prev) => {
      const temp = { ...prev };
      temp.documents.splice(index, 1);
      return temp;
    });
  };

  //clear fields
  const clear = () => {
    setCompany((prev) => ({
      ...prev,
      name: "",
      website: "",
      address: "",
      country: "",
      linkedIn: "",
      state: "",
      // customerId: "",
      gstNumber: "",
      documents: [],
    }));
    setContactInfo((prev) => ({
      ...prev,
      name: "",
      company: "",
      email: "",
      phone: "",
      alternateContact: "",
      address: "",
      country: "",
      linkedIn: "",
      // customerId: "",
      source: "",
      salesOwner: "",
      attributes: [],
    }));
    setAction((prev) => ({
      ...prev,
      toDo: "",
      doer: "",
      assignedBy: currentUser._id,
      dueDate: new Date(),
      schedule: false,
    }));
    setFiles((prev) => (prev = []));
    setCustomAttributes([]);
    setCustomContactAttributes([]);
    (async () => {
      try {
        const response = await getAttributesByEntity("Contacts");
        if (response.data.status) {
          setCustomContactAttributes(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  };

  //validate

  const checkPureData = (data) => {
    if (
      data !== undefined &&
      data !== null &&
      typeof data === "string" &&
      data.trim() !== ""
    ) {
      return true;
    }
    return false;
  };

  const validateMandatoryFields = (element, field) => {
    const mandatoryFileds = {
      company: ["name", "website", "country"],
      contact: ["name", "email", "country", "salesOwner"],
      action: ["toDo", "doer"],
    };
    let flag = true;
    Object.keys(element).forEach((ele, ind) => {
      console.log("element ::", element);
      console.log(!checkPureData(element[ele]), "run");
      if (
        mandatoryFileds[field].includes(ele) &&
        !checkPureData(element[ele])
      ) {
        console.log("run");
        if (field === "company") {
          toast(
            `${companyDynamicTitle?.displayName.singularName} ${ele} can not be empty`
          );
          flag = false;
        } else if (field === "contact") {
          toast(
            `${contactDynamicTitle?.displayName.singularName} ${ele} can not be empty`
          );
          flag = false;
        } else if (field === "action") {
          toast(`Action ${ele} can not be empty`);
          flag = false;
        }
      }
    });

    if (field === "company" && element["country"] === "India") {
      if (!checkPureData(element["state"])) {
        toast(
          `${companyDynamicTitle?.displayName.singularName} state can not be empty`
        );
        flag = false;
      }
      // if (!checkPureData(element["gstNumber"])) {
      //   toast(
      //     `${companyDynamicTitle?.displayName.singularName} GST number can not be empty`
      //   );
      //   flag = false;
      // }
    }

    return flag;
  };

  //validate custom attribute
  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(
          `${companyDynamicTitle?.displayName.singularName} ${ele.name} can not be empty`
        );
        flag = false;
      }
      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const contactAttributeValidateFunction = (customContactAttributes) => {
    let flag = true;
    customContactAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(
          `${contactDynamicTitle?.displayName.singularName} ${ele.name} can not be empty`
        );
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`Text should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`Text should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };
  //create Company
  const handleCreate = async () => {
    let data = { ...company };
    const isCompanyValid = validateMandatoryFields(company, "company");
    let isContactValid = true,
      isActionValid = true;
    contactInfo.attributes = [...customContactAttributes];
    if (showContactInfo) {
      isContactValid = validateMandatoryFields(contactInfo, "contact");
      if (contactInfo.email && !emailRegex.test(contactInfo.email)) {
        toast("Please enter a valid email address in contact details");
        return;
      }
      data["contact"] = {
        ...contactInfo,
        source: contactInfo.source ? contactInfo.source.name : "",
      };
    }
    if (action.doer || action.toDo) {
      isActionValid = validateMandatoryFields(action, "action");
      data["action"] = { ...action, schedule: checked };
    }

    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      setLoaderDialog((prev) => (prev = false));
      return;
    }
    if (showContactInfo) {
      const contactAttributeValidate = contactAttributeValidateFunction(
        customContactAttributes
      );
      if (!contactAttributeValidate) {
        return;
      }
    }

    const permission = isCompanyValid && isContactValid && isActionValid;
    if (!permission) {
      setLoaderDialog((prev) => (prev = false));
      return;
    }

    try {
      console.log(data);
      const fromData = new FormData();
      Object.keys(data).forEach((ele) => {
        if (ele !== "documents") {
          fromData.append(
            ele,
            typeof data[ele] === "string"
              ? data[ele]
              : JSON.stringify(data[ele])
          );
        }
      });
      if (data.documents.length) {
        const descriptionFiles = [];
        const checkedIndexes = [];
        data.documents.forEach((file, index) => {
          if (file.description) {
            descriptionFiles.push({
              fileIndex: index,
              description: file.description,
            });
          }
          if (file.checked) {
            checkedIndexes.push(index);
          }
          fromData.append("docFile", file);
        });
        fromData.append("descriptionFiles", JSON.stringify(descriptionFiles));
        fromData.append("checkedIndexes", JSON.stringify(checkedIndexes));
      }

      fromData.append("attributes", JSON.stringify(customAttributes));
      // fromData.append("contacts", JSON.stringify(temp));
      setLoaderDialog((prev) => (prev = true));

      const response = await createCompany(fromData);
      if (response.data.status) {
        setLoaderDialog((prev) => (prev = false));
        toast(
          `${companyDynamicTitle?.displayName.singularName} Created Successfully`
        );
        clear();
        afterCreate = !afterCreate;
      }
    } catch (error) {
      console.log("///", error);
      setLoaderDialog((prev) => (prev = false));

      console.log("error.response.data[0]", error.response.data.data[0]);
      toast.warn(error?.response?.data?.data[0]);
    }
  };

  useEffect(() => {
    setContactInfo((prev) => ({ ...prev, company: company.name }));
  }, [company.name]);
  const companyDynamicTitle = useDynamicCompanyTitle();
  const contactDynamicTitle = useDynamicContactTitle();

  useEffect(() => {
    setCustomContactAttributes([]);
    (async () => {
      try {
        const response = await getAttributesByEntity("Contacts");
        if (response.data.status) {
          setCustomContactAttributes(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Page>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${companyDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CompanyInfo
                      company={company}
                      handleChange={handleChange}
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      afterCreate={afterCreate}
                      fromCompanyCreate={false}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {!showContactInfo ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setShowContactInfo(true);
                }}
                variant="contained"
              >
                {`Add ${contactDynamicTitle?.displayName.singularName} Requirements`}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card
                className="child"
                sx={{
                  minWidth: "auto",
                  minHeight: "auto",
                  backgroundColor: "#f1f2f6",
                }}
                elevation={3}
                variant="outlined"
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={11.25} sm={11.25}>
                          <Typography variant="h5" gutterBottom color="primary">
                            {`${contactDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                          </Typography>
                        </Grid>
                        <Grid item xs={0.75} sm={0.75}>
                          <Tooltip title="Remove Requirement">
                            <Button
                              varaint="contained"
                              onClick={() => {
                                // setCustomLeadAttributes([]);
                                setShowContactInfo((prev) => (prev = false));
                                setContactInfo((prev) => ({
                                  ...prev,
                                  ...tempContactInfo,
                                  company: company.name,
                                }));
                              }}
                            >
                              <ClearIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Info
                        contact={contactInfo}
                        setContact={setContactInfo}
                        customAttributes={customContactAttributes}
                        setCustomAttributes={setCustomContactAttributes}
                        fromCompanyCreatePage={true}
                        afterCreate={afterCreate}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTION
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <CreateAction
                          createContactShowTimer={createCompanyShowTimer}
                          setCreateContactShowTimer={setCreateCompanyShowTimer}
                          setAction={setAction}
                          action={action}
                          checked={checked}
                          setChecked={setChecked}
                          setPage={() => {}}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={9} sm={9}>
                    <Typography variant="h5" gutterBottom color="primary">
                      DOCUMENTS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <div className="file-cards">
                      <div className="file-inputs">
                        {/* <input
                          type="file"
                          multiple="multiple"
                          onChange={uploadHandler}
                        /> */}

                        <Button
                          variant="contained"
                          size="medium"
                          startIcon={<AddIcon />}
                          onClick={handleClickOpen}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12}>
                            <FileList
                              fileList={company.documents}
                              removeFile={removeFile}
                              fromCompanyCreatePage={true}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
                <Dialog maxWidth="md" open={open} onClose={handleClose}>
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      fromCompanyPage={true}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submitFile}>Save</Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Stack display="flex" flexDirection="row-reverse">
              <Button variant="contained" onClick={handleCreate}>
                CREATE
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default CreateCompany;
