import history from "history/browser";
import { BroadcastChannel } from "broadcast-channel";

const logoutChannel = new BroadcastChannel("logout");
const loginChannel = new BroadcastChannel("login");

export const login = (token, name, id, role, isTenant) => {
  //   localStorage.setItem("token", "AUTH_TOKEN");
  //     history.push("/app/dashboard");
  localStorage.setItem("AUTH_TOKEN", token);
  localStorage.setItem(
    "AUTH_USER",
    JSON.stringify({ id, role, name, isTenant })
  );
};

export const loginAllTabs = () => {
  loginChannel.onmessage = () => {
    login();
    loginChannel.close();
  };
};

export const checkToken = () => {
  const token = localStorage.getItem("token", "AUTH_TOKEN");
  if (!token) return alert("You are not logged in");
  return token;
};

export const logout = () => {
  logoutChannel.postMessage("Logout");
  // localStorage.removeItem("token", "AUTH_TOKEN");
  localStorage.clear();
  window.location.href = window.location.origin + "/";
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};
