const apis = require("./api.requests");
const path = "/emailcampaign/v1/";

async function getAllEmailCampaigns() {
  return await apis.hitGetApi(`${path}/all`);
}

async function updateEmailCampaign(campaignId, data) {
  return await apis.hitPutApi(`${path}/update/${campaignId}`, data);
}

export { getAllEmailCampaigns, updateEmailCampaign };
