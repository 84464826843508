export const hasSpecialCharacter = (inputString) => {
  var pattern = /[!@#$%^&*()\-_=+\[\]{};:'",.<>/?\\|]/;
  console.log("inputString", inputString);
  if (Array.isArray(inputString)) {
    const result = inputString.map((element) => {
      return pattern.test(element);
    });

    return result.some((item) => item === true);
  } else {
    return pattern.test(inputString);
  }
};
