// component
import Iconify from "../../components/UI/Iconify";
import ContactsIcon from "@mui/icons-material/Contacts";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BookIcon from "@mui/icons-material/Book";
import ArchiveIcon from "@mui/icons-material/Archive";
import StorageIcon from "@mui/icons-material/Storage";
import { IconButton, Tooltip } from "@mui/material";
import BallotIcon from "@mui/icons-material/Ballot";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CampaignIcon from "@mui/icons-material/Campaign";
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: (
      <Tooltip title="Dashboard" placement="right-start">
        <IconButton>{getIcon("eva:pie-chart-2-fill")}</IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Customize",
    path: "/customize",
    icon: (
      <Tooltip title="Customize" placement="right-start">
        <IconButton>
          <AppRegistrationIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  // {
  //   title: "Users",
  //   path: "/users",
  //   icon: (
  //     <Tooltip title="Users" placement="right-start">
  //       <IconButton>{getIcon("eva:people-fill")}</IconButton>
  //     </Tooltip>
  //   ),
  // },
  // {
  //   title: "Roles",
  //   path: "/roles",
  //   icon: (
  //     <Tooltip title="Roles" placement="right-start">
  //       <IconButton>
  //         <ManageAccountsIcon />
  //       </IconButton>
  //     </Tooltip>
  //   ),
  // },
  {
    title: "Companies",
    path: "/companies",
    icon: (
      <Tooltip title="Companies" placement="right-start">
        <IconButton>
          <ApartmentIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Contacts",
    path: "/contacts",
    // icon: getIcon("mdi:sale"),
    icon: (
      <Tooltip title="Contacts" placement="right-start">
        <IconButton>
          <ContactsIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Leads",
    path: "/Leads",
    // icon: getIcon("mdi:sale"),
    icon: (
      <Tooltip title="Leads" placement="right-start">
        <IconButton>
          <LeaderboardIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Projects",
    path: "/projects",
    icon: (
      <Tooltip title="Projects" placement="right-start">
        <IconButton>
          <FolderSpecialIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Emails",
    path: "/emails",
    icon: (
      <Tooltip title="Emails" placement="right-start">
        <IconButton>
          <EmailIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  // {
  //   title: "Activity Logs",
  //   path: "/logs",
  //   icon: (
  //     <Tooltip title="Activity Logs" placement="right-start">
  //       <IconButton>
  //         <BookIcon />
  //       </IconButton>
  //     </Tooltip>
  //   ),
  // },
  // {
  //   title: "Archives",
  //   path: "/archives",
  //   icon: (
  //     <Tooltip title="Archives" placement="right-start">
  //       <IconButton>
  //         <ArchiveIcon />
  //       </IconButton>
  //     </Tooltip>
  //   ),
  // },
  {
    title: "Reports",
    path: "/reports",
    icon: (
      <Tooltip title="Reports" placement="right-start">
        <IconButton>
          <StorageIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Collection Reports",
    path: "/collectionreports",
    icon: (
      <Tooltip title="Collection Reports" placement="right-start">
        <IconButton>
          <ReceiptLongIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Accounts Report",
    path: "/accountsreport",
    icon: (
      <Tooltip title="Accounts Report" placement="right-start">
        <IconButton>
          <BadgeIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  {
    title: "Sales Campaign",
    path: "/salescampaign",
    icon: (
      <Tooltip title="Sales Campaign" placement="right-start">
        <IconButton>
          <CampaignIcon />
        </IconButton>
      </Tooltip>
    ),
  },
  // {
  //   title: "Email Template",
  //   path: "/emailtemplate",
  //   icon: (
  //     <Tooltip title="Email Template" placement="right-start">
  //       <IconButton>
  //         <BallotIcon />
  //       </IconButton>
  //     </Tooltip>
  //   ),
  // },
];

// children: [
//       {
//         title: "Lead Report",
//         path: "/reports",
//       },
//       {
//         title: "Invoice Report",
//         path: "/reports/invoice",
//       },
//     ],

export default navConfig;
