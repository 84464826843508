const apis = require("./api.requests");

const TAG = "salesCampaign.service";
const path = "/emailcampaign/v1";

async function createSalesCampaign(body) {
  return await apis.hitPostApi(`${path}/create`, body);
}

async function getSalesCampaign() {
  return await apis.hitPagedApi(`${path}/all`);
}

async function getSalesCampaignById(contactId) {
  return await apis.hitGetApi(`${path}/${contactId}/details`);
}

async function updateSalesCampaign(contactId, body) {
  return await apis.hitPutApi(`${path}/${contactId}`, body);
}

async function removeSalesCampaign(contactId) {
  return await apis.hitDeleteApi(`${path}/${contactId}`);
}

async function updateSalesCampaignExclusionEmail(contactId, status) {
  return await apis.hitPutApi(
    `${path}/updateExclusion/${contactId}?isExcluded=${status}`
  );
}

export {
  createSalesCampaign,
  getSalesCampaign,
  getSalesCampaignById,
  updateSalesCampaign,
  removeSalesCampaign,
  updateSalesCampaignExclusionEmail,
};
