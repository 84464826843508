import { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { getAllMyMails } from "../services/common.service";
import {
  createDataInIndexedDb,
  deleteDataInIndexedDb,
  getMyMails,
  getPassword,
  insertDataInIndexedDb,
} from "../utils/indexedDb";
import { isBase64KeyInPassword } from "../utils/isBase64KeyInPassword";
import { AuthContext } from "./auth.context";

export const MailSyncContext = createContext();

const MailSyncProvider = ({ children }) => {
  const { currentUser, setEmailPassword } = useContext(AuthContext);
  const [mailSync, setMailSync] = useState(false);
  const [openPasswordAlert, setOpenPasswordAlert] = useState(false);

  const handlegetAllContactEmail = async () => {
    // await createDataInIndexedDb();
    console.log("first");

    try {
      createDataInIndexedDb();
      const getpass = await getPassword();
      console.log("getpass", getpass);
      const isBase64Key = isBase64KeyInPassword(getpass);
      // console.log("isBase64Key", isBase64Key);
      setMailSync(true);
      const response = await getAllMyMails(currentUser.email, isBase64Key);
      console.log("Response315", response);
      if (response.data.message === "Aunthetication failed") {
        console.log("Authentication Failed");
        setMailSync(false);
        toast("Authentication Failed");
        await deleteDataInIndexedDb();
        setEmailPassword("");
        return "Aunthetication failed";
      }
      if (response.data.message === "Nothing to fetch") {
        toast("Email Not Found");
        setMailSync(false);
        return;
      }
      if (
        response.data.message === "Timed out while authenticating with server"
      ) {
        toast("Timed out while authenticating with server");
        setMailSync(false);
        return "Timed out while authenticating with server";
      }

      if (response.data.status) {
        console.log("response.data", response.data);
        const emailsArr = await getMyMails();
        if (emailsArr.length > 0) {
          console.log("email append");
          const lastIndex = emailsArr.length - 1;
          createDataInIndexedDb();
          insertDataInIndexedDb(response.data.data);
          setMailSync(false);
          toast("Email Sync Successfully");
        } else {
          createDataInIndexedDb();
          insertDataInIndexedDb(response.data.data);
          setMailSync(false);
          toast("Email Sync Successfully");
        }
      }
    } catch (err) {
      setOpenPasswordAlert(true);
      console.log("err");
    }
  };

  return (
    <MailSyncContext.Provider
      value={{
        handlegetAllContactEmail,
        mailSync,
        setMailSync,
        openPasswordAlert,
        setOpenPasswordAlert,
      }}
    >
      {children}
    </MailSyncContext.Provider>
  );
};

export default MailSyncProvider;
