import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getInvoiceDetailsByProjectId } from "../../services/projects.service";
import SearchNotFound from "../SearchNotFound";

const ProjectInvoiceAction = ({ projectId }) => {
  const [openDialog, setopenDialog] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    loaderEnable: true,
    data: [],
  });
  const [handlePagination, setHandlePagination] = useState({
    rowsPerPage: 5,
    page: 0,
    handleChangePage: (event, newPage) => {
      event.stopPropagation();
      setHandlePagination((prev) => ({
        ...prev,
        page: +newPage,
        rowsPerPage: 5,
      }));
    },
    handleChangeRowsPerPage: (event) => {
      event.stopPropagation();
      setHandlePagination((prev) => ({
        ...prev,
        page: 0,
        rowsPerPage: +event.target.value,
      }));
    },
  });

  const handleClickMenu = (event) => {
    event.stopPropagation();
    console.log("run dialog");
    setopenDialog(!openDialog);
  };

  const handleDialogClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  async function getInvoiceDetails(projectId) {
    try {
      const response = await getInvoiceDetailsByProjectId(projectId);
      if (response.data.status) {
        // console.log(response.data.data, "mahdetails");

        const filterArr = response.data.data.filter(
          // (elem, i) => elem.activeStatus || elem.paid === elem.amountTotal
          (elem, i) => !elem.isCancelled
        );
        console.log(filterArr, "mahdetails");

        setInvoiceDetails((prev) => ({
          ...prev,
          data: [...filterArr],
          loaderEnable: false,
        }));
      }
    } catch (error) {
      setInvoiceDetails((prev) => ({
        ...prev,
        loaderEnable: false,
      }));
      console.log(error, "getinvoice errors..");
    }
  }
  useEffect(() => {
    getInvoiceDetails(projectId);
  }, [projectId]);

  return (
    <>
      <IconButton onClick={(event) => handleClickMenu(event)}>
        <MoreVertIcon />
      </IconButton>
      {/* <LinearProgress /> */}

      <Dialog
        maxWidth="md"
        fullWidth
        open={openDialog}
        onClose={() => {}}
        onClick={handleDialogClick}
      >
        <DialogTitle>Invoice Details</DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Amount Paid</TableCell>
                  <TableCell>Amount Left</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceDetails.loaderEnable ? (
                  <LinearProgress />
                ) : !invoiceDetails.loaderEnable &&
                  invoiceDetails.data.length ? (
                  invoiceDetails.data
                    ?.slice(
                      handlePagination.page * handlePagination.rowsPerPage,
                      handlePagination.page * handlePagination.rowsPerPage +
                        handlePagination.rowsPerPage
                    )
                    .map((invoice, index) => {
                      let {
                        invoiceNumber,
                        invoiceDate,
                        amountTotal,
                        paid,
                        dueAmount,
                      } = invoice;
                      return (
                        <TableRow key={index}>
                          <TableCell>{invoiceNumber}</TableCell>
                          <TableCell>
                            {new Date(invoiceDate).toDateString()}
                          </TableCell>
                          <TableCell>{amountTotal || 0}</TableCell>
                          <TableCell>{paid || 0}</TableCell>
                          <TableCell>{dueAmount || 0}</TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      alignItem="center"
                      colSpan={5}
                      sx={{
                        py: 3,
                        marginLeft: "10px",
                      }}
                    >
                      <Paper
                        sx={{
                          display: "flex",
                          // textAlign: "center",
                          width: "150px",
                        }}
                      >
                        <Typography
                          gutterBottom
                          textAlign="center"
                          fontWeight="700"
                          color="#857C7A"
                          variant="subtitle1"
                          sx={{
                            //   width: "300px",
                            display: "flex",
                            // alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          No records of invoices.{" "}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {invoiceDetails.data.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={invoiceDetails.data.length}
              rowsPerPage={handlePagination.rowsPerPage}
              page={handlePagination.page}
              onPageChange={handlePagination.handleChangePage}
              onRowsPerPageChange={handlePagination.handleChangeRowsPerPage}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => handleClickMenu(event)}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProjectInvoiceAction;
