import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
// mock
import account from "../../_mock/account";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/UI/Logo";
import Scrollbar from "../../components/UI/Scrollbar";
import NavSection from "../../components/UI/NavSection";
import NavSectionOnlyIcon from "../../components/UI/NavSectionOnlyIcon";
//
import navConfig from "./NavConfig";
import { AuthContext } from "../../context/auth.context";
import Iconify from "../../components/UI/Iconify";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: 60,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  onOpenSidebar,
  setDeskTopClick,
  deskTopClick,
}) {
  const navigate = useNavigate();
  const { currentUser, accessModules } = useContext(AuthContext);
  const mappedArray = navConfig.map((element) => {
    for (let i = 0; i < accessModules.length; i++) {
      if (accessModules[i] === element.title.toUpperCase()) {
        return element;
      }
    }
  });

  const newMappedArray = accessModules?.reduce((acc, mod) => {
    if (mod === "USERS" && currentUser?.role === "SUPER_ADMIN") {
      acc.push({
        title: "Users",
        path: "/users",
        icon: (
          <Tooltip title="Users" placement="right-start">
            <IconButton>
              {<Iconify icon={"eva:people-fill"} width={22} height={22} />}
            </IconButton>
          </Tooltip>
        ),
      });
    }
    const navData = navConfig.find(({ title }) => {
      return title.toUpperCase() === mod;
    });
    if (navData) {
      acc.push(navData);
    }
    return acc;
  }, []);

  const sidebarModules = newMappedArray.filter((element) => {
    return element;
  });
  const { pathname } = useLocation();

  let isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        {/* <Logo /> */}
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        {/* <Button onClick={() => navigate(-1)}>go back</Button> */}
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
            <Box
              sx={{
                ml: 8,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: "text.primary", align: "right" }}
              >
                {/* //{account.displayName} */}
                {/* {currentUser.name} */}
                UlAlpha
              </Typography>
              {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {currentUser.role}
              </Typography> */}
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      {console.log("Current user role", currentUser.role)}
      <NavSection navConfig={sidebarModules} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: "absolute", top: -50 }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              From only $69
            </Typography>
          </Box>
          <Button
            href="https://material-ui.com/store/items/minimal-dashboard/"
            target="_blank"
            variant="contained"
          >
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  const desktopReanderOnlyIcon = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "23px",
        }}
      >
        {isDesktop && (
          <IconButton
            sx={{
              color: "text.primary",
            }}
            onClick={() => {
              setDeskTopClick(true);
              onOpenSidebar();
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
      </Box>

      {
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "79px",
          }}
        >
          <NavSectionOnlyIcon
            navConfig={sidebarModules}
            deskTopClick={deskTopClick}
            isDesktop={isDesktop}
          />
        </Box>
      }
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {deskTopClick && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open={true}
          variant="persistent"
          PaperProps={{
            sx: {
              width: 65.5,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {desktopReanderOnlyIcon}
        </Drawer>
      )}
    </RootStyle>
  );
}
