import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { createLead, getContactsForDrop } from "../../services/leads.service";
import Iconify from "../../components/UI/Iconify";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import LeadInfo from "../../components/Lead/Lead.view";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import LeadCreateComponent from "../../components/Lead/Lead.create";
import CreateAction from "../../components/Shared/Action/Actions.Create";
import Page from "../../components/UI/Page";
import Info from "../../components/Contacts/Info";
import { createContact } from "../../services/contacts.service";
import EventBus from "../../components/texteditor/EventBus";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useDynamicContactTitle } from "../Contacts/Contacts";
import { useDynamicLeadTitle } from "./Leads";
import getAttributesByEntity from "../../services/entity.attribute.services";

let hitApi = true;

let afterCreate = false;

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,7})*$/;

const ContactListForLeadCreate = () => {
  const leadDynamicTitle = useDynamicLeadTitle();
  const contactDynamicTitel = useDynamicContactTitle();
  const [contacts, setContacts] = useState([]);
  const [saveButton, setSaveButton] = React.useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  //  const navigate = useNavigate();

  //  const handleClick = (id) => {
  //   navigate(`/contacts/${id}`, { replace: true });
  //  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickAgree = () => {
    setIsCreateContactClicked(!isCreateContactClicked);
    handleClose();
  };
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const [isEditPage] = React.useState(true);
  const [subScreen, setSubScreen] = useState(false);
  const [selectedContact, setSelectedContact] = useState({ name: "" });
  const [isCreateContactClicked, setIsCreateContactClicked] = useState(false);

  const onClick = () => {
    setSubScreen(true);
  };

  const [isShown, setIsShown] = useState(false);
  const handleClick = (event) => {
    setIsShown(event);
  };

  let initialLeadState = {
    summary: "",
    skills: [],
    techOwner: "",
    status: "lead",
    description: "",
    files: [],
  };
  //TODO: Remove the curly braces
  const [lead, setLead] = React.useState(initialLeadState);
  const [toEdit, setToEdit] = React.useState(true);

  const initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    dueDate: new Date(),
    // dueTime: new Date(),
  };
  console.log(initialActionState.dueDate);
  const [action, setAction] = React.useState(initialActionState);

  let initialContactState = {
    name: "",
    company: "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    source: {
      source: "",
    },
    salesOwner: "",
  };

  const [contact, setContact] = React.useState(initialContactState);
  const [customAttributesLead, setCustomAttributesLead] = React.useState([]);
  const [customAttributesContact, setCustomAttributesContact] = React.useState(
    []
  );

  const [createContactShowTimer, setCreateContactShowTimer] =
    React.useState(false);

  useEffect(() => {
    if (
      currentUser.role === "ADMIN" ||
      currentUser.role === "DELIVERY_LEAD" ||
      currentUser.role === "SALES"
    ) {
      FetchContact();
    } else {
      navigate(`/unauthorized`);
    }
  }, []);

  // const handleDateFormat = (dueDate) => {
  //   var updatedDateFormat = new Date(dueDate);
  //   var month = updatedDateFormat.getMonth() + 1;
  //   var date = updatedDateFormat.getDate();
  //   var year = updatedDateFormat.getFullYear();
  //   var updatedDate = "" + month + "/" + date + "/" + year;
  //   return updatedDate;
  // };

  // const handleTimeFormat = (dueTime) => {
  //   let modifiedHour = "";
  //   let timeDesignator;
  //   var updatedTimeFormat = new Date(dueTime);
  //   var hour = updatedTimeFormat.getHours();
  //   modifiedHour = hour > 12 ? hour - 12 : hour;
  //   timeDesignator = hour >= 12 ? "PM" : "AM";
  //   var minute = updatedTimeFormat.getMinutes();
  //   var updatedTime =
  //     "" +
  //     (modifiedHour >= 10 ? modifiedHour : "0" + modifiedHour) +
  //     ":" +
  //     (minute >= 10 ? minute : "0" + minute) +
  //     " " +
  //     timeDesignator;
  //   console.log("updatedTime", updatedTime);
  //   return updatedTime;
  // };

  const FetchContact = async () => {
    await getContactsForDrop()
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          console.log("response", response);
          setContacts(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickId = (newVal) => {
    setSelectedContact(newVal);
    if (isCreateContactClicked) {
      handleClickOpen();
    }
    console.log(newVal._id);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function dateChange(date) {
    console.log(date);
    const dateTimeInSeconds = new Date(date).getTime();
    console.log(dateTimeInSeconds);
    const offSet = new Date().getTimezoneOffset();
    console.log(offSet);
    const offSetInSeconds = offSet * 60 * 1000;
    console.log(offSetInSeconds);
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    console.log(newDateInSeconds);
    const newDate = new Date(newDateInSeconds);
    console.log(newDate);
    const newDateFormat = formatDate(newDate);
    console.log(newDateFormat);
    return newDateFormat;
  }

  function formatDate(date) {
    console.log(date);
    if (!createContactShowTimer) {
      date = new Date(date);
    }
    date = new Date(date);

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":") +
      ":00" +
      ".000Z"
    );
  }

  const validate = (data) => {
    console.log(data);
    return data === "" || data === undefined || data === null ? false : true;
  };

  const validateAction = (toDo, doer) => {
    let result;
    if (toDo === "" && doer === "") {
      result = true;
    } else if (toDo !== "" && doer !== "") {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const handleCreateLead = async () => {
    if (isCreateContactClicked) {
      if (!validate(contact.name)) {
        toast("Name can not be empty");
        hitApi = false;
      }
      if (!validate(contact.email)) {
        toast("Email can not be empty");
        hitApi = false;
      }
      if (!validate(contact.salesOwner)) {
        toast("Sales Owner can not be empty");
        hitApi = false;
      }
      if (!validate(lead.summary)) {
        toast("Summary can not be empty");
        hitApi = false;
      }
      if (!validate(lead.techOwner)) {
        toast("Tech Owner can not be empty");
        hitApi = false;
      }
      if (!validateAction(action.toDo, action.doer)) {
        toast(
          "Fill all the action fields to register an action else leave all action fields empty"
        );
        hitApi = false;
      }
      const attributeValidateLead =
        attributeValidateFunction(customAttributesLead);
      if (!attributeValidateLead) {
        return;
      }
      const attributeValidateContact = attributeValidateFunction(
        customAttributesContact
      );
      if (!attributeValidateContact) {
        return;
      }
      if (
        validate(contact.name) &&
        validate(contact.email) &&
        validate(contact.salesOwner) &&
        validate(lead.summary) &&
        validate(lead.techOwner) &&
        validateAction(action.toDo, action.doer)
      ) {
        hitApi = true;
      }
      let temp = {
        name: contact.name,
        email: contact.email,
        country: contact.country,
        company: contact.company,
        phone: contact.phone,
        alternateContact: contact.alternateContact,
        linkedIn: contact.linkedIn,
        address: contact.address,
        source: contact.source.name,
        salesOwner: contact.salesOwner,
        attributes: [...customAttributesContact],
        lead: {
          summary: lead.summary,
          skillsArray: lead.skills,
          techOwner: lead.techOwner,
          description: lead.description,
          status: "lead",
          attributes: [...customAttributesLead],
        },
      };
      if (temp.email && !emailRegex.test(temp.email)) {
        toast("Please enter a valid email address in contact details");
        hitApi = false;
      }
      if (
        action.toDo !== null &&
        action.doer !== null &&
        action.dueDate !== null &&
        action.toDo !== undefined &&
        action.doer !== undefined &&
        action.dueDate !== undefined &&
        action.toDo !== "" &&
        action.doer !== "" &&
        action.dueDate !== ""
      ) {
        // action.dueDate = handleDateFormat(action.dueDate);
        // createContactShowTimer === true
        //   ? (action.dueTime = handleTimeFormat(action.dueTime))
        //   : (action.dueTime = "00:00:00");
        if (!createContactShowTimer) {
          action.dueDate = action.dueDate.toDateString();
        }

        temp = { ...temp, action };
        temp.action.dueDate = dateChange(temp.action.dueDate);
        temp.action.schedule = checked;
      }

      try {
        if (hitApi) {
          setSaveButton(true);
          // temp.lead.skillsArray = temp.lead.skillsArray.join(",")
          setLoaderDialog(true);
        }
        const response = hitApi ? await createContact(temp) : null;
        if (response.data.status) {
          toast(
            `${leadDynamicTitle?.displayName.singularName} created successfully`
          );
          setSaveButton(false);
          setLoaderDialog(false);
          afterCreate = !afterCreate;
          setCustomAttributesLead([]);
          setCustomAttributesContact([]);
        }
        console.log(response);
        ResetForm();
        setCustomAttributesLead([]);
        setCustomAttributesContact([]);
        setChecked(false);
        setIsCreateContactClicked(false);
        //console.log(temp);
      } catch (error) {
        setSaveButton(false);
        setLoaderDialog(false);

        if (error?.response?.data?.message === "Contact already exist!") {
          toast.warn("Contact already exist!");
          return;
        }
        // if (
        //   error.response.data.data[0] ===
        //   '"lead.summary" is not allowed to be empty'
        // ) {
        //   toast('"summary" is not allowed to be empty');
        // } else {
        //   toast(error.response.data.data[0]);
        // }
      }
    } else {
      try {
        if (!validate(selectedContact._id)) {
          toast(`Select a ${contactDynamicTitel?.displayName.singularName}`);
          hitApi = false;
        }
        if (!validate(lead.summary)) {
          toast("Summary can not be empty");
          hitApi = false;
        }
        if (!validate(lead.techOwner)) {
          toast("Tech Owner can not be empty");
          hitApi = false;
        }
        if (!validateAction(action.toDo, action.doer)) {
          toast(
            "Fill all the action fields to register an action else leave all action fields empty"
          );
          hitApi = false;
        }
        const attributeValidateLead =
          attributeValidateFunction(customAttributesLead);
        if (!attributeValidateLead) {
          return;
        }
        if (
          validate(selectedContact._id) &&
          validate(lead.summary) &&
          validate(lead.techOwner) &&
          validateAction(action.toDo, action.doer)
        ) {
          hitApi = true;
        }
        let temp = { ...lead, attributes: [...customAttributesLead] };
        temp.skillsArray = temp.skills;
        delete temp.skills;
        if (
          action.toDo !== null &&
          action.doer !== null &&
          action.dueDate !== null &&
          action.toDo !== undefined &&
          action.doer !== undefined &&
          action.dueDate !== undefined &&
          action.toDo !== "" &&
          action.doer !== "" &&
          action.dueDate !== ""
        ) {
          // action.dueDate = handleDateFormat(action.dueDate);
          // createContactShowTimer === true
          //   ? (action.dueTime = handleTimeFormat(action.dueTime))
          //   : (action.dueTime = "00:00:00");
          if (!createContactShowTimer) {
            action.dueDate = new Date(action.dueDate).toDateString();
          }
          temp = { ...temp, action };
          temp.action.dueDate = dateChange(temp.action.dueDate);
          temp.action.schedule = checked;
        }
        delete temp.files;
        if (hitApi) {
          setSaveButton(true);
          // temp.skills = temp.skills.join(",");
          // temp.skillsArray = temp.skills;
          // delete temp.skills;
          console.log("Temp", temp);
          setLoaderDialog(true);
        }
        const response = hitApi
          ? await createLead(selectedContact._id, temp)
          : null;
        if (response.data.status) {
          console.log(temp);
          // setIsLeadFetched(false);
          toast(
            `${leadDynamicTitle?.displayName.singularName} added successfully`
          );
          setLead(initialLeadState);
          EventBus.dispatch("EditorBus");
          handleClose();
          setChecked(false);
          afterCreate = !afterCreate;
          ResetForm();
          setCustomAttributesLead([]);
          setIsCreateContactClicked(false);
          setSaveButton(false);
          setLoaderDialog(false);
        }
      } catch (error) {
        setLoaderDialog(false);
        if (error.response.data.message) {
          toast(error.response.data.message);
        }
        toast(error.response.data.data[0]);
        console.log(error);
        setSaveButton(false);
      }
    }
  };

  const ResetForm = () => {
    setContact(initialContactState);
    setLead(initialLeadState);
    setAction(initialActionState);
    EventBus.dispatch("EditorBus");
    setSelectedContact({ name: "" });
    setCustomAttributesLead([]);
  };
  // Custom Attribute

  useEffect(() => {
    (async () => {
      try {
        const [response1, response2] = await Promise.all([
          getAttributesByEntity("Leads"),
          getAttributesByEntity("Contacts"),
        ]);
        if (
          response1.data.status
          // && response2.data.status
        ) {
          setCustomAttributesLead(response1.data.data);
          // setCustomAttributesContact(response2.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [afterCreate]);

  return (
    <Page>
      <Container>
        {/* <ToastContainer /> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Card
              elevation={5}
              variant="outlined"
              sx={{ backgroundColor: "#f1f2f6" }}
            >
              <CardContent>
                <Card elevation={3} variant="outlined">
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        {/* <Autocomplete
                  options={contacts}
                  value={selectedContact}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Contact list"
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 600, paddingLeft: "0px" }}
                  onChange={(e, newVal) => {
                    handleClickId(newVal);
                  }}
                /> */}
                        <Box>
                          <Autocomplete
                            id="tags-outlined"
                            options={contacts}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: 600, paddingLeft: "0px" }}
                            renderOption={(props, option) => {
                              console.log("Option", option);
                              return (
                                <Grid container spacing={0}>
                                  <Grid item xs={12}>
                                    <li
                                      {...props}
                                      style={{ borderColor: "white" }}
                                    >
                                      {props.key}
                                    </li>
                                  </Grid>
                                </Grid>
                              );
                            }}
                            value={selectedContact}
                            filterSelectedOptions
                            onChange={(e, newVal) => {
                              handleClickId(newVal);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${contactDynamicTitel?.displayName.singularName} list`}
                                placeholder={`${contactDynamicTitel?.displayName.singularName} list`}
                                data-testid="contactsDropdown"
                              />
                            )}
                          ></Autocomplete>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {currentUser.role === "ADMIN" ? (
                          <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            disabled={isCreateContactClicked}
                            onClick={() => {
                              setSelectedContact(null);
                              setIsCreateContactClicked(
                                !isCreateContactClicked
                              );
                            }}
                          >
                            {`Create ${contactDynamicTitel?.displayName.singularName}`}
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>

          {!isCreateContactClicked ? null : (
            <Grid item xs={12} sm={12}>
              <Card
                sx={{ backgroundColor: "#f1f2f6" }}
                elevation={5}
                variant="outlined"
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h5" gutterBottom color="primary">
                        {`${contactDynamicTitel?.displayName.singularName} DETAILS`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/* Lead Details */}
                      <Info
                        setContact={setContact}
                        // contact={contactDynamicTitel?.displayName.singularName}
                        contact={contact}
                        toEdit={toEdit}
                        setToEdit={setToEdit}
                        canEdit={isEditPage}
                        fromCreatePage={true}
                        customAttributes={customAttributesContact}
                        setCustomAttributes={setCustomAttributesContact}
                        afterCreate={afterCreate}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      REQUIREMENT DETAILS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/* Lead Details */}
                    <LeadCreateComponent
                      lead={lead}
                      setLead={setLead}
                      customAttributes={customAttributesLead}
                      setCustomAttributes={setCustomAttributesLead}
                      afterCreate={afterCreate}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTION
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <CreateAction
                          createContactShowTimer={createContactShowTimer}
                          setCreateContactShowTimer={setCreateContactShowTimer}
                          action={action}
                          setAction={setAction}
                          initialActionState={initialActionState}
                          // isEditPage={isEditPage}
                          setPage={setPage}
                          checked={checked}
                          setChecked={setChecked}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              onClick={handleCreateLead}
              data-testid="leadCreateButton"
            >{`Create ${leadDynamicTitle?.displayName.singularName}`}</Button>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you wish to discard changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleClickAgree} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={loaderDialog}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
            >
              Loading... Please Wait
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    </Page>
  );
};

export default ContactListForLeadCreate;

const top = [];
