const apis = require("./api.requests");

const TAG = "call.service";
const path = "/calls/v1";

async function createOutboundCall(body) {
    return await apis.hitPostApi(`${path}/outboundCall`, body);
}

async function getCall() {
    return await apis.hitGetApi(`${path}/history`);
}

async function removeCall(callId) {
    return await apis.hitPutApi(`${path}/endCall/${callId}`);
}


export {
    createOutboundCall,
    removeCall,
    getCall,
};
