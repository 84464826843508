import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { createDropReason, getDropReasons } from "../../services/leads.service";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth.context";

const DropReasonTabPanel = () => {
  const { currentUser } = useContext(AuthContext);

  const initialDropReasonState = {
    // initial state when user visits the page for the first time
    isLoading: true,
    dropReasonList: [],
  };
  const [dropReason, setDropReason] = useState(""); // for text field
  const [dropReasonsState, setDropReasonsState] = useState(
    initialDropReasonState
  ); // Holds the list and loading state
  const [isCreateClicked, setIsCreateClicked] = useState(false); // for create button
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const handleCancel = () => {
    setIsCreateClicked(false);
    setDropReason("");
  };

  const handleSubmit = async () => {
    if (dropReason.trim().length > 0) {
      setIsCreateLoading(true);
      const response = await createDropReason({ name: dropReason || "" }); // api to create drop reason
      setIsCreateLoading(false);

      if (response?.data?.status) {
        toast.success(
          response?.data?.message || "Reason created successfully."
        );
        fetchDropReasons();
        handleCancel();
      } else {
        toast.error(response?.data?.message || "Some error occurred");
      }
    } else {
      toast.error("Reason field cannot be empty.");
    }
  };

  const handleCreateReason = () => {
    setIsCreateClicked(true);
  };

  const handleChange = (e) => {
    setDropReason(e.target.value);
  };

  const fetchDropReasons = async () => {
    setDropReasonsState((prev) => ({ ...prev, isLoading: true }));

    try {
      const response = await getDropReasons();
      setDropReasonsState({
        isLoading: false,
        dropReasonList: response?.data?.data || [],
      });
    } catch (error) {
      console.error("Failed to fetch drop reasons:", error);
      toast.error("Failed to fetch drop reasons. Please try again.");
      setDropReasonsState({
        isLoading: false,
        dropReasonList: [],
      });
    }
  };

  useEffect(() => {
    fetchDropReasons();
  }, []);
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Reasons</Typography>

          {/* =============the buttons and textField should only be available for admins================ */}
          {currentUser?.role === "ADMIN" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              {isCreateClicked && (
                <TextField
                  size="small"
                  label="Reason"
                  required
                  value={dropReason || ""}
                  disabled={isCreateLoading}
                  onChange={handleChange}
                />
              )}
              {isCreateClicked ? (
                <>
                  <Button
                    variant="outlined"
                    sx={{ height: "40px" }}
                    disabled={isCreateLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ height: "40px" }}
                    disabled={isCreateLoading}
                    onClick={handleSubmit}
                  >
                    {isCreateLoading ? <CircularProgress size={18} /> : `Save`}
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  sx={{ height: "40px" }}
                  onClick={handleCreateReason}
                >
                  Create Reason
                </Button>
              )}
            </Box>
          )}
          {/* =============#############################################################================ */}
        </Box>
        {dropReasonsState?.isLoading ? (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 1,
              my: 3,
            }}
          >
            {dropReasonsState?.dropReasonList?.map((option) => (
              <Chip variant="outlined" label={option?.name} key={option?._id} />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DropReasonTabPanel;
