const apis = require("./api.requests");

const homeCompanyAccountrPath = `/accountandcompany/v1/`;

//Account
export async function getAllHomeAccounts() {
  return await apis.hitGetApi(`${homeCompanyAccountrPath}/accounts`);
}

export async function createHomeAccount(data) {
  return await apis.hitPostApi(`${homeCompanyAccountrPath}`, data);
}

export async function updateHomeAccount(id, data) {
  return await apis.hitPutApi(
    `${homeCompanyAccountrPath}/updateaccount/${id}`,
    data
  );
}

//Company

export async function createHomeCompany(data) {
  return await apis.hitPostApi(`${homeCompanyAccountrPath}/homecompany`, data);
}

export async function updateHomeCompany(id, data) {
  return await apis.hitPutApi(
    `${homeCompanyAccountrPath}/updatehomecompany/${id}`,
    data
  );
}

export async function getAllHomeCompanies() {
  return await apis.hitGetApi(`${homeCompanyAccountrPath}/homecompanies`);
}
