import React from "react";
import { useMemo } from "react";
import Scrollbar from "../UI/Scrollbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { UserListHead } from "../../sections/@dashboard/user";
import { useState } from "react";
import { filter } from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileList from "../../components/FileList";

const TABLE_HEAD = [
  { id: "cost", label: "Cost", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "schedule", label: "Schedule", alignRight: false },
  { id: "documents", label: "Document", alignRight: false },
];

function PriceNegotiationTableView({ contents }) {
  const dataView = useMemo(() => {
    const result = contents?.reduce((storage, content) => {
      storage.push({
        _id: content._id,
        currency: content.currency,
        cost: content.cost,
        schedule: content.schedule,
        documents: content.documents ? content.documents : [],
      });
      return storage;
    }, []);
    if (result && result.length) {
      return result;
    }
  }, [contents]);

  //Table Header
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order === "") setOrder("asc");
    if (order === "asc") setOrder("desc");
    setOrderBy(property);
    if (order === "desc") {
      setOrder("");
      setOrderBy("");
    }
  };

  //Table Body
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [filterName, setFilterName] = useState("");

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const filteredPrices = applySortFilter(
    dataView,
    getComparator(order, orderBy),
    filterName
  );

  //Table Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //dialogViewFileState
  const [dialogViewFileState, setDialogViewFileState] = useState({
    view: false,
    filesView: [],
    onClose: () => {
      setDialogViewFileState((prev) => ({
        ...prev,
        view: false,
        filesView: [],
      }));
    },
    onView: (priceId, allPricesData) => {
      const gotPriceData = allPricesData.find((ele) => ele._id === priceId);
      if (gotPriceData) {
        setDialogViewFileState((prev) => ({
          ...prev,
          view: true,
          filesView: [...gotPriceData.documents],
        }));
      }
    },
  });

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredPrices
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((price, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{price.cost}</TableCell>
                      <TableCell>{price.currency}</TableCell>
                      <TableCell>{price.schedule}</TableCell>
                      <TableCell>
                        <IconButton
                          sx={{ p: 0.5, ml: "18px" }}
                          disabled={!price.documents.length}
                          onClick={() => {
                            dialogViewFileState.onView(price._id, dataView);
                          }}
                        >
                          <VisibilityIcon
                            sx={{
                              color: !price.documents.length ? null : "#2065D1",
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={dataView ? dataView.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Scrollbar>
      <Dialog open={dialogViewFileState.view} maxWidth="sm">
        <DialogTitle>Files</DialogTitle>
        <DialogContent dividers>
          <FileList
            fileList={dialogViewFileState.filesView}
            forPriceNegotiation={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogViewFileState.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PriceNegotiationTableView;
