import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { htmlToNormalTextConverter } from "../utils/convertHTMLtoNormalText";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EmailTemplet({
  to,
  moduleName,
  eventName,
  subject,
  body,
  templateId,
  openConfermationDialog,
  wholeTemplate,
}) {
  const navigate = useNavigate();
  const [viewTemplate, setViewTemplate] = useState(false);
  const handelView = () => {
    console.log("helloo");
    setViewTemplate((prev) => {
      return !prev;
    });
  };
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(body ? body.trim() : null),
  });
  return (
    <>
      <Grid item xs={6}>
        <Card variant="outlined" sx={{ height: "100%" }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
                  <IconButton
                    color="error"
                    onClick={() => {
                      openConfermationDialog(templateId);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      console.log("wholeTemplate", wholeTemplate);
                      let data = null;
                      if (wholeTemplate?.users.length) {
                        let {
                          moduleName,
                          eventName,
                          body,
                          subject,
                          users,
                          _id,
                        } = wholeTemplate;
                        data = {
                          moduleName,
                          eventName,
                          body,
                          subject,
                          users,
                          _id,
                          to: [
                            // eslint-disable-next-line no-template-curly-in-string
                            "${admin}",
                            ...wholeTemplate.CC.filter((us) =>
                              us?.includes("$")
                            ),
                            wholeTemplate.to.includes("$")
                              ? wholeTemplate.to
                              : "",
                          ],
                        };
                      } else {
                        let {
                          moduleName,
                          eventName,
                          body,
                          subject,
                          users,
                          _id,
                        } = wholeTemplate;
                        data = {
                          users,
                          moduleName,
                          eventName,
                          body,
                          subject,
                          _id,
                          to: [
                            wholeTemplate.to,
                            ...wholeTemplate.CC.filter((us) =>
                              us?.includes("$")
                            ),
                          ],
                        };
                      }
                      navigate(`/emailtemplate/create`, {
                        state: {
                          isEdit: true,
                          emailTemplateData: {
                            ...data,
                            to: data.to.filter((d) => d),
                          },
                        },
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={handelView}>
                    <VisibilityIcon />
                  </IconButton>
                </Stack>
              </Grid>
              {/* <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3.5}>
                  <Typography color="#2065d1">To:</Typography>
                </Grid>
                <Grid item xs={8.5}>
                  <Typography fontWeight="650">{to}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3.5}>
                  <Typography color="#2065d1">Event Name:</Typography>
                </Grid>
                <Grid item xs={8.5}>
                  <Typography
                    sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
                    fontWeight="650"
                  >
                    {eventName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Typography color="#2065d1">Module Name:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography fontWeight="650">{moduleName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Typography color="#2065d1">Event Name:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
                      fontWeight="650"
                    >
                      {eventName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Typography color="#2065d1">Subject:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
                      fontWeight="650"
                    >
                      {subject}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3.5}>
                  <Typography color="#2065d1">Body:</Typography>
                </Grid>
                <Grid item xs={8.5}>
                  <Typography
                    fontWeight="650"
                    sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(body ? body : ""),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <BootstrapDialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={viewTemplate}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Email Template
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={handelView}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid
              item
              xs={2.6}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography color="#2065d1">Module Name:</Typography>
            </Grid>

            <Grid
              item
              xs={2.4}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography>{moduleName?.trim()}</Typography>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={2.6}>
              <Typography color="#2065d1">Event Name:</Typography>
            </Grid>
            <Grid item xs={5.5}>
              <Typography
                sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
              >
                {eventName}
              </Typography>
            </Grid>
            <Grid item xs={3.5}></Grid>
            <Grid item xs={2.6}>
              <Typography color="#2065d1">To</Typography>
            </Grid>
            <Grid item xs={9.4}>
              <Typography>{to}</Typography>
            </Grid>

            {wholeTemplate.CC.length !== 0 && (
              <>
                <Grid item xs={2.6}>
                  <Typography color="#2065d1">CC</Typography>
                </Grid>
                <Grid item xs={9.4}>
                  {" "}
                  <Typography>
                    {wholeTemplate.CC.map((item, index) => (
                      <span key={index}>
                        {index > 0 && ", "}
                        {item}
                      </span>
                    ))}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={2.6}>
              <Typography color="#2065d1">Subject:</Typography>
            </Grid>
            <Grid item xs={9.4}>
              <Typography
                sx={{ wordWrap: "break-word", wordBreak: "break-word" }}
              >
                {subject}
              </Typography>
            </Grid>

            <Grid item xs={2.6}>
              <Typography color="#2065d1">Body:</Typography>
            </Grid>
            <Grid item xs={9.4}>
              {/* <div dangerouslySetInnerHTML={sanitizedData()} /> */}
              <Typography>{htmlToNormalTextConverter(body)?.trim()}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handelView}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EmailTemplet;
