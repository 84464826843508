import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { CallingContext } from "../../context/calling.context";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import ContactPhoneSharpIcon from "@mui/icons-material/ContactPhoneSharp";
import { Grid } from "@mui/material";
import Timer from "react-compound-timer";

export default function CallPickedUpDialog() {
  const {
    openCall,
    setOpenCall,
    callTo,
    setCallTo,
    setCallState,
    callState,
    openCallPickedUpDialog,
    setOpenCallPickedUpDialog,
    startTimerForDialog,
  } = useContext(CallingContext);
  console.log("Open call pickedup dialog", openCallPickedUpDialog);

  // const handleClickOpenCallPickedUpDialog = () => {
  //     setOpenCallPickedUpDialog(true);
  // };

  // const handleCloseCallPickedUpDialog = () => {
  //     setOpenCallPickedUpDialog(false);
  // };

  return (
    <div>
      <Dialog
        open={openCallPickedUpDialog}
        // onClose={handleCloseCallPickedUpDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // maxWidth='xs'
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "370px", // Set your width here
            },
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <AccountCircleSharpIcon sx={{ fontSize: 100 }} />
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              <Grid
                container
                spacing={0}
                sx={{ marginLeft: 0, marginRight: 0 }}
              >
                <Grid item xs={12}>
                  Callee: <span style={{ color: "#2065d1" }}>{callTo}</span>
                </Grid>
                <Grid item xs={12}>
                  Status: <span style={{ color: "green" }}>Connected</span>
                </Grid>
                <Grid item xs={12}>
                  Call Duration:{" "}
                  <Timer initialTime={startTimerForDialog}>
                    <React.Fragment>
                      <div>
                        <Timer.Hours
                          formatValue={(value) =>
                            `${value < 10 ? `0${value}` : value}`
                          }
                        />
                        :
                        <Timer.Minutes
                          formatValue={(value) =>
                            `${value < 10 ? `0${value}` : value}`
                          }
                        />
                        :
                        <Timer.Seconds
                          formatValue={(value) =>
                            `${value < 10 ? `0${value}` : value}`
                          }
                        />
                      </div>
                    </React.Fragment>
                  </Timer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
