import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Dailog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Dialog,
  Typography,
  TextField,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import PaymentIcon from "@mui/icons-material/Payment";
import CancelIcon from "@mui/icons-material/Cancel";
import { paidInvoice } from "../../../../src/services/projects.service";
import { regerateInvoice } from "../../../services/projects.service";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

function InvoiceActionContextMenu({
  invoiceId,
  downloadUrl,
  cancelInvoice,
  isCancelled,
  invoiceIndex,
  payInvoiceAPI,
  regenerateInvoiceAPI,
  activeStatus,
  leadId,
  invoiceNumber,
  amount,
  date,
  paid,
  currency,
  getInvoiceDetails,
  dueAmount,
  setLoaderDialog,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setDialogOpen] = useState(false);
  const [openConfirmTabDownload, setOpenConfirmTabDownload] = useState(false);
  const [openConfirmTabCancel, setOpenConfirmTabCancel] = useState(false);
  const [menuStatusDisable, setMenuStatusDisable] = useState(true);
  const [menuCancelDisable, setMenuCancelDisable] = useState(false);
  const [paidValue, setPaidValue] = useState(0);
  const openMenu = Boolean(anchorEl);
  const { projectId } = useParams();

  console.log("projectId", projectId);
  const handleConfirmTabDownload = () => {
    // window.open(downloadUrl, "Download");

    regenerateInvoiceAPI(downloadUrl, invoiceId);
    setOpenConfirmTabDownload(false);
  };
  const handleConfirmTabCancel = () => {
    cancelInvoice(invoiceId, invoiceIndex, leadId);
    setOpenConfirmTabCancel(false);
  };
  const handleNotConfirmTab = () => {
    setOpenConfirmTabDownload(false);
    setOpenConfirmTabCancel(false);
  };

  const handleClickMenu = (event) => {
    // if (!activeStatus) return;
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDialogOpen = () => {
    setDialogOpen(!openDialog);
    setPaidValue(0);
  };
  const handlePaidChange = (event) => {
    const enteredAmount = event.target.value;
    if (!isNaN(enteredAmount)) {
      // if (enteredAmount >= 0) {
      setPaidValue(enteredAmount);
      // }
    } else {
      toast.warn("Please enter a valid number for the payment");
    }
  };
  const handlePayButton = async () => {
    if (paidValue === undefined || paidValue === null) {
      toast("Empty space are not allowed");
      return;
    }
    setLoaderDialog(true);
    let id = invoiceId;
    let bodyData = {
      projectId,
      paid: parseInt(paidValue),
    };
    try {
      const response = await paidInvoice(id, bodyData);
      console.log("response65", response);
      if (response.data.status) {
        getInvoiceDetails();
        toast("Invoice Paid Successfully");
        handleDialogOpen();
        setLoaderDialog(false);
      }
    } catch (error) {
      console.log("Error", error);
      setLoaderDialog(false);
    }
  };
  useEffect(() => {
    setMenuStatusDisable(activeStatus);
    setMenuCancelDisable(isCancelled);
  }, [activeStatus, isCancelled]);
  console.log(isCancelled, menuCancelDisable, "mah");
  const options = [
    {
      id: 223343,
      name: "Download",
      icon: <DownloadIcon sx={{ color: "#f55e30" }} />,
      isDisabled: false,
      handleClick: () => {
        handleCloseMenu();
        setOpenConfirmTabDownload(true);
      },
    },
    {
      id: 998811,
      name: "Payment received",
      icon: <PaymentIcon sx={{ color: "#2065d1" }} />,
      isDisabled: !menuStatusDisable || menuCancelDisable,
      handleClick: () => {
        console.log("activestatus", activeStatus);
        // payInvoiceAPI(invoiceId);
        handleCloseMenu();
        // handleClickMenu();
        handleDialogOpen();
      },
    },
    {
      id: 568886,
      name: "Cancel",
      icon: <CancelIcon sx={{ color: "red" }} />,
      isDisabled: !menuStatusDisable || menuCancelDisable,
      handleClick: () => {
        console.log("activestatus", activeStatus);
        handleCloseMenu();
        // handleClickMenu();
        setOpenConfirmTabCancel(true);
      },
      sx: { color: "red" },
    },
  ];

  console.log("paid value", paid);
  console.log("amount value", amount);
  console.log("activeStatus", activeStatus);
  return (
    <>
      <IconButton onClick={handleClickMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 16,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => option.handleClick()}
            sx={option.sx}
            disabled={option.isDisabled}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
      {options.map((opt) => {
        return (
          <>
            {opt.name === "Payment received" ? (
              <Dialog maxWidth="sm" open={openDialog} onClose={() => {}}>
                <DialogTitle>Make Payment</DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>Invoice Number :</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{invoiceNumber}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Invoice Date :</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{date}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Chargable Amount :</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {`${
                          isNaN(amount?.toFixed(2)) ? 0 : amount?.toFixed(2)
                        }  (${currency})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Paid Till Date :</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {isNaN(paid?.toFixed(2)) ? 0 : paid?.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Paid :</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="standard-number"
                        type="number"
                        value={paidValue}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                        style={{ width: "140px" }}
                        onChange={handlePaidChange}
                        onClick={(e) => {
                          if (e.target.value === "0") {
                            e.target.value = "";
                          }
                        }}
                      />
                      <div
                        style={{
                          fontSize: "12px",
                          // marginLeft: "8px",
                          color: "#808080",
                        }}
                      >
                        **Enter Paid Amount Here
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button color="error" onClick={handleDialogOpen}>
                    CANCEL
                  </Button>

                  <Button onClick={handlePayButton}>PAY</Button>
                </DialogActions>
              </Dialog>
            ) : null}
            {opt.name === "Download" ? (
              <Dialog
                maxWidth="sm"
                open={openConfirmTabDownload}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Download Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to download invoice {}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleNotConfirmTab} color="error">
                    CANCEL
                  </Button>
                  <Button onClick={handleConfirmTabDownload} autoFocus>
                    AGREE
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
            {opt.name === "Cancel" ? (
              <Dialog
                maxWidth="sm"
                open={openConfirmTabCancel}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Cancel Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to cancel invoice {}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleNotConfirmTab} color="error">
                    CANCEL
                  </Button>
                  <Button onClick={handleConfirmTabCancel} autoFocus>
                    AGREE
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
          </>
        );
      })}
    </>
  );
}

export default InvoiceActionContextMenu;
