import { Divider, Grid, Stack, TextField } from "@mui/material";
import React from "react";
import { capitalizedString } from "../../../utils/capitalizedString";

function CommonInformation({ costInforMations }) {
  return (
    <>
      <Stack rowGap="3px" direction="row">
        <TextField
          disabled
          label="Mode"
          placeholder="Mode"
          value={capitalizedString(costInforMations.mode)}
          sx={{ width: "33%", pr: "7px" }}
        />
        <TextField
          disabled
          label="Currency"
          placeholder="Currency"
          value={costInforMations.currency}
          sx={{ width: "33%", pr: "7px" }}
        />
        {costInforMations.mode?.toLowerCase() === "fixed" ? (
          <TextField
            disabled
            label="Total Cost"
            placeholder="Total Cost"
            value={
              costInforMations.modeDataContainer.totalCost
                ? costInforMations.modeDataContainer.totalCost
                : ""
            }
            sx={{ width: "33%" }}
          />
        ) : (
          <TextField
            disabled
            label="Number Of Resources"
            placeholder="Number Of Resources"
            value={
              costInforMations.modeDataContainer.noOfResources
                ? costInforMations.modeDataContainer.noOfResources
                : ""
            }
            sx={{ width: "33%" }}
          />
        )}
      </Stack>
      <Grid item xs={12} sx={{ paddingRight: "25px", marginTop: "16px" }}>
        <Divider />
      </Grid>
    </>
  );
}

export default CommonInformation;
