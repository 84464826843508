import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip, Typography } from "@mui/material";

function SkillApprovanceChip({ skillName, onApprove, onReject }) {
  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: "15px",
        width: "100%",
        height: "45px",
        padding: "7px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip title={skillName}>
        <div
          style={{
            width: "70%",
          }}
        >
          <Typography
            fontSize={17}
            variant="subtitle2"
            sx={{ textAlign: "left" }}
            noWrap
          >
            {skillName}
          </Typography>
        </div>
      </Tooltip>
      <div
        style={{
          width: "30%",
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <DeleteIcon
          sx={{ color: "red", ":hover": { cursor: "pointer" } }}
          onClick={onReject}
        />
        <CheckCircleOutlineIcon
          sx={{ color: "green", ":hover": { cursor: "pointer" } }}
          onClick={onApprove}
        />
      </div>
    </div>
  );
}

export default SkillApprovanceChip;
