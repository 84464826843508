import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { toast } from "react-toastify";
import { generateContent } from "./Action.View";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function EstimationMenu({
  allHeadingDetails,
  template,
  setIsTemplateDialogeOpen,
  copiedValue,
  setCopiedValue,
  fetchHeadingDetails,
  fromCreateOne,
  setFromCreateOne,
  toDo,
  storeTemplate,
  setStoreTemplate,
  selectedValue,
  actionId,
  setContent,
  setLoader,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // if (template === null || template === "") {
    //   toast("Please Select Estimate Template!");
    //   return;
    // }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCreateButton = () => {
    // if (template === null || template === "") {
    if (!selectedValue[actionId]) {
      toast("Please Select Estimate Template To Create New!");
      return;
    }
    const matchingHeading = allHeadingDetails.find(
      (elem) => storeTemplate?.headingName === elem?.headingName
    );
    console.log(matchingHeading);
    // console.log("mywww", storeTemplate);
    setStoreTemplate(() => ({
      headingName: matchingHeading.headingName,
      estimateType: matchingHeading.estimateType,
      outlines: JSON.parse(JSON.stringify(matchingHeading.outlines)),
      actionType: toDo,
    }));
    setContent(generateContent(matchingHeading?.outlines));
    console.log(copiedValue, "copied value");
    // setFromCreateOne(true);

    setTimeout(() => {
      setLoader((prev) => (prev = false));
    }, 5000);

    setAnchorEl(null);
    // fetchHeadingDetails();
    setIsTemplateDialogeOpen((prev) => !prev);
  };
  const handlePasteButton = () => {
    console.log(
      copiedValue,
      selectedValue,
      storeTemplate,
      selectedValue[actionId],
      "copied value"
    );
    if (!copiedValue) {
      toast.warn("Please copy the template first");
      setAnchorEl(null);
      setCopiedValue(false);
      return;
    }
    if (storeTemplate?.actionType !== toDo) {
      toast.warn("It can't be copied, action type must be same");
      setAnchorEl(null);
      setCopiedValue(false);
      return;
    }
    if (!selectedValue[actionId]) {
      toast.warn("Template is not selected");
      setAnchorEl(null);
      return;
    }
    setIsTemplateDialogeOpen((prev) => !prev);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="outlined"
        size="small"
        sx={{
          alignSelf: "center",
        }}
        disabled={allHeadingDetails.length > 0 ? false : true}
      >
        ESTIMATE
        {/* <AddIcon /> */}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 16,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <MenuItem onClick={handleCreateButton} disableRipple>
          <AddIcon />
          Create New
        </MenuItem>
        <MenuItem onClick={handlePasteButton} disableRipple>
          <ContentPasteIcon />
          Paste from the copied!
        </MenuItem>
      </StyledMenu>
    </>
  );
}
