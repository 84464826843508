import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { CallingContext } from "../../context/calling.context";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import ContactPhoneSharpIcon from "@mui/icons-material/ContactPhoneSharp";
import { Chip, Grid, IconButton, Tooltip } from "@mui/material";
import Timer from "react-compound-timer";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CallIcon from "@mui/icons-material/Call";
// import EndCallIcon from "../../public/end-call-icon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { "fa-solid fa - circle - phone - hangup"} from "@fortawesome/free-solid-svg-icons/fa-solid fa-circle-phone-hangup"

export default function CallPickedUpDialog() {
  const {
    openCall,
    setOpenCall,
    callTo,
    setCallTo,
    setCallState,
    callState,
    openCallIncomingDialog,
    setOpenCallIncomingDialog,
    startTimerForDialog,
    setCallFrom,
    callFrom,
  } = useContext(CallingContext);
  console.log("Open call pickedup dialog", openCallIncomingDialog);

  // const handleClickOpenCallPickedUpDialog = () => {
  //     setOpenCallPickedUpDialog(true);
  // };

  const handleCloseCallPickedUpDialog = () => {
    setOpenCallIncomingDialog(false);
  };

  const shrinkText = (text, count, insertDots) => {
    return (
      text.slice(0, count) + (text.length > count && insertDots ? "..." : "")
    );
  };

  return (
    <div>
      <Dialog
        open={openCallIncomingDialog}
        // onClose={handleCloseCallPickedUpDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // maxWidth='xs'
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px", // Set your width here
            },
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={0.5}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <AccountCircleSharpIcon sx={{ fontSize: 80, color: "#2065d1" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "12px" }}>Call From</span>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip title={callFrom}>
                <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                  {shrinkText(callFrom, 15, true)}
                </span>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <IconButton
                onClick={handleCloseCallPickedUpDialog}
                color="error"
                style={{ backgroundColor: "error" }}
              >
                <CallEndIcon />
              </IconButton>

              <FontAwesomeIcon icon="fa-solid fa-phone" />
              {/* <CallEndIcon color="error" style={{ backgroundColor: 'error' }} /> */}
              <div>&nbsp;&nbsp;&nbsp;</div>
              <IconButton
                onClick={handleCloseCallPickedUpDialog}
                autoFocus
                color="success"
              >
                <CallIcon />
              </IconButton>

              {/* <Chip icon={<CallEndIcon />} label="DECLINE" color="error" style={{ backgroundColor: '#ff4d4d' }} clickable="true" onClick={handleCloseCallPickedUpDialog} /><div>&nbsp;&nbsp;&nbsp;</div>
                            <Chip icon={<CallIcon />} label="ANSWER" color="success" style={{ backgroundColor: '#33cc00' }} clickable="true" onClick={handleCloseCallPickedUpDialog} /> */}
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions sx={{
                    disableSpacing: true,
                    "& .MuiDialog-container": {
                        "& .MuiDialogActions-root": {
                            marginLeft: 0,
                            paddingLeft: 0,
                            display: "flex",
                            justifyContent: "center"
                        }
                    },
                }}>
                    <Button onClick={handleCloseCallPickedUpDialog}>REJECT</Button>
                    <Button onClick={handleCloseCallPickedUpDialog} autoFocus>
                        ANSWER
                    </Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
