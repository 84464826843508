import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CardContent,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../UI/Page";
import Label from "../../UI/Label";
import Scrollbar from "../../UI/Scrollbar";
import Iconify from "../../UI/Iconify";
import SearchNotFound from "../../SearchNotFound";

// mock
import USERLIST from "../../../_mock/user";
import CommentView from "./Comment.View";
import CreateComment from "./Comment.Create";
import { getComments as getLeadComments } from "../../../services/leads.service";
import { getComments as getContactComments } from "../../../services/contacts.service";
import { getComments as getProjectComments } from "../../../services/projects.service";
import constants from "../../../constants";
import { fetchCompanyComments } from "../../../services/companies.service";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "ownedBy", label: "Owned By", alignRight: false },
  { id: "last", label: "Last", alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  // { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function CommentSection({
  contactInfoObj,
  setComment,

  setUpdateLeadCalled,
  comment,
  id,
  leadId,
  counts,
  projectId,
  initialCommentState,
  setLoadedComments,
  loadedComments,
  commentType,
  fromLeadPage,
  fromContactPage,
  techOwner,
  salesOwner,
  currentUser,
  lead,
  fromProjectPage,
  allDoers,
  contact,
  fromCompanyViewPage,
  setIactionedApiCalled,
  handleClickOpen,
  handleClose,
  descriptionObj,
  files,
  setDescriptionObj,
  setFiles,
  setLoaderDialog,
}) {
  //const [loadedComments, setLoadedComments] = useState([]);

  // const [page, setPage] = useState(0);

  //console.log("loadedComments", loadedComments);

  const [count, setCount] = useState(1);

  //This variable is used for UI pagination
  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  const [addedCommentCounter, setAddedCommentCounter] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  //let obj = contactInfoObj || {};

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log("Inside handle change page", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) >
      loadedComments.length /*&&
      loadedComments.length < count*/
    ) {
      console.log("Fetching new data");
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredComments = applySortFilter(
    loadedComments,
    getComparator(order, orderBy),
    filterName
  );

  // useEffect(() => {
  //   getComments(id, leadId, page)
  //     .then((response) => {
  //       setLoadedComments(response.data.data);
  //       console.log(loadedComments);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  function FetchComments(pageCount = 1) {
    if (commentType === constants.LEADS) {
      getLeadComments(id, leadId, pageCount)
        .then((response) => {
          if (response.data.status) {
            setLoadedComments((prevState) => [
              ...prevState,
              ...response.data.data.leadComments,
            ]);
            console.log(loadedComments);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (commentType === constants.PROJECTS) {
      getProjectComments(id, projectId, pageCount)
        .then((response) => {
          console.log("response ", response.data.data.projectComments);
          if (response.data.status) {
            setLoadedComments((prevState) => [
              ...prevState,
              ...response.data.data.projectComments,
            ]);
            console.log(loadedComments);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (commentType === constants.CONTACTS) {
      getContactComments(id, pageCount)
        .then((response) => {
          if (response.data.status) {
            setLoadedComments((prevState) => [
              ...prevState,
              ...response.data.data.allComments,
            ]);
            console.log(loadedComments);
            setCount(response.data.data.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (commentType === constants.COMPANIES) {
      fetchCompanyComments(id, pageCount)
        .then((response) => {
          if (response.data.status) {
            setLoadedComments((prevState) => [
              ...prevState,
              ...response.data.data.companyComments,
            ]);
            setCount(response.data.data.count);
          } else {
            setLoadedComments([]);
            setCount([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  useEffect(() => {
    console.log("Use effect triggered");
    FetchComments(pageCount);
  }, [pageCount]);

  // useEffect(() => {
  //   console.log("Use effect triggered");
  //   FetchComments(pageCount);
  // }, [addedCommentCounter]);

  const isCommentNotFound = filteredComments.length === 0;
  useEffect(() => {
    console.log("counts", counts);
    setCount(counts);
  }, [counts]);

  console.log(loadedComments, "comment");
  return (
    <>
      <Card elevation={3} variant="outlined">
        <CardContent>
          <CreateComment
            contact={contact}
            setComment={setComment}
            comment={comment}
            id={id}
            leadId={leadId}
            projectId={projectId}
            initialCommentState={initialCommentState}
            commentType={commentType}
            addedCommentCounter={addedCommentCounter}
            setAddedCommentCounter={setAddedCommentCounter}
            loadedComments={loadedComments}
            setLoadedComments={setLoadedComments}
            setPageCount={setPageCount}
            FetchComments={FetchComments}
            setPage={setPage}
            fromLeadPage={fromLeadPage}
            fromContactPage={fromContactPage}
            techOwner={techOwner}
            salesOwner={salesOwner}
            currentUser={currentUser}
            lead={lead}
            fromProjectPage={fromProjectPage}
            allDoers={allDoers}
            fromCompanyViewPage={fromCompanyViewPage}
            setIactionedApiCalled={setIactionedApiCalled}
            handleDocClickOpen={handleClickOpen}
            handleDocClose={handleClose}
            descriptionObj={descriptionObj}
            files={files}
            setDescriptionObj={setDescriptionObj}
            setFiles={setFiles}
            setLoaderDialog={setLoaderDialog}
            setUpdateLeadCalled={setUpdateLeadCalled}
          />
          <br />
          <Scrollbar>
            <TableContainer>
              <Table>
                {/* {hasComments && ( */}
                {loadedComments.length > 0 ? (
                  <TableBody>
                    {loadedComments
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const {
                          comment,
                          uploadDocumentAwsS3Url,
                          createdBy,
                          _id,
                          createdAt,
                          price,
                        } = row;
                        const isItemSelected = selected.indexOf(_id) !== -1;
                        //console.log("Map execution", createdBy);
                        return (
                          <TableRow
                            sx={{ border: "none" }}
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell>
                              <CommentView
                                name={createdBy.name}
                                //date={row.date}
                                comment={comment}
                                uploadDocumentAwsS3Url={uploadDocumentAwsS3Url}
                                createdAt={createdAt}
                                price={price}
                                allDoers={allDoers}
                                fromLeadPage={fromLeadPage}
                                fromContactPage={fromContactPage}
                                techOwner={techOwner}
                                salesOwner={salesOwner}
                              ></CommentView>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {/* <SearchNotFound searchQuery={filterName} /> */}
                        No comments available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {/* )} */}

                {/* {!hasComments && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* {hasComments && ( */}
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>

      {/* )} */}
    </>
  );
}

function applySortFilter(array, comparator, query) {
  //console.log("array", array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
