// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/UI/ScrollToTop";
import { BaseOptionChartStyle } from "./components/Unused/chart/BaseOptionChart";
import { AuthContext } from "./context/auth.context";
import { useContext, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MailClientConsentDialog from "./components/Dialogs/MailClientConsentDialog";
import { useNavigate } from "react-router-dom";
import PhoneSnackbar from "./components/UI/PhoneSnackbar";
import CallPickedUpDialog from "./components/Dialogs/CallPickedUpDialog";
import CallIncomingDialog from "./components/Dialogs/CallIncomingDialog";
import SyncEmailSnackbar from "./components/UI/SyncEmailSnackbar";
// import firebase from "./firebase";
// import "./messaging_init_in_sw";
import Notification from "./firebaseNotifications/Notification";
import { logoutAllTabs } from "./auth/auth";
import { getEntityName } from "./services/common.service";
import CallDialer from "./layouts/dashboard/CallDialer";

// ----------------------------------------------------------------------

export default function App({ pathName }) {
  const {
    currentUser,
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUser,
    sessionMessageFlag,
    setSessionMessageFlag,
    entityName,
    setEntityName,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const localToken = window.localStorage.getItem("AUTH_TOKEN");
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      //console.log(response);
      return response;
    },
    function (err) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      console.log(err, err.response);

      if (err.response) {
        console.log(err.response.status);
        console.log(err.response.statusText);
        console.log(err.message);
        console.log(err.response.headers); // 👉️ {... response headers here}
        console.log(err.response.data); // 👉️ {... response data here}
      }

      if (
        err.response.status === 403 &&
        err.response.data.message === "Invalid token or token has expired!"
      ) {
        setSessionMessageFlag(true);
        window.localStorage.clear();
        setIsAuthenticated(false);
        setCurrentUser({ id: "", role: "", name: "", phone: "" });
        navigate(`/login`, { replace: true });
      }

      if (err.response.status === 403) {
        console.log(err);
        // setSessionMessageFlag(true);
        // window.localStorage.clear();
        // setIsAuthenticated(false);
        // setCurrentUser({ id: "", role: "", name: "", phone: "" });
        navigate(
          `/unauthorized`,
          { state: `${err.response.data.message}` },
          { replace: true }
        );
      }
      if (err.response.status === 405) {
        console.log("check active user ........");
        setSessionMessageFlag(true);
        window.localStorage.clear();
        setIsAuthenticated(false);
        navigate(`/`, { replace: true });
      }

      if (err.response.status === 401) {
        setSessionMessageFlag(true);
        window.localStorage.clear();
        setIsAuthenticated(false);
        setCurrentUser({ id: "", role: "", name: "", phone: "" });
      }
      return Promise.reject(err);
    }
  );
  // const responseData = function () {
  //   getEntityName()
  //     .then((response) => {
  //       if (response) {
  //         const entityId = response?.data.data[0]._id;

  //         let result = response?.data.data.slice(1);
  //         result = result.map((item, index) => ({
  //           ...item,
  //           id: index + 1,
  //           toEdit: false,
  //           entityId: entityId,
  //         }));

  //         setEntityName(result);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const messaging = firebase.messaging();
  // messaging.requestPermission().then(() => {
  //   // console.log("Token: ", token);
  //   return messaging.getToken();
  // }).then((token) => {
  //   console.log("Token: ", token);
  // }).catch((error) => {
  //   console.log(error);
  // })

  useEffect(() => {
    if (localToken !== null) {
      // responseData();
    }
    logoutAllTabs();
  }, []);
  console.log("testing");

  return (
    <>
      <>
        <ToastContainer />
        <ScrollToTop />
        <BaseOptionChartStyle />
        {/* <Notification /> */}
        <Router pathName={pathName} />
        {/* <ComboBox/> */}
      </>
      {/* <MailClientConsentDialog /> */}
      <CallPickedUpDialog />
      {/* <CallIncomingDialog /> */}
      <PhoneSnackbar />
      <Notification />
      <SyncEmailSnackbar />
      <MailClientConsentDialog />
      {isAuthenticated && <CallDialer />}
    </>
  );
}
