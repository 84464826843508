import {
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useContext, useState } from "react";
import GoogleButton from "react-google-button";
import { ToastContainer, toast } from "react-toastify";
import Page from "../components/UI/Page";
import { AuthContext } from "../context/auth.context";
import {
  createGoogleTokens,
  directChangePassword,
} from "../services/users.service";

let hitApi = true;

function Profile() {
  const { currentUser, isAuthenticated, setIsAuthenticated, setCurrentUser } =
    useContext(AuthContext);

  const handleChange = (e) => {};

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // setIsLoaded(false);
      console.log(codeResponse);
      setCurrentUser((prevState) => ({
        ...prevState,
        isGoogleToken: true,
      }));
      const response = await createGoogleTokens({ code: codeResponse.code });
      console.log(response);
      if (response.data.status) {
        setCurrentUser((prevState) => ({
          ...prevState,
          isGoogleToken: true,
        }));
      }
    },
    onError: (err) => {
      console.log("Login Failed", err);
    },
    flow: "auth-code",
    scope: "email profile openid https://www.googleapis.com/auth/calendar",
  });

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid conatiner spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{
                backgroundColor: "#f1f2f6",
              }}
              elevation={5}
              variant="outlined"
            >
              <CardContent sx={{ backgroundColor: "#ffff" }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  {currentUser.role === "SUPER_ADMIN" ? (
                    <>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          alignSelf: "center",
                        }}
                      >
                        <Avatar sx={{ bgcolor: "#2065d1" }}>
                          {currentUser.name.charAt(0)}
                        </Avatar>
                        <Typography variant="h5" component="h2">
                          {currentUser.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {currentUser.role}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {currentUser.email}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom color="primary">
                        Profile
                      </Typography>
                      <Card elevation={3} variant="outlined">
                        <CardContent>
                          {!currentUser.isGoogleToken ? (
                            <GoogleButton
                              /* type="light" */
                              type="dark"
                              onClick={() => {
                                console.log("Google button clicked");
                                login();
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{ marginBottom: 0 }}
                            >
                              Google consent activated
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default Profile;
