import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import React from "react";

function InvoiceReportFilterOptions({
  allMonths,
  state,
  monthYearListing,
  handleChange,
  renderName,
  handleFetch,
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3.6}>
          <FormControl fullWidth size="small">
            <InputLabel>Month</InputLabel>
            <Select
              sx={{ maxHeight: "42px" }}
              label="Month"
              name="month"
              value={allMonths[state.month] || ""}
              onChange={handleChange}
            >
              {monthYearListing("month").map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3.6}>
          <FormControl fullWidth size="small">
            <InputLabel>Year</InputLabel>
            <Select
              sx={{ maxHeight: "42px" }}
              label="Year"
              name="year"
              value={state.year || ""}
              onChange={handleChange}
            >
              {monthYearListing("year").map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3.6}>
          <FormControl fullWidth size="small">
            <InputLabel>Sales Owner</InputLabel>
            <Select
              sx={{ maxHeight: "42px" }}
              label="Sales Owner"
              multiple
              value={state.salesOwners || []}
              name="salesOwners"
              onChange={handleChange}
              input={<OutlinedInput label="Sales Owner" />}
              renderValue={(selected) => renderName(selected)}
            >
              <MenuItem value={"ALL"}>
                <Checkbox
                  checked={state?.users?.length == state?.salesOwners?.length}
                />
                <ListItemText primary={"ALL"} />
              </MenuItem>
              {state.users.map((user, index) => (
                <MenuItem key={index} value={user?._id}>
                  <Checkbox checked={state.salesOwners.includes(user?._id)} />
                  <ListItemText primary={user?.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={1.2}
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
        >
          <Button
            variant="contained"
            startIcon={<ManageSearchIcon />}
            onClick={handleFetch}
          >
            Fetch
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default InvoiceReportFilterOptions;
