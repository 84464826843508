// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useContext } from 'react';
import { AuthContext } from '../context/auth.context';



const firebaseConfig = {
    apiKey: "AIzaSyBRjuQr7XLsB1ZDGjA0a1XGKzackzCKVF4",
    authDomain: "ul-alpha-366712.firebaseapp.com",
    projectId: "ul-alpha-366712",
    storageBucket: "ul-alpha-366712.appspot.com",
    messagingSenderId: "589317535878",
    appId: "1:589317535878:web:7f9c77d77df1b88c99f907",
    measurementId: "G-JB0BMZBKZJ"
};

initializeApp(firebaseConfig);
let tempToken;
const messaging = getMessaging();



export const requestForToken = () => {

    return getToken(messaging, { vapidKey: `BEW8_XjPsGYU7BWuhoOcdnKVjO_NhgBe3c65HpKPMntUb0DsU8NSZm6H5mSgVAqw0NLXe5wfx0xIToBh4LjHlRY` })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                tempToken = currentToken;
                // return currentToken;
                // const { currentUser, fcmToken, setFcmToken } = useContext(AuthContext);
                // setFcmToken(currentToken);
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const getFcmToken = () => {
    return tempToken;
}
