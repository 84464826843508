import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast, ToastContainer } from "react-toastify";
import Page from "../components/UI/Page";
import React from "react";
import { useState, useEffect } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ActivityLogsBody from "../components/ActivityLogsBody";
import { getActivityLogs } from "../services/common.service";

const modules = ["Users", "Roles", "Contacts", "Leads", "Projects"];

const events = [
  "Create",
  "Read",
  "Update",
  "Delete",
  "Action Create",
  "Action Read",
  "Action Update",
  "Action Done",
  "Document Create",
  "Document Read",
  "Document Delete",
  "Comment Create",
  "Comment Read",
];

const intervals = [
  {
    label: "Last Day",
    value: new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toDateString(),
  },
  {
    label: "Last 7 Days",
    value: new Date(
      new Date().setDate(new Date().getDate() - 7)
    ).toDateString(),
  },
  {
    label: "Last 2 Weeks",
    value: new Date(
      new Date().setDate(new Date().getDate() - 14)
    ).toDateString(),
  },
  {
    label: "Last Month",
    value: new Date(
      new Date().setDate(new Date().getDate() - 30)
    ).toDateString(),
  },
  { label: "Custom", value: "Custom" },
];

const ActivityLogs = () => {
  const [leadFilter, setLeadFilter] = useState({
    modules: [...modules],
    events: [...events],
  });
  const [allChecked, setAllChecked] = useState({
    modules: true,
    events: true,
  });
  const [popup, setPopup] = useState(false);
  const [singleModule, setSingleModule] = useState("Users");
  const [singleEvent, setSingleEvent] = useState("Create");
  const [interval, setInterval] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  );
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showDateGrid, setShowDateGrid] = useState(false);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);

  const handleClickOpen = () => {
    setPopup(true);
  };

  const handleClose = () => {
    setPopup(false);
    // setDeletePopupOpen(false);
  };

  const handleChangeInterval = (e) => {
    console.log(e.target.value);
    if (e.target.name === "interval") {
      if (e.target.value !== "Custom" && interval === "Custom") {
        toast("Close the chip to select another interval");
        return;
      }
      if (e.target.value === "Custom") {
        setPopup(true);
      }
      setInterval(e.target.value);
    }
  };
  const checkWhichDropdown = (name) => {
    if (name === "modules") {
      return modules;
    }
    if (name === "events") {
      return events;
    }
  };
  const handleChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);

    if (
      e.target.value.filter((i) => i !== "ALL").length ===
        checkWhichDropdown(e.target.name).length &&
      e.target.value[e.target.value.length - 1] === "ALL"
    ) {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = false;
        return temp;
      });

      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = [];
        return temp;
      });

      return;
    }

    setLeadFilter((prevState) => {
      const temp = { ...prevState };
      temp[e.target.name] =
        typeof e.target.value === "string"
          ? e.target.value
              .filter((i) => {
                return i !== "ALL";
              })
              .split(",")
          : e.target.value.filter((i) => {
              return i !== "ALL";
            });
      return temp;
    });

    if (
      e.target.value.filter((i) => i !== "ALL").length ===
        checkWhichDropdown(e.target.name).length ||
      e.target.value[0] === "ALL" ||
      e.target.value.includes("ALL")
    ) {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = true;
        return temp;
      });
      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        console.log("checkWhichDropdown", checkWhichDropdown(e.target.name));
        temp[e.target.name] = [...checkWhichDropdown(e.target.name)];
        return temp;
      });
    } else {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = false;
        return temp;
      });
    }
    console.log(leadFilter);
  };

  const handleStartDateChange = (e) => {
    console.log("e", e);
    if (endDate.getTime() > e) {
      setStartDate(e);
    } else {
      toast("End date can not be less than or eual to start date");
    }
  };
  const handleEndDateChange = (e) => {
    console.log("e", e);
    if (startDate.getTime() < e) {
      setEndDate(e);
    } else {
      toast("End date can not be less than or eual to start date");
    }
  };
  const formatDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };

  const getLogs = async () => {
    console.log(interval);
    try {
      if (!interval) {
        toast("Interval is mandatory");
        return;
      }
      const filter = {};
      const onLoad = false;
      filter.module = leadFilter.modules;
      filter.event = leadFilter.events;
      if (showDateGrid) {
        filter.from = new Date(startDate).getTime();
        filter.to = new Date(endDate).getTime();
      } else {
        filter.from = new Date(interval).getTime();
        filter.to = new Date().getTime();
      }
      const response = await getActivityLogs(filter, onLoad);
      if (response.data.status && response.data.data.length) {
        setResults(response.data.data);
        toast("Seach completed successfully ");
        setPage(0);
      } else {
        toast("No logs found");
        setResults(response.data.data);
        setPage(0);
      }
    } catch (error) {
      console.log(error);
      setPage(0);
    }
  };

  // const getLogsOnPageLoad = async () => {
  //   try {
  //     const onLoad = true;
  //     const filter = {
  //       module: "Users",
  //       event: "Create",
  //       interval: new Date(
  //         new Date().setDate(new Date().getDate() - 1)
  //       ).getTime(),
  //     };
  //     const response = await getActivityLogs(filter, onLoad);
  //     if (response.data.status) {
  //       setResults(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    // getLogsOnPageLoad();
  }, []);

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Card sx={{ padding: 0 }} elevation={5} variant="outlined">
              <CardContent
              // sx={{
              //   paddingTop: 0,
              //   paddingRight: 2,
              //   paddingLeft: 2,
              //   "&:last-child": { pb: 0 },
              // }}
              >
                <Grid
                  container
                  spacing={0}
                  // sx={{ marginTop: "17px", marginBottom: "17px" }}
                >
                  {/* <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant="h5" gutterBottom color="primary" sx={{ marginBottom: 0 }}>
                                            ACTIVITY LOGS
                                        </Typography></Grid> */}
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={showDateGrid ? 2.75 : 3.65}
                        sx={{ paddingRight: "15px" }}
                      >
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-module-select-label">
                            Module
                          </InputLabel>
                          <Select
                            multiple
                            label="Module"
                            name="modules"
                            value={leadFilter.modules}
                            renderValue={(value) =>
                              value.length === modules.length
                                ? "ALL"
                                : value.join(", ")
                            }
                            onChange={handleChange}
                            sx={{ minWidth: "200px", maxHeight: "40px" }}
                          >
                            <MenuItem value="ALL">
                              <Checkbox checked={allChecked.modules} />
                              <ListItemText primary="ALL" />
                            </MenuItem>
                            {modules.map((_module, index) => {
                              return (
                                <MenuItem key={index} value={_module}>
                                  <Checkbox
                                    checked={
                                      allChecked.modules
                                        ? true
                                        : leadFilter.modules.indexOf(_module) >
                                          -1
                                    }
                                  />
                                  <ListItemText primary={_module} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={showDateGrid ? 2.75 : 3.65}
                        sx={{ paddingRight: "15px" }}
                      >
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-event-select-label">
                            Event
                          </InputLabel>
                          <Select
                            sx={{ minWidth: "200px", maxHeight: "40px" }}
                            labelId="demo-event-select-label"
                            id="demo-event-select"
                            multiple
                            label="Event"
                            name="events"
                            value={leadFilter.events}
                            renderValue={(value) =>
                              value.length === events.length
                                ? "ALL"
                                : value.join(", ")
                            }
                            onChange={handleChange}
                          >
                            <MenuItem value="ALL">
                              <Checkbox checked={allChecked.events} />
                              <ListItemText primary="ALL" />
                            </MenuItem>
                            {events.map((event, index) => {
                              return (
                                <MenuItem key={index} value={event}>
                                  <Checkbox
                                    checked={
                                      allChecked.events
                                        ? true
                                        : leadFilter.events.indexOf(event) > -1
                                    }
                                  />
                                  <ListItemText primary={event} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={showDateGrid ? 2.75 : 3.65}
                        sx={{ paddingRight: "15px" }}
                      >
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-dateRange-select-label">
                            Interval
                          </InputLabel>
                          <Select
                            sx={{ minWidth: "200px", maxHeight: "40px" }}
                            labelId="demo-dateRange-select-label"
                            id="demo-dateRange-select"
                            label="Interval"
                            name="interval"
                            value={interval}
                            onChange={handleChangeInterval}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {intervals.map((interval, index) => {
                              return (
                                <MenuItem key={index} value={interval.value}>
                                  {interval.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {showDateGrid ? (
                        <Grid
                          item
                          xs={showDateGrid ? 2.75 : 0}
                          sx={{ paddingTop: "3px" }}
                        >
                          <Chip
                            label={`${formatDate(
                              new Date(startDate).toDateString()
                            )} - ${formatDate(
                              new Date(endDate).toDateString()
                            )}`}
                            variant="outlined"
                            onDelete={() => {
                              setShowDateGrid(false);
                              setInterval("");
                            }}
                          />
                        </Grid>
                      ) : null}
                      <Grid
                        item
                        xs={showDateGrid ? 1 : 1.05}
                        sx={{ display: "flex", flexDirection: "row-reverse" }}
                      >
                        <Tooltip title="Fetch Results">
                          <Button
                            sx={{ maxHeight: "40px" }}
                            variant="contained"
                            size="small"
                            onClick={getLogs}
                          >
                            <ManageSearchIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <ActivityLogsBody
                      results={results}
                      page={page}
                      setPage={setPage}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog open={popup} onClose={() => {}}>
          <DialogTitle textAlign="center">Define Interval</DialogTitle>
          <DialogContent>
            <Grid container spacing={2.5}>
              <Grid item xs={6} sm={6} sx={{ marginTop: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DesktopDatePicker
                    fullWidth
                    label="From"
                    inputFormat="MMM/dd/yyyy"
                    value={startDate}
                    maxDate={new Date().setDate(new Date().getDate() - 1)}
                    name="startDate"
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6} sm={6} sx={{ marginTop: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DesktopDatePicker
                    required
                    fullWidth
                    label="To"
                    inputFormat="MMM/dd/yyyy"
                    // minDate={project.timeline.deadline}
                    // minDate={}
                    value={endDate}
                    name="endDate"
                    maxDate={new Date()}
                    onChange={handleEndDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                setInterval("");
              }}
              sx={{ color: "red" }}
            >
              CANCEL
            </Button>
            <Button
              onClick={(e) => {
                setShowDateGrid(true);
                handleClose();
              }}
            >
              SET
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ActivityLogs;
