import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddPricenegociation from "../../components/Lead/AddPricenegociation";
import PriceNegotiationChart from "../../components/Lead/PriceNegotiationChart";
import {
  getPriceNegociation,
  getAllResourceDescription,
} from "../../services/leads.service";
import PriceNegotiationTableView from "../../components/PriceNegotiation/PriceNegotiationTableView";
import FileUpload from "../../components/Fileupload";
import FileList from "../../components/FileList";

export default function PriceNegociationSection({
  leadId,
  contactId,
  setLoaderDialog,
  setIactionedApiCalled,
  fetchLeadDetails,
  setUpdateLeadCalled,
  isPriceNegotiation,
}) {
  const [priceAndDetailsAdded, setPriceAndDetailsAdded] = useState(false);
  const [priceNegotiationListing, setPriceNegotiationListing] = useState([]);
  const [resourceDescription, setResourceDescription] = useState([]);

  //chart

  const [deatilsWithPrice, setDetailsWithPrice] = useState([]);
  const [detailsWithTotalPrice, setDetailsWithTotalPrice] = useState([]);

  const showDateAndTime = (value) => {
    const newValue =
      new Date(value).toDateString() +
      " " +
      new Date(value).toLocaleTimeString();
    return newValue;
  };

  //file state
  const [files, setFiles] = useState([]);

  const [fileState, setFileState] = useState({
    open: false,
    selectedFiles: [],
    onClose: () => {
      setFileState((prev) => ({ ...prev, open: false }));
    },
    onOpen: () => {
      setFileState((prev) => ({ ...prev, open: true }));
    },
    clearSelectedFiles: () => {
      setFileState((prev) => ({ ...prev, open: false }));
      setFiles([]);
    },
    clearAllFiles: () => {
      setFileState((prev) => ({ ...prev, selectedFiles: [] }));
      setFiles([]);
    },
    closeBackDrop: () => {
      setLoaderDialog(false);
    },
    openBackDrop: () => {
      setLoaderDialog(true);
    },
  });

  const submitFile = () => {
    fileState.onClose();
    setFileState((prev) => ({
      ...prev,
      selectedFiles: [...prev.selectedFiles, ...files],
    }));
    fileState.clearSelectedFiles();
  };

  const handleRemoveFile = (index) => {
    setFileState((prev) => {
      const temp = { ...prev };
      temp.selectedFiles.splice(index, 1);
      return temp;
    });
  };

  //get price negotiation apis

  const getAllPriceNegotiationDetails = async () => {
    try {
      const date = new Date();
      const response = await getPriceNegociation(contactId, leadId, date);
      let totalTemp = [];
      let temp = [];
      if (response.data.status) {
        setPriceNegotiationListing(response.data.data);

        response.data.data.forEach((data, index) => {
          totalTemp.push({
            x: new Date(data.createdAt).toLocaleDateString(),
            // y: parseFloat(data.rate_per_unit * data.number_of_resources),
            y: parseFloat(data.cost),
            z: [
              // data.resource_description,
              data.schedule,
              new Date(data.createdAt).toLocaleTimeString(),
            ],
          });
          temp.push({
            x: showDateAndTime(data.createdAt),
            // y: parseFloat(data.rate_per_unit),
            y: parseFloat(data.cost),
            z: [
              // data.resource_description,
              data.schedule,
              new Date(data.createdAt).toLocaleTimeString(),
            ],
          });
        });

        setDetailsWithTotalPrice(totalTemp);
        setDetailsWithPrice(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllResourceDescriptions = async () => {
    try {
      const response = await getAllResourceDescription();
      if (response.data.status) {
        console.log(response.data);
        setResourceDescription(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPriceNegotiationDetails();
    getAllResourceDescriptions();
  }, [priceAndDetailsAdded]);
  useEffect(() => {
    getAllPriceNegotiationDetails();
  }, [isPriceNegotiation]);

  return (
    <Card
      className="child"
      sx={{ minWidth: "auto", minHeight: "auto" }}
      elevation={3}
      variant="outlined"
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <PriceNegotiationChart
              detailsWithTotalPrice={detailsWithTotalPrice}
              deatilsWithPrice={deatilsWithPrice}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AddPricenegociation
              id={leadId}
              conId={contactId}
              priceAndDetailsAdded={priceAndDetailsAdded}
              setPriceAndDetailsAdded={setPriceAndDetailsAdded}
              resourceDescription={resourceDescription}
              fetchLeadDetails={fetchLeadDetails}
              setUpdateLeadCalled={setUpdateLeadCalled}
              getAllPriceNegotiationDetails={() => {
                getAllPriceNegotiationDetails();
              }}
              fileDialogOpen={fileState.onOpen}
              uploadableFiles={fileState.selectedFiles}
              clearFilesView={fileState.clearAllFiles}
              backDropView={fileState.openBackDrop}
              backDropHide={fileState.closeBackDrop}
              setIactionedApiCalled={setIactionedApiCalled}
              fromCreateProposal={false}
            />
          </Grid>
          {fileState.selectedFiles && fileState.selectedFiles.length ? (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FileList
                    fileList={fileState.selectedFiles}
                    fromProjectCreatePage={true}
                    removeFile={handleRemoveFile}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontSize="12px" color="gray">
                    Note: The above documents will not be uploaded until the
                    price is created, they will be uploaded automatically once
                    the price is established.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12} md={12} lg={12}>
            {/* <Scrollbar>
              <TableContainer>
                <Table>
                  {priceNegotiationListing.length > 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <PriceNegotiationListing
                            leadId={leadId}
                            contactId={contactId}
                            data={priceNegotiationListing}
                            resourceDescription={resourceDescription}
                            priceAndDetailsAdded={priceAndDetailsAdded}
                            setPriceAndDetailsAdded={setPriceAndDetailsAdded}
                            getAllPriceNegotiationDetails={() => {
                              setPriceNegotiationListing([]);
                              getAllPriceNegotiationDetails();
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          No details available
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar> */}
            {priceNegotiationListing && priceNegotiationListing.length ? (
              <PriceNegotiationTableView contents={priceNegotiationListing} />
            ) : (
              <Typography
                textAlign="center"
                fontWeight="bold"
                fontFamily="sans-serif"
                fontSize="20px"
                pt="10px"
              >
                No Pricing Records Found
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Dialog maxWidth="md" open={fileState.open} onClose={fileState.onClose}>
        <DialogContent>
          <FileUpload
            files={files}
            setFiles={setFiles}
            fromPriceNegotiationCreateTime={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={fileState.clearSelectedFiles}>Cancel</Button>
          {/* <Button>Save</Button> */}
          <Button onClick={submitFile}>Add</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
