import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DraggableCopyableElement from "./DraggableCopyableElement";

function ObjectViewAccordion({ object, currentModule, fromSalesCampaign }) {
  let { keys = [], title = "" } = object;
  return (
    <Accordion
      sx={{
        border: "1px solid",
        borderColor: "#ccc",
        borderRadius: 1,
        minHeight: {
          xs: "auto", // For extra-small screens
          sm: fromSalesCampaign ? "100%" : "auto", // For small screens
          md: fromSalesCampaign ? "45px" : "auto", // For medium screens
          lg: fromSalesCampaign ? "55px" : "auto", // For large screens
          xl: fromSalesCampaign ? "55px" : "auto", // For extra-large screens
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: 0 }}>
        {keys?.map((key, keyIndex) =>
          typeof key === "object" &&
          title?.toUpperCase() === currentModule?.toUpperCase() ? (
            <Box
              key={keyIndex}
              onClick={() => {
                navigator.clipboard.writeText(key);
              }}
            >
              <ObjectViewAccordion object={key} currentModule={currentModule} />
            </Box>
          ) : typeof key === "string" ? (
            <>
              <DraggableCopyableElement
                label={key}
                index={key.length}
                title={title}
                currentModule={currentModule}
              />
            </>
          ) : null
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default ObjectViewAccordion;
