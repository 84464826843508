const apis = require("./api.requests");
const path = "/companies/v1";

async function getAllCompanies() {
  return await apis.hitGetApi(`${path}/all`);
}

async function createCompany(data) {
  return await apis.hitPostApiWithFormData(`${path}/`, data);
}

async function getCompanyById(id) {
  return await apis.hitGetApi(`${path}/${id}/details`);
}

async function updateCompany(id, data) {
  return await apis.hitPutApi(`${path}/${id}`, data);
}

async function getCompanyActions(id, pagecount) {
  return await apis.hitPagedApi(`${path}/${id}/actions`, pagecount);
}

async function createCompanyAction(companyId, data) {
  return await apis.hitPostApi(`${path}/${companyId}/actions`, data);
}

async function companyActionMarkAsDone(companyId, actionId, date) {
  return await apis.hitPostApi(
    `${path}/${companyId}/actions/${actionId}/markAsDone`,
    date
  );
}

async function fetchCompanyComments(id, pageCount) {
  return await apis.hitPagedApi(`${path}/${id}/comments`, pageCount);
}

async function createCompanyComment(id, body) {
  const { comment, createdBy, cleanedContent } = body;
  let comments = {
    comment: cleanedContent,
    createdBy,
  };
  var formData = new FormData();
  let indexes = [];
  let descriptionFiles = [];
  formData.append("companyId", id);

  if (body.files && body.files.length > 0) {
    body.files.map((ele, index) => {
      console.log("ele", ele);
      if (ele.checked) {
        indexes.push(index);
      }
      if (ele.description && ele.description.length) {
        descriptionFiles.push({
          fileIndex: index,
          description: ele.description,
        });
      }
      formData.append("docFile", ele);
    });
  } else {
    formData.append("docFile", []);
    console.log("No files to process");
  }

  formData.append("checkedIndexes", JSON.stringify(indexes));
  formData.append("descriptionFiles", JSON.stringify(descriptionFiles));
  formData.append("comment", JSON.stringify(comments));
  return await apis.hitPostApi(`${path}/${id}/comments`, formData);
}

//upload document
async function uploadCompanyDocument(companyId, files) {
  console.log(files);
  if (files.length) {
    const formData = new FormData();
    const descriptionFiles = [],
      checkedIndexes = [];

    files.forEach((ele, index) => {
      if (ele.hasOwnProperty("description")) {
        console.log("hiiii");
        descriptionFiles.push({
          fileIndex: index,
          description: ele.description,
        });
      }
      if (ele.checked) {
        checkedIndexes.push(index);
      }

      formData.append("docFile", ele);
    });

    formData.append("descriptionFiles", JSON.stringify(descriptionFiles));
    formData.append("checkedIndexes", JSON.stringify(checkedIndexes));

    return await apis.hitPostApiWithFormData(
      `${path}/${companyId}/uploadDocs`,
      formData
    );
  }
}

async function deleteCompanyDocument(companyId, documentId) {
  return await apis.hitDeleteApi(
    `${path}/${companyId}/${documentId}/deleteDoc`
  );
}

// Function - To Get Companies Data Based On Given Key And Search Value
async function getCompanySearch(key, value) {
  return await apis.hitGetApi(
    `${path}/search?searchKey=${key}&searchValue=${encodeURIComponent(value)}`,
    value
  );
}

async function getCompanyDetailsByContactId(contactId) {
  return await apis.hitGetApi(`${path}/${contactId}/companyDetailsByContactId`);
}

async function getActionDoers(companyId) {
  return await apis.hitGetApi(`${path}/${companyId}/getAllDoers`);
}

export {
  getAllCompanies,
  createCompany,
  updateCompany,
  getCompanyById,
  getCompanyActions,
  getCompanySearch,
  createCompanyAction,
  fetchCompanyComments,
  createCompanyComment,
  companyActionMarkAsDone,
  uploadCompanyDocument,
  deleteCompanyDocument,
  getCompanyDetailsByContactId,
  getActionDoers,
};
