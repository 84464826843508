import React, { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import { fetchLeadStatusLogs } from "../services/leads.service";
import LeadStatusLogsDialog from "../components/Lead/LeadStatusLogsDialog";

const LeadStatusLogsContext = createContext();

export const LeadStatusLogsProvider = ({ children }) => {
  const [leadStatusLogs, setLeadStatusLogs] = useState({
    loaderEnable: true,
    logs: [],
    openLogsDialog: false,
  });

  const [logPagination, setLogPagination] = useState({
    rowsPerPage: 5,
    page: 0,
  });

  const handleOpenStatusLogsDialog = async (contactId, leadId) => {
    setLeadStatusLogs((prev) => ({
      ...prev,
      openLogsDialog: true,
      loaderEnable: true,
    }));
    try {
      const response = await fetchLeadStatusLogs(contactId, leadId);
      if (response.status) {
        setLeadStatusLogs((prev) => ({
          ...prev,
          logs: response.data.data[0]?.statusLogs || [],
          loaderEnable: false,
        }));
      } else {
        toast("No Logs Found");
        setLeadStatusLogs((prev) => ({
          ...prev,
          loaderEnable: false,
        }));
      }
    } catch (error) {
      console.error(error);
      toast("Error fetching logs");
      setLeadStatusLogs((prev) => ({
        ...prev,
        loaderEnable: false,
      }));
    }
  };

  const handleCloseStatusLogsDialog = () => {
    setLeadStatusLogs((prev) => ({
      ...prev,
      openLogsDialog: false,
      logs: [],
    }));
    setLogPagination((prev) => ({
      ...prev,
      page: 0,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setLogPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  return (
    <LeadStatusLogsContext.Provider
      value={{
        leadStatusLogs,
        logPagination,
        handleOpenStatusLogsDialog,
        handleCloseStatusLogsDialog,
        handleChangePage,
      }}
    >
      <>
        {children}
        <LeadStatusLogsDialog />
      </>
    </LeadStatusLogsContext.Provider>
  );
};

export const useLeadStatusLogs = () => useContext(LeadStatusLogsContext);
