import { Box, Button, IconButton, LinearProgress } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PhoneSnackbar from "../components/UI/PhoneSnackbar";
import Timer from "react-compound-timer";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import PhoneIcon from "@mui/icons-material/Phone";

export const CallingContext = createContext();

const CallingProvider = ({ children }) => {
  const [openCall, setOpenCall] = useState(false);
  const [openCallPickedUpDialog, setOpenCallPickedUpDialog] = useState(false);
  const [openCallIncomingDialog, setOpenCallIncomingDialog] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [callTo, setCallTo] = useState("");
  const [callState, setCallState] = useState({
    isRinging: false,
    isPickedUp: false,
    isConnected: false,
    // isTerminated: false
  });
  const [startTimerForDialog, setStartTimerForDialog] = useState(0);
  const [startTimerForSnackbar, setStartTimerForSnackbar] = useState(2000);
  const [callId, setCallId] = useState("");
  const [callDisability, setCallDisability] = useState(false);
  const [callFrom, setCallFrom] = useState("");

  let timeOut;

  // const handleCall = (_id) => {
  //     try {
  //         // const response = await createCall({ fromUser: currentUser._id, toUser: _id })
  //         // console.log("response---->", response);
  //         // if (response.data.status) {
  //         //   console.log("isRinging started")
  //         // }
  //         if (callState.isRinging) {
  //             console.log("Inside handle call if");
  //             // timeOut();
  //             setTimeout(() => {
  //                 if (callState.isRinging) {
  //                     setCallState((prev) => ({
  //                         ...prev, isRinging: false, isPickedUp: true, isConnected: false
  //                     }))
  //                     // handleCallPickedUpDialog();
  //                 }

  //             }, 5000);
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }

  const handleCallPickedUpDialog = () => {
    if (
      !callState.isRinging &&
      callState.isPickedUp &&
      !callState.isConnected
    ) {
      console.log("Handle call dialog triggered");
      setTimeout(() => {
        setCallState((prev) => ({
          ...prev,
          isRinging: false,
          isPickedUp: false,
          isConnected: true,
        }));
      }, 100);
    }
  };

  // const checkCallStatIcon = (_id) => {
  //     return callState.isRinging || callState.isPickedUp || callState.isConnected ? callId === _id ? <PhoneForwardedIcon /> : <PhoneIcon /> : <PhoneIcon />
  // }

  const checkCallStatDisabled = () => {
    return callState.isRinging || callState.isPickedUp || callState.isConnected
      ? true
      : false;
  };

  useEffect(() => {
    console.log(
      "Ringing",
      callState.isRinging,
      "Pickedup",
      callState.isPickedUp,
      "Connected",
      callState.isConnected
    );
    if (
      !callState.isRinging &&
      !callState.isPickedUp &&
      !callState.isConnected
    ) {
      setOpenCall(false);
      setOpenCallPickedUpDialog(false);
      clearTimeout(timeOut);
    }
    if (
      callState.isRinging &&
      !callState.isPickedUp &&
      !callState.isConnected
    ) {
      setOpenCall(true); /*handleCall(callId);*/
    }
    if (
      !callState.isRinging &&
      callState.isPickedUp &&
      !callState.isConnected
    ) {
      setOpenCall(false);
      setOpenCallPickedUpDialog(true);
      handleCallPickedUpDialog();
    }
    if (
      !callState.isRinging &&
      !callState.isPickedUp &&
      callState.isConnected
    ) {
      setOpenCall(true);
      setOpenCallPickedUpDialog(false);
    }
    if (callState.isRinging || callState.isPickedUp || callState.isConnected) {
      /*checkCallStatIcon(callId);*/ checkCallStatDisabled();
    }
  }, [callState.isRinging, callState.isConnected, callState.isConnected]);

  return (
    <>
      <CallingContext.Provider
        value={{
          openCall,
          setOpenCall,
          callTo,
          setCallTo,
          callState,
          setCallState,
          setOpenCallPickedUpDialog,
          openCallPickedUpDialog,
          openCallIncomingDialog,
          setOpenCallIncomingDialog,
          startTimerForDialog,
          startTimerForSnackbar,
          callId,
          setCallId,
          checkCallStatDisabled,
          timeOut,
          callDisability,
          setCallDisability,
          notification,
          setNotification,
          callFrom,
          setCallFrom,
        }}
      >
        {children}
      </CallingContext.Provider>
    </>
  );
};

export default CallingProvider;
