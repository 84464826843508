import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { Dialog, MuiThemeProvider } from "@material-ui/core";
import {
  Button,
  CardActionArea,
  CardActions,
  createTheme,
  Grid,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import CallEndRoundedIcon from "@mui/icons-material/CallEndRounded";
import { AuthContext } from "../../context/auth.context";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import { useState } from "react";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CallingContext } from "../../context/calling.context";
import CallEndIcon from "@mui/icons-material/CallEnd";
import Timer from "react-compound-timer";

export default function PhoneSnackbar({}) {
  const [mute, setMute] = useState(true);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(0);

  const progressRef = React.useRef(() => {});

  // const { currentUser } = React.useContext(AuthContext);
  const {
    openCall,
    setOpenCall,
    callTo,
    setCallTo,
    startTimerForSnackbar,
    callState,
    setCallState,
    setOpenCallPickedUpDialog,
    timeOut,
    callDisability,
    setCallDisability,
  } = React.useContext(CallingContext);

  const handleClose = () => {
    setOpenCall(false);
    // setCurrentButton("Calls");
  };
  const handleMute = () => {
    if (mute) {
      setMute(false);
    } else setMute(true);
  };

  const action = (
    <React.Fragment>
      <Button disabled>
        {callState.isRinging &&
        !callState.isPickedUp &&
        !callState.isConnected ? (
          <Box sx={{ width: "150%" }}>
            <LinearProgress
              variant="buffer"
              value={progress}
              valueBuffer={buffer}
            />
          </Box>
        ) : null}
        {!callState.isRinging &&
        !callState.isPickedUp &&
        callState.isConnected ? (
          <Timer initialTime={startTimerForSnackbar}>
            <React.Fragment>
              <div>
                <Timer.Hours
                  formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
                />
                :
                <Timer.Minutes
                  formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
                />
                :
                <Timer.Seconds
                  formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
                />
              </div>
            </React.Fragment>
          </Timer>
        ) : null}
      </Button>

      <IconButton
        size="small"
        aria-label="close"
        color="error"
        varaint="error"
        onClick={() => {
          clearTimeout(timeOut);
          setCallDisability(false);
          setOpenCall(false);
          setCallTo("");
          setOpenCallPickedUpDialog(false);
          setCallState((prev) => ({
            ...prev,
            isRinging: false,
            isPickedUp: false,
            isConnected: false,
          }));
        }}
      >
        <CallEndIcon />
      </IconButton>
    </React.Fragment>
  );
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(0);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });
  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  // useEffec(() => {
  //     console.log("Open call", openCall, "Call to", callTo)
  // }, [])

  return (
    <Snackbar
      // sx={{
      //     minWidth: "10px", width: "10px", height: "10%", '& .MuiSnackbar-root': {
      //         minWidth: '10%',
      //     }
      // }}
      // width="60px"
      open={openCall}
      // autoHideDuration={1000}
      onClose={() => {}}
      message={
        callState.isRinging && !callState.isPickedUp
          ? `Calling ${callTo}`
          : `Talking with ${callTo}`
      }
      action={action}
    />
  );
}
