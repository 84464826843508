const constants = {
  ARCHIVES: "ARCHIVES",
  PROJECTS: "PROJECTS",
  LEADS: "LEADS",
  CONTACTS: "CONTACTS",
  DASHBOARD: "DASHBOARD",
  COMPANIES: "COMPANIES",
  CONTACTDROPDOWN: {
    NAME: "Name",
    EMAIL: "Email",
    COUNTRY: "Country",
    PHONE: "Phone",
  },
};

export default constants;

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
