import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import "./FileItem.css";
import { download } from "../../../services/common.service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import { deleteDocument as deleteLeadDocument } from "../../../services/leads.service";
import { deleteDocument as deleteProjectDocument } from "../../../services/projects.service";
import { deleteCompanyDocument } from "../../../services/companies.service";

const FileItem = ({
  file,
  key,
  fileList,
  deleteFile,
  fromProjectCreatePage,
  contactId,
  leadId,
  length,
  setLead,
  setFiles,
  fromProjectViewPage,
  projectId,
  setIsProjectFetched,
  removeFile,
  setUpdateLeadCalled,
  fromCompanyCreatePage,
  fromCompanyViewPage,
  companyId,
  fetchCompanyById,
  setIactionedApiCalled,
  forPriceNegotiation,
}) => {
  console.log("---->File", file);

  const [deletePopupOpen, setDeletePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setDeletePopupOpen(false);
  };

  console.log("delete file data", file.location, file._id);
  console.log("Ids", contactId, leadId);
  const fileName =
    fromProjectCreatePage || fromCompanyCreatePage
      ? file.name
      : file.location.split("/").pop();
  console.log("delete file data", fileName.split("-").pop());

  const removeDocument = async (id) => {
    console.log("Resource id", id);
    handleDeletePopupOpen(id);
  };

  const handleDeletePopupOpen = (id) => {
    setDeletePopupOpen({
      state: true,
      onSuccess: async () => {
        console.log("id", id);
        try {
          // setFiles((prevState) => [...prevState, ...file]);
          console.log("Set files called before delete");
          const response = fromCompanyViewPage
            ? await deleteCompanyDocument(companyId, file._id)
            : fromProjectViewPage
            ? await deleteProjectDocument(projectId, file._id)
            : await deleteLeadDocument(contactId, leadId, file._id);
          if (response.data.status) {
            toast("Document deleted successfully");

            if (fromCompanyViewPage) fetchCompanyById();
            fromProjectViewPage
              ? setIsProjectFetched((prevState) => (prevState = false))
              : setUpdateLeadCalled((prevState) => (prevState = false));
            setIactionedApiCalled(true);
            setFiles([]);
            handleClose();
            console.log("Set files called after delete");
          } else {
            handleClose();
          }
        } catch (error) {
          console.log(error);
          handleClose();
        }
      },
    });
    return id;
  };
  return (
    <>
      <div
        className="parent"
        style={{
          border: "1px solid black",
          borderRadius: "5px",
          padding: "1em 1.5em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="file-item"
          style={{
            listStyle: "none",
            backgroundColor: "#2065d1 38",
            display: "flex",
            alignItems: "center",
          }}
        >
          <DescriptionIcon sx={{ color: "#2065d1" }} />
          {fromProjectCreatePage || fromCompanyCreatePage ? (
            <p
              style={{
                flex: 1,
                fontSize: "0.9rem",
                paddingLeft: "10px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="fileName"
            >
              {fileName}
            </p>
          ) : (
            <>
              <Tooltip title={fileName}>
                <p
                  style={{
                    flex: 1,
                    fontSize: "0.9rem",
                    paddingLeft: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {fileName.split("-").slice(1).join("-")}
                </p>
                {/* <p>{fileName}</p> */}
                {/* <p>{fileName.split("-").pop()}</p> */}
              </Tooltip>
            </>
          )}
          <div
            className="actions"
            style={{
              justifySelf: "flex-end",
              cursor: "pointer",
            }}
          >
            {/* <div className="loading"></div> */}
            {/* {file.isUploading && (
            <FontAwesomeIcon icon={faSpinner} className="fa-spin " />
          )} */}
            {/* {!file.isUploading && <FontAwesomeIcon icon={faTrash} />} */}
            {forPriceNegotiation ? (
              <>
                <IconButton size="medium" disableRipple="true">
                  <DownloadIcon
                    icon={faDownload}
                    style={{ color: "#f55e30" }}
                    onClick={() => {
                      window.open(file.location, "Download");
                      // download("http://localhost:3022" + file.location, fileName);65.2.5.232
                      // download(file.location, fileName);
                      // window.open("http://localhost:3022" + file.location, "Download");
                      // download("http://localhost:3022" + file.location, fileName);
                      download(file.location, fileName);

                      // download(
                      //   "http://ulalpha-staging.s3.amazonaws.com/62b94f19ac0dba1c6037f6c2/62baa99ca0d27dc5d0b7b1da/165657389175511.Typescript.docx",
                      //   fileName
                      // );
                    }}
                  />
                </IconButton>
              </>
            ) : fromProjectCreatePage || fromCompanyCreatePage ? (
              <IconButton
                size="medium"
                disableRipple="true"
                onClick={deleteFile}
              >
                <DeleteForeverIcon style={{ color: "#f55e30" }} />
              </IconButton>
            ) : (
              <>
                <IconButton
                  size="medium"
                  disableRipple="true"
                  onClick={removeDocument}
                >
                  <DeleteForeverIcon style={{ color: "#f55e30" }} />
                </IconButton>
                <IconButton size="medium" disableRipple="true">
                  <DownloadIcon
                    icon={faDownload}
                    style={{ color: "#f55e30" }}
                    onClick={() => {
                      window.open(file.location, "Download");
                      // download("http://localhost:3022" + file.location, fileName);65.2.5.232
                      // download(file.location, fileName);
                      // window.open("http://localhost:3022" + file.location, "Download");
                      // download("http://localhost:3022" + file.location, fileName);
                      download(file.location, fileName);

                      // download(
                      //   "http://ulalpha-staging.s3.amazonaws.com/62b94f19ac0dba1c6037f6c2/62baa99ca0d27dc5d0b7b1da/165657389175511.Typescript.docx",
                      //   fileName
                      // );
                    }}
                  />
                </IconButton>
              </>
            )}
          </div>
        </div>
        {file.description && (
          <Stack direction="row" spacing={2} alignItems="center">
            <InfoIcon sx={{ color: "#2065D1" }} />
            <TextField
              fullWidth
              disabled
              variant="standard"
              size="small"
              value={file.description}
            />
          </Stack>
        )}
      </div>

      <Dialog open={deletePopupOpen.state} onClose={handleClose}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you wish to delete this document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={deletePopupOpen.onSuccess}>Agree</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileItem;
