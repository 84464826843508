import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, Grid, Stack, TextField } from "@mui/material";
import constants from "../../constants";
import { getContactSearch } from "../../services/contacts.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserSearch } from "../../services/users.service";
import { searchTenants } from "../../services/superAdmin.service";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [{ Name: "name" }, { Country: "country" }];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SearchUser({
  setCount,
  users,
  setUsers,
  column,
  setColumn,
  typeText,
  setTypeText,
  page,
  pageCount,
  setPageCount,
  setPage,
  setIsLoading,
  currentUser,
}) {
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isEmptyTextField, setIsEmptyTextField] = React.useState(false);
  const theme = useTheme();
  //   const [column, setColumn] = React.useState("");

  //   let result = constants.CONTACTDROPDOWN.map((e) => e);
  console.log("In search value", users, typeText);
  const notify = () => toast("Please Select a column!");
  const handleChange = (event) => {
    setIsEmpty(false);
    const {
      target: { value },
    } = event;
    setColumn(value);
  };
  var regex = /^[a-zA-Z ]*$/;
  var regexPhone = /^\+?\d*$/;
  const searchChange = (e) => {
    if (column === "Name") {
      const value = regex.test(e.target.value);
      if (!value) {
        toast("Numbers and special characters are not allowed");
        return;
      }
    }
    if (column === "Phone") {
      const value = regexPhone.test(e.target.value);
      if (!value) {
        toast(
          "Alphabets, spaces and special characters except '+' are not allowed"
        );
        return;
      }
    }
    setUsers([]);
    setTypeText(e.target.value);
    setCount(1);
    setPage(0);

    console.log("setTypetext", e.target.value);
  };

  React.useEffect(() => {
    if (typeText) {
      setIsLoading(true);
      setIsEmpty(false);
      setIsEmptyTextField(true);

      const lowerColumnName = column.toLowerCase();

      const timerId = setTimeout(() => {
        if (currentUser.role === "SUPER_ADMIN") {
          searchTenants(lowerColumnName, typeText, currentUser?.isTenant).then(
            (response) => {
              const result = response.data.data ? response.data.data : [];
              setUsers((prevState) => [...prevState, ...result]);
              setCount(result.length);
              setIsLoading(false);
            }
          );
        } else {
          getUserSearch(lowerColumnName, typeText).then((response) => {
            const result = response.data.data ? response.data.data : [];
            setUsers((prevState) => [...prevState, ...result]);
            setCount(result.length);
            setIsLoading(false);
          });
        }
      }, 1000);

      return () => {
        clearTimeout(timerId); // Clear the timeout on unmount or typeText change
      };
    } else {
      setIsEmptyTextField(false);
    }
  }, [typeText]);

  return (
    <Stack direction="row" spacing={1}>
      <Select
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        displayEmpty
        value={column}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (isEmpty) {
            return <>Select Column</>;
          }

          return selected;
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <>Select Column</>
        </MenuItem>
        <MenuItem value="Name">Name</MenuItem>
        <MenuItem value="Phone">Phone</MenuItem>
      </Select>

      <TextField
        size="small"
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        inputProps={column ? "" : { readOnly: true }}
        label="Search"
        variant="outlined"
        value={typeText}
        onChange={searchChange}
        InputLabelProps={{ shrink: isEmptyTextField }}
        onClick={() => {
          if (!column) {
            notify();
          }
        }}
      />
    </Stack>
  );
}
