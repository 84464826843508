import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Card,
  Box,
  Button,
  Typography,
  Container,
  CardContent,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EventBus from "../../components/texteditor/EventBus";
import Page from "../../components/UI/Page";
import Info from "../../components/Contacts/Info";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateAction from "../../components/Shared/Action/Actions.Create";
import LeadCreateComponent from "../../components/Lead/Lead.create";
import { createContact } from "../../services/contacts.service";
import { AuthContext } from "../../context/auth.context";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import { useDynamicContactTitle } from "./Contacts";
import { useDynamicLeadTitle } from "../Leads/Leads";
import { useDynamicCompanyTitle } from "../Companies/Companies";
import getAttributesByEntity from "../../services/entity.attribute.services";

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,7})*$/;

let hitApi = true;
let afterCreate = false;
const ContactCreate = () => {
  const contactDynamicTitle = useDynamicContactTitle();
  const leadDynamicTitle = useDynamicLeadTitle();
  const companyDynamicTitle = useDynamicCompanyTitle();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const loaction = useLocation();
  const [customAttributes, setCustomAttributes] = useState([]);
  const [customLeadAttributes, setCustomLeadAttributes] = useState([]);

  let initialLeadState = {
    summary: "",
    skills: [],
    techOwner: "",
    description: "",
    files: [],
  };
  const [saveButton, setSaveButton] = React.useState(false);
  const [isAutocomplete, setIsAutocomplete] = React.useState(false);

  //TODO: Remove the curly braces
  const [lead, setLead] = React.useState(initialLeadState);
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  let initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    dueDate: new Date(),
    // dueTime: new Date(),
  };
  const [action, setAction] = React.useState(initialActionState);

  let initialContactState = {
    name: "",
    company: "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    source: "",
    salesOwner: "",
  };

  const [contact, setContact] = React.useState(initialContactState);

  const [contactCreated, setContactCreated] = useState(false);

  const [toEdit, setToEdit] = React.useState(false);

  const navigate = useNavigate();

  const [showLeadSection, setShowLeadSection] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (currentUser.role !== "ADMIN" && currentUser.role !== "SALES") {
      navigate(`/unauthorized`);
    }
  }, []);

  const ResetForm = () => {
    setContact(initialContactState);
    setContact((prev) => ({
      ...prev,
      company: "",
    }));
    setLead(initialLeadState);
    setAction(initialActionState);
    EventBus.dispatch("EditorBus");
    setCreateContactShowTimer(false);
  };

  const [isEditPage] = React.useState(false);
  const [createContactShowTimer, setCreateContactShowTimer] =
    React.useState(false);
  const notify = () =>
    toast(
      `Successfully ${contactDynamicTitle?.displayName.singularName} created!`
    );

  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const validatePhone = (data) => {
    console.log(data.length);
    let upperFlag = "Up";
    let lowerFlag = "Low";
    if (data.length > 0) {
      if (data.length >= 10 && data.length <= 14) {
        const regexPattern = /^\+?\d+$/;
        // return result = regexPattern.test(data);
        if (!regexPattern.test(data)) {
          return upperFlag;
        }
      } else {
        return lowerFlag;
      }
      // return data.regex(/^\+?\d+$/) ? true : false
    }
    return null;
  };

  const validateAction = (param1, param2) => {
    let result;
    if (param1 === "" && param2 === "") {
      result = true;
    } else if (param1 !== "" && param2 !== "") {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function dateChange(date) {
    console.log(date);
    const dateTimeInSeconds = new Date(date).getTime();
    console.log(dateTimeInSeconds);
    const offSet = new Date().getTimezoneOffset();
    console.log(offSet);
    const offSetInSeconds = offSet * 60 * 1000;
    console.log(offSetInSeconds);
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    console.log(newDateInSeconds);
    const newDate = new Date(newDateInSeconds);
    console.log(newDate);
    const newDateFormat = formatDate(newDate);
    console.log(newDateFormat);
    return newDateFormat;
  }

  function formatDate(date) {
    console.log(date);
    if (!createContactShowTimer) {
      date = new Date(date);
    }
    date = new Date(date);

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":") +
      ":00" +
      ".000Z"
    );
  }

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const leadAttributeValidateFunction = (customLeadAttributes) => {
    let flag = true;
    customLeadAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`Text should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`Text should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const handleCreateContact = async () => {
    const flag = validatePhone(contact.phone);
    if (!validate(contact.name)) {
      toast("Name can not be empty");
      hitApi = false;
    }
    if (!validate(contact.company)) {
      toast(
        `${companyDynamicTitle?.displayName.singularName} can not be empty`
      );
      hitApi = false;
    }
    if (!validate(contact.email)) {
      toast("Email can not be empty");
      hitApi = false;
    }
    if (!validate(contact.country)) {
      toast("Country can not be empty");
      hitApi = false;
    }
    if (!validate(contact.salesOwner)) {
      toast("Sales Owner can not be empty");
      hitApi = false;
    }
    if (flag === "Up") {
      toast(
        'No alphabets, spaces or special characters except "+" is allowed in phone number'
      );
      hitApi = false;
    }
    if (flag === "Low") {
      toast("Invalid phone number length");
      hitApi = false;
    }
    if (!validate(lead.summary) && showLeadSection === true) {
      toast("Summary can not be empty");
      hitApi = false;
    }
    if (!validate(lead.techOwner) && showLeadSection === true) {
      toast("Techowner can not be empty");
      hitApi = false;
    }
    if (!validateAction(action.toDo, action.doer)) {
      toast(
        "Fill all the action fields to register an action else leave all action fields empty"
      );
      hitApi = false;
    }
    if (
      validate(contact.name) &&
      validate(contact.email) &&
      validate(contact.salesOwner) &&
      validate(contact.country) &&
      validate(contact.company) &&
      validatePhone(contact.phone) !== "Up" &&
      validatePhone(contact.phone) !== "Low" &&
      validateAction(action.toDo, action.doer)
    ) {
      if (showLeadSection) {
        if (validate(lead.summary) && validate(lead.techOwner)) {
          hitApi = true;
        } else {
          hitApi = false;
          return;
        }
      } else {
        hitApi = true;
      }
    }
    console.log("contact.company", contact.company);
    let temp = {
      name: contact.name,
      email: contact.email,
      country: contact.country,
      company: contact.company,
      phone: contact.phone,
      alternateContact: contact.alternateContact,
      linkedIn: contact.linkedIn,
      address: contact.address,
      source: contact?.source?.name || "",
      salesOwner: contact.salesOwner,
      lead: {
        summary: lead.summary,
        skillsArray: lead.skills,
        techOwner: lead.techOwner,
        description: lead.description,
        status: "lead",
        attributes: [...customLeadAttributes],
      },
    };
    if (!emailRegex.test(temp.email)) {
      toast("Please enter a valid email address");
      return;
    }
    if (!showLeadSection) {
      delete temp.lead;
    }
    if (
      action.toDo !== null &&
      action.doer !== null &&
      action.dueDate !== null &&
      action.toDo !== undefined &&
      action.doer !== undefined &&
      action.dueDate !== undefined &&
      action.toDo !== "" &&
      action.doer !== "" &&
      action.dueDate !== ""
    ) {
      // action.dueDate = handleDateFormat(action.dueDate);
      // createContactShowTimer === true
      //   ? (action.dueTime = handleTimeFormat(action.dueTime))
      //   : (action.dueTime = "00:00:00");
      if (!createContactShowTimer) {
        console.log("duedate string", typeof action.dueDate);
        if (action.dueDate) {
          action.dueDate = new Date(action.dueDate);
        }
        action.dueDate = action.dueDate?.toDateString();
      }
      temp = { ...temp, action };
      // temp.action.dueDate = formatDate(temp.action.dueDate);
      temp.action.dueDate = dateChange(temp.action.dueDate);
      temp.action.schedule = checked;
    }
    console.log("---->4", temp);
    if (typeof temp.company !== "string") {
      console.log(300);
      if (temp.company !== null && temp.company !== undefined) {
        temp.company = temp.company.companyName;
      } else {
        temp.company = "";
      }
    }

    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      return;
    }
    if (showLeadSection) {
      const leadAttributeValidate =
        leadAttributeValidateFunction(customLeadAttributes);
      if (!leadAttributeValidate) {
        return;
      }
    }

    try {
      if (hitApi) {
        setSaveButton(true);
        setLoaderDialog(true);
      }
      console.log("temp", temp);
      const response = hitApi
        ? await createContact({ ...temp, attributes: [...customAttributes] })
        : null;
      if (response.data.status) {
        notify();
        setSaveButton(false);
        setLoaderDialog(false);
        setCreateContactShowTimer(false);
        setContactCreated((prev) => (prev = true));
        afterCreate = !afterCreate;
        setCustomAttributes([]);
        // setCustomLeadAttributes([]);
      }
      console.log(response);
      console.log("after cretae contact response", contact);
      setContact({ company: "" });
      ResetForm();
      setChecked(false);
      setIsAutocomplete(true);
      console.log(temp);
    } catch (error) {
      console.log("Error", error);
      // if (error.response.data.message) {
      //   toast(error.response.data.message);
      // }
      setSaveButton(false);
      setLoaderDialog(false);

      if (error.response.data.message === "Contact already exist!") {
        toast.warn(error.response.data.message);
        return;
      }
      if (
        error.response.data.data[0] ===
        '"lead.summary" is not allowed to be empty'
      ) {
        toast('"summary" is not allowed to be empty');
      } else {
        toast(error.response.data.data[0]);
      }
    }
  };

  useEffect(() => {
    if (loaction?.state) {
      const keys = Object.keys(initialContactState);
      console.log("Keys---", keys);
      const temp = {};
      keys.forEach((key) => {
        temp[key] =
          loaction.state[key] !== undefined ? loaction?.state[key] : "";
      });

      setContact(temp);
    }
  }, [loaction.state]);

  useEffect(() => {
    setCustomLeadAttributes([]);
    (async () => {
      try {
        const response = await getAttributesByEntity("Leads");
        if (response.data.status) {
          setCustomLeadAttributes(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [afterCreate, contactCreated]);
  console.log("resultsss3", contact);
  return (
    <Page>
      <Container>
        <Grid container spacing={4}>
          <Grid item>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${contactDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Info
                      isAutocomplete={isAutocomplete}
                      setContact={setContact}
                      contact={contact}
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      canEdit={isEditPage}
                      fromCreatePage={true}
                      contactCreated={contactCreated}
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      afterCreate={afterCreate}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Dialog open={loaderDialog}>
              <DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                sx={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingBottom: "20px",
                }}
              >
                Loading... Please Wait
              </DialogActions>
            </Dialog>
          </Grid>

          {showLeadSection ? null : (
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setShowLeadSection(true);
                }}
                variant="contained"
              >
                {`Add ${leadDynamicTitle?.displayName.singularName} Requirements`}
              </Button>
            </Grid>
          )}

          {showLeadSection ? (
            <Grid item>
              <Card
                sx={{ backgroundColor: "#f1f2f6" }}
                elevation={5}
                variant="outlined"
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={11.25} sm={11.25}>
                      <Typography variant="h5" gutterBottom color="primary">
                        REQUIREMENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item xs={0.75} sm={0.75}>
                      <Tooltip title="Remove Requirement">
                        <Button
                          varaint="contained"
                          onClick={() => {
                            // setCustomLeadAttributes([]);
                            setShowLeadSection(false);
                          }}
                        >
                          <ClearIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <LeadCreateComponent
                        setLead={setLead}
                        lead={lead}
                        customAttributes={customLeadAttributes}
                        setCustomAttributes={setCustomLeadAttributes}
                        afterCreate={afterCreate}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            false
          )}

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTION
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card elevation={3} variant="outlined">
                      <CardContent>
                        <CreateAction
                          createContactShowTimer={createContactShowTimer}
                          setCreateContactShowTimer={setCreateContactShowTimer}
                          setAction={setAction}
                          action={action}
                          isEditPage={isEditPage}
                          checked={checked}
                          setChecked={setChecked}
                          setPage={() => {}}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <Card style={{marginTop:"20px",display:"flex",flexDirection:"row-reverse"}}> */}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button
          disabled={saveButton}
          onClick={handleCreateContact}
          style={{ marginRight: "28px" }}
          variant="contained"
        >
          CREATE
        </Button>
      </div>
      {/* </Card> */}
    </Page>
  );
};
export default ContactCreate;
