import React from "react";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Page from "../../components/UI/Page";
import {
  Card,
  CardContent,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Switch,
  FormHelperText,
} from "@mui/material";
import ReportAll from "../../components/Reports/ReportAll";
import ReportDrop from "../../components/Reports/ReportDrop";
import { getReport } from "../../services/report.service";
import { getUsersByRoles } from "../../services/users.service";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import { getIncludedCountries } from "../../services/contacts.service";
import Nivopie from "../Nivo/Nivopie";
import Nivobar from "../Nivo/Nivobar";
import Nivoline from "../Nivo/Nivoline";
import { getDropReasons } from "../../services/leads.service";
import { getTechStacks } from "../../services/projects.service";

const Reports = () => {
  const { currentUser } = useContext(AuthContext);
  const initialReportFigures = {
    newLeads: 0,
    prospect: 0,
    opportunity: 0,
    drop: 0,
    close: 0,
  };

  const [popup, setPopup] = useState(false);
  const [interval, setInterval] = useState("Current Month");
  // new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()
  const [colorSeven, setColorSeven] = useState("primary");
  const [colorTwo, setColorTwo] = useState("default");
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [reportInterval, setReportInterval] = useState(null);
  const [customDate, setCustomDate] = useState(false);
  const [showChip, setShowChip] = useState(false);

  const [showReportAll, setShowReportAll] = useState(true);
  const [refreshReportData, setRefreshReportData] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportFigures, setReportFigures] = useState({
    count: initialReportFigures,
    leadCountBySkills: {},
    leadCountByReasons: {},
  });
  const [primaryComparetor, setprimaryComparetor] = useState("Sales Owner");
  const [comparetorList, setComparetorList] = useState([
    "Sales Owner",
    "Status",
    "Country",
  ]);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countries, setCountries] = useState([]);
  const [salesOwners, setSalesOwners] = useState([]);
  const [skills, setSkills] = useState([]);
  const [dropReason, setDropReason] = useState([]);
  const [allChecked, setAllChecked] = useState({
    status: true,
    country: true,
    salesOwner: true,
    skills: true,
    dropReason: true,
  });

  let status = ["NewLead", "Prospect", "Opportunity", "Drop", "Close"];

  const [leadFilter, setLeadFilter] = useState({
    status: [...status],
    country: [...countries],
    salesOwner: [],
    skills: [],
    dropReason: [],
  });

  const [selectedTab, setSelectedTab] = React.useState(0);

  const initialFilters = [
    {
      chartName: "Pie Chart",
      checked: true,
    },
    {
      chartName: "Bar Chart",
      checked: false,
    },
    {
      chartName: "Line Chart",
      checked: false,
    },
  ];
  const [chartFilter, setChartFilter] = useState([...initialFilters]);
  const [selectChartName, setSelectChartName] = useState("Pie Chart");
  const [isCompared, setIsCompared] = React.useState(false);
  const [salesOwnerFigure, setSalesOwnerFigure] = useState([]);

  const afterRemoveChip = () => {
    setCustomDate(false);
    setShowChip(false);
    setStartDate(new Date().setDate(new Date().getDate() - 1));
    setEndDate(new Date());
    setPage(0);
    setInterval("");
    setReportData([]);
    setReportFigures({
      count: initialReportFigures,
      leadCountBySkills: {},
      leadCountByReasons: {},
    });
  };

  const handleChange = (e) => {
    console.log(e.target.value, "my value");
    if (e.target.name === "interval") {
      if (e.target.value !== "Custom" && interval === "Custom") {
        toast("Close the chip to select another interval");
        return;
      }
      if (e.target.value === "Custom") {
        setEndDate(new Date());
        setPopup(true);
      }
      setInterval(e.target.value);
    }
  };
  console.log(interval, "my value");

  const handleComparetor = (e) => {
    setIsCompared(false);
    setprimaryComparetor(e.target.value);
  };

  const checkWhichDropdown = (name) => {
    if (name === "status") {
      return status;
    } else if (name === "country") {
      return countries;
    } else if (name === "salesOwner") {
      const temp = [];
      salesOwners.forEach((item, index) => {
        if (item.active_status === true) {
          temp.push(item._id);
        }
      });
      return temp;
    } else if (name === "skills") {
      const temp = [];
      skills.forEach((item, index) => {
        temp.push(item._id);
      });
      return temp;
    } else if (name === "dropReason") {
      const temp = [];
      dropReason.forEach((item, index) => {
        temp.push(item?._id);
      });
      return temp;
    } else return null;
  };

  const handleOnlyOneSalesOwner = (e) => {
    setLeadFilter((prevState) => {
      const temp = { ...prevState };
      temp.salesOwner = [e.target.value];
      return temp;
    });
  };

  const handleLeadFilter = (e) => {
    console.log("handle Lead Filter", e.target);
    if (
      e.target.value.filter((i) => i !== "ALL").length ===
        checkWhichDropdown(e.target.name).length &&
      e.target.value[e.target.value.length - 1] === "ALL"
    ) {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = false;
        return temp;
      });

      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = [];
        return temp;
      });

      return;
    }

    setLeadFilter((prevState) => {
      const temp = { ...prevState };
      temp[e.target.name] =
        typeof e.target.value === "string"
          ? e.target.value
              .filter((i) => {
                return i !== "ALL";
              })
              .split(",")
          : e.target.value.filter((i) => {
              return i !== "ALL";
            });
      return temp;
    });

    if (
      e.target.value.filter((i) => i !== "ALL").length ===
        checkWhichDropdown(e.target.name).length ||
      e.target.value[0] === "ALL" ||
      e.target.value.includes("ALL")
    ) {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = true;
        return temp;
      });
      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = [...checkWhichDropdown(e.target.name)];
        return temp;
      });
    } else {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = false;
        return temp;
      });
    }
  };

  const handleSalesOwnerForRender = (value) => {
    // Filter out only active sales owners
    const onlyActiveSalesOwners = salesOwners?.filter(
      (user) => user?.active_status
    );

    // If there's only one value, find and return its name
    if (value.length === 1) {
      const matchedOwner = salesOwners?.find((e) => e._id === value[0]);
      return matchedOwner ? matchedOwner?.name : "";
    }

    // If all active sales owners are selected, return "ALL"
    if (value.length === onlyActiveSalesOwners.length) {
      return ["ALL"];
    }

    // Find and collect names for selected IDs
    const selectedNames = salesOwners
      .filter((user) => value.includes(user._id))
      .map((user) => user.name);

    return selectedNames.join(", ");
  };

  const handleSkillsForRender = (value) => {
    // If there's only one value, find and return its name
    if (value.length === 1) {
      const matchedOwner = skills?.find((e) => e._id === value[0]);
      return matchedOwner ? matchedOwner?.name : "";
    }

    if (value.length === skills.length) {
      return ["ALL"];
    }

    // Find and collect names for selected IDs
    const selectedNames = skills
      ?.filter((skill) => value?.includes(skill?._id))
      ?.map((skill) => skill?.name);

    return selectedNames?.join(", ");
  };

  const handleDropReasonForRender = (value) => {
    console.log("handleDropReasonForRender", value, dropReason);
    // If there's only one value, find and return its name
    if (value.length === 1) {
      const matchedOwner = dropReason?.find((e) => e._id === value[0]);
      return matchedOwner ? matchedOwner?.name : "";
    }

    if (value.length === dropReason.length) {
      return ["ALL"];
    }

    // Find and collect names for selected IDs
    const selectedNames = dropReason
      ?.filter((option) => value?.includes(option?._id))
      ?.map((option) => option?.name);

    return selectedNames?.join(", ");
  };

  const handlePageChange = (e, newPage) => {
    console.log("newPage", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > reportData.length &&
      reportData.length < pageCount
    ) {
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  //format Date

  function dateChange(date) {
    console.log(date, "last month");
    const dateTimeInSeconds = new Date(date).getTime();
    const offSet = new Date().getTimezoneOffset();
    const offSetInSeconds = offSet * 60 * 1000;
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    const newDate = new Date(newDateInSeconds);
    const newDateFormat = formatDate(newDate);
    console.log(newDateFormat, "last month");
    return newDateFormat;
  }

  function formatDate(date) {
    console.log("date", date);
    date = new Date(date);

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":") +
      // ":00" +
      ".000Z"
    );
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function getQuarterDates(interval) {
    const today = new Date();
    let year = today.getFullYear();
    const month = today.getMonth();
    let startMonth, endMonth;
    let startDate, endDate;
    console.log("checkquater", year);

    if (interval === "Current Quarter") {
      if (month >= 0 && month <= 2) {
        startMonth = 0;
        endMonth = month;
      } else if (month >= 3 && month <= 5) {
        startMonth = 3;
        endMonth = month;
      } else if (month >= 6 && month <= 8) {
        startMonth = 6;
        endMonth = month;
      } else {
        startMonth = 9;
        endMonth = month;
      }
    } else if (interval === "Last Quarter") {
      console.log(month, "checkquater");
      if (month >= 0 && month <= 2) {
        startMonth = 9;
        endMonth = 11;
        year -= 1;
      } else if (month >= 3 && month <= 5) {
        startMonth = 0;
        endMonth = 2;
      } else if (month >= 6 && month <= 8) {
        startMonth = 3;
        endMonth = 5;
      } else {
        startMonth = 6;
        endMonth = 8;
      }
    } else if (interval === "Current Month") {
      startDate = new Date(year, month, 1);
      endDate = today;
      return { startDate, endDate };
    } else if (interval === "Last Month") {
      const lastMonth = month === 0 ? 11 : month - 1;
      const lastMonthYear = month === 0 ? year - 1 : year;
      startDate = new Date(lastMonthYear, lastMonth, 1);
      endDate = new Date(lastMonthYear, lastMonth + 1, 0, 23, 59, 59, 999);
      startDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      );
      endDate = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      );
      console.log("last month", startDate, endDate);
      return { startDate, endDate };
    }
    console.log(startMonth, year, endMonth, "checkquater");

    startDate = new Date(year, startMonth, 1);
    endDate =
      interval === "Current Quarter" ? today : new Date(year, endMonth + 1, 0);

    return { startDate, endDate };
  }

  //Api Calls

  const handleIntervals = (intervals) => {
    if (!interval) {
      toast("Interval is mandatory");
      return;
    }
    if (intervals !== "Custom") {
      console.log(intervals, "checkquater");
      if (intervals === "Last Quarter") {
        let { startDate, endDate } = getQuarterDates(intervals);

        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        console.log("filter---->", filter);
        getReportData(filter, onLoad, pageCount);
        return;
      } else if (intervals === "Current Quarter") {
        let { startDate, endDate } = getQuarterDates(intervals);

        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        console.log("filter---->", filter, { startDate, endDate });
        getReportData(filter, onLoad, pageCount);
        return;
      } else if (intervals === "Current Month") {
        let { startDate, endDate } = getQuarterDates(intervals);
        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        console.log("filter---->", filter, { startDate, endDate });
        getReportData(filter, onLoad, pageCount);
        return;
      } else if (intervals === "Last Month") {
        let { startDate, endDate } = getQuarterDates(intervals);
        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        getReportData(filter, onLoad, pageCount);
        return;
      }

      const filter = { from: dateChange(interval), to: "" };
      const onLoad = false;
      console.log("filter---->", filter);
      getReportData(filter, onLoad, pageCount);
    } else {
      console.log("check quater  else");

      console.log("showDateGrid--", startDate, endDate);
      const filter = { from: dateChange(startDate), to: dateChange(endDate) };
      const onLoad = false;
      getReportData(filter, onLoad, pageCount);
    }
  };

  const getReportData = async (filter, onLoad, pageCount) => {
    if (!filter?.to) {
      const date = new Date();
      filter.to = dateChange(date);
    }
    try {
      // Create a copy of leadFilter
      const sanitizedLeadFilter = { ...leadFilter };

      if (selectedTab === 1) {
        delete sanitizedLeadFilter.salesOwner;
        delete sanitizedLeadFilter.status;
      }

      // we only need dropReason when user selects drop report
      if (selectedTab !== 2) {
        delete sanitizedLeadFilter.dropReason;
      }

      // we don't need skills when users selects drop report
      if (selectedTab === 2) {
        delete sanitizedLeadFilter.skills;
      }

      // when user select skillset tab then we have to remove these dropdowns
      // if (selectedTab === 1) {
      //   delete sanitizedLeadFilter.salesOwner;
      //   delete sanitizedLeadFilter.status;
      // }

      // Get the data from APIs
      const response = await getReport(
        filter,
        onLoad,
        pageCount,
        sanitizedLeadFilter
      );

      if (response?.data?.status) {
        setPage(0);
        let allLeads = [];

        if (
          leadFilter?.status?.length === 1 &&
          leadFilter?.status[0] === "NewLead"
        ) {
          allLeads = response?.data?.data?.newLeads;
        } else {
          allLeads = [
            ...response?.data?.data?.allLeads,
            ...response?.data?.data?.newLeads,
          ];
        }

        allLeads?.length
          ? toast("Lead report fetched successfully")
          : toast("No match found");

        setReportData(allLeads);
        setCount(allLeads?.length);
        setIsCompared(false);

        // used object.assign to get all the required keys for bar chart

        setReportFigures({
          count: Object.assign(
            {},
            initialReportFigures,
            response?.data?.data?.count
          ),
          leadCountBySkills: response?.data?.data?.leadCountBySkills || {
            reactjs: 1,
            python: 2,
            nodejs: 5,
            mongodb: 3,
            java: 10,
          },
          leadCountByReasons: response?.data?.data?.leadCountByReasons || {
            "low budget": 3,
            "no resource": 5,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCountries = async () => {
    try {
      const response = await getIncludedCountries();
      if (response?.data?.status) {
        setLeadFilter((prevState) => ({
          ...prevState,
          country: [...response.data.data?.filter((d) => d !== "")],
        }));
        setCountries(
          (prev) => (prev = response.data.data?.filter((d) => d !== ""))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesOwners = async () => {
    try {
      const response =
        currentUser.role === "ADMIN" && (await getUsersByRoles(3));
      if (response?.data?.status) {
        response.data?.data?.forEach((sw) => {
          if (sw.active_status === true) {
            setLeadFilter((prevState) => {
              const temp = { ...prevState };
              temp.salesOwner.push(sw._id);
              return temp;
            });
          }
        });
        setSalesOwners(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSkills = async () => {
    try {
      const response = await getTechStacks();
      response?.data?.data?.forEach((sw) => {
        setLeadFilter((prevState) => {
          const temp = { ...prevState };
          temp.skills.push(sw?._id);
          return temp;
        });
      });
      setSkills(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDropReason = async () => {
    try {
      const response = await getDropReasons();
      console.log(response?.data?.data, "drop reason");
      response?.data?.data?.forEach((sw) => {
        setLeadFilter((prevState) => {
          const temp = { ...prevState };
          temp.dropReason.push(sw?._id);
          return temp;
        });
      });
      setDropReason(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleChartSwitch(pIndex, chartName) {
    setSelectChartName(chartName);
    let newFilterers = chartFilter?.map(({ chartName }, index) => {
      return { chartName: chartName, checked: pIndex === index };
    });
    setChartFilter(newFilterers);
  }

  useEffect(() => {
    getAllCountries();
    getSalesOwners();
    getSkills();
    getDropReason();
    if (currentUser && currentUser?.role === "SALES") {
      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp.salesOwner = [currentUser?._id];
        return temp;
      });
    }
  }, []);

  const isLengthValid = (array, condition) => {
    return array.length === 2 || array.length === 3 || array.length === 4;
  };
  const isWidthLengthValid = (array) => array.length === 3;

  console.log(salesOwnerFigure, " myowners");

  return (
    <Page>
      <Container>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            // sx={{ marginTop: "10px" }}
          >
            {/* <Card elevation={3} variant="outlined"> */}
            {/* <CardContent> */}
            {showReportAll ? (
              <ReportAll
                // after
                showChip={showChip}
                setShowChip={setShowChip}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                afterRemoveChip={afterRemoveChip}
                handleChange={handleChange}
                popup={popup}
                setPopup={setPopup}
                customDate={customDate}
                setCustomDate={setCustomDate}
                leadFilter={leadFilter}
                handleLeadFilter={handleLeadFilter}
                status={status}
                countries={countries}
                salesOwners={salesOwners}
                skills={skills}
                dropReason={dropReason}
                handleSalesOwnerForRender={handleSalesOwnerForRender}
                handleSkillsForRender={handleSkillsForRender}
                handleDropReasonForRender={handleDropReasonForRender}
                allChecked={allChecked}
                setAllChecked={setAllChecked}
                currentUser={currentUser}
                handleOnlyOneSalesOwner={handleOnlyOneSalesOwner}
                //before
                reportData={reportData}
                setReportData={setReportData}
                interval={interval}
                setInterval={setInterval}
                initialReportFigures={initialReportFigures}
                reportFigures={reportFigures}
                setReportFigures={setReportFigures}
                handleIntervals={handleIntervals}
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            ) : (
              <ReportDrop
                reportData={reportData}
                handleChange={handleChange}
                interval={interval}
                setInterval={setInterval}
                reportFigures={reportFigures}
                popup={popup}
                setPopup={setPopup}
                showChip={showChip}
                setShowChip={setShowChip}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                handleIntervals={handleIntervals}
              />
            )}
            {/* </CardContent> */}
            {/* </Card> */}
          </Grid>
        </Grid>
        {/* {reportData?.length > 0 && (
          <Grid item xs={12} sx={{ marginTop: "30px" }}>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={8.5} sx={{ alignSelf: "center" }}>
                    <Grid container spacing={1}>
                      {chartFilter?.map(({ chartName, checked }, index) => {
                        return (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              key={index}
                              variant={checked ? "filled" : "outlined"}
                              clickable
                              label={chartName}
                              color={checked ? "primary" : "default"}
                              onClick={() => {
                                handleChartSwitch(index, chartName);
                              }}
                              sx={{
                                border: checked
                                  ? "3px solid #3366FF"
                                  : "3px solid #fff",
                                borderRadius: "62px",
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3.5}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <FormControl fullWidth sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-dateRange-select-label">
                          Primary Comparetor
                        </InputLabel>
                        <Select
                          labelId="demo-dateRange-select-label"
                          id="demo-dateRange-select"
                          label="Primary Comparetor"
                          name="interval"
                          value={primaryComparetor}
                          onChange={handleComparetor}
                        >
                          {comparetorList?.map((interval, index) => {
                            return (
                              <MenuItem key={index} value={interval}>
                                {interval}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isCompared}
                              onChange={handleCompare}
                              disabled={
                                primaryComparetor === "Sales Owner"
                                  ? !isLengthValid(leadFilter.salesOwner)
                                  : primaryComparetor === "Country"
                                  ? !isLengthValid(leadFilter.country)
                                  : primaryComparetor === "Status"
                                  ? !isLengthValid(leadFilter.status)
                                  : true 
                              }
                              name="jason"
                            />
                          }
                          label="Compare"
                        />
                        <FormHelperText>
                          {!isCompared
                            ? "*Atleast select 2 to 4 for compare"
                            : null}
                        </FormHelperText>
                      </FormGroup>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )} */}
        {/* {!isCompared ? (
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ marginTop: "-2px" }}>
              {reportData?.length > 0 ? (
                <Card elevation={3} variant="outlined" sx={{ marginTop: 3 }}>
                  <CardContent sx={{ marginTop: 2 }}>
                    {selectChartName === "Pie Chart" ? (
                      <Nivopie
                        reportFigures={reportFigures}
                        reportData={reportData}
                      />
                    ) : selectChartName === "Bar Chart" ? (
                      <Nivobar
                        reportFigures={reportFigures}
                        reportData={reportData}
                      />
                    ) : (
                      <Nivoline
                        reportFigures={reportFigures}
                        reportData={reportData}
                      />
                    )}
                  </CardContent>
                </Card>
              ) : (
                <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <Typography
                    textAlign="center"
                    fontWeight="700"
                    color="#857C7A"
                  >
                    {`Please fetch all the records to show charts`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent={
              (primaryComparetor === "Sales Owner" &&
                isWidthLengthValid(leadFilter.salesOwner)) ||
              (primaryComparetor === "Country" &&
                isWidthLengthValid(leadFilter.country)) ||
              (primaryComparetor === "Status" &&
                isWidthLengthValid(leadFilter.status))
                ? "center"
                : undefined
            }
          >
            {reportData.length > 0 ? (
              salesOwnerFigure.length &&
              salesOwnerFigure.map(({ salesOwner, reportFigures }) => (
                <Grid
                  xs={
                    leadFilter.salesOwner.length === 2
                      ? 6
                      : leadFilter.salesOwner.length === 3
                      ? 6
                      : 6
                  }
                  sx={{
                    width:
                      (primaryComparetor === "Sales Owner" &&
                        isWidthLengthValid(leadFilter.salesOwner)) ||
                      (primaryComparetor === "Country" &&
                        isWidthLengthValid(leadFilter.country)) ||
                      (primaryComparetor === "Status" &&
                        isWidthLengthValid(leadFilter.status))
                        ? 1 / 2
                        : undefined,
                  }}
                  item
                >
                  <Card elevation={3} variant="outlined" sx={{ marginTop: 3 }}>
                    <CardContent sx={{ m: 1 }}>
                      {selectChartName === "Pie Chart" ? (
                        <Nivopie
                          reportFigures={reportFigures}
                          reportData={reportData}
                          isCompared={isCompared}
                          salesOwnerName={salesOwner}
                          status={leadFilter.status}
                          countries={leadFilter.country}
                          selectOwnerList={leadFilter.salesOwner}
                          OwnersNameList={salesOwners}
                          primaryComparetor={primaryComparetor}
                        />
                      ) : selectChartName === "Bar Chart" ? (
                        <Nivobar
                          reportFigures={reportFigures}
                          reportData={reportData}
                          isCompared={isCompared}
                          salesOwnerName={salesOwner}
                          status={leadFilter.status}
                          countries={leadFilter.country}
                          selectOwnerList={leadFilter.salesOwner}
                          OwnersNameList={salesOwners}
                          primaryComparetor={primaryComparetor}
                          salesOwnerFigure={salesOwnerFigure}
                        />
                      ) : (
                        <Nivoline
                          reportFigures={reportFigures}
                          reportData={reportData}
                          isCompared={isCompared}
                          countries={leadFilter.country}
                          selectOwnerList={leadFilter.salesOwner}
                          OwnersNameList={salesOwners}
                          salesOwnerName={salesOwner}
                          status={leadFilter.status}
                          primaryComparetor={primaryComparetor}
                          salesOwnerFigure={salesOwnerFigure}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sx={{ marginTop: 4 }}>
                <Typography textAlign="center" fontWeight="700" color="#857C7A">
                  {`Please fetch all the records to show charts`}
                </Typography>
              </Grid>
            )}
          </Grid>
        )} */}
      </Container>
    </Page>
  );
};

export default Reports;
