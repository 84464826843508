import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Teams from "../Teams/Teams";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CardContent,
} from "@mui/material";
// components
import Page from "../UI/Page";
import Label from "../UI/Label";
import Scrollbar from "../UI/Scrollbar";
import Iconify from "../UI/Iconify";
import SearchNotFound from "../SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../sections/@dashboard/user";
// mock
import USERLIST from "../../_mock/user";
import CommentView from "../Shared/Comments/Comment.View";
import CreateComment from "../Shared/Comments/Comment.Create";
import CreateAction from "../Shared/Action/Actions.Create";
import { getActions as getContactActions } from "../../services/contacts.service";
import { getActions as getLeadActions } from "../../services/leads.service";
import { getActions as getProjectActions } from "../../services/projects.service";

import constants from "../../constants";
import CreateTeam from "./CreateTeam";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "ownedBy", label: "Owned By", alignRight: false },
  { id: "last", label: "Last", alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  // { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TeamSection({
  contactInfoObj,
  action,
  setAction,
  initialActionState,
  isProjectEditPage,
  setLoadedActions,
  actionType,
  id,
  leadId,
  projectId,
  resource,
  setResource,
  toEdit,
  setToEdit,
  tempProjectTeam,
  setTempProjectTeam,
  tarf,
  teamSingle,
  setTeamSingle,
  setIsProjectFetched,
  currentUser,
  project,
  setIactionedApiCalled,
}) {
  console.log("Loaded actions", resource);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  //   const [addedActionCounter, setAddedActionCounter] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  let obj = contactInfoObj || {};

  console.log("setIsProjectFetched", setIsProjectFetched);

  //   const handleRequestSort = (event, property) => {
  //     const isAsc = orderBy === property && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(property);
  //   };
  //   console.log("leadId", leadId);
  //   console.log(isProjectEditPage);

  //   const handleSelectAllClick = (event) => {
  //     if (event.target.checked) {
  //       const newSelecteds = USERLIST.map((n) => n.name);
  //       setSelected(newSelecteds);
  //       return;
  //     }
  //     setSelected([]);
  //   };

  const handleClick = (event, name) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  //   const handleChangePage = (event, newPage) => {
  //     console.log("Inside handle change page", newPage);
  //     if (
  //       rowsPerPage * (parseInt(newPage) + 1) >
  //       resource.length /*&&
  //       loadedComments.length < count*/
  //     ) {
  //       console.log("Fetching new data");
  //       setPageCount((prevState) => prevState + 1);
  //     }
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  //   const handleFilterByName = (event) => {
  //     setFilterName(event.target.value);
  //   };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  let filteredUsers;
  if (resource.length > 0) {
    filteredUsers = applySortFilter(
      resource,
      getComparator(order, orderBy),
      filterName
    );
  }

  //   function FetchActions(pageCount = 1) {
  //     if (actionType === constants.LEADS) {
  //       getLeadActions(id, leadId, pageCount)
  //         .then((response) => {
  //           console.log("Response of lead actions", response);
  //           if (response.data.status) {
  //             setLoadedActions((prevState) => [
  //               ...prevState,
  //               ...response.data.data.leadActions,
  //             ]);
  //             console.log(resource);
  //             setCount(response.data.data.count);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //     if (actionType === constants.CONTACTS) {
  //       getContactActions(id, pageCount)
  //         .then((response) => {
  //           if (response.data.status) {
  //             console.log("resource", response.data);
  //             setLoadedActions((prevState) => [
  //               ...prevState,
  //               ...response.data.data.allActions,
  //             ]);

  //             setCount(response.data.data.count);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //     if (actionType === constants.PROJECTS) {
  //       getProjectActions(projectId, pageCount)
  //         .then((response) => {
  //           console.log("Response of project actions", response);
  //           if (response.data.status) {
  //             setLoadedActions((prevState) => [
  //               ...prevState,
  //               ...response.data.data.projectActions,
  //             ]);
  //             console.log(resource);
  //             setCount(response.data.data.count);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }
  //   }

  useEffect(() => {
    console.log("Use effect triggered");
    // FetchActions(pageCount);
  }, []);

  // const isUserNotFound = filteredUsers.length === 0;
  // const hasActions = obj && obj.actions && obj.actions.length > 0;

  return (
    <>
      <Card elevation={3} variant="outlined">
        <CardContent>
          <CreateTeam
            teamSingle={teamSingle}
            setTeamSingle={setTeamSingle}
            projectId={projectId}
            resource={resource}
            setResource={setResource}
            setIsProjectFetched={setIsProjectFetched}
            currentUser={currentUser}
            project={project}
            setIactionedApiCalled={setIactionedApiCalled}
          />
          <div>
            <br />
          </div>
          <Scrollbar>
            <TableContainer>
              <Table>
                {resource.length > 0 ? (
                  <TableBody>
                    {resource
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        // console.log("rowdata", row.doer.doerId);
                        // const { userId, assignedRole, contribution } = row;

                        const isItemSelected =
                          selected.indexOf(row.name) !== -1;

                        return (
                          <TableRow
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell>
                              <Teams
                                // key={id}
                                index={index}
                                projectId={projectId}
                                row={row}
                                setIsProjectFetched={setIsProjectFetched}
                                setResource={setResource}
                                project={project}
                                currentUser={currentUser}
                                setIactionedApiCalled={setIactionedApiCalled}
                              ></Teams>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {/* <SearchNotFound searchQuery={filterName} /> */}
                        No Resources Available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          {/* {hasActions && ( */}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          {/* )} */}
        </CardContent>
      </Card>
    </>
  );
}
