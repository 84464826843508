import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  callHippoCredential,
  getCallHippoCredential,
  updateCallHippoCredential,
} from "../../services/callHippoCredentials.service";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

function CallHippoCredentials() {
  const [isLoaderEnable, setIsLoaderEnable] = useState(false);

  const [isDialougeOpen, setIsDialougeOpen] = useState(false);
  const initialCredentials = {
    apiToken: "",
    emailId: "",
  };
  const [credentialDetails, setCredentialDetails] =
    useState(initialCredentials);
  const [tempcredentialDetails, setTempcredentialDetails] =
    useState(initialCredentials);
  const [fetchCredentialDetails, setFetchCredentialDetails] = useState(null);
  const [isCredentialPresent, setIsCredentialPresent] = useState(false);
  const [credentialId, setCredentialId] = useState("");
  const checkPureData = (data) => {
    if (
      data !== undefined &&
      data !== null &&
      typeof data === "string" &&
      data.trim() !== ""
    ) {
      return true;
    }

    return false;
  };

  const handleDialouge = () => {
    setIsDialougeOpen((prev) => !prev);
    fetchCredentials();
  };

  const handleCredentialsChange = (event) => {
    const { name, value } = event.target;
    if (value && !value?.trim()) {
      if (name === "apiToken") {
        toast.warn("API Token can not contain only space");
        return;
      }
      toast.warn("Email can not contain only space");
      return;
    }

    setCredentialDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handelCredentials = async () => {
    const trimmedApiToken = credentialDetails.apiToken.trim();
    const trimmedEmail = credentialDetails.emailId.trim();

    if (!checkPureData(trimmedApiToken)) {
      toast("API Token can not be empty");
      return;
    }
    if (!checkPureData(trimmedEmail)) {
      toast("Email can not be empty");
      return;
    }
    if (
      trimmedApiToken === tempcredentialDetails.apiToken.trim() &&
      trimmedEmail === tempcredentialDetails.emailId.trim()
    ) {
      toast("Nothing to update");
      return;
    }
    const data = {
      ...credentialDetails,
      apiToken: trimmedApiToken,
      emailId: trimmedEmail,
    };
    try {
      console.log(data, "data");
      const response = await callHippoCredential(data);
      if (response.data.status) {
        toast("Credential Stored Successfully");
        setCredentialDetails(initialCredentials);
        fetchCredentials();
        setIsDialougeOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateCredentials = async () => {
    const trimmedApiToken = credentialDetails.apiToken.trim();
    const trimmedEmail = credentialDetails.emailId.trim();

    if (!checkPureData(trimmedApiToken)) {
      toast("API Token can not be empty");
      return;
    }
    if (!checkPureData(trimmedEmail)) {
      toast("Email can not be empty");
      return;
    }

    if (
      trimmedApiToken === tempcredentialDetails.apiToken.trim() &&
      trimmedEmail === tempcredentialDetails.emailId.trim()
    ) {
      toast("Nothing to update");
      return;
    }
    const data = {
      ...credentialDetails,
      apiToken: trimmedApiToken,
      emailId: trimmedEmail,
    };
    try {
      console.log(data, "data");
      const response = await updateCallHippoCredential(credentialId, data);
      if (response.data.status) {
        toast("Credential Updated Successfully");
        setCredentialDetails(initialCredentials);
        fetchCredentials();
        setIsDialougeOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("fetchCredentialDetails", fetchCredentialDetails);
  const fetchCredentials = async () => {
    setIsLoaderEnable(true);
    try {
      const response = await getCallHippoCredential();
      if (response.data.status) {
        const { apiToken, emailId, _id } = response.data.data;
        console.log(response.data, "data");
        setIsLoaderEnable(false);
        setCredentialDetails((prev) => ({
          apiToken,
          emailId,
        }));
        setTempcredentialDetails((prev) => ({
          apiToken,
          emailId,
        }));
        setFetchCredentialDetails((prev) => ({
          apiToken,
          emailId,
        }));
        setCredentialId(_id);
        setIsCredentialPresent(true);
      }
    } catch (error) {
      console.log(error);
      setIsCredentialPresent(false);
      setIsLoaderEnable(false);
    }
  };
  useEffect(() => {
    fetchCredentials();
  }, []);
  const truncateValue = (value, maxLength) => {
    return value.length > maxLength ? value.slice(0, maxLength) + "..." : value;
  };
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 4 }}>
        <Grid xs={isCredentialPresent ? 12 : 10}>
          {isLoaderEnable ? (
            <LinearProgress sx={{ marginTop: "25px" }} />
          ) : isCredentialPresent ? (
            <Card>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Grid item xs={2}>
                    <Typography variant="h6">Call Credential</Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    <Typography>
                      <span style={{ color: "#2056D1", marginRight: "12px" }}>
                        API Token :{" "}
                      </span>
                      {truncateValue(fetchCredentialDetails?.apiToken, 30)}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    <Typography>
                      <span style={{ color: "#2056D1", marginRight: "12px" }}>
                        Email :
                      </span>
                      {truncateValue(fetchCredentialDetails?.emailId, 30)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => setIsDialougeOpen((prev) => !prev)}
                      sx={{
                        "&:hover": { color: "#2065D1" },
                        marginLeft: "40%",
                      }}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <Typography
              textAlign="center"
              fontWeight="700"
              color="#857C7A"
              sx={{ marginTop: 1.5 }}
            >
              {`Please Submit Your Call Hippo Credential`}
            </Typography>
          )}
        </Grid>
        <Grid xs={isCredentialPresent ? 0 : 2}>
          {isCredentialPresent ? null : ( // </Button> //   Edit Credential // > //   sx={{ marginLeft: 3, marginTop: 2 }} //   onClick={() => setIsDialougeOpen((prev) => !prev)} //   size="medium" //   variant="contained" // <Button
            <Button
              variant="contained"
              size="medium"
              onClick={() => setIsDialougeOpen((prev) => !prev)}
              // startIcon={<ManageSearchIcon />}
            >
              Add Credential
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog maxWidth="lg" open={isDialougeOpen} onClose={() => {}}>
        <DialogTitle>
          {credentialDetails.apiToken !== "" && credentialDetails.emailId !== ""
            ? "Edit Credential"
            : "Add Credential"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} sx={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <TextField
                label="API Token"
                variant="outlined"
                placeholder="API Token"
                type="text"
                name="apiToken"
                required
                value={credentialDetails.apiToken}
                fullWidth
                onChange={handleCredentialsChange}
                sx={{ minWidth: "400px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                placeholder="Email"
                type="text"
                name="emailId"
                required
                value={credentialDetails.emailId}
                fullWidth
                onChange={handleCredentialsChange}
                sx={{ minWidth: "400px" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialouge}>
            CANCEL
          </Button>
          <Button
            disabled={false}
            onClick={
              isCredentialPresent ? handleUpdateCredentials : handelCredentials
            }
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CallHippoCredentials;
