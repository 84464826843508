import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import Page from "../components/UI/Page";
import { useState, useEffect } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { green } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import ManageRoles from "../components/ManageRoles";
import Iconify from "../components/UI/Iconify";
import {
  changeStatus,
  createRole,
  createRoles,
  getRoles,
  updateRole,
} from "../services/roles.service";
import { spread } from "lodash";

let hitApi = true;

const Roles = () => {
  const [rolesFromApi, setRolesFromApi] = useState([]);
  const [tempRolesFromApi, setTempRolesFromApi] = useState([]);
  const [roleNameWithIndex, setRoleNameWithIndex] = useState([]);
  const [roleFetched, setRoleFetched] = useState(false);
  const initialRoleState = {
    roleName: "",
    modules: [
      {
        read: false,
        write: false,
        manage: false,
        moduleName: "DASHBOARD",
      },
      {
        read: false,
        write: false,
        manage: false,
        moduleName: "USERS",
      },
      {
        read: false,
        write: false,
        manage: false,
        moduleName: "ROLES",
      },
      {
        read: false,
        write: false,
        manage: false,
        moduleName: "CONTACTS",
      },
      {
        read: false,
        write: false,
        manage: false,
        moduleName: "LEADS",
      },
      {
        read: false,
        write: false,
        manage: false,
        moduleName: "PROJECTS",
      },
    ],
  };
  const [rolesPopup, setRolesPopup] = useState(initialRoleState);

  const Dashboard = {
    View: true,
  };
  const initialAccessModulesState = [
    {
      Users: {
        Create: false,
        Edit: false,
        View: false,
      },
    },
    {
      Roles: {
        Create: false,
        Edit: false,
        View: false,
      },
    },
    {
      Companies: {
        Create: false,
        Edit: false,
        View: false,
        AddAction: false,
        MarkAsDone: false,
        AddComment: false,
        UploadDocument: false,
        UploadPrivateDocument: false,
        ViewPrivateDocument: false,
        DeleteDocument: false,
        DeletePrivateDocument: false,
        CreateContact: false,
        ViewContact: false,
      },
    },
    {
      Contacts: {
        Create: false,
        Edit: false,
        View: false,
        AddAction: false,
        MarkAsDone: false,
        AddComment: false,
        CreateLead: false,
        ViewLead: false,
      },
    },
    {
      Leads: {
        Create: false,
        Edit: false,
        View: false,
        AddAction: false,
        MarkAsDone: false,
        AddComment: false,
        UploadDocument: false,
        UploadPrivateDocument: false,
        ViewPrivateDocument: false,
        DeleteDocument: false,
        DeletePrivateDocument: false,
        PriceNegotiation: false,
        EmailAssociation: false,
      },
    },
    {
      Projects: {
        Create: false,
        Edit: false,
        View: false,
        AddResource: false,
        ViewResource: false,
        EditResource: false,
        ViewProjectStatus: false,
        EditProjectStatus: false,
        AddAction: false,
        AddComment: false,
        UploadDocument: false,
        ViewPrivateDocument: false,
        UploadPrivateDocument: false,
        DeleteDocument: false,
        DeletePrivateDocument: false,
      },
    },
    {
      Report: {
        View: false,
      },
    },
    {
      Email: {
        EmailSync: false,
        EmailCompose: false,
      },
    },
    {
      Archives: {
        RestoreDocument: false,
      },
    },
    {
      Call: {
        Calling: false,
      },
    },
    {
      Email_Template: {
        Create: false,
        Edit: false,
        View: false,
      },
    },
  ];

  const [accessRoles, setAccessRoles] = useState(initialAccessModulesState);
  const [roleName, setRoleName] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [saveButton, setSaveButton] = useState(false);

  const [popup, setPopup] = useState(false);
  // const [roleName, setRoleName] = React.useState("");

  const [isEditable, setIsEditable] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });

  // const handleStatusChange = async (e, index) => {
  //   console.log(
  //     rolesFromApi[index].active_status,
  //     tempRolesFromApi[index].active_status
  //   );
  //   console.log(e.target.checked);
  //   const temp = [...rolesFromApi];
  //   temp[index].active_status = e.target.checked;
  //   setRolesFromApi(temp);
  //   if (
  //     rolesFromApi[index].active_status !==
  //     tempRolesFromApi[index].active_status
  //   ) {
  //     const id = temp[index]._id;
  //     const response = await changeStatus(id);
  //     if (response.data.status) {
  //       toast("Status changed successfully");
  //       const temp = [...rolesFromApi];
  //       temp[index].isOnEdit = false;
  //       setRolesFromApi(temp);
  //       setRoleFetched(false);
  //     }
  //   }
  // };

  // const handleChange = (id) => (event, isExpanded) => {
  //   // console.log("isexpanded", isExpanded);
  //   setOpen(isExpanded);                                               //DOUBTS
  //   setExpanded(isExpanded ? id : false);
  //   console.log("Executed");
  // };

  // const handleUpdateCheck = () => {
  //     for (let i = 0; i < rolesFromApi.length; i++) {
  //         rolesFromApi[i]
  //     }
  // }

  const handleAccordionExpand = (event, index) => {
    setRoleNameWithIndex((prev) => {
      const temp = [...prev];
      temp[index]["accordionExpand"] = !temp[index]["accordionExpand"];
      return temp;
    });
  };

  const handleCancel = (index) => {
    console.log("first");
    setRoleNameWithIndex((prev) => {
      const temp = [...prev];
      temp[index]["accessToEdit"] = false;
      return temp;
    });
    setRoleFetched(false);
  };

  const handleRolesForUpdate = (event, index, module, key, innerInd) => {
    // setRolesFromApi((prev) => {
    //   const temp = [...prev];
    //   temp[index][innerInd][module][key] = event.target.checked;
    //   return temp;
    // });

    const temp = [...rolesFromApi];
    temp[index][innerInd][module][key] = event.target.checked;
    setRolesFromApi([...temp]);
  };

  // const handleCheckboxChange = (e, index, innerIndex) => {
  //   console.log("handleCheckboxChange Index", index, innerIndex);
  //   const temp = [...rolesFromApi];
  //   let tempName = e.target.name;
  //   let tempValue = e.target.checked;
  //   if (tempValue) {
  //     if (tempName === "manage" && tempValue === true) {
  //       temp[index].modules[innerIndex][tempName] = tempValue;
  //       temp[index].modules[innerIndex]["write"] = tempValue;
  //       temp[index].modules[innerIndex]["read"] = tempValue;
  //     } else if (tempName === "write" && tempValue === true) {
  //       temp[index].modules[innerIndex][tempName] = tempValue;
  //       temp[index].modules[innerIndex]["read"] = tempValue;
  //     } else {
  //       temp[index].modules[innerIndex][tempName] = tempValue;
  //     }
  //   } else {
  //     if (tempName === "read") {
  //       if (
  //         temp[index].modules[innerIndex]["manage"] === true ||
  //         temp[index].modules[innerIndex]["write"] === true
  //       ) {
  //         toast("Read can't be unchecked if manage or read is checked");
  //         return;
  //       } else {
  //         temp[index].modules[innerIndex][tempName] = tempValue;
  //       }
  //     } else if (tempName === "write") {
  //       if (temp[index].modules[innerIndex]["manage"] === true) {
  //         toast("Write can't be unchecked if manage is checked");
  //         return;
  //       } else {
  //         temp[index].modules[innerIndex][tempName] = tempValue;
  //       }
  //     } else {
  //       temp[index].modules[innerIndex][tempName] = tempValue;
  //     }
  //   }
  //   setRolesFromApi(temp);
  // };

  const handleClickOpen = () => {
    setPopup(true);
  };

  const handleClose = () => {
    setPopup(false);
    setDeletePopupOpen(false);
  };

  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const validateRoleName = (name) => {
    let temp = true;
    rolesFromApi.map((role, index) => {
      if (role.roleName.toUpperCase() === name.trim().toUpperCase()) {
        temp = false;
      }
    });
    return temp;
  };

  const validateModules = (modules) => {
    let count = modules.length;
    modules.map((_module, index) => {
      if (
        _module["read"] === true ||
        _module["write"] === true ||
        _module["manage"] === true
      ) {
        count--;
      }
    });
    return count == modules.length ? false : true;
  };

  const validateSpecialCharacter = (data) => {
    return data.match(/[%<>\\$'"@!#^&*)(}{1234567890]/) ? false : true;
  };

  const handleEditable = (num) => {
    const temp = [...roleNameWithIndex];
    temp[num].accessToEdit = !roleNameWithIndex[num].accessToEdit;
    setRoleNameWithIndex(temp);
  };

  const handlePopupChange = (e) => {
    setRoleName((prev) => (prev = e.target.value));
  };

  const handlePopupCheckboxChange = (e, index) => {
    console.log("checkBoxes---->", e.target.name, e.target.checked, index);
    const temp = { ...rolesPopup };
    let tempName = e.target.name;
    let tempValue = e.target.checked;
    if (tempValue) {
      if (tempName === "manage" && tempValue === true) {
        console.log("Inside manage");
        temp.modules[index][tempName] = tempValue;
        temp.modules[index]["write"] = tempValue;
        temp.modules[index]["read"] = tempValue;
      } else if (tempName === "write" && tempValue === true) {
        console.log("Inside write");
        temp.modules[index][tempName] = tempValue;
        temp.modules[index]["read"] = tempValue;
      } else {
        console.log("Inside else");
        temp.modules[index][tempName] = tempValue;
      }
    } else {
      if (tempName === "read") {
        if (
          temp.modules[index]["manage"] === true ||
          temp.modules[index]["write"] === true
        ) {
          toast("Read can't be unchecked if manage or read is checked");
          return;
        } else {
          temp.modules[index][tempName] = tempValue;
        }
      } else if (tempName === "write") {
        if (temp.modules[index]["manage"] === true) {
          toast("Write can't be unchecked if manage is checked");
          return;
        } else {
          temp.modules[index][tempName] = tempValue;
        }
      } else {
        temp.modules[index][tempName] = tempValue;
      }
    }
    console.log("tempName--->", tempName);
    console.log(
      "temp.modules[index].e.target.name--->",
      temp.modules[index][tempName]
    );
    setRolesPopup(temp);
  };

  const handleUpdate = async (index, _id, roleName) => {
    if (JSON.stringify(rolesFromApi) === JSON.stringify(tempRolesFromApi)) {
      toast("Nothing to update");
      return;
    } else {
      // if (!comparedata(rolesFromApi, tempRolesFromApi)) {
      const data = rolesFromApi[index];
      data.push({ roleName: roleName }, { _id: _id });
      const response = await updateRole(_id, data);
      if (response.data.status) {
        toast("Accesses updated successfully");
        const temp = [...roleNameWithIndex];
        temp[index].accessToEdit = !roleNameWithIndex[index].accessToEdit;
        setRoleNameWithIndex(temp);
        setRoleFetched(false);
      }
      // } else {
      //   toast("Nothing to update");
      // }
    }
  };

  const handleCreate = async () => {
    console.log("Button Clicked!");
    console.log("rolespopup-----", rolesPopup);
    // if (!validate(rolesPopup.roleName)) {
    if (!validate(roleName)) {
      toast("Role name cannot be empty");
      hitApi = false;
    }
    // if (!validateModules(rolesPopup.modules)) {
    //   toast("All modules cannot be empty");
    //   hitApi = false;
    // }
    // if (!validateRoleName(rolesPopup.roleName)) {
    //   toast("Role name already exists");
    //   hitApi = false;
    // }
    if (!validateSpecialCharacter(roleName)) {
      toast("Special characters and numbers are not allowed");
      hitApi = false;
    }
    if (
      validate(roleName) &&
      // validateModules(rolesPopup.modules) &&
      // validateRoleName(rolesPopup.roleName) &&
      validateSpecialCharacter(roleName)
    ) {
      hitApi = true;
    }
    try {
      // setSaveButton(true);
      console.log("Inside Try");
      const temp = [...accessRoles];
      temp.unshift({ Dashboard: Dashboard });
      if (roleName.length && roleName.trim() !== "") {
        temp.push({ roleName: roleName.toUpperCase() });
      }

      const response = hitApi ? await createRole(temp) : null;
      if (response.data.status) {
        // setSaveButton(false);
        setAccessRoles(initialAccessModulesState);
        toast("Roles created successfully");
        handleClose();
        setRoleFetched(false);
      } else {
        // setSaveButton(false);
      }
    } catch (error) {
      console.log(error);
      // setSaveButton(false);
      toast(error.response.data.data[0]);
    }
  };

  const handleAccessPerModule = (event, index, key) => {
    setAccessRoles((prev) => {
      const temp = [...prev];
      temp[index][key][event.target.name] = event.target.checked;
      return temp;
    });
  };

  const formatRoleResponse = (response) => {
    const temp = [];
    response.forEach((ele, index) => {
      const result = ele.find((childEle) =>
        Object.keys(childEle).includes("roleName")
      );
      const result1 = ele.find((childEle) =>
        Object.keys(childEle).includes("_id")
      );
      if (result && result1) {
        ele.splice(ele.indexOf(result), 1);
        ele.splice(ele.indexOf(result1), 1);
        // setRoleNameWithIndex((prev) => {
        //   const temp = [...prev];
        temp.push({
          role: result.roleName,
          roleId: result1._id,
          indexOfRole: index,
          accordionExpand: false,
          accessToEdit: false,
          // });
          // return temp;
        });
      }
    });
    setRoleNameWithIndex(temp);
    return response;
  };

  useEffect(() => {
    (async () => {
      if (!roleFetched) {
        try {
          const response = await getRoles(1);
          if (response.data.status) {
            const tempData = JSON.stringify(
              formatRoleResponse(response.data.data)
            );
            setTempRolesFromApi(JSON.parse(tempData));
            setRolesFromApi(JSON.parse(tempData));
            setRoleFetched(true);
            // setRoleFetched(false);
          } else {
            console.log("Different status");
          }
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [roleFetched]);
  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={5} variant="outlined">
              <CardContent>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={handleClickOpen}
                    >
                      New Role
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: 0 }} elevation={3} variant="outlined">
              <CardContent
                sx={{
                  paddingTop: 0,
                  paddingRight: 2,
                  paddingLeft: 2,
                  "&:last-child": { pb: 0 },
                }}
              >
                <Grid container spacing={0}>
                  {roleNameWithIndex.map((role, index) => {
                    return (
                      <Grid item xs={12}>
                        <Accordion
                          expanded={role.accordionExpand}
                          onChange={(event) => {
                            handleAccordionExpand(event, index);
                          }}
                          key={index}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Grid container spacing={0}>
                              <Grid item xs={3}>
                                <Typography sx={{ flexShrink: 0 }}>
                                  {role.role.replace("_", " ")}
                                </Typography>
                              </Grid>
                              <Grid item xs={7.4}>
                                {/* <Typography sx={{ display: "flex", flexDirection: 'row-reverse' }}>
                                                                    {expanded === role._id ? <AppRegistrationIcon /> : null}
                                                                </Typography> */}
                              </Grid>
                              <Grid item xs={1.6}>
                                {/* <Typography>
                                  {expanded === role._id ? null : (
                                    <Typography sx={{ color: "blue" }}>
                                      {role.active_status ? (
                                        <span style={{ color: "green" }}>
                                          ACTIVE
                                        </span>
                                      ) : (
                                        <span style={{ color: "red" }}>
                                          INACTIVE
                                        </span>
                                      )}
                                    </Typography>
                                  )}
                                </Typography> */}
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                  }}
                                >
                                  {role.accessToEdit ? (
                                    <>
                                      <Tooltip title="Save">
                                        <IconButton>
                                          <SaveIcon
                                            onClick={() => {
                                              handleUpdate(
                                                index,
                                                role.roleId,
                                                role.role
                                              );
                                            }}
                                            sx={{
                                              "&:hover": { color: "green" },
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <span>&nbsp;</span>
                                      <Tooltip title="Cancel">
                                        <IconButton>
                                          <ClearIcon
                                            onClick={() => {
                                              handleCancel(index);
                                            }}
                                            sx={{ "&:hover": { color: "red" } }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <Tooltip title="Edit">
                                      <IconButton>
                                        <AppRegistrationIcon
                                          onClick={() => {
                                            handleEditable(index);
                                          }}
                                          sx={{
                                            color: "#000000",
                                            "&:hover": { color: "#007aff" },
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                {rolesFromApi[role.indexOfRole].map(
                                  (ele, innerInd) =>
                                    Object.entries(ele).map(
                                      (
                                        [moduleName, value],
                                        childInnerIndex
                                      ) => {
                                        return (
                                          <Grid
                                            container
                                            spacing={0}
                                            key={childInnerIndex}
                                          >
                                            <Grid item xs={3}>
                                              <span>
                                                {moduleName.includes("_")
                                                  ? moduleName.replace("_", " ")
                                                  : moduleName}
                                              </span>
                                            </Grid>
                                            <Grid item xs={9}>
                                              <Grid container spacing={0}>
                                                {Object.keys(value).map(
                                                  (key) => {
                                                    if (key === "_id") {
                                                      return null;
                                                    } else {
                                                      return (
                                                        <Grid item xs={3}>
                                                          <FormControl size="small">
                                                            <FormGroup row>
                                                              <FormControlLabel
                                                                control={
                                                                  <Checkbox
                                                                    disabled={
                                                                      !role[
                                                                        "accessToEdit"
                                                                      ]
                                                                    }
                                                                    name={key}
                                                                    checked={
                                                                      value[key]
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      handleRolesForUpdate(
                                                                        event,
                                                                        index,
                                                                        moduleName,
                                                                        key,
                                                                        innerInd
                                                                      );
                                                                    }}
                                                                  />
                                                                }
                                                                label={key}
                                                              />
                                                            </FormGroup>
                                                          </FormControl>
                                                        </Grid>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        {index === rolesFromApi.length - 1 ? null : <Divider />}
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* <Box
            onClick={handleClickOpen}
            sx={{ position: "fixed", bottom: 16, right: 16 }}
          >
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Box> */}
        </Grid>
        <Dialog open={popup} onClose={handleClose} maxWidth="lg">
          <DialogTitle textAlign="center">Add New Role</DialogTitle>
          <DialogContent>
            <Grid container spacing={2.5}>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TextField
                  required
                  id="outlined-basic-name"
                  fullWidth
                  label="Enter Role name"
                  variant="outlined"
                  type="text"
                  name="roleName"
                  value={roleName}
                  onChange={handlePopupChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" textAlign="center">
                  Assign Access
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "0px", paddingTop: 0 }}>
                <Grid container spacing={0} sx={{ m: 0, paddingTop: 0 }}>
                  {accessRoles.map((module, index) => {
                    return Object.entries(module).map(([modKey, value]) => {
                      return (
                        <>
                          <Grid
                            item
                            xs={2}
                            sx={{ marginTop: "8px", paddingTop: 0 }}
                          >
                            <Typography
                              sx={{
                                display: "inline",
                                marginLeft: "5px",
                              }}
                            >
                              {modKey.includes("_")
                                ? modKey.replace("_", " ")
                                : modKey}
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Grid container spacing={0}>
                              {Object.keys(value).map((key) => {
                                return (
                                  <Grid item xs={3}>
                                    <FormControl size="small">
                                      <FormGroup row>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={(e) => {
                                                handleAccessPerModule(
                                                  e,
                                                  index,
                                                  modKey
                                                );
                                              }}
                                              name={key}
                                              checked={value[key]}
                                            />
                                          }
                                          label={key}
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                          {index === accessRoles.length - 1 ? null : (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                        </>
                      );
                    });
                  })}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: "red" }}>
              CANCEL
            </Button>
            <Button disabled={saveButton} onClick={handleCreate}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

function convertData(data) {
  let _newData = {};
  data.forEach((role) => {
    _newData[role.roleName] = {};
    role.modules.forEach((_module) => {
      _newData[role.roleName][_module.moduleName] = {
        read: _module.read,
        write: _module.write,
        manage: _module.manage,
      };
    });
  });
  return _newData;
}

function comparedata(_data_1, _data_2) {
  const _new_data_1 = convertData(_data_1);
  const _new_data_2 = convertData(_data_2);
  let compareState = true;
  Object.keys(_new_data_1).forEach((_role) => {
    Object.keys(_new_data_1[_role]).forEach((_module) => {
      Object.keys(_new_data_1[_role][_module]).forEach((_access) => {
        if (
          _new_data_1[_role][_module][_access] !=
          _new_data_2[_role][_module][_access]
        )
          compareState = false;
      });
    });
  });
  return compareState;
}

export default Roles;
