const apis = require("./api.requests");

const TAG = "roles.service";
const path = "/roles/v1";

async function createRole(body) {
  return await apis.hitPostApi(`${path}/create`, body);
}

async function getRoles(data) {
  return await apis.hitGetApi(`${path}/all?type=${data}`);
}

async function getRolesName(data) {
  return await apis.hitGetApi(`${path}/roles`);
}

async function updateRole(id, body) {
  return await apis.hitPutApi(`${path}/update/${id}`, body);
}

async function changeStatus(id) {
  return await apis.hitPutApi(`${path}/status/${id}`);
}

export { createRole, getRoles, getRolesName, updateRole, changeStatus };
