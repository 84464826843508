import React from "react";
import { useDrop } from "react-dnd";

function DropOnEditor({ Container = <></>, handleDrop }) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "copyableElement",
    drop: (item) => {
      handleDrop(item, "body"); // Pass the field name and dropped label to the handler
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  return <div ref={drop}>{Container}</div>;
}

export default DropOnEditor;
