import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Paper,
  CardContent,
  Chip,
} from "@mui/material";
// components
import Page from "../UI/Page";
import Label from "../UI/Label";
import Scrollbar from "../UI/Scrollbar";
import Iconify from "../UI/Iconify";
import SearchNotFound from "../SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../sections/@dashboard/user";
// mock
//import USERLIST from "../_mock/user";
import DashboardAction from "./DashboardAction";
import { getMyActions } from "../../services/common.service";
import * as React from "react";
import { styled } from "@mui/material/styles";

import { tableCellClasses } from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import { getWatcherLeadList } from "../../services/contacts.service";
import { ActionContext } from "../../context/action.context";

// ----------------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: "name", label: "Name", alignRight: false },
//   { id: "email", label: "Email", alignRight: false },
//   { id: "ownedBy", label: "Owned By", alignRight: false },
//   { id: "last", label: "Last", alignRight: false },
//   // { id: 'status', label: 'Status', alignRight: false },
//   // { id: "" },
// ];

// ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function DashboardActionList(props) {
  const { actionList, fetchActions } = props;
  // const [page, setPage] = useState(0);

  // const [order, setOrder] = useState("asc");

  // const [selected, setSelected] = useState([]);

  // const [orderBy, setOrderBy] = useState("name");

  // const [filterName, setFilterName] = useState("");

  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const { setRowColor } = React.useContext(ActionContext);

  console.log("action list date", actionList);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = actionList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleRemove = (payload) => {
  //   console.log("Handle remove", payload);
  //   let targetType = payload.data.data.targetType;
  //   let actionId = payload.data.data.actionId;
  //   let actionFilterList = actionList.filter((ele) => {
  //     return ele.targetType != targetType && ele._id != actionId;
  //   });
  //   // setActionList(actionFilterList);
  //   console.log(actionList);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - actionList.length) : 0;

  // const filteredActions = applySortFilter(
  //   actionList,
  //   getComparator(order, orderBy),
  //   filterName
  // );

  // const isUserNotFound = filteredActions.length === 0;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#e3f2fd",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ede7f6",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const dateMatch = (date) => {
    const dueDate = new Date(date).toDateString();
    const todayDate = new Date().toDateString();
    if (dueDate === todayDate) {
      return true;
    }
    return false;
  };

  const futureDateMatch = (date) => {
    const dueDate = new Date(date).setHours(0, 0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0, 0);
    if (dueDate > todayDate) {
      return true;
    }
  };

  const pastDateMatch = (date) => {
    const dueDate = new Date(date).setHours(0, 0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0, 0);
    if (dueDate < todayDate) {
      return true;
    }
  };
  const handleRowClick = (event) => {
    const backgroundColor = window
      .getComputedStyle(event.currentTarget)
      .getPropertyValue("background-color");
    setRowColor(() => backgroundColor);
  };

  return (
    <Page title="User">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card sx={{ height: 400 }}>
                  <CardContent style={{ padding: 14 }}>
                    <Typography align="left" sx={{ marginBottom: 1 }}>
                      Pending Until Today
                    </Typography>
                    <TableContainer
                      sx={{ maxHeight: 340, overflow: "auto" }}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableBody>
                          {actionList.map((row, index) => {
                            return pastDateMatch(row.dueDate) ? (
                              <>
                                <StyledTableRow
                                  key={index}
                                  onClick={handleRowClick}
                                >
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    style={{ padding: 10, cursor: "pointer" }}
                                  >
                                    <DashboardAction
                                      id={row.contactId}
                                      leadId={row.leadId}
                                      projectId={row.projectId}
                                      actionId={row._id}
                                      isDone={row.isDone}
                                      doneOn={row.doneOn}
                                      doer={row.doer}
                                      doneBy={row.doneBy}
                                      targetKey={row._id}
                                      toDo={row.toDo}
                                      dueDate={row.dueDate}
                                      targetType={row.targetType}
                                      targetInfo={row.targetInfo}
                                      actionType={row.actionType}
                                      fetchActions={fetchActions}
                                      contactName={row.contactName}
                                      leadSummary={row.leadSummary}
                                      projectTitle={row.projectTitle}
                                      companyId={row.companyId}
                                      companyName={row.companyName}
                                    ></DashboardAction>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            ) : null;
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={{ height: 400 }}>
                  <CardContent style={{ padding: 14 }}>
                    <Typography align="left" sx={{ marginBottom: 1 }}>
                      Due Today
                    </Typography>
                    <TableContainer
                      sx={{ maxHeight: 340, overflow: "auto" }}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableBody>
                          {actionList.map((row, index) => {
                            return dateMatch(row.dueDate) ? (
                              <>
                                <StyledTableRow
                                  key={index}
                                  onClick={handleRowClick}
                                >
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    style={{ padding: 10, cursor: "pointer" }}
                                  >
                                    <DashboardAction
                                      id={row.contactId}
                                      leadId={row.leadId}
                                      projectId={row.projectId}
                                      actionId={row._id}
                                      isDone={row.isDone}
                                      doneOn={row.doneOn}
                                      doer={row.doer}
                                      doneBy={row.doneBy}
                                      targetKey={row._id}
                                      toDo={row.toDo}
                                      dueDate={row.dueDate}
                                      targetType={row.targetType}
                                      targetInfo={row.targetInfo}
                                      actionType={row.actionType}
                                      fetchActions={fetchActions}
                                      contactName={row.contactName}
                                      leadSummary={row.leadSummary}
                                      projectTitle={row.projectTitle}
                                      companyId={row.companyId}
                                      companyName={row.companyName}
                                    ></DashboardAction>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            ) : null;
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              {/* <Grid item xs={4}>
                <Card sx={{ height: 400 }}>
                  <CardContent style={{ padding: 14 }}>
                    <Typography align="left" sx={{ marginBottom: 1 }}>
                      Upcoming
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: 340, overflow: "auto" }}
                    >
                      <Table aria-label="customized table">
                        <TableBody>
                          {actionList.map((row, index) => {
                            return futureDateMatch(row.dueDate) ? (
                              <>
                                <StyledTableRow key={index}>
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    style={{ padding: 10 }}
                                  >
                                    <DashboardAction
                                      // handleRemove={handleRemove}
                                      id={row.contactId}
                                      leadId={row.leadId}
                                      projectId={row.projectId}
                                      actionId={row._id}
                                      isDone={row.isDone}
                                      doneOn={row.doneOn}
                                      doer={row.doer}
                                      doneBy={row.doneBy}
                                      targetKey={row._id}
                                      toDo={row.toDo}
                                      dueDate={row.dueDate}
                                      targetType={row.targetType}
                                      targetInfo={row.targetInfo}
                                      actionType={row.actionType}
                                      fetchActions={fetchActions}
                                      contactName={row.contactName}
                                      leadSummary={row.leadSummary}
                                      projectTitle={row.projectTitle}
                                    ></DashboardAction>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            ) : null;
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
