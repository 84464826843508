import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import { useState } from "react";
import { forgotPassword } from "../../services/auth.service";

const ForgotPassword = () => {
  const notify = () =>
    toast("An email has been sent to you. Please check to reset password.");
  const [email, setEmail] = useState({});
  const handleChange = (name, value) => {
    setEmail((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (email) => {
    forgotPassword(email)
      .then((response) => {
        if (!!response.data.status) {
          notify();
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Card
        className="child"
        elevation={5}
        sx={{ width: "40vw", minHeight: "auto", backgroundColor: "#03203C" }}
      >
        <ToastContainer />
        <CardContent>
          <Grid container spacing={4} marginTop="20px">
            <Grid item>
              <Typography
                sx={{ marginTop: "0px" }}
                align="left"
                variant="h2"
                color="white"
              >
                Forgot Password?
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h4" color="white" marginTop="20px">
                Don't worry! Just fill in your email and we will send you a link
                to reset your password.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Email"
                variant="outlined"
                placeholder="Forgot password"
                type="email"
                required
                fullWidth
                sx={{ input: { color: "white" } }}
                onBlur={(e) => {
                  handleChange("email", e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit(email);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPassword;
