import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Grid,
  InputLabel,
  FormControl,
  Stack,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Autocomplete,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import React from "react";
import {
  createGoogleTokens,
  getUserById,
  getUsers,
} from "../../../services/users.service";
import {} from "../../../services/contacts.service";
import { toast } from "react-toastify";
import { createAction as createContactAction } from "../../../services/contacts.service";
import { createAction as createLeadAction } from "../../../services/leads.service";
import { createAction as createProjectAction } from "../../../services/projects.service";

import constants from "../../../constants";
import AddIcon from "@mui/icons-material/Add";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import { AuthContext } from "../../../context/auth.context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import handleButtonByRoleAccess from "../../../utils/handleButttonByRoleAccess";
import { createCompanyAction } from "../../../services/companies.service";
import { getActionNames } from "../../../services/common.service";

let hitApi = true;

function AssignToDropdown(props) {
  const [value, setValue] = React.useState(props.list[0]);
  const handleChange = (event) => {
    setValue(event.target.value);
    props.setDropdownValue(event.target.value);
  };
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleChange}
      sx={{ width: "200px" }}
    >
      {props.list.map((element) => (
        <MenuItem value={element}>{element}</MenuItem>
      ))}
    </Select>
  );
}

function CreateAction({
  action,
  setAction,
  id,
  leadId,
  projectId,
  initialActionState,
  actionType,
  loadedActions,
  setLoadedActions,
  isEditPage,
  isProjectEditPage,
  setPageCount,
  FetchActions,
  setPage,
  addedActionCounter,
  setAddedActionCounter,
  setCreateContactShowTimer,
  checked,
  setChecked,
  getAllDoers,
  lead,
  fromContactPage,
  contact,
  fromLeadPage,
  fromProjectPage,
  allDoers,
  techOwner,
  salesOwner,

  //Company
  createCompanyShowTimer,
  setCreateCompanyShowTimer,

  fromCompanyViewPage,
  companyId,
}) {
  const [actionNames, setActionNames] = React.useState([]);
  async function getAllActionNames() {
    try {
      const response = await getActionNames();
      if (response.data.status) {
        setActionNames([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    let fromAction = true;
    getAllActionNames();
    getUsers(0, false, fromAction).then((results) => {
      if (results.data.status) {
        const usernames = [];
        results.data.data.forEach((user) => {
          if (user.active_status) {
            usernames.push({ name: user.name, id: user._id });
          }
        });
        setList([...usernames]);
        setDropdownValue(usernames[0]);
      }
    });
  }, []);
  let toastId = null;
  const [showTimer, setShowTimer] = React.useState(false);
  //

  const [list, setList] = React.useState([]);
  const [saveButton, setSaveButton] = React.useState(false);
  //console.log(isEditPage);
  const [dropDownValue, setDropdownValue] = React.useState(list[0]);
  // const [action, setAction] = React.useState("");
  // const [dueDate, setDueDate] = React.useState(null);
  const [actionObj, setActionObj] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  console.log(currentUser);

  // const handleChangeAction = (e, newValue) => {
  //   if (e.target.value.length > 20) {
  //     if (toastId == null) {
  //       toastId = toast.warning(
  //         "Action should not be more then 20 characters."
  //       );
  //     } else {
  //       toast.update(toastId, "Action should not be more then 20 characters.");
  //     }
  //   } else {
  //     setPage(0);
  //     setAction((prevState) => ({
  //       ...prevState,
  //       [e.target.name]: e.target.value,
  //     }));
  //   }
  // };

  const handleChange = (e) => {
    setPage(0);
    setAction((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckBoxChange = (event) => {
    // setChecked(event.target.checked);
    // if (event.target.checked === true) { setShowTimer(true); }
    // if (event.target.checked) {
    //   if (currentUser.isGoogleToken) {
    //     setChecked(event.target.checked);
    //   } else {
    //     setOpen(true);
    //     return;
    //   }
    // }
    setChecked(event.target.checked);
  };

  //console.log("action type", actionType);
  const [value, setValue] = React.useState(new Date());

  const handleDateChange = (newValue) => {
    // setValue(newValue);
    setAction((prevState) => ({
      ...prevState,
      dueDate: newValue,
    }));
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // setIsLoaded(false);
      console.log(codeResponse);
      // setCurrentUser((prevState) => ({
      //   ...prevState,
      //   isGoogleAuthenticated: true
      // }));

      const response = await createGoogleTokens({ code: codeResponse.code });
      console.log(response);
      if (response.status) {
        // setOpen(false);
        setChecked(true);
        const res = await getUserById(currentUser._id, true);
        if (res.data.status) {
          setCurrentUser(res.data.data);
        }
      }
    },
    onError: (err) => {
      console.log("Login Failed", err);
      setOpen(false);
      toast("Authentication failed");
    },
    flow: "auth-code",
    scope: "email profile openid https://www.googleapis.com/auth/calendar",
  });
  // const handleTimeChange = (newValue) => {
  //   // setValue(newValue);
  //   setAction((prevState) => ({
  //     ...prevState,
  //     dueTime: newValue,
  //   }));
  // };

  // const handleDateFormat = (dueDate) => {
  //   var updatedDateFormat = new Date(dueDate);
  //   var month = updatedDateFormat.getMonth() + 1;
  //   var date = updatedDateFormat.getDate();
  //   var year = updatedDateFormat.getFullYear();
  //   var updatedDate = "" + month + "/" + date + "/" + year;
  //   return updatedDate;
  // };

  // const handleTimeFormat = (dueTime) => {
  //   let modifiedHour = "";
  //   let timeDesignator;
  //   var updatedTimeFormat = new Date(dueTime);
  //   var hour = updatedTimeFormat.getHours();
  //   modifiedHour = hour > 12 ? hour - 12 : hour;
  //   timeDesignator = hour >= 12 ? "PM" : "AM";
  //   var minute = updatedTimeFormat.getMinutes();
  //   var updatedTime =
  //     "" +
  //     (modifiedHour >= 10 ? modifiedHour : "0" + modifiedHour) +
  //     ":" +
  //     (minute >= 10 ? minute : "0" + minute) +
  //     " " +
  //     timeDesignator;
  //   console.log("updatedTime", updatedTime);
  //   return updatedTime;
  // };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  // function formatDate(date) {
  //   console.log(date);
  //   if (!showTimer) {
  //     date = new Date(date);
  //   }
  //   return (
  //     [
  //       date.getFullYear(),
  //       padTo2Digits(date.getMonth() + 1),
  //       padTo2Digits(date.getDate()),
  //     ].join("-") +
  //     " " +
  //     [
  //       padTo2Digits(date.getHours()),
  //       padTo2Digits(date.getMinutes()),
  //       padTo2Digits(date.getSeconds()),
  //     ].join(":")
  //   );
  // }

  const validateAction = (toDo, doer) => {
    let result;
    // if (toDo === "" && doer === "") {
    //   result = true;
    // } else
    if (toDo.trim() !== "" && doer !== "") {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  function dateChange(date) {
    console.log(date);
    const dateTimeInSeconds = new Date(date).getTime();
    console.log(dateTimeInSeconds);
    const offSet = new Date().getTimezoneOffset();
    console.log(offSet);
    const offSetInSeconds = offSet * 60 * 1000;
    console.log(offSetInSeconds);
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    console.log(newDateInSeconds);
    const newDate = new Date(newDateInSeconds);
    console.log(newDate);
    const newDateFormat = formatDate(newDate);
    console.log(newDateFormat);
    return newDateFormat;
  }

  function formatDate(date) {
    console.log(date);
    if (!showTimer) {
      date = new Date(date);
    }
    date = new Date(date);

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":") +
      ":00" +
      ".000Z"
    );
  }

  const AddAction = async (e) => {
    console.log(action);
    if (!validateAction(action.toDo, action.doer)) {
      toast(
        "Fill all the action fields to register an action else leave all action fields empty"
      );
      hitApi = false;
    }
    if (validateAction(action.toDo, action.doer)) {
      hitApi = true;
    }
    console.log("Add action triggered from" + actionType);
    if (
      action.toDo !== null &&
      action.doer !== null &&
      action.dueDate !== null &&
      action.toDo !== undefined &&
      action.doer !== undefined &&
      action.dueDate !== undefined &&
      action.toDo !== "" &&
      action.doer !== "" &&
      action.dueDate !== ""
    ) {
      try {
        if (hitApi) {
          setSaveButton(true);
          setLoaderDialog(true);
        }
        let response;
        if (!showTimer) {
          action.dueDate = new Date(action.dueDate).toDateString();
        }
        const temp = { ...action, schedule: checked };
        console.log(temp);
        temp.dueDate = dateChange(temp.dueDate);
        delete temp.meetLink;
        //temp.dueDate = temp.dueDate.toLocaleString();
        // temp.dueDate = handleDateFormat(temp.dueDate);
        // showTimer === true
        //   ? (temp.dueTime = handleTimeFormat(temp.dueTime))
        //   : (temp.dueTime = "00:00:00");
        if (actionType === constants.LEADS) {
          response = hitApi ? await createLeadAction(id, leadId, temp) : null;
        }
        if (actionType === constants.PROJECTS) {
          console.log(id);
          response = hitApi ? await createProjectAction(projectId, temp) : null;
        }
        if (actionType === constants.CONTACTS) {
          response = hitApi ? await createContactAction(id, temp) : null;
        }
        if (actionType === constants.COMPANIES) {
          response = hitApi ? await createCompanyAction(companyId, temp) : null;
        }
        if (response.data.status) {
          if (fromLeadPage) {
            getAllDoers();
          }
          setLoadedActions([]);
          setPageCount(1);
          FetchActions();
          console.log(action);
          toast("Action added successfully");
          setAddedActionCounter(addedActionCounter + 1);
          setAction(initialActionState);
          setShowTimer(false);
          setChecked(false);
          // setCreateContactShowTimer(false);
          setSaveButton(false);
          setLoaderDialog(false);
        }
      } catch (error) {
        console.log(error);
        setSaveButton(false);
        setLoaderDialog(false);
        toast("Action couldn't be added");
      }
    }
  };
  console.log("action.create checked", checked, isEditPage, action);
  return (
    <>
      {/* <Grid container spacing={2}> */}
      {/* <Grid item xs={12} sm={12}> */}
      <Grid container spacing={2}>
        <Grid item xs={6} sm={isEditPage ? 3.5 : 4}>
          {/* <TextField
            label="Action"
            variant="outlined"
            placeholder="Action Name"
            type="text"
            name="toDo"
            value={action.toDo}
            fullWidth
            onChange={handleChangeAction}
          /> */}
          <Autocomplete
            freeSolo
            inputValue={action.toDo || ""}
            value={action.toDo || ""}
            options={actionNames.map((action) => action.actionName)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Action Name"
                placeholder="Action Name"
                onChange={(e) => {
                  let {
                    target: { value },
                  } = e;
                  if (value?.length > 30) {
                    toast.warn(
                      "Action Name can not be more than 30 characters"
                    );
                    return;
                  }
                  if (value && !value.trim()) {
                    toast.warn("Action Name can not contain only spaces");
                    return;
                  }
                  setAction((prevState) => ({
                    ...prevState,
                    toDo: value,
                  }));
                }}
              />
            )}
            onChange={(event, value) => {
              console.log(value);
              setAction((prevState) => ({
                ...prevState,
                toDo: value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} sm={isEditPage ? 3.5 : 4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Assigned To:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Assigned To:"
              id="demo-simple-select"
              value={action.doer}
              name="doer"
              onChange={handleChange}
              // sx={{ width: "200px" }}
            >
              <MenuItem value="">Select</MenuItem>
              {list.map((element, index) => (
                <MenuItem key={index} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={isEditPage ? 3.5 : 4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              fullWidth
              label="Due Date"
              inputFormat="MMM/dd/yyyy"
              value={action.dueDate}
              name="dueDate"
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        {!isEditPage && action.toDo?.toLowerCase().includes("meet") ? (
          <Grid
            item
            xs={6}
            sm={isEditPage ? 2 : 4}
            sx={{ direction: "column" }}
          >
            {
              // !isEditPage ? (
              !showTimer ? (
                <Button
                  onClick={() => {
                    setShowTimer(true);
                    setCreateContactShowTimer(true);
                  }}
                >
                  Set Time
                </Button>
              ) : (
                <Stack direction="row" spacing={2}>
                  <FormGroup sx={{ marginTop: "8px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked} //Action create
                          onChange={handleCheckBoxChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Schedule?"
                    />
                  </FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      fullWidth
                      label="Time"
                      // value={action.dueTime}
                      value={action.dueDate}
                      // name="dueTime"
                      name="dueDate"
                      // onChange={handleTimeChange}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>

                  <CancelIcon
                    onClick={() => {
                      setShowTimer(false);
                      setCreateContactShowTimer(false);
                      setCreateCompanyShowTimer(false);
                      setChecked((prev) => (prev = false));
                    }}
                  />
                </Stack>
              )
              // ) : null
            }
          </Grid>
        ) : null}
        {isEditPage ? (
          fromCompanyViewPage ? (
            <Grid
              item
              xs={6}
              sm={1.5}
              sx={{
                //   display: "flex",
                //   flexDirection: "row-reverse",
                marginTop: "5px",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                size="large"
                disabled={saveButton}
                onClick={AddAction}
                startIcon={<AddIcon />}
                sx={{ maxHeight: "45px" }}
              >
                Add
              </Button>
            </Grid>
          ) : null
        ) : null}
        {isEditPage ? (
          fromContactPage ? (
            handleButtonByRoleAccess(
              "CONTACTS",
              currentUser,
              contact.salesOwner,
              allDoers
            ) ? (
              <Grid
                item
                xs={6}
                sm={1.5}
                sx={{
                  //   display: "flex",
                  //   flexDirection: "row-reverse",
                  marginTop: "5px",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={saveButton}
                  onClick={AddAction}
                  startIcon={<AddIcon />}
                  sx={{ maxHeight: "45px" }}
                >
                  Add
                </Button>
              </Grid>
            ) : null
          ) : null
        ) : null}
        {isEditPage ? (
          fromLeadPage ? (
            handleButtonByRoleAccess(
              "LEADS",
              currentUser,
              lead.salesOwner,
              allDoers,
              lead.techOwner
            ) ? (
              <Grid
                item
                xs={6}
                sm={1.5}
                sx={{
                  //   display: "flex",
                  //   flexDirection: "row-reverse",
                  marginTop: "5px",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={saveButton}
                  onClick={AddAction}
                  startIcon={<AddIcon />}
                  sx={{ maxHeight: "45px" }}
                >
                  Add
                </Button>
              </Grid>
            ) : null
          ) : null
        ) : null}
        {isEditPage ? (
          fromProjectPage ? (
            handleButtonByRoleAccess(
              "PROJECTS",
              currentUser,
              salesOwner,
              allDoers,
              techOwner
            ) ? (
              <Grid
                item
                xs={6}
                sm={1.5}
                sx={{
                  //   display: "flex",
                  //   flexDirection: "row-reverse",
                  marginTop: "5px",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={saveButton}
                  onClick={AddAction}
                  startIcon={<AddIcon />}
                  sx={{ maxHeight: "45px" }}
                >
                  Add
                </Button>
              </Grid>
            ) : null
          ) : null
        ) : null}
        {isEditPage && action.toDo?.toLowerCase().includes("meet") ? (
          !showTimer ? (
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  setShowTimer(true);
                  setCreateContactShowTimer(true);
                }}
              >
                Set Time
              </Button>
            </Grid>
          ) : (
            <Grid item xs={2} lg={4}>
              <Stack direction="row" spacing={2}>
                <FormGroup sx={{ marginTop: "8px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckBoxChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Schedule?"
                  />
                </FormGroup>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    fullWidth
                    label="Time"
                    // value={action.dueTime}
                    value={action.dueDate}
                    // name="dueTime"
                    name="dueDate"
                    // onChange={handleTimeChange}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>

                <CancelIcon
                  onClick={() => {
                    setShowTimer(false);
                    setCreateContactShowTimer(false);
                  }}
                />
              </Stack>
            </Grid>
          )
        ) : null}
      </Grid>
      {/* </Grid> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's auth service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            To schedule an event, you have to give consent to Google auth. By
            clicking "Agree", you will be redirected to Profile section, where
            you can give consent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "red" }}>
            DISAGREE
          </Button>
          <Button
            autoFocus
            onClick={() => {
              login();
              setOpen(false);
            }}
          >
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
      {/* </Grid> */}
    </>
  );
}

export default CreateAction;
