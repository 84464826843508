const apis = require("./api.requests");
const FileDownload = require("js-file-download");
const axios = require("axios");

const commonPath = "/common/v1";

async function createProposal(data) {
  return await apis.hitPostApi(`${commonPath}/proposal`, data);
}
async function getAllProposal(data) {
  return await apis.hitGetApi(`${commonPath}/proposal/all`);
}

async function updateProposalbyId(proposalId, data) {
  return await apis.hitPutApi(`${commonPath}/proposal/${proposalId}`, data);
}
async function addProposalTemplatebyId(proposalId, data) {
  return await apis.hitPostApi(
    `${commonPath}/proposal/template/${proposalId}`,
    data
  );
}
async function updateProposalTemplatebyId(proposalId, templateId, data) {
  return await apis.hitPutApi(
    `${commonPath}/proposal/${proposalId}/template/${templateId}`,
    data
  );
}

async function removeProposalTemplatebyId(proposalId, templateId) {
  return await apis.hitDeleteApi(
    `${commonPath}/proposal/${proposalId}/template/${templateId}`
  );
}

async function proposalTemplates() {
  return await apis.hitGetApi(`${commonPath}/proposalTemplates`);
}

export {
  createProposal,
  getAllProposal,
  updateProposalbyId,
  addProposalTemplatebyId,
  updateProposalTemplatebyId,
  removeProposalTemplatebyId,
  proposalTemplates,
};
