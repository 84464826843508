import {
  TextField,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import "./Comment.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { createComment as createLeadcomment } from "../../../services/leads.service";
import { createComment as createContactComment } from "../../../services/contacts.service";
import { createComment as createProjectcomment } from "../../../services/projects.service";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../../context/auth.context";
import { useContext, useState } from "react";
import constants from "../../../constants";
import AddIcon from "@mui/icons-material/Add";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReactQuill from "react-quill";
import handleButtonByRoleAccess from "../../../utils/handleButttonByRoleAccess";
import { createCompanyComment } from "../../../services/companies.service";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import FilePresentIcon from "@mui/icons-material/FilePresent";

import Editor from "../../texteditor/Editor";
import Iconify from "../../UI/Iconify";
import { Height } from "@material-ui/icons";
import EventBus from "../../texteditor/EventBus";
import { cleanHtml } from "../../../utils/convertHTMLtoNormalText";

function CreateComment({
  contact,
  comment,
  setComment,

  setUpdateLeadCalled,
  id,
  leadId,
  projectId,
  initialCommentState,
  commentType,
  setAddedCommentCounter,
  addedCommentCounter,
  setPageCount,
  setLoadedComments,
  loadedComments,
  FetchComments,
  setPage,
  fromProjectCreatePage,
  fromLeadPage,
  fromContactPage,
  techOwner,
  salesOwner,
  lead,
  fromProjectPage,
  allDoers,

  fromCompanyViewPage,
  setIactionedApiCalled,
  handleDocClickOpen,
  handleDocClose,
  descriptionObj,
  files,
  setDescriptionObj,
  setFiles,
  setLoaderDialog,
}) {
  const { currentUser, isAuthenticated, setIsAuthenticated, setCurrentUser } =
    useContext(AuthContext);
  console.log("current user", comment);
  const [saveButton, setSaveButton] = useState(false);
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState("");
  const [unit, setUnit] = useState("");
  const units = [
    // "unit/hour",
    // "unit/day",
    // "unit/week",
    // "unit/month",
    // "unit/Fixed",

    "Hourly",
    "Daily",
    "Weekly",
    "Monthly",
    "Fixed",
  ];
  const currencies = ["USD ($)", "INR (₹)"];
  let priceSet;
  const handleChange = (e) => {
    console.log("Inside handle change1");
    setPage(0);
    console.log("Inside handle change2");
    setComment((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(e.target.value);
  };
  const htmlString = (txt) => {
    const htmlString = txt;

    const parser = new DOMParser();

    const dom = parser.parseFromString(htmlString, "text/html");

    const plainText = dom.body.textContent;

    return plainText;
  };
  // const cleanHtmls = (html) => {
  //   const div = document.createElement("div");
  //   div.innerHTML = html;

  //   const paragraphs = div.querySelectorAll("p");

  //   paragraphs.forEach((p) => {
  //     if (p.innerHTML.trim() === "<br>") {
  //       p.remove();
  //     }
  //   });

  //   return div.innerHTML;
  // };
  const cleanHtmls = (html) => {
    const cleanTag = "<p><br></p>";

    // Trim cleanTag from the beginning
    while (html.startsWith(cleanTag)) {
      html = html.substring(cleanTag.length).trim();
    }

    // Trim cleanTag from the end
    while (html.endsWith(cleanTag)) {
      html = html.substring(0, html.length - cleanTag.length).trim();
    }

    return html;
  };
  const handleChangeDesc = (e) => {
    console.log(e, "eeeeeeee");
    let updatedOutlines;
    const htmlString = (txt) => {
      const htmlString = txt;

      const parser = new DOMParser();

      const dom = parser.parseFromString(htmlString, "text/html");

      const plainText = dom.body.textContent;

      return plainText.trim();
    };

    // const descText = htmlString(e);
    const descText = cleanHtml(e);

    // if (!descText) {

    //   setComment((prevState) => ({
    //     ...prevState,
    //     comment: "",
    //   }));
    //   // if (e === "<p><br></p>") return;
    //   toast.warn("Only spaces are not allowed!");
    //   return;
    // }

    setComment((prevState) => ({
      ...prevState,
      comment: e,
    }));
  };
  console.log(comment, "eeeee");

  const pageChange = () => {};

  const [open, setOpen] = useState(false);
  const [priceToBeAdded, setPriceToBeAdded] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("comment type", commentType);

  const validate = (data) => {
    if (data === "" || data === null || data === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const addPriceTag = () => {
    if (!validate(price) || price <= 0) {
      toast("Price can not be empty");
      priceSet = false;
    }
    if (!validate(unit)) {
      toast("Unit can not be empty");
      priceSet = false;
    }
    if (!validate(currency)) {
      toast("Currency can not be empty");
      priceSet = false;
    }
    if (validate(price) && price > 0 && validate(unit) && validate(currency)) {
      priceSet = true;
    }
    if (priceSet) {
      console.log("Inside Price Set");
      let temp = price + " " + currency + " " + unit;
      // setComment((prevState) => ({
      //   ...prevState, price: temp, comment: "Price Added"
      // }));
      // console.log("Price det", price, currency, unit);
      // const newUnit = unit.split("/");
      // console.log("newUnit", newUnit);
      // let temp = price + " " + currency + "/" + newUnit[1];
      // console.log("temp", temp);
      handleClose();
      setPriceToBeAdded(true);
      AddComment(true, temp);
    }
  };

  Object.keys(descriptionObj).forEach((eleInd) => {
    files[parseInt(eleInd)].description = descriptionObj[parseInt(eleInd)];
  });
  console.log(descriptionObj, files, "obj");

  const AddComment = async (defaultComment, temp) => {
    // setSaveButton(false);
    try {
      delete comment.createdAt;
      if (comment.comment !== "" || defaultComment === true) {
        if (!htmlString(comment.comment)?.trim()) {
          toast("Comment can not be empty");
          return;
        }
        const cleanedContent = cleanHtmls(comment.comment);
        console.log(cleanedContent, "eeeeeecom");
        // setSaveButton(true);
        setLoaderDialog(true);
        let response;
        if (commentType === constants.PROJECTS) {
          Object.keys(descriptionObj).forEach((eleInd) => {
            files[parseInt(eleInd)].description =
              descriptionObj[parseInt(eleInd)];
          });
          console.log(comment);
          response = await createProjectcomment(
            id,
            projectId,
            defaultComment
              ? {
                  comment: "Price Added",
                  price: temp,
                  createdBy: currentUser._id,
                }
              : {
                  ...comment,
                  createdBy: currentUser._id,
                  files: files,
                  cleanedContent,
                }
          );
        }
        if (commentType === constants.LEADS) {
          response = await createLeadcomment(
            id,
            leadId,
            defaultComment
              ? {
                  comment: "Price Added",
                  price: temp,
                  createdBy: currentUser._id,
                }
              : {
                  ...comment,
                  createdBy: currentUser._id,
                  files: files,
                  cleanedContent,
                  // price: addPriceTag()
                }
          );
        }
        if (commentType === constants.CONTACTS) {
          response = await createContactComment(
            id,
            defaultComment
              ? {
                  comment: "Price Added",
                  price: temp,
                  createdBy: currentUser._id,
                }
              : {
                  ...comment,
                  createdBy: currentUser._id,
                  files: files,
                  cleanedContent,
                }
          );
        }
        if (commentType === constants.COMPANIES) {
          response = await createCompanyComment(id, {
            ...comment,
            createdBy: currentUser._id,
            files: files,
            cleanedContent,
          });
        }
        if (response.data.status) {
          setLoadedComments([]);
          setDescriptionObj({});
          setFiles((prev) => []);
          setPageCount(1);
          FetchComments();
          console.log(comment);
          toast("Comment added successfully");
          setAddedCommentCounter(addedCommentCounter + 1);
          setComment(initialCommentState);

          EventBus.dispatch("EditorBus");
          setSaveButton(false);
          setLoaderDialog(false);
          setPriceToBeAdded(false);
          setPrice(0);
          setCurrency("");
          setUnit("");
          setIactionedApiCalled((prev) => !prev);
          // fetchLeadDetails();
          setUpdateLeadCalled((prevState) => (prevState = false));
        } else setLoaderDialog(false);
      } else {
        console.log("else");

        toast("Comment can not be empty");
      }
    } catch (error) {
      console.log(error);
      setSaveButton(false);
      setLoaderDialog(false);
    }
  };
  console.log(currentUser.role, "currentUser.role");
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <span>Add your comments here</span>
      </Grid> */}
      {fromLeadPage ||
      fromContactPage ||
      fromCompanyViewPage ||
      fromProjectPage ? (
        currentUser._id === techOwner ||
        currentUser._id === salesOwner ||
        currentUser.role === "ADMIN" ||
        currentUser.role === "SALES" ||
        currentUser.role === "DELIVERY_LEAD" ? (
          <Grid
            item
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            sx={{ minHeight: "auto" }}
            // sx={{
            //   minHeight: "auto",
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
          >
            <div
              class="react-quill-container"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              {/* <Editor
                style={{
                  width: "100%",
                  fontSize: "24px",
                  marginTop: 6,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingRight: 12,
                  paddingLeft: 12,
                  border: "1px solid",
                  borderRadius: 10,
                }}
                // lead={lead}
                // setLead={setLead}
                callback={(det) => {
                  // setLead((prevState) => ({
                  //   ...prevState,
                  //   description: det,
                  // }));
                  setComment((prevState) => ({
                    ...prevState,
                    comment: det,
                  }));
                }}
                onChange={handleChangeDesc}
              /> */}

              <ReactQuill
                style={{ padding: 0, width: "100%", minHeight: "auto" }}
                readonly
                value={comment.comment}
                preserveWhitespace="true"
                placeholder="Add your comment here"
                name="comment"
                onChange={(e) => handleChangeDesc(e)}
                // className={"app ql-editor blurred-editor"}
                className={"app ql-editor "}
              />
              {fromContactPage ||
              fromProjectPage ||
              fromCompanyViewPage ||
              (fromLeadPage &&
                handleButtonByRoleAccess(
                  "LEADS",
                  currentUser,
                  lead.salesOwner,
                  allDoers,
                  lead.techOwner
                )) ? (
                <div
                  style={{
                    width: "7%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {files.length > 0 ? (
                    <Tooltip placement="top" title="View documents">
                      <IconButton
                        // sx={{ backgroundColor: "red" }}
                        onClick={() => handleDocClickOpen(true)}
                      >
                        <FilePresentIcon
                          color="primary"
                          sx={{
                            fontSize: 30,
                            // marginRight: "28px",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleDocClickOpen()}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title="Uploads document">
                      <AttachFileIcon
                        color="primary"
                        sx={{
                          fontSize: 30,
                          // marginRight: "28px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDocClickOpen()}
                      />
                    </Tooltip>
                  )}
                </div>
              ) : null}
              {/* <label>Comment</label> */}
            </div>
            {/* <TextField
              fullWidth
              variant="outlined"
              placeholder="Comment"
              type="text"
              name="comment"
              required
              multiline
              value={comment.comment}
              onChange={handleChange}
            //onFocus={pageChange}
            /> */}
          </Grid>
        ) : (
          <Grid item xs={10} sm={10}>
            <TextField
              fullWidth
              //disabled={toEdit}
              //label="Name"
              variant="outlined"
              placeholder="Comment"
              type="text"
              name="comment"
              required
              multiline
              value={comment.comment}
              onChange={handleChange}
              //onFocus={pageChange}
            />
          </Grid>
        )
      ) : (
        <Grid item xs={10} sm={10}>
          <TextField
            fullWidth
            //disabled={toEdit}
            //label="Name"
            variant="outlined"
            placeholder="Comment"
            type="text"
            name="comment"
            required
            multiline
            value={comment.comment}
            onChange={handleChange}
            //onFocus={pageChange}
          />
        </Grid>
      )}

      <Grid
        item
        xs={1}
        sm={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {console.log("techOwner113", techOwner, currentUser.role)}
        {fromContactPage ? (
          handleButtonByRoleAccess(
            "CONTACTS",
            currentUser,
            contact.salesOwner,
            allDoers
          ) ? (
            <>
              <Button
                variant="contained"
                size="large"
                disabled={saveButton}
                onClick={() => {
                  AddComment(false);
                }}
                startIcon={<AddIcon />}
                sx={{ maxHeight: "45px" }}
              >
                Add
              </Button>
            </>
          ) : null
        ) : null}

        {fromLeadPage ? (
          handleButtonByRoleAccess(
            "LEADS",
            currentUser,
            lead.salesOwner,
            allDoers,
            lead.techOwner
          ) ? (
            <>
              <Button
                variant="contained"
                size="large"
                disabled={saveButton}
                onClick={() => {
                  AddComment(false);
                }}
                startIcon={<AddIcon />}
                sx={{ maxHeight: "45px", marginRight: 0, marginLeft: 0 }}
              >
                Add
              </Button>
            </>
          ) : null
        ) : null}

        {fromProjectPage ? (
          handleButtonByRoleAccess(
            "PROJECTS",
            currentUser,
            salesOwner,
            allDoers,
            techOwner
          ) ? (
            <>
              <Button
                variant="contained"
                size="large"
                disabled={saveButton}
                onClick={() => {
                  AddComment(false);
                }}
                startIcon={<AddIcon />}
                sx={{ maxHeight: "45px" }}
              >
                Add
              </Button>
            </>
          ) : null
        ) : null}

        {fromCompanyViewPage ? (
          <>
            <Button
              variant="contained"
              size="large"
              disabled={saveButton}
              onClick={() => {
                AddComment(false);
              }}
              startIcon={<AddIcon />}
              sx={{ maxHeight: "45px" }}
            >
              Add
            </Button>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
export default CreateComment;
